import React from 'react'
import TableProcedures from './TableProcedures'
import Options from './Options'

//images
import buildingIcon from '../../../../assets/img/pages/icons/building.png'

function Table(props) {

    const handleToggleRow = rowId => {
        let rows = document.querySelectorAll('tr')

        for (let i = 0; i < rows.length; i++) {
            if (rows[i].getAttribute('data-row') == rowId) {
                if (document.querySelector(`tr[data-row="${rowId}"]`).classList.contains('active')) {
                    document.querySelector(`tr[data-row="${rowId}"]`).classList.remove('active')
                } else rows[i].classList.add('active')
            }
        }

    }

    return (
        <table>
            <thead>
                <tr>

                    <th>
                        Обект
                    </th>
                    <th>
                        Адрес
                    </th>
                    <th>
                        Фирма
                    </th>
                    <th>
                        Собственик
                    </th>
                    <th>
                        Лице, извършващо дейност
                    </th>
                    <th className="options">Опции</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(p =>
                    <React.Fragment key={'p-' + p.group.id}>
                        <tr className={p.is_expiring ? 'expiring' : ''}>

                            <td onClick={() => handleToggleRow(p.group.id)}>
                                <img src={buildingIcon} alt="Building" />
                                {p.group.activity ? `${p.group.activity.name} - ` : ''}
                                {p.group.name}
                            </td>

                            <td onClick={() => handleToggleRow(p.group.id)}>
                                {p.group && p.group.town
                                    ? [
                                        p.group.town.name,
                                        p.group.street
                                            ? p.group.street.suff + ' ' + p.group.street.name
                                            : '',
                                        p.group.street_number ? p.group.street_number : '',
                                        p.group.block ? `бл. ${p.group.block}` : '',
                                        p.group.entrance ? `вх. ${p.group.entrance}` : '',
                                        p.group.floor ? `ет. ${p.group.floor}` : '',
                                        p.group.apartment ? `ап. ${p.group.apartment}` : '',
                                    ].filter(el => el !== '').join(', ')
                                    : ''}
                            </td>

                            <td onClick={() => handleToggleRow(p.group.id)}>
                                {p.company
                                    ?
                                    p.company.name
                                    :
                                    '-'
                                }
                            </td>

                            <td onClick={() => handleToggleRow(p.group.id)}>
                                {p.owner
                                    ?
                                    p.owner.name
                                    :
                                    '-'
                                }
                            </td>

                            <td onClick={() => handleToggleRow(p.group.id)}>
                                {p.worker
                                    ?
                                    p.worker.name
                                    :
                                    '-'
                                }
                            </td>

                            <td>
                                {p.changeable &&
                                    <Options
                                        id={p.categorization_procedure_id}
                                        hasClosingProcedure={p.has_closing_procedure}
                                        refreshData={props.refreshData}
                                    />
                                }
                            </td>
                        </tr>

                        <tr className="collapsable" data-row={p.group.id}>
                            {p.items.length > 0 ?
                                <td colSpan="10">
                                    <TableProcedures
                                        tabGroup={props.tabGroup}
                                        data={p.items}
                                        hasClosingProcedure={p.has_closing_procedure}
                                        refreshData={props.refreshData}
                                    />
                                </td>
                                :
                                <td className="no-procedures">
                                    Няма процедури.
                                </td>
                            }
                        </tr>

                    </React.Fragment>
                )}
            </tbody>
        </table>
    )
}

export default Table