import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import { Link } from 'react-router-dom'

//images
import menuIcon from '../../../../assets/img/pages/icons/table-menu.png'
import editIcon from '../../../../assets/img/pages/icons/edit.png'
import deleteIcon from '../../../../assets/img/pages/icons/delete.png'

function Table() {
    const [table, setTable] = useState({
        options: null,
    })

    const handleTableMenu = e => {
        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        })
    }

    const handleClose = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        })
    }

    return (
        <table>
            <thead>
                <tr>
                    <th>
                        <label className="checkbox">
                            <input autoComplete="off" role="presentation"  type="checkbox" />
                            <span className="checkmark"></span>
                        </label>
                    </th>
                    <th>Вх.№ / Дата</th>
                    <th>Фирма</th>
                    <th>Търговски обект</th>
                    <th>Адрес на обекта</th>
                    <th>Срок</th>
                    <th>Статус</th>
                    <th className="options">Опции</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <label className="checkbox">
                            <input autoComplete="off" role="presentation"  type="checkbox" />
                            <span className="checkmark"></span>
                        </label>
                    </td>
                    <td>91239123</td>
                    <td>Драган Драганович</td>
                    <td>Дюнерите</td>
                    <td>бл.Цариградско шосе 88</td>
                    <td>12.09.2020</td>
                    <td>В процес на завършване</td>
                    <td>
                        <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />
                        <Menu
                            id="simple-menu"
                            anchorEl={table.options}
                            keepMounted
                            open={Boolean(table.options)}
                            onClose={handleClose}
                        >
                            {/* Когато всички стъпки в оформление са завършение да стане на редактиране */}
                            <Link
                                to="/outlets/config"
                                className="menu-option">
                                <img src={editIcon} alt="edit" />
                                Оформление
                            </Link>
                            <div className="menu-option">
                                <img src={deleteIcon} alt="delete" />
                                Изтриване
                            </div>
                        </Menu>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

export default Table