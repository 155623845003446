import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';

//images
import successIcon from '../../../assets/img/modals/success.png'
import { useAuthContext } from '../../../contexts/AuthContext';

function SignCompleted(props, ref) {

    const auth = useAuthContext()

    const [success, setSuccess] = useState({
        overlay: false,
        modal: false,
        onClose: null,
    })

    const date = new Date();

    useImperativeHandle(ref, () => ({
        open: () => {
            setSuccess(success => ({
                ...success,
                overlay: true,
            }));
            setTimeout(() => {
                setSuccess(success => ({
                    ...success,
                    modal: true,
                }))
            }, 50);
        },
        close: () => {
            handleClose()
        },
        onClose: fn => {
            setSuccess(success => ({
                ...success,
                onClose: fn
            }))
        }
    }));

    const handleClose = e => {
        e.preventDefault();

        setSuccess(success => ({
            ...success,
            modal: false,
        }))

        setTimeout(() => {
            setSuccess(success => ({
                ...success,
                overlay: false,
            }))
        }, 100);

        if (typeof success.onClose === 'function') {
            success.onClose();
        }
    }

    return (
        <div className={`${success.overlay ? 'visible' : ''} overlay`}>
            <div className={`${success.modal ? 'show' : ''} box`}>
                <img src={successIcon} alt="success" />

                <p>
                    Информацията е потвърдена
                    <span>
                        на {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}г. {date.getHours()}:{date.getMinutes()} ч.
                    </span>
                    <span>
                        от {auth.name()} {auth.position()} {auth.department()}
                    </span>
                </p>

                <div className="buttons">
                    <button className="btn-primary" onClick={handleClose}>
                        Добре
                    </button>

                </div>
            </div>
        </div>
    )
}

export default forwardRef(SignCompleted);