import React, { useState, useEffect, useRef } from 'react'
import { useFieldsContext } from '../../contexts/FieldsContext';
import { FormControl, Select, MenuItem, Checkbox, Button } from '@material-ui/core';
import Api from '../../helpers/Api';
import SelectPosition from '../modals/SelectPosition'
import editIcon from '../../assets/img/pages/icons/edit.png'
import editIcon2 from '../../assets/img/pages/icons/edit-field.jpg'
import * as Constants from '../../helpers/Constants'

function Index(props) {

    const fieldsContext = useFieldsContext();

    const selectPositionModalRef = useRef(null);

    let editable = props.editable || true
    let inputName = props.name || 'signed_by';
    let inputPositionName;
    let selectName;
    let inputReasonName;

    if (inputName.includes(']')) {
        inputPositionName = inputName.replace(']', '_position_id]');
        selectName = inputName.replace(']', '_name]');
        inputReasonName = inputName.replace(']', '_reason]');
    } else {
        inputPositionName = inputName + '_position_id';
        selectName = inputName + '_name';
        inputReasonName = inputName + '_reason';
    }

    const [state, setState] = useState({
        names: [],
        refresh: false,
        open: false,
        selected: props.selected || null,
        reason: props.reason || null,
        editable: editable
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            selected: props.selected
        }))
    }, [props.selected]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            reason: props.reason
        }))
    }, [props.reason]);

    useEffect(() => {
        let url = 'officials/find';

        if (props.group_id) {
            url += '?group_id=' + props.group_id;
        }

        Api.get(url)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    names: res.data
                }))
            })
    }, [state.refresh]);

    const handleSelectClose = () => {
        setState(prev => ({
            ...prev,
            open: false
        }))
    }

    const handleSelectOpen = () => {
        setState(prev => ({
            ...prev,
            open: true
        }))
    }

    const handleSelectChange = e => {
        let id = e.target.value;

        if (id) {
            let person = state.names.filter(p => {
                return p.id == id;
            })[0];

            if (person) {

                setState(prev => ({
                    ...prev,
                    selected: person
                }))

                // handleShowPositions(person);

            }

            if (typeof props.handleModify === 'function') {
                props.handleModify();
            }
        }

    }

    const handleAdd = () => {
        const modal = fieldsContext.addOfficialPersonRef.current;

        handleSelectClose();

        modal.open();

        if (props.group_id) {
            modal.setSelectedGroups([props.group_id]);
        }

        modal.onSuccess(person => {

            modal.close();

            let inThatGroup = true;

            if (props.group_id) {
                inThatGroup = person.groups.filter(g => g.id == props.group_id).length;
            }

            let rows = state.names;

            if (inThatGroup) {
                rows = rows.concat(person);
            }

            setState(prev => ({
                ...prev,
                names: rows,
                selected: inThatGroup ? person : prev.selected,
                // refresh: new Date().getTime()
            }))

            // handleSelectOpen();

            if (typeof props.handleModify === 'function') {
                props.handleModify();
            }
        })
    }

    const handleShowPositions = person => {

        if (person._positions.length > 1) {
            let modal = selectPositionModalRef.current;

            let positionId;

            if (state.selected && person.id === state.selected.id) {
                positionId = state.selected.position ? state.selected.position.id : null;
            } else {
                positionId = person.position ? person.position.id : null
            }

            modal.open(person, positionId, person._positions);

            modal.onSelect(position => {
                person.position = position;

                setState(prev => ({
                    ...prev,
                    selected: person
                }));

                if (typeof props.handleModify === 'function') {
                    props.handleModify();
                }

                // handleSelectOpen();
            });
        }

    }

    const handleReason = e => {
        let val = e.target.value;

        setState(prev => ({
            ...prev,
            reason: val,
            editable: false
        }));
    }

    return (
        <>
            <SelectPosition
                ref={selectPositionModalRef}
            />

            <label>
                Подпис
            </label>

            <FormControl variant="outlined">
                <Select
                    open={state.open}
                    onOpen={handleSelectOpen}
                    onClose={handleSelectClose}
                    onChange={handleSelectChange}
                    value={state.selected ? state.selected.id : ''}
                    renderValue={() => {
                        return state.selected ?
                            <>
                                <p>{state.selected.name}</p>
                                <i>{state.selected.position.name}</i>
                            </>
                            : ''
                    }}
                    style={{ width: '250px' }}
                    name={selectName}
                >
                    {state.names.length === 0
                        ?
                        <p style={{ padding: '15px', fontSize: '13px' }}>Все още няма добавени служители</p>
                        :
                        state.names.map(name =>

                            <MenuItem
                                key={name.id}
                                value={name.id}

                            >
                                <Checkbox
                                    checked={state.selected && state.selected.id == name.id ? true : false}
                                    value={name.id}
                                />
                                <div>
                                    <p>{name.name}</p>
                                    {name.position && name.position.id &&
                                        <div className="sign-position" onClick={e => handleShowPositions(name)}>
                                            <i className={name._positions.length > 1 ? 'editable' : ''}>
                                                {state.selected && name.id === state.selected.id ? state.selected.position.name : name.position.name}
                                            </i>
                                            {name._positions.length > 1 &&
                                                <span>
                                                    <img src={editIcon} />
                                                </span>
                                            }
                                        </div>
                                    }
                                </div>
                            </MenuItem>

                        )}

                    <Button
                        className="btn-add full-width"
                        onClick={handleAdd}
                    >
                        Добави
                    </Button>

                </Select>
            </FormControl>

            <input
                type="hidden"
                name={inputName}
                value={state.selected ? state.selected.id : ''}
            />

            <input
                type="hidden"
                name={inputPositionName}
                value={state.selected && state.selected.position ? state.selected.position.id : ''}
            />

            {props.group_id === Constants.MAYORS && state.selected && !state.selected.mayor &&
                <>
                    <br />

                    <div className="col able-to-edit">
                        <label>
                            Основание
                        </label>
                        {state.editable && state.reason && <img
                            src={editIcon2}
                            alt="edit"
                            className="edit"
                            onClick={e => fieldsContext.handleEdit(e)}
                        />}
                        <input
                            type="text"
                            name={inputReasonName}
                            value={state.reason}
                            onChange={handleReason}
                            readOnly={state.editable && state.reason ? true : false}
                        />
                    </div>
                </>
            }
        </>
    )
}

export default Index