import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Api from '../../../../helpers/Api'

//partials
import Steps from './steps/Nav'
import Application from './steps/application/Index'
import Permission from './steps/permission/Index'

//images
import protocolIcon from '../../../../assets/img/pages/icons/protocol.png'

function Config() {

    const params = useParams()

    // id на процедурата
    const id = params.id

    // Стейта на навигацията е вдигнат едно ниво нагоре, за да може лесно да се променя
    const [config, setConfig] = useState({
        step: null,
        firstStepFinish: false,
        secondStepFinish: false,
    })

    const [procedure, setProcedure] = useState({
        data: null,
        original: null,
        loading: true,
        refresh: false,
        initial: true,
    })

    useEffect(() => loadProcedure(), [procedure.refresh])

    const handleCurrent = current => {
        let currentStep
        currentStep = current

        setConfig(config => ({
            ...config,
            step: currentStep,
        }))
    }

    //steps finish
    const handleFinish = (position, isFinished) => {
        if (position == 1) {
            setConfig(config => ({
                ...config,
                firstStepFinish: isFinished,
            }))
        } else if (position == 2) {
            setConfig(config => ({
                ...config,
                firstStepFinish: true,
                secondStepFinish: isFinished,
            }))
        }
    }

    const loadProcedure = () => {
        Api.get(`/procedures/tradeobjects/show/${id}`)
            .then(res => {

                if (procedure.initial) {
                    setConfig(config => ({
                        ...config,
                        step: res.data.actual_phase_id == 0 ? '2' : res.data.actual_phase_id
                    }))
                }

                if (res.data.actual_phase_id >= 2) handleFinish(1, true)
                else if (res.data.actual_phase_id == 0) handleFinish(2, true)

                setProcedure(prev => ({
                    ...prev,
                    data: res.data,
                    original: res.data,
                    loading: false,
                    initial: false
                }))


            }).catch(err => {
                setProcedure(prev => ({
                    ...prev,
                    data: null,
                    original: null,
                    loading: false
                }))
            })
    }

    const setData = data => {
        setProcedure(prev => ({
            ...prev,
            data: data
        }))
    }

    const setOriginal = data => {
        setProcedure(prev => ({
            ...prev,
            data: data,
            original: data
        }))

        if (data.actual_phase_id == 2) handleFinish(1, true)
        else if (data.actual_phase_id == 3) handleFinish(2, false)
        else if (data.actual_phase_id == 0) handleFinish(2, true)

    }

    const refreshData = () => {
        setProcedure(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }))
    }

    return (
        <section id="outlets-config">
            {!procedure.loading &&
                <div className="wrapper top negative-margin">
                    <Steps
                        handleCurrent={handleCurrent}
                        getCurrent={config.step}
                        firstStepFinish={config.firstStepFinish}
                        secondStepFinish={config.secondStepFinish}
                        thirdStepFinish={config.thirdStepFinish}
                        fourthStepFinish={config.fourthStepFinish}
                    />
                    {config.step == 1 ?
                        <Application
                            loading={procedure.loading}
                            data={procedure.data}
                            original={procedure.original}
                            editable={true}
                            editableObject={procedure.data.subtype_id == 1 ? true : false}
                            setData={setData}
                            setOriginal={setOriginal}
                            refreshData={refreshData}
                            handleCurrent={handleCurrent}
                            handleFinish={handleFinish}
                        />
                        :
                        <Permission
                            loading={procedure.loading}
                            data={procedure.data}
                            original={procedure.original}
                            editable={true}
                            setData={setData}
                            setOriginal={setOriginal}
                            refreshData={refreshData}
                            handleCurrent={handleCurrent}
                            handleFinish={handleFinish}
                        />
                    }

                </div>
            }
        </section>
    )
}

export default Config