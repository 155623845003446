import React from 'react'
import { useHistory } from 'react-router-dom'

//images
import procedureIcon from '../../../../assets/img/pages/icons/procedure.png'
import ProcedureOptions from '../../../partials/ProcedureOptions';

function TableProcedures(props) {

    const history = useHistory();

    const handleEdit = e => {
        let currentUrl = e.target.parentElement.getAttribute('data-url')

        if (currentUrl) {
            history.push(currentUrl)
        }
    }

    return (
        props.data ?
            <table>
                <thead>
                    <tr>
                        <th>Вх. № / Дата</th>
                        <th>Лиценз № / Дата</th>
                        <th>Вид процедура</th>
                        {/* <th>Фирма</th>
                        <th>Адрес</th> */}


                        <th>
                            Дата на изтичане
                        </th>


                        <th>Статус</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(p =>

                        <tr key={'p-' + p.id} onClick={handleEdit} data-url={
                            p.subtype_id == 3 ?
                                'temporary-shops/close/edit/' + p.type.relation + '/' + p.id
                                :
                                p.canceled
                                    ? '/temporary-shops/cancel/edit/' + p.type.relation + '/' + p.id
                                    : '/temporary-shops/config/' + p.id

                        }>
                            <td>
                                <img src={procedureIcon} alt="procedure" />
                                {p.request_no && p.request_date_dmy ?
                                    <>
                                        №{p.request_no}/{p.request_date_dmy}
                                    </>
                                    :
                                    '-'
                                }
                            </td>
                            <td>

                                {p.license_no && p.license_date_dmy ?
                                    <>
                                        №{p.license_no}/{p.license_date_dmy}
                                    </>
                                    :
                                    '-'
                                }
                            </td>
                            <td>
                                {p.name}
                            </td>
                            {/* <td>
                                {p.company && p.company.name ?
                                    p.company.name
                                    :
                                    '-'
                                }
                            </td>
                            <td>
                                {p.company
                                    ? [
                                        p.company.town && p.company.town.name,
                                        p.company.street ? `${p.company.street.suff} ${p.company.street.name} ${p.company.street_number ? p.company.street_number : ''}` : ''
                                    ].join(', ')
                                    : '-'}
                            </td> */}

                            <td>
                                {p.license_period_to_dmy || '-'}
                            </td>

                            <td className={`current-step ${p.actual_phase_id == 0 ? 'finished-steps' : ''} ${p.canceled ? 'canceled' : ''}`}>
                                {p.actual_phase_id != 0 ? `${p.actual_phase_id}.` : ''} {p.actual_phase_id == 0 && !p.canceled ? '' : p.phase}
                            </td>

                            <td>
                                {p.subtype_id === 1 &&
                                    <ProcedureOptions
                                        id={p.id}
                                        procedureId={p.procedure_id}
                                        type={p.type.relation}
                                        typeId={p.type_id}
                                        phaseId={p.actual_phase_id}
                                        closeable={p.closeable}
                                        deletable={p.deletable}
                                        cancelable={p.cancelable}
                                        refreshData={props.refreshData}
                                    />
                                }
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            : ''
    )
}

export default TableProcedures;
