import React, { useEffect, useState, useRef } from 'react'
import { Texts } from '../../helpers/Texts'

//images
import pdfIcon from '../../assets/img/pages/icons/pdf.png'
import Api from '../../helpers/Api'
import loader from '../../assets/img/app/loader.svg'

//modals
import AttentionModal from '../modals/messages/Attention'
import QuestionModal from '../modals/messages/Question'

function FilesPanel(props) {

    const allowEdit = Boolean(props.allowEdit)
    const allowUpload = Boolean(props.allowUpload);

    //refs
    const attentionModalRef = useRef(null)
    const questionModalRef = useRef(null)

    const [files, setFiles] = useState({
        data: [],
        refresh: false,
        signed: props.uploadedDocument,
        loadingGenerate: false,
        loadingUpload: false,
    })

    useEffect(() => {
        if (props.id && props.type) {
            Api.get('procedures/documents', {
                params: {
                    id: props.id,
                    type: props.type
                }
            }).then(res => {
                setFiles(prev => ({
                    ...prev,
                    data: res.data
                }))
            })
        }

    }, [files.refresh])

    const handleGenerate = () => {

        if (!props.generateFile) {
            attentionModalRef.current.open()
            return
        }

        setFiles(prev => ({
            ...prev,
            loadingGenerate: true
        }))

        Api.post('procedures/generate-document', {
            id: props.id,
            type: props.type
        }).then(res => {

            setFiles(prev => ({
                ...prev,
                refresh: new Date().getTime(),
                loadingGenerate: false,
            }))

            if (typeof props.refreshData === 'function') {
                props.refreshData()
                window.open(res.data.url, '_blank')
            }
        })
    }

    const handleUpload = e => {
        if (e.target.files.length === 0) return

        let fd = new FormData()

        setFiles(prev => ({
            ...prev,
            loadingUpload: true
        }))

        fd.append('id', props.id)
        fd.append('type', props.type)
        fd.append('file', e.target.files[0])

        Api.post('procedures/upload-document', fd, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            setFiles(prev => ({
                ...prev,
                refresh: new Date().getTime(),
                loadingUpload: false,
            }))

            if (typeof props.refreshData === 'function') {
                props.refreshData()
            }
        })
    }

    const handleAskIfSigned = () => {
        if (!files.signed) questionModalRef.current.open()
    }

    const handleSigned = () => {
        Api.post('procedures/mark-as-upload', {
            id: props.id,
            type: props.type
        }).then(() => {
            if (typeof props.refreshData === 'function') {
                props.refreshData()
            }
        })

        setFiles(files => ({ ...files, signed: true }))
    }

    return (
        <div className="files-panel">
            <AttentionModal
                ref={attentionModalRef}
                mainMessage='Моля, попълнете всички полета!'
                secondaryMessage='За да генерирате документа, трябва всички полета да са попълнени.'
            />
            <QuestionModal
                ref={questionModalRef}
                mainMessage='Потвърждавате ли, че документа е подписан?'
                secondaryMessage='След потвърждаване на подписа ще може да продължите напред.'
                onSuccess={handleSigned}
                cancelButtonText='Отказ'
                continueButtonText='Потвърждавам'
            />
            <h2>
                Документи
            </h2>
            {props.type !== 'request' ?
                <>
                    <h3>
                        {Texts.filesPanel[props.type].generate}
                    </h3>
                    <div className="holder">
                        {files.data && files.data.generated && files.data.generated.length === 0
                            ?
                            <p className="centered">
                                {allowEdit
                                    ?
                                    props.generateFile
                                        ? 'Натиснете бутона Генериране, за да създадете документ'
                                        : 'Моля, попълнете данните, за да генерирате документ'
                                    : 'Все още няма генериран документ'
                                }
                            </p>
                            :
                            <ul className="uploaded-files col">
                                {files.data && files.data.generated && files.data.generated.map(file =>
                                    <li key={'file-' + file.id}>
                                        <span>
                                            {file.date}
                                        </span>
                                        <img src={pdfIcon} alt="pdf" />
                                        <a href={file.url} className={[file.is_deleted ? 'removed' : '', 'underline'].join(' ')} target="_blank">
                                            {file.display_name}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        }
                        {allowEdit && props.generateFile && <hr />}

                        {allowEdit &&
                            <div className="buttons">
                                {files.loadingGenerate ?
                                    <img src={loader} alt="loading" />
                                    :
                                    props.generateFile && <button className="btn-upload-file btn-generate" onClick={handleGenerate}>
                                        Генериране
                                </button>
                                }

                            </div>
                        }
                    </div>
                </>
                :
                ''
            }
            {allowEdit && (allowUpload || (files.data && files.data.generated && files.data.generated.length > 0)) ?
                <>
                    <h3>
                        Отблеязване на документа като подписан
                    </h3>
                    <div className="holder">
                        <div className="extra-holder">
                            <label className="checkbox">
                                Документа е подписан
                                <input autoComplete="off" role="presentation"
                                    type="checkbox"
                                    name="signed"
                                    onChange={handleAskIfSigned}
                                    checked={files && files.signed ? true : false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <h3>
                        {Texts.filesPanel[props.type].upload}
                    </h3>
                    <div className="holder">
                        {files.data && files.data.signed && files.data.signed.length === 0
                            ?
                            <p className="centered">
                                Все още няма прикачени файлове
                            </p>
                            :
                            <ul className="uploaded-files col">
                                {files.data && files.data.signed && files.data.signed.map(file =>
                                    <li key={'signed-' + file.id}>
                                        <span>
                                            {file.date}
                                        </span>
                                        <img src={pdfIcon} alt="pdf" />
                                        <a href={file.url} className={[file.is_deleted ? 'removed' : '', 'underline'].join(' ')} target="_blank">
                                            {file.display_name}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        }
                        <hr />
                        <div className="buttons">
                            {files.loadingUpload ?
                                <img src={loader} alt="loading" />
                                :
                                <button className="btn-upload-file">
                                    Прикачи нов
                                    <input autoComplete="off" role="presentation" type="file" accept="application/pdf" onChange={handleUpload} />
                                </button>
                            }
                        </div>
                    </div>
                </>
                :
                ''
            }
        </div>
    )
}

export default FilesPanel