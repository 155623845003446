import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { daysOfWeek } from '../../helpers/Days';

function FreeDays(props, ref) {
    const [state, setState] = useState({
        overlay: false,
        modal: false,
        selected: {},
        modified: false,
    })

    useEffect(() => {
        let s = state.selected;

        if (props.selected) {
            props.selected.map(e => {
                s[e] = 1;
            });

            setState(prev => ({
                ...prev,
                selected: s

            }));
        }

    }, [props]);

    useImperativeHandle(ref, () => ({
        open: () => {
            setState(prev => ({
                ...prev,
                overlay: true,
            }))

            setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    modal: true,
                }))
            }, 50)
        },
        close: () => {
            handleClose()
        }
    }))

    const handleClose = e => {
        e.preventDefault()

        setState(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
                modified: false,
            }))
        }, 100)
    }

    const handleChange = e => {

        let id = Number(e.target.getAttribute('data-id'));
        let selected = state.selected;

        if (selected[id]) {
            delete selected[id];
        } else {
            selected[id] = 1;
        }

        setState(prev => ({
            ...prev,
            selected: selected,
            modified: true,
        }));


        if (typeof props.callback === 'function') {
            props.callback(Object.keys(selected).map(d => Number(d)));
        }

    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`${state.modal ? 'show' : ''} form-box free-days`}>
                <h2>
                    Избиране на дни
               </h2>
                <p>
                    Изберете почивни дни чрез маркиране
                </p>
                <hr />
                <div className="scroller">
                    {daysOfWeek.map(d =>
                        <div key={d.id} className="row">
                            <label className="checkbox">
                                {d.name}
                                <input autoComplete="off" role="presentation"
                                    type="checkbox"
                                    name={'free_days[' + d.id + ']'}
                                    data-id={d.id}
                                    checked={state.selected[d.id] ? true : false}
                                    onClick={handleChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    )}
                </div>

                {Object.values(state.selected).length === 0 &&
                    <input type="hidden" name="free_days" value="0" />
                }

                <hr />
                <div className="buttons">
                    <button className={`btn-default ${state.modified ? 'btn-primary' : ''}`} onClick={handleClose}>
                        {state.modified ?
                            'Готово'
                            :
                            'Затвори'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(FreeDays)