import React, { createContext, useContext, useState, useMemo } from "react";
import Api from "../helpers/Api";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext({});

const Auth = props => {
    const history = useHistory()

    const [authData, setAuthData] = useState({
        isLogged: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).isLogged : false,
        user: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).user : {},
    })

    const login = (data, logged) => {

        let state = {
            isLogged: logged ? true : false,
            user: data
        }

        localStorage.setItem('auth', JSON.stringify(state))
        setAuthData(state)
    }

    const refresh = (tries = 1) => {
        Api.get('auth/profile')
            .then(res => {
                let state = authData.user;

                state.user = res.data

                login(state, true)
            }).catch(err => {
                if (!err.response || err.response.status == 500 || err.response.status == 401) {
                    if (tries === 1) {
                        refresh(2);
                    } else {
                        logout()
                    }
                }
            })
    }

    const logout = () => {
        localStorage.clear();
        window.location = '/login'
    }

    const id = () => {
        if (authData.user && authData.user.user) {
            return Number(authData.user.user.id);
        }
        return null;
    }

    const name = () => {
        if (authData.user && authData.user.user) {
            return authData.user.user.name;
        }
        return null;
    }

    const department = () => {
        if (authData.user && authData.user.user && authData.user.user.department) {
            return authData.user.user.department.name;
        }
        return null;
    }

    const position = () => {
        if (authData.user && authData.user.user && authData.user.user.position) {
            return authData.user.user.position.name;
        }
        return null;
    }

    const isAdmin = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.is_admin);
        }
        return false;
    }

    const isSuperAdmin = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.superadmin);
        }
        return false;
    }

    const canViewTradeObjects = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.can_view_trade_objects);
        }
        return false;
    }

    const canViewTemporaryShops = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.can_view_temporary_shops);
        }
        return false;
    }

    const canViewMobileFacilities = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.can_view_mobile_facilities);
        }
        return false;
    }

    const canViewCategorizations = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.can_view_categorizations);
        }
        return false;
    }

    const canViewObligations = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.can_view_obligations);
        }
        return false;
    }

    const canWriteRequest = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.can_write_request);
        }
        return false;
    }

    const canWriteDocuments = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.can_write_documents);
        }
        return false;
    }

    const canViewExpiringLicenses = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.can_view_expiring_licenses);
        }
        return false;
    }

    const canManageUsers = () => {
        if (authData.user && authData.user.user) {
            return Boolean(authData.user.user.can_manage_users);
        }
        return false;
    }

    useMemo(() => {
        if (authData.isLogged) {
            refresh()
        }
    }, [])

    const auth = useMemo(() => ({
        ...authData,
        logout,
        login,
        refresh,
        id,
        name,
        department,
        position,
        isAdmin,
        isSuperAdmin,
        canViewTradeObjects,
        canViewTemporaryShops,
        canViewMobileFacilities,
        canViewCategorizations,
        canViewObligations,
        canWriteRequest,
        canWriteDocuments,
        canViewExpiringLicenses,
        canManageUsers
    }), [authData]);

    return <AuthContext.Provider value={auth} {...props} />;
}

export const useAuthContext = () => useContext(AuthContext);

export default Auth;