import React, { forwardRef, useRef, useEffect, useState } from 'react'

//context
import { useStepsContext } from '../../../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

import Dropdown from '../../../../../../app/Dropdown';

import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import Select from '../../../../../../app/Select';

function BuildingData(props, ref) {

    const fieldsContext = useFieldsContext();

    const data = props.data;
    const original = props.original;
    const fields = props.fields;
    const setFields = props.setFields;
    const editable = props.editable || false;
    const handleFieldChange = props.handleFieldChange;

    const fieldsData = {
        object: {},
    }

    // const [fields, setFields] = useState(fieldsData)

    // useEffect(() => {
    //     if (original) {
    //         setFields(original)
    //     }
    // }, [original])


    // const handleFieldChange = e => {

    //     let isObj = Boolean(e.target.name.includes('['))
    //     let name = e.target.name
    //     let val = e.target.value

    //     if (isObj) {
    //         let nameFirstPart = name.split('[')[0]
    //         let nameSecondPart = name.split('[')[1].split(']')[0]

    //         setFields(prev => ({
    //             ...prev,
    //             [nameFirstPart]: {
    //                 ...prev[nameFirstPart],
    //                 [nameSecondPart]: val,
    //             }
    //         }))
    //     } else {
    //         setFields(prev => ({
    //             ...prev,
    //             [name]: val
    //         }))
    //     }

    // }

    const handleChekboxChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = Number(e.target.value)

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }
    }

    const objectMunicipalityRef = useRef(null);
    const objectTownRef = useRef(null);
    const objectRegionRef = useRef(null);
    const objectStreetRef = useRef(null);

    // dropdown change
    const handleObjectChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                object: newData
            }))
        }
    }

    const handleObjectCountryChange = newData => {
        objectMunicipalityRef.current.reset()
        objectTownRef.current.reset();
        objectRegionRef.current.reset();
        objectStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                country_id: newData.id,
                country: newData,
            }
        }))
    }

    const handleObjectMunicipalityChange = newData => {
        objectTownRef.current.reset();
        objectRegionRef.current.reset();
        objectStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                municipality_id: newData.id,
                municipality: newData,
            }
        }))
    }

    const handleObjectTownChange = newData => {
        objectStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                town_id: newData.id,
                town: newData,
            }
        }))
    }

    return (
        <div className="panel" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            1
                        </span>
                        Наименование на обекта
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_name">
                                    Наименование на обекта
                                </label>
                                {editable && original && original.object && original.object.name && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation" 
                                    id="object_name"
                                    name="object[name]"
                                    type="text"
                                    placeholder="Въведете име"
                                    value={fields && fields.object ? fields.object.name : ''}
                                    readOnly={editable && original && original.object && original.object.name ? true : false}
                                    onChange={handleFieldChange}
                                />

                                <input autoComplete="off" role="presentation"  type="hidden" name="object[id]" value={
                                    fields.object.id ? fields.object.id : ''
                                } />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Тип място за настаняване по класове
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_class">
                                    Клас
                                </label>
                                <select
                                    id="object_class"
                                    name="object[class]"
                                    value={fields && fields.object.class ? fields.object.class : ''}
                                    onChange={handleFieldChange}
                                >
                                    <option disabled selected>
                                        Избор
                                    </option>
                                    <option value="a">
                                        Клас "А"
                                    </option>
                                    <option value="b">
                                        Клас "Б"
                                    </option>
                                </select>
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_activity_id">
                                    Категория
                                </label>
                                {editable && original.object.activity_id && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <select
                                    id="object_activity_id"
                                    name="object[activity_id]"
                                    value={fields.object.activity_id ? fields.object.activity_id : ''}
                                    readOnly={editable && original.object.activity_id ? true : false}
                                    onChange={handleFieldChange}
                                >

                                    <option disabled selected>
                                        Избор
                                    </option>

                                    {!fields.object.class || fields.object.class === 'a'
                                        ?
                                        <>
                                            <option value="28">
                                                Хотел
                                            </option>
                                            <option value="47">
                                                Мотел
                                            </option>
                                            <option value="48">
                                                Апартаментен туристически комплекс
                                            </option>
                                            <option value="49">
                                                Вилно селище
                                            </option>
                                            <option value="50">
                                                Туристическо селище
                                            </option>
                                            <option value="51">
                                                Вила
                                            </option>
                                        </>
                                        :
                                        <>
                                            <option value="52">
                                                Семеен хотел
                                            </option>
                                            <option value="53">
                                                Хостел
                                            </option>
                                            <option value="54">
                                                Пансион
                                            </option>
                                            <option value="55">
                                                Почивна станция
                                            </option>
                                            <option value="56">
                                                Къща за гости
                                            </option>
                                            <option value="57">
                                                Стая за гости
                                            </option>
                                            <option value="58">
                                                Апартамент за гости
                                            </option>
                                            <option value="59">
                                                Бунгало
                                            </option>
                                            <option value="60">
                                                Къмпинг
                                            </option>
                                        </>
                                    }
                                </select>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Капацитет на обекта
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_beds">
                                    Брой легла
                                </label>
                                {editable && original.object.beds != null && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation" 
                                    id="object_beds"
                                    type="number"
                                    className="field-small"
                                    name="object[beds]"
                                    value={fields.object.beds != null ? fields.object.beds : ''}
                                    readOnly={editable && original.object && original.object.beds != null ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_rooms">
                                    Брой стаи
                                </label>
                                <div className="extra-holder">
                                    {editable && original.object.rooms != null && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation" 
                                        id="object_rooms"
                                        type="number"
                                        className="field-small"
                                        name="object[rooms]"
                                        value={fields.object.rooms != null ? fields.object.rooms : ''}
                                        readOnly={editable && original.object && original.object.rooms != null ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                    <span className="extra-label">
                                        От които:
                                    </span>
                                    <input autoComplete="off" role="presentation"  type="hidden" />
                                </div>
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_rooms_1">
                                    С едно легло
                                </label>
                                {editable && original.object.rooms_1 != null && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation" 
                                    type="number"
                                    className="field-small"
                                    name="object[rooms_1]"
                                    value={fields.object.rooms_1 != null ? fields.object.rooms_1 : ''}
                                    readOnly={editable && original.object && original.object.rooms_1 != null ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_rooms_2">
                                    С две легла
                                </label>
                                {editable && original.object.rooms_2 != null && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation" 
                                    type="number"
                                    className="field-small"
                                    name="object[rooms_2]"
                                    value={fields.object.rooms_2 != null ? fields.object.rooms_2 : ''}
                                    readOnly={editable && original.object && original.object.rooms_2 != null ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_rooms_3">
                                    С три легла
                                </label>
                                {editable && original.object.rooms_3 != null && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation" 
                                    type="number"
                                    className="field-small"
                                    name="object[rooms_3]"
                                    value={fields.object.rooms_3 != null ? fields.object.rooms_3 : ''}
                                    readOnly={editable && original.object && original.object.rooms_3 != null ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_rooms_4">
                                    С четири легла
                                </label>
                                {editable && original.object.rooms_4 != null && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation" 
                                    type="number"
                                    className="field-small"
                                    name="object[rooms_4]"
                                    value={fields.object.rooms_4 != null ? fields.object.rooms_4 : ''}
                                    readOnly={editable && original.object && original.object.rooms_4 != null ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_apartments">
                                    Брой апартаменти
                                </label>
                                {editable && original.object.apartments != null && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation" 
                                    type="number"
                                    className="field-small"
                                    name="object[apartments]"
                                    value={fields.object.apartments != null ? fields.object.apartments : ''}
                                    readOnly={editable && original.object && original.object.apartments != null ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            4
                        </span>
                        Заведения за хранене и развлечения в обекта
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <label className="checkbox">
                                Не
                                <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="object[has_restaurants]"
                                    value="0"
                                    checked={fields.object.has_restaurants == 0 ? true : false}
                                    onChange={handleChekboxChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="row">
                            <label className="checkbox">
                                Да
                                <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="object[has_restaurants]"
                                    value="1"
                                    checked={fields.object.has_restaurants == 1 ? true : false}
                                    onChange={handleChekboxChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        {fields.object.has_restaurants == 1 &&
                            <div className="row for-choice">
                                <div className="col col-auto able-to-edit">
                                    <label htmlFor="object_restaurants_count">
                                        Брой
                                    </label>
                                    {editable && original.object.restaurants_count && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation" 
                                        id="object_restaurants_count"
                                        type="number"
                                        className="field-small"
                                        name="object[restaurants_count]"
                                        value={fields.object.restaurants_count ? fields.object.restaurants_count : ''}
                                        readOnly={editable && original.object && original.object.restaurants_count ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="object_restaurants_type">
                                        Вид
                                    </label>
                                    {editable && original.object.restaurants_type && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation" 
                                        id="object_restaurants_type"
                                        type="text"
                                        // className="field-small"
                                        name="object[restaurants_type]"
                                        value={fields.object.restaurants_type ? fields.object.restaurants_type : ''}
                                        readOnly={editable && original.object && original.object.restaurants_type ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="col ">
                    <h3>
                        <span>
                            5
                        </span>
                        Период на експлоатация
                    </h3>
                    <div className="holder">
                        <div className="col able-to-edit">
                            <label htmlFor="object_work_period_id">
                                Период
                                    </label>
                            {editable && original && original.object && original.object.work_period_id && <img
                                src={editIcon}
                                alt="edit"
                                className="edit"
                                onClick={e => fieldsContext.handleEdit(e)}
                            />}
                            <select
                                id="object_work_period_id"
                                name="object[work_period_id]"
                                value={fields && fields.object && fields.object.work_period_id ? fields.object.work_period_id : ''}
                                readOnly={editable && original && original.object && original.object.work_period_id ? true : false}
                                onChange={handleFieldChange}
                            >
                                <option disabled>
                                    Избор
                                        </option>
                                <option value="1">
                                    Целогодишно
                                        </option>
                                <option value="2">
                                    Сезонно
                                        </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            6
                        </span>
                        Категория, която се заявява
                    </h3>
                    <div className="holder">
                        <div className="col able-to-edit">
                            <label htmlFor="object_category_id">
                                Брой звезди
                            </label>
                            {editable && original && original.object && original.object.category_id && <img
                                src={editIcon}
                                alt="edit"
                                className="edit"
                                onClick={e => fieldsContext.handleEdit(e)}
                            />}
                            <select
                                id="object_category_id"
                                name="object[category_id]"
                                value={fields && fields.object && fields.object.category_id ? fields.object.category_id : ''}
                                readOnly={editable && original && original.object && original.object.category_id ? true : false}
                                onChange={handleFieldChange}
                            >
                                <option disabled>
                                    Избор
                                </option>
                                <option value="1">
                                    Една звезда
                                </option>
                                <option value="2">
                                    Две звезди
                                </option>
                                <option value="3">
                                    Три звезди
                                </option>
                                <option value="4">
                                    Четири звезди
                                </option>
                                <option value="5">
                                    Пет звезди
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            7
                        </span>
                                Настояща категория
                    </h3>
                    <div className="holder">
                        <div className="col able-to-edit">
                            <label htmlFor="object_current_category_id">
                                Брой звезди
                            </label>
                            {editable && original && original.object && original.object.current_category_id != null && <img
                                src={editIcon}
                                alt="edit"
                                className="edit"
                                onClick={e => fieldsContext.handleEdit(e)}
                            />}
                            <select
                                id="object_current_category_id"
                                name="object[current_category_id]"
                                value={fields.object.current_category_id != null ? fields.object.current_category_id : ''}
                                readOnly={editable && original.object.current_category_id != null ? true : false}
                                onChange={handleFieldChange}
                            >
                                <option disabled>
                                    Избор
                                </option>
                                <option value="0">
                                    Няма
                                </option>
                                <option value="1">
                                    Една звезда
                                        </option>
                                <option value="2">
                                    Две звезди
                                        </option>
                                <option value="3">
                                    Три звезди
                                        </option>
                                <option value="4">
                                    Четири звезди
                                        </option>
                                <option value="5">
                                    Пет звезди
                                </option>
                            </select>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            8
                        </span>
                        Адрес на обекта
                    </h3>

                    <div className="holder">
                        <div className="row x2">

                            <div className="col">

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_country">
                                            Държава
                                        </label>
                                        {editable && original && original.object && original.object.country && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            inputName="object[country]"
                                            inputValue={fields && fields.object && fields.object.country ? fields.object.country.name : 'България'}
                                            inputIdName="object[country_id]"
                                            inputIdValue={fields && fields.object && fields.object.country_id ? fields.object.country_id : 1}
                                            inputPlaceholder="Изберете държава"
                                            inputDisabled={editable && original && original.object && original.object.country ? true : false}
                                            url="countries/find"
                                            onChange={handleObjectCountryChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_municipality">
                                            Община
                                        </label>
                                        {editable && original && original.object && original.object.municipality && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={objectMunicipalityRef}
                                            inputName="object[municipality]"
                                            inputValue={fields && fields.object && fields.object.municipality ? fields.object.municipality.name : ''}
                                            inputIdName="object[municipality_id]"
                                            inputIdValue={fields && fields.object && fields.object.municipality_id}
                                            inputPlaceholder="Изберете община"
                                            inputDisabled={editable && original && original.object && original.object.municipality ? true : false}
                                            url="municipalities/find"
                                            params={{
                                                country_id: fields && fields.object && fields.object.country_id
                                            }}
                                            onChange={handleObjectMunicipalityChange}
                                        />
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_town">
                                            Населено място
                                        </label>
                                        {editable && original && original.object && original.object.town && original.object.town && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={objectTownRef}
                                            inputName="object[town]"
                                            inputValue={fields && fields.object && fields.object.town
                                                ? fields.object.town.id
                                                    ? `${fields.object.town.kind} ${fields.object.town.name}`
                                                    : fields.object.town.name
                                                : ''}
                                            inputIdName="object[town_id]"
                                            inputIdValue={fields && fields.object && fields.object.town_id}
                                            inputPlaceholder="Изберете населено място"
                                            inputDisabled={editable && original && original.object && original.object.town && original.object.town.id ? true : false}
                                            url="towns/find"
                                            params={{
                                                munic_id: fields && fields.object && fields.object.municipality && fields.object.municipality.id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.kind} {data.name}</>;
                                            }}
                                            onChange={handleObjectTownChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_postcode">
                                            Пощенски код
                                        </label>
                                        {editable && original && original.object && original.object.postcode && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="object_postcode"
                                            name="object[postcode]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.postcode ? fields.object.postcode : ''}
                                            readOnly={editable && original && original.object && original.object.postcode ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_region">
                                            Район/квартал
                                        </label>
                                        {editable && original && original.object && original.object.region && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={objectRegionRef}
                                            inputName="object[region]"
                                            inputValue={fields && fields.object && fields.object.region ? fields.object.region.name : ''}
                                            inputIdName="object[region_id]"
                                            inputIdValue={fields && fields.object && fields.object.region_id}
                                            inputPlaceholder="Изберете район/квартал"
                                            inputDisabled={editable && original && original.object && original.object.region ? true : false}
                                            url="regions/find"
                                            params={{
                                                town_id: fields && fields.object && fields.object.town_id
                                            }}

                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_street">
                                            Булевард/площад/улица
                                        </label>
                                        {editable && original && original.object && original.object.street && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={objectStreetRef}
                                            inputName="object[street]"
                                            inputValue={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                            inputIdName="object[street_id]"
                                            inputIdValue={fields && fields.object && fields.object.street_id}
                                            inputPlaceholder="Изберете улица"
                                            inputDisabled={editable && original && original.object && original.object.street ? true : false}
                                            url="streets/find"
                                            params={{
                                                town_id: fields && fields.object && fields.object.town_id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.suff} {data.name}</>;
                                            }}
                                        />
                                    </div>

                                </div>

                            </div>

                            <div className="col">

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_phone">
                                            Тел. номер
                                        </label>
                                        {editable && original && original.object && original.object.phone && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="object_phone"
                                            name="object[phone]"
                                            type="text"
                                            placeholder="Въведете тел. номер"
                                            value={fields && fields.object && fields.object.phone ? fields.object.phone : ''}
                                            readOnly={editable && original && original.object && original.object.phone ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_fax">
                                            Факс
                                        </label>
                                        {editable && original && original.object && original.object.fax && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="object_fax"
                                            name="object[fax]"
                                            type="text"
                                            placeholder="Въведете факс"
                                            value={fields && fields.object && fields.object.fax ? fields.object.fax : ''}
                                            readOnly={editable && original && original.object && original.object.fax ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_email">
                                            Е-поща
                                        </label>
                                        {editable && original && original.object && original.object.email && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="object_email"
                                            name="object[email]"
                                            type="text"
                                            placeholder="Въведете е-поща"
                                            value={fields && fields.object && fields.object.email ? fields.object.email : ''}
                                            readOnly={editable && original && original.object && original.object.email ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_street_number">
                                            №
                                        </label>
                                        {editable && original && original.object && original.object.street_number && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="object_street_number"
                                            name="object[street_number]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.street_number ? fields.object.street_number : ''}
                                            readOnly={editable && original && original.object && original.object.street_number ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_block">
                                            Блок
                                        </label>
                                        {editable && original && original.object && original.object.block && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="object_block"
                                            name="object[block]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.block ? fields.object.block : ''}
                                            readOnly={editable && original && original.object && original.object.block ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_entrance">
                                            Вход
                                        </label>
                                        {editable && original && original.object && original.object.entrance && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="object_entrance"
                                            name="object[entrance]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.entrance ? fields.object.entrance : ''}
                                            readOnly={editable && original && original.object && original.object.entrance ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_floor">
                                            Етаж
                                        </label>
                                        {editable && original && original.object && original.object.floor && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="object_floor"
                                            name="object[floor]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.floor ? fields.object.floor : ''}
                                            readOnly={editable && original && original.object && original.object.floor ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_apartment">
                                            Апартамент
                                        </label>
                                        {editable && original && original.object && original.object.apartment && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="object_apartment"
                                            name="object[apartment]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.apartment ? fields.object.apartment : ''}
                                            readOnly={editable && original && original.object && original.object.apartment ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default React.memo(BuildingData, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})