import React, { useRef, useState, useEffect } from 'react'

//modals
import SelectSign from './SelectSign'
import { useAuthContext } from '../../contexts/AuthContext';

function SignPanel(props) {

    const auth = useAuthContext()

    //refs
    const signsRef = useRef(null)

    const mayorSign = 'mayor' in props;
    const accountantSign = 'accountant' in props;
    const agreedSign = 'agreed' in props;
    const preparedSign = 'prepared' in props;

    const readOnly = props.readOnly || false;

    const [state, setState] = useState({
        mayor: null,
        accountant: null,
        prepared: null,
        agreed: null
    });

    useEffect(() => {

        if (!props.panelName) {
            alert('Missing panel name!');
        }

        setState(prev => ({
            ...prev,
            mayor: props.mayor,
            accountant: props.accountant,
            prepared: props.prepared,
            agreed: props.agreed
        }))
    }, [props]);

    const handleSelectSignModal = e => {
        e.preventDefault()

        signsRef.current.open()
    }

    const handleSelected = (k, v) => {
        setState(prev => ({
            ...prev,
            [k]: v
        }));
    }

    return (
        <>
            <SelectSign
                ref={signsRef}
                mayor={state.mayor}
                allowMayorSign={mayorSign}
                mayorInput={props.mayorInput}
                mayorReason={props.mayorReason}
                accountant={state.accountant}
                allowAccountantSign={accountantSign}
                accountantInput={props.accountantInput}
                prepared={state.prepared}
                allowPreparedSign={preparedSign}
                preparedInput={props.preparedInput}
                preparedLabel={props.preparedLabel}
                defaultPreparedId={props.defaultPreparedId}
                agreed={state.agreed}
                allowAgreedSign={agreedSign}
                agreedLabel={props.agreedLabel}
                agreedInput={props.agreedInput}
                handleSelected={handleSelected}
                handleModify={props.handleModify}
            />

            <h3>
                <span>
                    {props.panelNumber || 4}
                </span>
                Подпис
            </h3>
            <div className="holder" name={props.panelName}>

                <div className="row">

                    <ul className="col">
                        {mayorSign &&
                            <li className="row">
                                <div className="col">
                                    <span className="extra-label">
                                        Кмет
                                </span>
                                    <p>
                                        {state.mayor ? state.mayor.name : '-'}
                                    </p>
                                    <i>
                                        {state.mayor && state.mayor.position ? state.mayor.position.name : '-'}
                                    </i>
                                </div>
                            </li>
                        }
                        {accountantSign &&
                            <li className="row">
                                <div className="col">
                                    <span className="extra-label">
                                        Счетоводител
                                </span>
                                    <p>
                                        {state.accountant ? state.accountant.name : '-'}
                                    </p>
                                    <i>
                                        {state.accountant && state.accountant.position ? state.accountant.position.name : '-'}
                                    </i>
                                </div>
                            </li>
                        }
                        {preparedSign &&
                            <li className="row">
                                <div className="col">
                                    <span className="extra-label">
                                        Изготвил
                            </span>
                                    <p>
                                        {state.prepared ? state.prepared.name : '-'}
                                    </p>
                                    <i>
                                        {state.prepared && state.prepared.position ? state.prepared.position.name : '-'}
                                    </i>
                                </div>
                            </li>
                        }
                    </ul>

                    {agreedSign &&
                        <ul className="col">
                            <li className="row">
                                <div className="col">
                                    <span className="extra-label">
                                        Съгласували
                                </span>
                                    {state.agreed && state.agreed.map(p =>
                                        <React.Fragment key={'a-' + p.id}>
                                            <p>
                                                {p.name}
                                            </p>
                                            <i>
                                                {p.position ? p.position.name : '-'}
                                            </i>
                                        </React.Fragment>
                                    )}
                                </div>
                            </li>

                        </ul>
                    }

                </div>
                {!readOnly && auth.canWriteDocuments() &&
                    <div className="buttons">
                        <button onClick={handleSelectSignModal}>
                            Промяна
                        </button>
                    </div>
                }
            </div>
        </>
    )
}

export default React.memo(SignPanel, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
})