import axios from 'axios';

const Api = axios.create({
    // baseURL: 'http://192.168.0.5/obshtina-sobstvenost/public/api/'
    // baseURL: 'http://partner.danielstandard.com/vtsobstvenost/backend/public/api/'
    // baseURL: 'http://localhost/municipality-backend/public/api/'
    baseURL: 'http://partner.danielstandard.com/vtdemo/backend/public/api/'
});

Api.interceptors.request.use(config => {

    const auth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null;
    const token = auth && auth.user && auth.user.access_token;

    config.params = config.params || {};

    config.headers['Authorization'] = `Bearer ${token}`;

    return config;
});

export default Api;