import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { useFieldsContext } from '../../../../../contexts/FieldsContext'
import editIcon from '../../../../../assets/img/pages/icons/edit-field.jpg'
import Dropdown from '../../../../app/Dropdown'
import { useAuthContext } from '../../../../../contexts/AuthContext'
import Api from '../../../../../helpers/Api'
import SignPanel from './SignPanel'
import SelectUser from './SelectUser'
import checkIcon from '../../../../../assets/img/pages/icons/check.png'

function Fields(props, ref) {
    const auth = useAuthContext()
    const fieldsContext = useFieldsContext()

    const protocolFields = {
        signs: [],
        signs_keyed: {}
    };

    const fieldsData = {
        company: {},
        person: {},
        protocol: protocolFields
    }

    const [fields, setFields] = useState(fieldsData);
    const [regards, setRegards] = useState([]);
    const [users, setUsers] = useState({});

    const original = props.original || null;
    const editable = props.editable || false;
    const readonly = !auth.canWriteRequest();


    useImperativeHandle(ref, () => ({
        getRegards: () => regards,
        getSigns: () => fields.protocol.signs_keyed
    }))

    useEffect(() => {
        if (original) {

            if (!original.protocol) {
                original.protocol = protocolFields;
            }

            setFields(original)
        }
    }, [original])

    useEffect(() => {
        console.log(fields)
    }, [fields])

    useEffect(() => {
        loadRegards()
        loadUsers()
    }, [])

    const loadRegards = () => {
        let url = 'procedures/obligations/regards';

        if (original) {
            url += '?procedure_id=' + original.id;
        }

        Api.get(url)
            .then(res => {

                let data = res.data.filter(r => {

                    if (auth.canWriteRequest()) {
                        return true;
                    }

                    if (r.user_id === auth.id()) {
                        return true;
                    }

                    return false;
                });

                setRegards(data);
            });
    }

    const loadUsers = () => {
        let url = 'procedures/obligations/users';

        if (original) {
            url += '?procedure_id=' + original.id;
        }

        Api.get(url)
            .then(res => {
                setUsers(res.data);
            })
    }

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleSignChange = (regardId, userId, user = null) => {

        let exists = users[regardId].users.filter(u => u.id == userId).length;

        setUsers(prev => ({
            ...prev,
            [regardId]: {
                ...prev[regardId],
                users: user && !exists ? prev[regardId].users.concat(user) : prev[regardId].users,
                default: Number(userId)
            }
        }))
    }

    const handleInputChange = (regardId, name, value) => {

        regardId = Number(regardId)

        let data = {};

        if (fields.protocol.signs_keyed[regardId]) {
            data = fields.protocol.signs_keyed[regardId];
        }

        data[name] = value;

        setFields(prev => ({
            ...prev,
            protocol: {
                ...prev.protocol,
                signs_keyed: {
                    ...prev.protocol.signs_keyed,
                    [regardId]: data
                }
            }
        }));

    }

    const canModify = regardId => {

        let regard = fields.protocol.signs_keyed[regardId] || false;

        if (regard) {
            return Boolean(auth.id() === Number(regard.user_id));
        }

        return false;
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                            </span>
                            Номер и Дата
                        </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="protocol_no">
                                    № протокол
                                </label>
                                {!readonly && editable && original && ((original.protocol && original.protocol.no) || original.protocol_gno) && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="protocol_no"
                                    name="protocol[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    value={fields && fields.protocol && fields.protocol.no ? fields.protocol.no : fields.protocol_gno}
                                    readOnly={readonly || (editable && ((original.protocol && original.protocol.no) || original.protocol_gno)) ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="protocol_date">
                                    Дата
                                    </label>
                                {!readonly && editable && original && original.protocol && original.protocol.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="protocol_date"
                                    name="protocol[date]"
                                    type="date"
                                    className="field-medium"
                                    defaultValue={editable && fields && fields.protocol ? fields.protocol.date_formatted : ''}
                                    readOnly={readonly || (editable && original && original.protocol && original.protocol.date) ? true : false}

                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* step 2 */}
                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Данни за заявителя
                    </h3>
                    <div className="holder">
                        {!fields.applicant_type || fields.applicant_type === 'company'
                            ?
                            <div className="col">

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_name">
                                            Точно наименование на фирмата
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            id="company_name"
                                            value={fields && fields.company && fields.company.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_eik">
                                            ЕИК / Булстат
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_eik"
                                            type="text"
                                            value={fields && fields.company && fields.company.eik}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_phone">
                                            Тел. номер
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.phone}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_town">
                                            Населено място
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.town
                                                ? fields.company.town.id
                                                    ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                    : fields.company.town.name
                                                : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_street">
                                            Улица
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_street_number">
                                                    №
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_street_number"
                                                    type="text"
                                                    min="0"
                                                    step="1"
                                                    value={fields && fields.company && fields.company.street_number}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_entrance">
                                                    Вход
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_entrance"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.entrance}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_block">
                                                    Блок
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_block"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.block}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_floor">
                                                    Етаж
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_floor"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.floor}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="company_mol">
                                            МОЛ
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            id="mol"
                                            type="text"
                                            value={fields && fields.company && fields.company.mol}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col">

                                    </div>

                                    <div className="col">

                                    </div>
                                </div>

                            </div>
                            :
                            <div className="col">


                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="person_name">
                                            Трите имена на лицето
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_name"
                                            type="text"
                                            value={fields && fields.person && fields.person.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="person_egn">
                                            ЕГН
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_egn"
                                            type="text"
                                            value={fields && fields.person && fields.person.egn}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="person_phone">
                                            Тел. номер
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_phone"
                                            type="text"
                                            value={fields && fields.person && fields.person.phone}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="person_town">
                                            Населено място
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_town"
                                            type="text"
                                            value={fields && fields.person && fields.person.town
                                                ? `${fields.person.town.kind} ${fields.person.town.name}`
                                                : ''
                                            }
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="person_street">
                                            Улица
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_street"
                                            type="text"
                                            value={fields && fields.person && fields.person.street
                                                ? `${fields.person.street.suff} ${fields.person.street.name}`
                                                : ''
                                            }
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="person_street_number">
                                                    №
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="person_street_number"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    className="field-small"
                                                    value={fields && fields.person && fields.person.street_number}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="person_entrance">
                                                    Вход
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="person_entrance"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.person && fields.person.entrance}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="person_block">
                                                    Блок
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="person_block"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.person && fields.person.block}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="person_floor">
                                                    Етаж
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="person_floor"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.person && fields.person.floor}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Констатации
                    </h3>
                    <div className="holder">
                        {regards.map((r, i) =>
                            <React.Fragment key={'r-' + r.id}>

                                <input type="hidden" name="protocol[signs][regard_id][]" value={r.id} />

                                <div className="row">
                                    <div className="col">

                                        <div className="row" style={{ alignItems: 'center' }}>
                                            <div className="col col-auto" style={{ marginRight: '5px' }}>
                                                <h3 style={{ marginBottom: '0' }}>
                                                    <span>{i + 1}</span>
                                                </h3>
                                            </div>
                                            <div className="col">
                                                за наличие или липса {r.name}
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col" style={{ width: '300px' }}>
                                                <label>Служител</label>
                                                {/* <select
                                                    name="protocol[signs][user_id][]"
                                                    className={['field-big', !auth.canWriteDocuments() ? 'disabled' : ''].join(' ')}
                                                    style={{ width: '200px' }}
                                                    value={users[r.id] && users[r.id].default ? users[r.id].default : ''}
                                                    onChange={e => handleSignChange(r.id, e.target.value)}
                                                >
                                                    <option value="" disabled>Избор</option>
                                                    {users[r.id] && users[r.id].users.map(u =>
                                                        <option key={'u-' + u.id} value={u.id}>{u.name}</option>
                                                    )}
                                                </select> */}
                                                <SelectUser
                                                    users={users[r.id] ? users[r.id].users : []}
                                                    selected={users[r.id] && users[r.id].default ? users[r.id].default : ''}
                                                    department_id={r.department_id}
                                                    disabled={!auth.canWriteRequest() || (fields.protocol && fields.protocol.signs_keyed[r.id] && fields.protocol.signs_keyed[r.id].date)}
                                                    handleChange={selected => handleSignChange(r.id, selected.id, selected)}
                                                />
                                            </div>

                                            {/* <div className="col col-auto">
                                                <label>Отдел / Дирекция</label>
                                                <input
                                                    type="text"
                                                    className="field-big"
                                                    value={
                                                        users[r.id] && users[r.id].default
                                                            ? users[r.id].users.filter(u => u.id === users[r.id].default)[0].department.name
                                                            : ''
                                                    }
                                                    disabled
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`protocol[signs][department_id][${r.id}]`}
                                                    value={
                                                        users[r.id] && users[r.id].default
                                                            ? users[r.id].users.filter(u => u.id === users[r.id].default)[0].department.id
                                                            : ''
                                                    }
                                                />
                                            </div>

                                            <div className="col col-auto">
                                                <label>Позиция</label>
                                                <input
                                                    type="text"
                                                    className="field-big"
                                                    value={
                                                        users[r.id] && users[r.id].default
                                                            ? users[r.id].users.filter(u => u.id === users[r.id].default)[0].position.name
                                                            : ''
                                                    }
                                                    disabled
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`protocol[signs][position_id][${r.id}]`}
                                                    value={
                                                        users[r.id] && users[r.id].default
                                                            ? users[r.id].users.filter(u => u.id === users[r.id].default)[0].position.id
                                                            : ''
                                                    }
                                                />
                                            </div> */}

                                            <div className="col" style={{ width: '100px' }}>
                                                <label>задължения</label>
                                                <select
                                                    name={`protocol[signs][has_obligation][${r.id}]`}
                                                    className={[
                                                        !auth.canWriteRequest() && !canModify(r.id) ? 'disabled' : '',
                                                        fields.protocol && fields.protocol.signs_keyed[r.id] && fields.protocol.signs_keyed[r.id].date ? 'disabled' : ''
                                                    ].join(' ')}
                                                    value={
                                                        fields && fields.protocol && fields.protocol.signs_keyed && fields.protocol.signs_keyed[r.id] && fields.protocol.signs_keyed[r.id].has_obligation !== null
                                                            ? fields.protocol.signs_keyed[r.id].has_obligation
                                                            : ''
                                                    }
                                                    onChange={e => handleInputChange(r.id, 'has_obligation', e.target.value)}
                                                    style={{ width: '100px' }}
                                                >
                                                    <option value="" disabled>Избор</option>
                                                    <option value={1}>Има</option>
                                                    <option value={0}>Няма</option>
                                                </select>
                                            </div>

                                            {fields.protocol && fields.protocol.signs_keyed[r.id] && fields.protocol.signs_keyed[r.id].has_obligation == 1 &&
                                                <>
                                                    <div className="col" style={{ width: '150px' }}>
                                                        <label>в размер на</label>
                                                        <input
                                                            type="number"
                                                            min="0"
                                                            step="0.01"
                                                            className={[
                                                                'field-medium',
                                                                !auth.canWriteRequest() && !canModify(r.id) ? 'disabled' : '',
                                                                fields.protocol.signs_keyed[r.id] && fields.protocol.signs_keyed[r.id].date ? 'disabled' : ''
                                                            ].join(' ')}
                                                            name={`protocol[signs][obligation][${r.id}]`}
                                                            value={
                                                                fields && fields.protocol && fields.protocol.signs_keyed && fields.protocol.signs_keyed[r.id]
                                                                    ? fields.protocol.signs_keyed[r.id].obligation
                                                                    : ''
                                                            }
                                                            onChange={e => handleInputChange(r.id, 'obligation', e.target.value)}
                                                            style={{ width: '150px' }}
                                                        />
                                                    </div>

                                                    <div className="col">
                                                        <label>произтичащи от</label>
                                                        <input
                                                            type="text"
                                                            className={[
                                                                !auth.canWriteRequest() && !canModify(r.id) ? 'disabled' : '',
                                                                fields.protocol.signs_keyed[r.id] && fields.protocol.signs_keyed[r.id].date ? 'disabled' : ''
                                                            ].join(' ')}
                                                            name={`protocol[signs][reason][${r.id}]`}
                                                            value={
                                                                fields && fields.protocol && fields.protocol.signs_keyed && fields.protocol.signs_keyed[r.id]
                                                                    ? fields.protocol.signs_keyed[r.id].reason
                                                                    : ''
                                                            }
                                                            style={{ width: '100%' }}
                                                            onChange={e => handleInputChange(r.id, 'reason', e.target.value)}
                                                        />
                                                    </div>
                                                </>
                                            }


                                        </div>

                                        <div className="row">
                                            <div className="col col-auto" style={{ marginLeft: 'auto' }}>

                                                {fields && fields.protocol && fields.protocol.signs_keyed && fields.protocol.signs_keyed[r.id] && fields.protocol.signs_keyed[r.id].date
                                                    ?
                                                    <p style={{ fontSize: '13px', color: '#0d7d0d' }}>
                                                        <img src={checkIcon} alt="check" style={{ marginRight: '10px' }} />
                                                        подписано
                                                        {fields.protocol.signs_keyed[r.id].user_id && fields.protocol.signs_keyed[r.id].user_id === fields.protocol.signs_keyed[r.id].signed_by
                                                            ?
                                                            <>
                                                                <span> от {fields.protocol.signs_keyed[r.id].user.name}</span>,
                                                                <span> {fields.protocol.signs_keyed[r.id].user.position.name.toLowerCase()}</span>
                                                                <span> отдел {fields.protocol.signs_keyed[r.id].user.department.name}</span>
                                                                <span> на {fields.protocol.signs_keyed[r.id].date_full} ч.</span>
                                                            </>
                                                            :
                                                            ''
                                                        }
                                                    </p>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>

            <div className="row x2">
                <div className="col">
                    <SignPanel
                        prepared={fields && fields.protocol && fields.protocol.preparedsignature}
                        agreed={fields && fields.protocol && fields.protocol.agreedsignature}
                        handleModify={props.handleModify}
                    />
                </div>
            </div>

        </div>

    )
}

export default React.memo(React.forwardRef(Fields), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})