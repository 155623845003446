import React, { useState, useRef } from 'react'
import { Link, Redirect } from 'react-router-dom'

//context
import { useAuthContext } from '../../../contexts/AuthContext'

//helpers
import Api from '../../../helpers/Api'

//styles
import '../../../assets/css/login.css'

//images
import houseIcon from '../../../assets/img/pages/icons/house.png'
import logo from '../../../assets/img/pages/login/logo.png'
import loader from '../../../assets/img/app/loader.svg'

function Login() {
    const auth = useAuthContext()

    const formRef = useRef(null)

    const [login, setLogin] = useState({
        validate: false,
        loading: false,
    })

    if (auth.isLogged) return <Redirect to="/outlets?current=1&page=1" />

    const handleLogin = e => {
        e.preventDefault()

        let data = new FormData(formRef.current);

        setLogin(login => ({
            ...login,
            loading: true,
            validate: false,
        }))

        Api.post('login', data)
            .then(res => {
                console.log(res)
                setLogin(login => ({
                    ...login,
                    loading: false,
                    validate: false,
                }))
            })
            .catch(err => {
                setLogin(login => ({
                    ...login,
                    loading: false,
                    validate: true,
                }))
            })
    }

    return (
        <section id="login">
            <div className="wrapper">
                <img src={logo} className="logo" alt="Veliko Tarnovo" />
                <form autoComplete="off" role="presentation"  ref={formRef}  onSubmit={handleLogin}>
                    <img src={houseIcon} alt="house" />
                    <h1>
                        Забравена парола
                    </h1>
                    <div className="row">
                        <div className="col full-width">
                            <label htmlFor="email">
                                Е-поща
                            </label>
                            <input autoComplete="off" role="presentation" 
                                type="text"
                                placeholder="Въведете е-поща..."
                                id="email"
                                name="email"
                                className={login && login.validate && 'validate'}
                            />
                        </div>
                    </div>

                    <div className="row buttons">
                        {login.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <button type="button" onClick={handleLogin}>
                                Изпрати парола
                            </button>
                        }
                    </div>

                    <div className="row center">
                        <Link to="/login">
                            Вход
                        </Link>
                    </div>
                </form>
            </div>
        </section>
    )
}
export default Login