import React, { useState, useEffect } from 'react'

function Applicant(props) {
    const [tab, setTab] = useState({
        current: 1,
    })

    useEffect(() => {
        let tabs = document.getElementsByClassName('tabs')[0].children
        tabs[props.changeTab].click()

    }, [props.changeTab])


    useEffect(() => {
        //tabs
        let tabs = document.getElementsByClassName('tabs')[0].children
        let indicator = document.getElementsByClassName('indicator')
        let activeTab = document.getElementsByClassName('active-tab')
        let currentTab

        for (let i = 0; i < tabs.length; i++) {
            tabs[i].addEventListener('click', e => {
                if (!e.target || !activeTab[0]) return

                indicator[0].style.left = `${e.target.offsetLeft - 20}px`
                indicator[0].style.width = `${e.target.clientWidth}px`
                activeTab[0].classList.remove('active-tab')
                e.target.classList.add('active-tab')
                currentTab = e.target.getAttribute('data-tab')

                if (tabs.length == 6) {
                    setTab(tab => ({
                        ...tab,
                        //Не се използва в момента
                        current: currentTab
                    }))
                    props.currentTab(currentTab)
                }
            }, false)
        }
        //end tabs
    }, [])


    return (
        <ul className="tabs">
            <li className="indicator"></li>
            <li data-tab="1" className="active-tab" data-validate={props.validateTabs && props.validateTabs.applicant && props.validateTabs.applicant ? 'validate-tabs' : ''} >
                Данни на заявителя
            </li>
            <li data-tab="2" data-validate={props.validateTabs && props.validateTabs.object && props.validateTabs.object ? 'validate-tabs' : ''} >
                Данни на обекта
            </li>
            <li data-tab="3">
                Данни на персонала
            </li>
            <li data-tab="4" data-validate={props.validateTabs && props.validateTabs.others && props.validateTabs.others ? 'validate-tabs' : ''}>
                Други обстоятелства
            </li>
            <li data-tab="5">
                Приложени документи
            </li>
        </ul>
    )
}

export default Applicant