import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'

//context
import { useFieldsContext } from '../../../../../../contexts/FieldsContext'

//images
import editIcon from '../../../../../../assets/img/pages/icons/edit-field.jpg'
import Dropdown from '../../../../../app/Dropdown';

function Application(props, ref) {

    const fieldsContext = useFieldsContext();

    const data = props.data;
    const original = props.original;
    const editable = props.editable || false;

    const companyMunicipalityRef = useRef(null);
    const companyTownRef = useRef(null);
    const companyRegionRef = useRef(null);
    const companyStreetRef = useRef(null);

    const ownerMunicipalityRef = useRef(null);
    const ownerTownRef = useRef(null);
    const ownerRegionRef = useRef(null);
    const ownerStreetRef = useRef(null);

    const workerMunicipalityRef = useRef(null);
    const workerTownRef = useRef(null);
    const workerRegionRef = useRef(null);
    const workerStreetRef = useRef(null);

    const [fields, setFields] = useState({
        request: {},
        company: {},
        object: {},
        order: {},
        owner: {},
        worker: {}
    });

    useImperativeHandle(ref, () => ({
        setCompany: data => setCompany(data),
        setCompanyId: id => setCompanyId(id),
        setObjectId: id => setObjectId(id)
    }))


    useEffect(() => {
        if (original) {
            setFields(original);
        }
        console.log(original)
    }, [original]);

    const handleFieldChange = e => {

        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleCheckboxChange = e => {
        let name = e.target.name
        let checked = e.target.checked;


        setFields(prev => ({
            ...prev,
            [name]: checked

        }))

    }

    const handleRadioChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = Number(e.target.value)

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }
    }

    const setCompany = newData => {
        setFields(prev => ({
            ...prev,
            company: newData
        }))
    }

    const setCompanyId = id => {
        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                id: id
            }
        }))
    }

    const setObjectId = id => {
        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                id: id
            }
        }))
    }

    // dropdown change
    const handleCompanyChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                company: newData
            }))
        }
    }

    const handleCompanyCountryChange = newData => {
        companyMunicipalityRef.current.reset()
        companyTownRef.current.reset();
        companyRegionRef.current.reset();
        companyStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                country_id: newData.id,
                country: newData,
            }

        }))
    }

    const handleCompanyMunicipalityChange = newData => {
        companyTownRef.current.reset();
        companyRegionRef.current.reset();
        companyStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                municipality_id: newData.id,
                municipality: newData,
            }


        }))
    }

    const handleCompanyTownChange = newData => {
        companyStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                town_id: newData.id,
                town: newData,
            }

        }))
    }

    const handleCompanyRegionChange = newData => {
        // todo
    }

    const handleOwnerTypeChange = e => {
        handleFieldChange(e);

        setFields(prev => ({
            ...prev,
            owner: {}
        }))
    }

    const handleWorkerTypeChange = e => {
        handleFieldChange(e);

        setFields(prev => ({
            ...prev,
            worker: {}
        }))
    }

    // owner
    const handleOwnerChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                owner: newData
            }))
        }
    }

    const handleOwnerCountryChange = newData => {
        ownerMunicipalityRef.current.reset()
        ownerTownRef.current.reset();
        ownerRegionRef.current.reset();
        ownerStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            owner: {
                ...prev.owner,
                country_id: newData.id,
                country: newData,
            }

        }))
    }

    const handleOwnerMunicipalityChange = newData => {
        ownerTownRef.current.reset();
        ownerRegionRef.current.reset();
        ownerStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            owner: {
                ...prev.owner,
                municipality_id: newData.id,
                municipality: newData,
            }


        }))
    }

    const handleOwnerTownChange = newData => {
        ownerStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            owner: {
                ...prev.owner,
                town_id: newData.id,
                town: newData,
            }

        }))
    }


    // worker
    const handleWorkerChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                worker: newData
            }))
        }
    }

    const handleWorkerCountryChange = newData => {
        workerMunicipalityRef.current.reset()
        workerTownRef.current.reset();
        workerRegionRef.current.reset();
        workerStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            worker: {
                ...prev.worker,
                country_id: newData.id,
                country: newData,
            }

        }))
    }

    const handleWorkerMunicipalityChange = newData => {
        workerTownRef.current.reset();
        workerRegionRef.current.reset();
        workerStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            worker: {
                ...prev.worker,
                municipality_id: newData.id,
                municipality: newData,
            }


        }))
    }

    const handleWorkerTownChange = newData => {
        workerStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            worker: {
                ...prev.worker,
                town_id: newData.id,
                town: newData,
            }

        }))
    }

    return (
        <div className="panel">

            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и Дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="request_no">
                                    Входящ №
                                </label>
                                {editable && original && original.request && original.request.no && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request_no"
                                    name="request[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={editable && fields && fields.request ? fields.request.no : ''}
                                    readOnly={editable && original && original.request && original.request.no ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="request_date">
                                    Дата
                                </label>
                                {editable && original && original.request && original.request.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request_date"
                                    name="request[date]"
                                    type="date"
                                    defaultValue={editable && fields && fields.request && fields.request.date_formatted ? fields.request.date_formatted : fieldsContext.today()}
                                    readOnly={editable && original && original.request && original.request.date ? true : false}

                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col">
                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    1
                            </span>
                            Заявител
                            </h3>
                            <div className="holder">

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="change_applicant">
                                            Име, презиме, фамилия
                                        </label>
                                        {editable && original && original.applicant && original.applicant.name && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            inputName="applicant[name]"
                                            inputValue={fields && fields.applicant && fields.applicant.name}
                                            inputIdName="applicant[id]"
                                            inputIdValue={fields && fields.applicant && fields.applicant.id}
                                            inputPlaceholder="Въведете име"
                                            inputDisabled={editable && original && original.applicant && original.applicant.name ? true : false}
                                            url="persons/find"
                                            renderText={(data) => {
                                                return <>{data.name} {data.egn ? `(ЕГН: ${data.egn})` : ''}</>
                                            }}
                                            renderInputText={(data) => {
                                                return data.name
                                            }}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    2
                                </span>
                                Упълномощено лице на
                            </h3>
                            <div className="holder">

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="company_name">
                                            Наименование на търговеца
                                        </label>
                                        {editable && original && original.company && original.company.name && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            inputName="company[name]"
                                            inputValue={fields && fields.company && fields.company.name}
                                            inputIdName="company[id]"
                                            inputIdValue={fields && fields.company && fields.company.id}
                                            inputPlaceholder="Въведете име"
                                            inputDisabled={editable && original && original.company && original.company.name ? true : false}
                                            url="companies/find"
                                            onChange={handleCompanyChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_eik">
                                            ЕИК / Булстат
                                        </label>
                                        {editable && original && original.company && original.company.eik && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="eik"
                                            name="company[eik]"
                                            type="text"
                                            placeholder="Въведете ЕИК / Булстат"
                                            value={fields && fields.company && fields.company.eik ? fields.company.eik : ''}
                                            readOnly={editable && original && original.company && original.company.eik ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>

                                <div className="row x2">

                                    <div className="col">

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_country">
                                                    Държава
                                                </label>
                                                {editable && original && original.company && original.company.country && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    inputName="company[country]"
                                                    inputValue={fields && fields.company && fields.company.country ? fields.company.country.name : ''}
                                                    inputIdName="company[country_id]"
                                                    inputIdValue={fields && fields.company && fields.company.country_id}
                                                    inputPlaceholder="Изберете държава"
                                                    inputDisabled={editable && original && original.company && original.company.country ? true : false}
                                                    url="countries/find"
                                                    onChange={handleCompanyCountryChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_municipality">
                                                    Община
                                                </label>
                                                {editable && original && original.company && original.company.municipality && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={companyMunicipalityRef}
                                                    inputName="company[municipality]"
                                                    inputValue={fields && fields.company && fields.company.municipality ? fields.company.municipality.name : ''}
                                                    inputIdName="company[municipality_id]"
                                                    inputIdValue={fields && fields.company && fields.company.municipality_id}
                                                    inputPlaceholder="Изберете община"
                                                    inputDisabled={editable && original && original.company && original.company.municipality ? true : false}
                                                    url="municipalities/find"
                                                    params={{
                                                        country_id: fields && fields.company && fields.company.country_id
                                                    }}
                                                    onChange={handleCompanyMunicipalityChange}
                                                />
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_town">
                                                    Населено място
                                                </label>
                                                {editable && original && original.company && original.company.town && original.company.town && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={companyTownRef}
                                                    inputName="company[town]"
                                                    inputValue={fields && fields.company && fields.company.town
                                                        ? fields.company.town.id
                                                            ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                            : fields.company.town.name
                                                        : ''}
                                                    inputIdName="company[town_id]"
                                                    inputIdValue={fields && fields.company && fields.company.town_id}
                                                    inputPlaceholder="Изберете населено място"
                                                    inputDisabled={editable && original && original.company && original.company.town && original.company.town.id ? true : false}
                                                    url="towns/find"
                                                    params={{
                                                        munic_id: fields && fields.company && fields.company.municipality && fields.company.municipality.id
                                                    }}
                                                    renderText={(data) => {
                                                        return <>{data.kind} {data.name}</>;
                                                    }}
                                                    onChange={handleCompanyTownChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_postcode">
                                                    Пощенски код
                                        </label>
                                                {editable && original && original.company && original.company.postcode && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="company_postcode"
                                                    name="company[postcode]"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.postcode ? fields.company.postcode : ''}
                                                    readOnly={editable && original && original.company && original.company.postcode ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_region">
                                                    Район/квартал
                                                </label>
                                                {editable && original && original.company && original.company.region && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={companyRegionRef}
                                                    inputName="company[region]"
                                                    inputValue={fields && fields.company && fields.company.region ? fields.company.region.name : ''}
                                                    inputIdName="company[region_id]"
                                                    inputIdValue={fields && fields.company && fields.company.region_id}
                                                    inputPlaceholder="Изберете район/квартал"
                                                    inputDisabled={editable && original && original.company && original.company.region ? true : false}
                                                    url="regions/find"
                                                    params={{
                                                        town_id: fields && fields.company && fields.company.town_id
                                                    }}

                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_street">
                                                    Булевард/площад/улица
                                                </label>
                                                {editable && original && original.company && original.company.street && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={companyStreetRef}
                                                    inputName="company[street]"
                                                    inputValue={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                                    inputIdName="company[street_id]"
                                                    inputIdValue={fields && fields.company && fields.company.street_id}
                                                    inputPlaceholder="Изберете улица"
                                                    inputDisabled={editable && original && original.company && original.company.street ? true : false}
                                                    url="streets/find"
                                                    params={{
                                                        town_id: fields && fields.company && fields.company.town_id
                                                    }}
                                                    renderText={(data) => {
                                                        return <>{data.suff} {data.name}</>;
                                                    }}
                                                />
                                            </div>

                                        </div>

                                    </div>

                                    <div className="col">

                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_street_number">
                                                    №
                                                </label>
                                                {editable && original && original.company && original.company.street_number && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="company_street_number"
                                                    name="company[street_number]"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.street_number ? fields.company.street_number : ''}
                                                    readOnly={editable && original && original.company && original.company.street_number ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_block">
                                                    Блок
                                                </label>
                                                {editable && original && original.company && original.company.block && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="company_block"
                                                    name="company[block]"
                                                    type="text"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.block ? fields.company.block : ''}
                                                    readOnly={editable && original && original.company && original.company.block ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_floor">
                                                    Етаж
                                        </label>
                                                {editable && original && original.company && original.company.floor && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="company_floor"
                                                    name="company[floor]"
                                                    type="text"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.floor ? fields.company.floor : ''}
                                                    readOnly={editable && original && original.company && original.company.floor ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_apartment">
                                                    Апартамент
                                        </label>
                                                {editable && original && original.company && original.company.apartment && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="company_apartment"
                                                    name="company[apartment]"
                                                    type="text"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.apartment ? fields.company.apartment : ''}
                                                    readOnly={editable && original && original.company && original.company.apartment ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="company_phone">
                                                    Тел. номер
                                        </label>
                                                {editable && original && original.company && original.company.phone && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="company_phone"
                                                    name="company[phone]"
                                                    type="text"
                                                    placeholder="Въведете тел. номер"
                                                    value={fields && fields.company && fields.company.phone ? fields.company.phone : ''}
                                                    readOnly={editable && original && original.company && original.company.phone ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_fax">
                                                    Факс
                                                </label>
                                                {editable && original && original.company && original.company.fax && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="company_fax"
                                                    name="company[fax]"
                                                    type="text"
                                                    placeholder="Въведете факс"
                                                    value={fields && fields.company && fields.company.fax ? fields.company.fax : ''}
                                                    readOnly={editable && original && original.company && original.company.fax ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="company_email">
                                                    Е-поща
                                                </label>
                                                {editable && original && original.company && original.company.email && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="company_email"
                                                    name="company[email]"
                                                    type="text"
                                                    placeholder="Въведете е-поща"
                                                    value={fields && fields.company && fields.company.email ? fields.company.email : ''}
                                                    readOnly={editable && original && original.company && original.company.email ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Данни за обекта
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label>
                                    Наименование
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object ? `${fields.object.name}` : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>
                                    Адрес
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object
                                        ? (
                                            fields.object.town ?
                                                (
                                                    (`${fields.object.town.kind} ${fields.object.town.name} `)
                                                    + (fields.object.region ? `кв. ${fields.object.region.name} ` : '')
                                                    + (fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name} ${fields.object.street_number} ` : '')
                                                    + (fields.object.block ? `бл. ${fields.object.block} ` : '')
                                                    + (fields.object.floor ? `ет. ${fields.object.floor} ` : '')
                                                    + (fields.object.apartment ? `ап. ${fields.object.apartment} ` : '')
                                                )
                                                :
                                                ''
                                        )
                                        : ''}
                                    readOnly
                                />
                            </div>
                            <div className="col">
                                <label>
                                    Собственост
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? fields.owner.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col">
                                <label>
                                    Вид
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object.activity ? fields.object.activity.name : ''}
                                    readOnly
                                />
                            </div>

                            <div className="col">
                                <label>
                                    № на издаденото удостоверение
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.approved_certificate_no ? fields.approved_certificate_no : ''}
                                    readOnly
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            4
                        </span>
                        Настъпила промяна в обстоятелствата относно
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <label className="checkbox">
                                РЕГИСТРИРАН ТУРОПЕРАТОР И/ИЛИ ТУРИСТИЧЕСКИ АГЕНТ
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name=""
                                    value="1"
                                    checked={false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                КАТЕГОРИЗИРАН ТУРИСТИЧЕСКИ ОБЕКТ по чл. 3, ал. 2, т. 1, 2 и 3 от Закона за туризма
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name=""
                                    value="1"
                                    checked={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                ТУРИСТИЧЕСКО СДРУЖЕНИЕ
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name=""
                                    value="1"
                                    checked={false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                ТУРИСТИЧЕСКИ ИНФОРМАЦИОНЕН ЦЕНТЪР
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name=""
                                    value="1"
                                    checked={false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                СЕРТИФИЦИРАН ЦЕНТЪР по чл. 138, ал. 2 от Закона за туризма
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name=""
                                    value="1"
                                    checked={false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                ОРГАНИЗАЦИЯ ЗА УПРАВЛЕНИЕ НА ТУРИСТИЧЕСКИ РАЙОН
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name=""
                                    value="1"
                                    checked={false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                ЕКСКУРЗОВОД
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name=""
                                    value="1"
                                    checked={false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                ПЛАНИНСКИ ВОДАЧ
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name=""
                                    value="1"
                                    checked={false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                СКИ УЧИТЕЛ
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name=""
                                    value="1"
                                    checked={false}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            5
                        </span>
                        Променени данни или обстоятелства
                    </h3>
                    <div className="holder" name="changes">
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <label className="checkbox">
                                        СОБСТВЕНИК
                                        <input autoComplete="off" role="presentation"
                                            type="checkbox"
                                            name="has_owner_change"
                                            value="1"
                                            checked={fields && fields.has_owner_change ? true : false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                {fields && fields.has_owner_change &&
                                    <>
                                        <br />
                                        <div className="row">
                                            <div>
                                                <div className="row">
                                                    <label className="checkbox radio">
                                                        Заявителят
                                                        <input autoComplete="off" role="presentation"
                                                            type="radio"
                                                            name="request[applicant_is_owner]"
                                                            value="1"
                                                            checked={fields && fields.request.applicant_is_owner == 1 ? true : false}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                                <div className="row">
                                                    <label className="checkbox radio">
                                                        Друго лице
                                                        <input autoComplete="off" role="presentation"
                                                            type="radio"
                                                            name="request[applicant_is_owner]"
                                                            value="0"
                                                            checked={fields && fields.request.applicant_is_owner == 0 ? true : false}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                                {fields && fields.request.applicant_is_owner == 0 &&
                                                    <>
                                                        <br />
                                                        <div className="row">
                                                            <div className="col">
                                                                <label className="checkbox radio">
                                                                    Фирма
                                                                    <input autoComplete="off" role="presentation"
                                                                        type="radio"
                                                                        name="request[owner_type]"
                                                                        value="company"
                                                                        checked={fields && fields.request.owner_type !== 'person' ? true : false}
                                                                        onChange={handleOwnerTypeChange}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                            <div className="col">
                                                                <label className="checkbox radio">
                                                                    Физическо лице
                                                                    <input autoComplete="off" role="presentation"
                                                                        type="radio"
                                                                        name="request[owner_type]"
                                                                        value="person"
                                                                        checked={fields && fields.request.owner_type === 'person' ? true : false}
                                                                        onChange={handleOwnerTypeChange}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        {fields && fields.request.owner_type === 'person'
                                                            ?
                                                            <div className="row for-choice">
                                                                <div className="col able-to-edit">
                                                                    <label htmlFor="owner_name">
                                                                        Име, презиме, фамилия
                                                                    </label>
                                                                    {editable && fields && fields.owner && fields.owner.name && <img
                                                                        src={editIcon}
                                                                        alt="edit"
                                                                        className="edit"
                                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                                    />}
                                                                    <Dropdown
                                                                        inputName="owner[name]"
                                                                        inputValue={fields && fields.owner && fields.owner.name}
                                                                        inputIdName="owner[id]"
                                                                        inputIdValue={fields && fields.owner && fields.owner.id}
                                                                        inputPlaceholder="Въведете име"
                                                                        inputDisabled={editable && fields && fields.owner && fields.owner.name ? true : false}
                                                                        url="persons/find"
                                                                        renderText={(data) => {
                                                                            return <>{data.name} {data.egn ? `(ЕГН: ${data.egn})` : ''}</>
                                                                        }}
                                                                        renderInputText={(data) => data.name}
                                                                        onChange={handleOwnerChange}
                                                                    />
                                                                </div>
                                                                <div className="col able-to-edit">
                                                                    <label htmlFor="owner_egn">
                                                                        ЕГН
                                                                    </label>
                                                                    {editable && fields && fields.owner && fields.owner.egn && <img
                                                                        src={editIcon}
                                                                        alt="edit"
                                                                        className="edit"
                                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                                    />}
                                                                    <input autoComplete="off" role="presentation"
                                                                        id="egn"
                                                                        name="owner[egn]"
                                                                        type="text"
                                                                        placeholder="Въведете ЕГН"
                                                                        value={fields && fields.owner && fields.owner.egn ? fields.owner.egn : ''}
                                                                        readOnly={editable && fields && fields.owner && fields.owner.egn ? true : false}
                                                                        onChange={handleFieldChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="row for-choice">
                                                                <div className="col able-to-edit">
                                                                    <label htmlFor="owner_name">
                                                                        Наименование
                                                                    </label>
                                                                    {editable && fields && fields.owner && fields.owner.name && <img
                                                                        src={editIcon}
                                                                        alt="edit"
                                                                        className="edit"
                                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                                    />}
                                                                    <Dropdown
                                                                        inputName="owner[name]"
                                                                        inputValue={fields && fields.owner && fields.owner.name}
                                                                        inputIdName="owner[id]"
                                                                        inputIdValue={fields && fields.owner && fields.owner.id}
                                                                        inputPlaceholder="Въведете име"
                                                                        inputDisabled={editable && fields && fields.owner && fields.owner.name ? true : false}
                                                                        url="companies/find"
                                                                        onChange={handleOwnerChange}
                                                                    />
                                                                </div>

                                                                <div className="col able-to-edit">
                                                                    <label htmlFor="owner_eik">
                                                                        ЕИК
                                                                    </label>
                                                                    {editable && fields && fields.owner && fields.owner.eik && <img
                                                                        src={editIcon}
                                                                        alt="edit"
                                                                        className="edit"
                                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                                    />}
                                                                    <input autoComplete="off" role="presentation"
                                                                        id="eik"
                                                                        name="owner[eik]"
                                                                        type="text"
                                                                        placeholder="Въведете ЕИК / Булстат"
                                                                        value={fields && fields.owner && fields.owner.eik ? fields.owner.eik : ''}
                                                                        readOnly={editable && fields && fields.owner && fields.owner.eik ? true : false}
                                                                        onChange={handleFieldChange}
                                                                    />
                                                                </div>
                                                            </div>

                                                        }

                                                        <div className="row">

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="owner_country">
                                                                    Държава
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.country && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    inputName="owner[country]"
                                                                    inputValue={fields && fields.owner && fields.owner.country ? fields.owner.country.name : ''}
                                                                    inputIdName="owner[country_id]"
                                                                    inputIdValue={fields && fields.owner && fields.owner.country_id}
                                                                    inputPlaceholder="Изберете държава"
                                                                    inputDisabled={editable && fields && fields.owner && fields.owner.country ? true : false}
                                                                    url="countries/find"
                                                                    onChange={handleOwnerCountryChange}
                                                                />
                                                            </div>

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="owner_municipality">
                                                                    Община
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.municipality && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    ref={ownerMunicipalityRef}
                                                                    inputName="owner[municipality]"
                                                                    inputValue={fields && fields.owner && fields.owner.municipality ? fields.owner.municipality.name : ''}
                                                                    inputIdName="owner[municipality_id]"
                                                                    inputIdValue={fields && fields.owner && fields.owner.municipality_id}
                                                                    inputPlaceholder="Изберете община"
                                                                    inputDisabled={editable && fields && fields.owner && fields.owner.municipality ? true : false}
                                                                    url="municipalities/find"
                                                                    params={{
                                                                        country_id: fields && fields.owner && fields.owner.country_id
                                                                    }}
                                                                    onChange={handleOwnerMunicipalityChange}
                                                                />
                                                            </div>

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="owner_town">
                                                                    Населено място
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.town && fields.owner.town && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    ref={ownerTownRef}
                                                                    inputName="owner[town]"
                                                                    inputValue={fields && fields.owner && fields.owner.town
                                                                        ? fields.owner.town.id
                                                                            ? `${fields.owner.town.kind} ${fields.owner.town.name}`
                                                                            : fields.owner.town.name
                                                                        : ''}
                                                                    inputIdName="owner[town_id]"
                                                                    inputIdValue={fields && fields.owner && fields.owner.town_id}
                                                                    inputPlaceholder="Изберете населено място"
                                                                    inputDisabled={editable && fields && fields.owner && fields.owner.town && fields.owner.town.id ? true : false}
                                                                    url="towns/find"
                                                                    params={{
                                                                        munic_id: fields && fields.owner && fields.owner.municipality && fields.owner.municipality.id
                                                                    }}
                                                                    renderText={(data) => {
                                                                        return <>{data.kind} {data.name}</>;
                                                                    }}
                                                                    onChange={handleOwnerTownChange}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col able-to-edit">
                                                                <label htmlFor="owner_postcode">
                                                                    Пощенски код
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.postcode && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="owner_postcode"
                                                                    name="owner[postcode]"
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    placeholder=""
                                                                    value={fields && fields.owner && fields.owner.postcode ? fields.owner.postcode : ''}
                                                                    readOnly={editable && fields && fields.owner && fields.owner.postcode ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="owner_region">
                                                                    Район/квартал
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.region && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    ref={ownerRegionRef}
                                                                    inputName="owner[region]"
                                                                    inputValue={fields && fields.owner && fields.owner.region ? fields.owner.region.name : ''}
                                                                    inputIdName="owner[region_id]"
                                                                    inputIdValue={fields && fields.owner && fields.owner.region_id}
                                                                    inputPlaceholder="Изберете район/квартал"
                                                                    inputDisabled={editable && fields && fields.owner && fields.owner.region ? true : false}
                                                                    url="regions/find"
                                                                    params={{
                                                                        town_id: fields && fields.owner && fields.owner.town_id
                                                                    }}

                                                                />
                                                            </div>

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="owner_street">
                                                                    Улица
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.street && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    ref={ownerStreetRef}
                                                                    inputName="owner[street]"
                                                                    inputValue={fields && fields.owner && fields.owner.street ? `${fields.owner.street.suff} ${fields.owner.street.name}` : ''}
                                                                    inputIdName="owner[street_id]"
                                                                    inputIdValue={fields && fields.owner && fields.owner.street_id}
                                                                    inputPlaceholder="Изберете улица"
                                                                    inputDisabled={editable && fields && fields.owner && fields.owner.street ? true : false}
                                                                    url="streets/find"
                                                                    params={{
                                                                        town_id: fields && fields.owner && fields.owner.town_id
                                                                    }}
                                                                    renderText={(data) => {
                                                                        return <>{data.suff} {data.name}</>;
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col col-auto able-to-edit">
                                                                <label htmlFor="owner_street_number">
                                                                    №
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.street_number && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="owner_street_number"
                                                                    name="owner[street_number]"
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    placeholder=""
                                                                    className="field-small"
                                                                    value={fields && fields.owner && fields.owner.street_number ? fields.owner.street_number : ''}
                                                                    readOnly={editable && fields && fields.owner && fields.owner.street_number ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>

                                                            <div className="col col-auto able-to-edit">
                                                                <label htmlFor="owner_block">
                                                                    Блок
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.block && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="owner_block"
                                                                    name="owner[block]"
                                                                    type="text"
                                                                    placeholder=""
                                                                    className="field-small"
                                                                    value={fields && fields.owner && fields.owner.block ? fields.owner.block : ''}
                                                                    readOnly={editable && fields && fields.owner && fields.owner.block ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>

                                                            <div className="col col-auto able-to-edit">
                                                                <label htmlFor="owner_floor">
                                                                    Етаж
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.floor && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="owner_floor"
                                                                    name="owner[floor]"
                                                                    type="text"
                                                                    placeholder=""
                                                                    className="field-small"
                                                                    value={fields && fields.owner && fields.owner.floor ? fields.owner.floor : ''}
                                                                    readOnly={editable && fields && fields.owner && fields.owner.floor ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>

                                                            <div className="col col-auto able-to-edit">
                                                                <label htmlFor="owner_apartment">
                                                                    Апартамент
                                                                </label>
                                                                {editable && fields && fields.owner && fields.owner.apartment && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="owner_apartment"
                                                                    name="owner[apartment]"
                                                                    type="text"
                                                                    placeholder=""
                                                                    className="field-small"
                                                                    value={fields && fields.owner && fields.owner.apartment ? fields.owner.apartment : ''}
                                                                    readOnly={editable && fields && fields.owner && fields.owner.apartment ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="col">
                                <div className="row">
                                    <label className="checkbox">
                                        ЛИЦЕ, ИЗВЪРШВАЩО ДЕЙНОСТ В ОБЕКТА
                                        <input autoComplete="off" role="presentation"
                                            type="checkbox"
                                            name="has_worker_change"
                                            value="1"
                                            checked={fields && fields.has_worker_change ? true : false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                {fields && fields.has_worker_change &&
                                    <>
                                        <br />
                                        <div className="row">
                                            <div className="">
                                                <div className="row">
                                                    <label className="checkbox radio">
                                                        Заявителят
                                                <input autoComplete="off" role="presentation"
                                                            type="radio"
                                                            name="request[applicant_is_worker]"
                                                            value="1"
                                                            checked={fields && fields.request.applicant_is_worker == 1 ? true : false}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                                <div className="row">
                                                    <label className="checkbox radio">
                                                        Друго лице
                                                <input autoComplete="off" role="presentation"
                                                            type="radio"
                                                            name="request[applicant_is_worker]"
                                                            value="0"
                                                            checked={fields && fields.request.applicant_is_worker == 0 ? true : false}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>

                                                {fields && fields.request.applicant_is_worker == 0 &&
                                                    <>
                                                        <br />
                                                        <div className="row">
                                                            <div className="col">
                                                                <label className="checkbox radio">
                                                                    Фирма
                                            <input autoComplete="off" role="presentation"
                                                                        type="radio"
                                                                        name="request[worker_type]"
                                                                        value="company"
                                                                        checked={fields && fields.request.worker_type !== 'person' ? true : false}
                                                                        onChange={handleWorkerTypeChange}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                            <div className="col">
                                                                <label className="checkbox radio">
                                                                    Физическо лице
                                            <input autoComplete="off" role="presentation"
                                                                        type="radio"
                                                                        name="request[worker_type]"
                                                                        value="person"
                                                                        checked={fields && fields.request.worker_type === 'person' ? true : false}
                                                                        onChange={handleWorkerTypeChange}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        </div>

                                                        {fields && fields.request.worker_type === 'person'
                                                            ?
                                                            <div className="row for-choice">
                                                                <div className="col able-to-edit">
                                                                    <label htmlFor="worker_name">
                                                                        Име, презиме, фамилия
                                            </label>
                                                                    {editable && fields && fields.worker && fields.worker.name && <img
                                                                        src={editIcon}
                                                                        alt="edit"
                                                                        className="edit"
                                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                                    />}
                                                                    <Dropdown
                                                                        inputName="worker[name]"
                                                                        inputValue={fields && fields.worker && fields.worker.name}
                                                                        inputIdName="worker[id]"
                                                                        inputIdValue={fields && fields.worker && fields.worker.id}
                                                                        inputPlaceholder="Въведете име"
                                                                        inputDisabled={editable && fields && fields.worker && fields.worker.name ? true : false}
                                                                        url="persons/find"
                                                                        renderText={(data) => {
                                                                            return <>{data.name} {data.egn ? `(ЕГН: ${data.egn})` : ''}</>
                                                                        }}
                                                                        renderInputText={(data) => data.name}
                                                                        onChange={handleWorkerChange}
                                                                    />
                                                                </div>
                                                                <div className="col able-to-edit">
                                                                    <label htmlFor="worker_egn">
                                                                        ЕГН
                                            </label>
                                                                    {editable && fields && fields.worker && fields.worker.egn && <img
                                                                        src={editIcon}
                                                                        alt="edit"
                                                                        className="edit"
                                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                                    />}
                                                                    <input autoComplete="off" role="presentation"
                                                                        id="egn"
                                                                        name="worker[egn]"
                                                                        type="text"
                                                                        placeholder="Въведете ЕГН"
                                                                        value={fields && fields.worker && fields.worker.egn ? fields.worker.egn : ''}
                                                                        readOnly={editable && fields && fields.worker && fields.worker.egn ? true : false}
                                                                        onChange={handleFieldChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className="row for-choice">
                                                                <div className="col able-to-edit">
                                                                    <label htmlFor="worker_name">
                                                                        Наименование
                                            </label>
                                                                    {editable && fields && fields.worker && fields.worker.name && <img
                                                                        src={editIcon}
                                                                        alt="edit"
                                                                        className="edit"
                                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                                    />}
                                                                    <Dropdown
                                                                        inputName="worker[name]"
                                                                        inputValue={fields && fields.worker && fields.worker.name}
                                                                        inputIdName="worker[id]"
                                                                        inputIdValue={fields && fields.worker && fields.worker.id}
                                                                        inputPlaceholder="Въведете име"
                                                                        inputDisabled={editable && fields && fields.worker && fields.worker.name ? true : false}
                                                                        url="companies/find"
                                                                        onChange={handleWorkerChange}
                                                                    />
                                                                </div>

                                                                <div className="col able-to-edit">
                                                                    <label htmlFor="worker_eik">
                                                                        ЕИК
                                            </label>
                                                                    {editable && fields && fields.worker && fields.worker.eik && <img
                                                                        src={editIcon}
                                                                        alt="edit"
                                                                        className="edit"
                                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                                    />}
                                                                    <input autoComplete="off" role="presentation"
                                                                        id="eik"
                                                                        name="worker[eik]"
                                                                        type="text"
                                                                        placeholder="Въведете ЕИК / Булстат"
                                                                        value={fields && fields.worker && fields.worker.eik ? fields.worker.eik : ''}
                                                                        readOnly={editable && fields && fields.worker && fields.worker.eik ? true : false}
                                                                        onChange={handleFieldChange}
                                                                    />
                                                                </div>
                                                            </div>

                                                        }

                                                        <div className="row">

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="worker_country">
                                                                    Държава
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.country && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    inputName="worker[country]"
                                                                    inputValue={fields && fields.worker && fields.worker.country ? fields.worker.country.name : ''}
                                                                    inputIdName="worker[country_id]"
                                                                    inputIdValue={fields && fields.worker && fields.worker.country_id}
                                                                    inputPlaceholder="Изберете държава"
                                                                    inputDisabled={editable && fields && fields.worker && fields.worker.country ? true : false}
                                                                    url="countries/find"
                                                                    onChange={handleWorkerCountryChange}
                                                                />
                                                            </div>

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="worker_municipality">
                                                                    Община
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.municipality && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    ref={workerMunicipalityRef}
                                                                    inputName="worker[municipality]"
                                                                    inputValue={fields && fields.worker && fields.worker.municipality ? fields.worker.municipality.name : ''}
                                                                    inputIdName="worker[municipality_id]"
                                                                    inputIdValue={fields && fields.worker && fields.worker.municipality_id}
                                                                    inputPlaceholder="Изберете община"
                                                                    inputDisabled={editable && fields && fields.worker && fields.worker.municipality ? true : false}
                                                                    url="municipalities/find"
                                                                    params={{
                                                                        country_id: fields && fields.worker && fields.worker.country_id
                                                                    }}
                                                                    onChange={handleWorkerMunicipalityChange}
                                                                />
                                                            </div>

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="worker_town">
                                                                    Населено място
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.town && fields.worker.town && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    ref={workerTownRef}
                                                                    inputName="worker[town]"
                                                                    inputValue={fields && fields.worker && fields.worker.town
                                                                        ? fields.worker.town.id
                                                                            ? `${fields.worker.town.kind} ${fields.worker.town.name}`
                                                                            : fields.worker.town.name
                                                                        : ''}
                                                                    inputIdName="worker[town_id]"
                                                                    inputIdValue={fields && fields.worker && fields.worker.town_id}
                                                                    inputPlaceholder="Изберете населено място"
                                                                    inputDisabled={editable && fields && fields.worker && fields.worker.town && fields.worker.town.id ? true : false}
                                                                    url="towns/find"
                                                                    params={{
                                                                        munic_id: fields && fields.worker && fields.worker.municipality && fields.worker.municipality.id
                                                                    }}
                                                                    renderText={(data) => {
                                                                        return <>{data.kind} {data.name}</>;
                                                                    }}
                                                                    onChange={handleWorkerTownChange}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col able-to-edit">
                                                                <label htmlFor="worker_postcode">
                                                                    Пощенски код
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.postcode && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="worker_postcode"
                                                                    name="worker[postcode]"
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    placeholder=""
                                                                    value={fields && fields.worker && fields.worker.postcode ? fields.worker.postcode : ''}
                                                                    readOnly={editable && fields && fields.worker && fields.worker.postcode ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="worker_region">
                                                                    Район/квартал
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.region && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    ref={workerRegionRef}
                                                                    inputName="worker[region]"
                                                                    inputValue={fields && fields.worker && fields.worker.region ? fields.worker.region.name : ''}
                                                                    inputIdName="worker[region_id]"
                                                                    inputIdValue={fields && fields.worker && fields.worker.region_id}
                                                                    inputPlaceholder="Изберете район/квартал"
                                                                    inputDisabled={editable && fields && fields.worker && fields.worker.region ? true : false}
                                                                    url="regions/find"
                                                                    params={{
                                                                        town_id: fields && fields.worker && fields.worker.town_id
                                                                    }}

                                                                />
                                                            </div>

                                                            <div className="col able-to-edit">
                                                                <label htmlFor="worker_street">
                                                                    Улица
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.street && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <Dropdown
                                                                    ref={workerStreetRef}
                                                                    inputName="worker[street]"
                                                                    inputValue={fields && fields.worker && fields.worker.street ? `${fields.worker.street.suff} ${fields.worker.street.name}` : ''}
                                                                    inputIdName="worker[street_id]"
                                                                    inputIdValue={fields && fields.worker && fields.worker.street_id}
                                                                    inputPlaceholder="Изберете улица"
                                                                    inputDisabled={editable && fields && fields.worker && fields.worker.street ? true : false}
                                                                    url="streets/find"
                                                                    params={{
                                                                        town_id: fields && fields.worker && fields.worker.town_id
                                                                    }}
                                                                    renderText={(data) => {
                                                                        return <>{data.suff} {data.name}</>;
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>

                                                        <div className="row">
                                                            <div className="col col-auto able-to-edit">
                                                                <label htmlFor="worker_street_number">
                                                                    №
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.street_number && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="worker_street_number"
                                                                    name="worker[street_number]"
                                                                    type="number"
                                                                    min="0"
                                                                    step="1"
                                                                    placeholder=""
                                                                    className="field-small"
                                                                    value={fields && fields.worker && fields.worker.street_number ? fields.worker.street_number : ''}
                                                                    readOnly={editable && fields && fields.worker && fields.worker.street_number ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>

                                                            <div className="col col-auto able-to-edit">
                                                                <label htmlFor="worker_block">
                                                                    Блок
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.block && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="worker_block"
                                                                    name="worker[block]"
                                                                    type="text"
                                                                    placeholder=""
                                                                    className="field-small"
                                                                    value={fields && fields.worker && fields.worker.block ? fields.worker.block : ''}
                                                                    readOnly={editable && fields && fields.worker && fields.worker.block ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>

                                                            <div className="col col-auto able-to-edit">
                                                                <label htmlFor="worker_floor">
                                                                    Етаж
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.floor && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="worker_floor"
                                                                    name="worker[floor]"
                                                                    type="text"
                                                                    placeholder=""
                                                                    className="field-small"
                                                                    value={fields && fields.worker && fields.worker.floor ? fields.worker.floor : ''}
                                                                    readOnly={editable && fields && fields.worker && fields.worker.floor ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>

                                                            <div className="col col-auto able-to-edit">
                                                                <label htmlFor="worker_apartment">
                                                                    Апартамент
                                        </label>
                                                                {editable && fields && fields.worker && fields.worker.apartment && <img
                                                                    src={editIcon}
                                                                    alt="edit"
                                                                    className="edit"
                                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                                />}
                                                                <input autoComplete="off" role="presentation"
                                                                    id="worker_apartment"
                                                                    name="worker[apartment]"
                                                                    type="text"
                                                                    placeholder=""
                                                                    className="field-small"
                                                                    value={fields && fields.worker && fields.worker.apartment ? fields.worker.apartment : ''}
                                                                    readOnly={editable && fields && fields.worker && fields.worker.apartment ? true : false}
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>


                            <div className="col">
                                <div className="row">
                                    <label className="checkbox">
                                        ИМЕ НА ОБЕКТА
                                        <input autoComplete="off" role="presentation"
                                            type="checkbox"
                                            name="has_object_change"
                                            value="1"
                                            checked={fields && fields.has_object_change ? true : false}
                                            onChange={handleCheckboxChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="row">
                                    {fields && fields.has_object_change &&
                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            name="object[name]"
                                            value={fields.object ? fields.object.name : ''}
                                            onChange={handleFieldChange}
                                        />
                                    }
                                    <input autoComplete="off" role="presentation" type="hidden" name="object[id]" value={
                                        fields.object ? fields.object.id : ''
                                    }
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Application)