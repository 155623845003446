import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react';

//images
import successIcon from '../../../assets/img/modals/success.png'

function Success(props, ref) {

    const [success, setSuccess] = useState({
        overlay: false,
        modal: false,
        onClose: null,
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            setSuccess(success => ({
                ...success,
                overlay: true,
            }));
            setTimeout(() => {
                setSuccess(success => ({
                    ...success,
                    modal: true,
                }))
            }, 50);
        },
        close: () => {
            handleClose()
        },
        onClose: fn => {
            setSuccess(success => ({
                ...success,
                onClose: fn
            }))
        }
    }));

    const handleGenerate = е => {
        document.querySelector('.btn-generate').click()
        handleClose(е)
    }

    const handleClose = e => {
        e.preventDefault();

        setSuccess(success => ({
            ...success,
            modal: false,
        }))

        setTimeout(() => {
            setSuccess(success => ({
                ...success,
                overlay: false,
            }))
        }, 100);

        if (typeof success.onClose === 'function') {
            success.onClose();
        }
    }

    return (
        <div className={`${success.overlay ? 'visible' : ''} overlay`}>
            <div className={`${success.modal ? 'show' : ''} box`}>
                <img src={successIcon} alt="success" />
                <h3>
                    {props.mainMessage}
                </h3>
                {props.canGenerate ?
                    <p>
                        Използвайте бутона "Генериране", за да разпечатате документ.
                    </p>
                    :
                    <p className="secondary-text">
                        {props.secondaryMessage}
                    </p>
                }
                <div className="buttons">
                    <button className="btn-primary" onClick={handleClose}>
                        Добре
                    </button>
                    {props.canGenerate ?
                        <button className="btn-primary" onClick={handleGenerate}>
                            Генериране
                        </button>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Success);