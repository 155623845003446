import React, { useEffect, useLayoutEffect, useState, useRef } from 'react'

function Ckeditor(props) {

    let editor = null;
    let value = props.value;

    const toolbarRef = useRef(null);
    const editorRef = useRef(null);
    const [data, setData] = useState('');

    useEffect(() => {
        setData(props.value);
    }, [props.value]);

    useEffect(() => {

        if (window.editor) {
            return;
        }

        window.DecoupledDocumentEditor
            .create(editorRef.current, {

                toolbar: {
                    items: [
                        'bold',
                        'italic',
                        'underline',
                        'strikethrough',
                        '|',
                        'alignment',
                        '|',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'indent',
                        'outdent',
                        '|'
                    ]
                },
                language: 'bg',
                licenseKey: '',

            }).then(instance => {

                window.editor = instance;

                editor = instance;

                // Set a custom container for the toolbar.
                document.querySelector('.document-editor__toolbar').appendChild(instance.ui.view.toolbar.element);
                document.querySelector('.ck-toolbar').classList.add('ck-reset_all');

                editor.model.document.on('change', (e) => {
                    setData(editor.getData());

                    if (typeof props.handleModify === 'function') {
                        props.handleModify();
                    }
                })
            }).catch(error => {
                console.warn('Build id: 4kvi2rj5yrxy-mveog3r06b7r');
                console.error(error);
            });

        return () => {

            if (window.editor) {

                window.editor.destroy();

                // Remove the toolbar from DOM.
                // window.editor.ui.view.toolbar.element.remove();

                // Remove the editable from DOM.
                // window.editor.ui.view.editable.element.remove();

                window.editor = null;
                editor = null;

                console.log('Editor was destroyed');

                document.querySelectorAll('.document-editor__toolbar').forEach(e => {
                    e.innerHTML = '';
                });


                toolbarRef.current.innerHTML = '';

                toolbarRef.current.remove();
                editorRef.current.remove();

            }
        }

    }, []);

    useEffect(() => {
        if (window.editor) {
            window.editor.setData(value)

            if (typeof props.handleModify === 'function') {
                props.handleModify();
            }
        }
    }, [value, props.adkey]);

    return (
        <>
            <div ref={toolbarRef} className="document-editor__toolbar"></div>
            <div ref={editorRef} className="editor"></div>

            <textarea name={props.name} value={data} hidden />
        </>
    )
}

export default React.memo(Ckeditor, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});