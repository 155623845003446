import React, { useRef, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useFieldsContext } from '../../../../contexts/FieldsContext'
import Api from '../../../../helpers/Api'

//modals
import Question from '../../../modals/messages/Question'
import Attention from '../../../modals/messages/Attention'
import Success from '../../../modals/messages/Success'

//partials
import Application from './partials/shared/Application'
import { Texts } from '../../../../helpers/Texts'

//images
import addIcon from '../../../../assets/img/pages/icons/add.png'
import loader from '../../../../assets/img/app/loader.svg'
import { useAuthContext } from '../../../../contexts/AuthContext'

function Create(props) {

    const auth = useAuthContext();

    if (!auth.canWriteRequest()) {
        props.history.push('/outlets');
    }

    //refs
    const appRef = useRef(null)
    const companyValidationRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)
    const successModalRef = useRef(null)

    const fieldsContext = useFieldsContext()

    const initialState = {
        validated: false,
        loading: false,
        rewriteCompany: false,
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        if (state.validated) {
            setTimeout(() => submitData(), 100)
        }
    }, [state.validated])

    const handleCreate = e => {
        e.preventDefault()

        let form = document.getElementById('application');

        setState(prev => ({
            ...prev,
            loading: true
        }))

        let companyData = fieldsContext.simplifySerializedInputs(fieldsContext.serializeInputs(form, 'company'), 'company')

        // валидация за фирмата
        fieldsContext.validateCompany(companyData)
            .then(() => {
                setState(prev => ({
                    ...prev,
                    validated: true
                }))
            })
            .catch(res => {
                // да се покаже попъп
                companyValidationRef.current.open()

                // да се изпълни при клик на Отказ бутон
                companyValidationRef.current.onCancel(() => {
                    if (res.company) appRef.current.setCompany(res.company)
                    setState(prev => ({
                        ...prev,
                        rewriteCompany: false
                    }))
                })

                companyValidationRef.current.onSuccess(() => {
                    setState(prev => ({
                        ...prev,
                        rewriteCompany: true
                    }))
                })

                // да се изпълни при затваряне
                companyValidationRef.current.onClose(() => {
                    setState(prev => ({
                        ...prev,
                        validated: true
                    }))
                })
            })
    }


    const submitData = () => {

        let form = document.getElementById('application');

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form)

        if (!state.rewriteCompany) {
            data.append('company[readonly]', true)
        }

        Api.post('procedures/tradeobjects/addedit', data)
            .then(res => {

                if (res.data.id) {
                    // props.history.push(`/outlets/config/${res.data.id}`)

                    successModalRef.current.open();

                    successModalRef.current.onClose(() => {
                        props.history.push('/outlets?current=1&id=' + res.data.id);
                    })
                }
            }).catch(err => {
                if (err.response.status === 422) {
                    attentionModalRef.current.open(err.response.data.error)

                    let companyId = err.response.data.company_id;
                    let objectId = err.response.data.object_id;

                    if (companyId) {
                        appRef.current.setCompanyId(companyId);
                    }

                    if (objectId) {
                        appRef.current.setObjectId(objectId);
                    }

                    let fields = err.response.data.fields;

                    if (typeof fields === 'object') {
                        Object.entries(fields).map(i => {
                            if (i[1] && typeof i[1] === 'object') {
                                Object.entries(i[1]).map(j => {
                                    if (j[1]) {
                                        let name = `${i[0]}[${j[0]}]`;

                                        let inputs = form.querySelectorAll("[name='" + name + "']");

                                        inputs.forEach(i => {
                                            i.classList.add('validate');
                                        });
                                    }
                                })
                            } else {
                                if (i[1]) {
                                    let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            }
                        });
                    }

                } else {
                    errorModalRef.current.open()
                }
            }).finally(() => {
                setState(initialState)
                stopLoading()
            })
    }

    const stopLoading = () => {
        setState(prev => ({
            ...prev,
            loading: false
        }))
    }

    return (
        <>
            <Question
                ref={companyValidationRef}
                secondaryMessage={Texts.companyValidation}
                cancelButtonText="Не"
                continueButtonText="Да"
            />

            <Attention
                ref={attentionModalRef}
                mainMessage='Моля, попълнете всички полета!'
                secondaryMessage="За да запазите заявлението, трябва да попълните всички полета."
            />

            <Attention
                ref={errorModalRef}
                mainMessage='Нещо се обърка!'
            />

            <Success
                ref={successModalRef}
                mainMessage='Заявлението е запазено!'
            />

            <section>
                <div className="wrapper top negative-margin">
                    <h1>
                        <img src={addIcon} alt="add" />
                        Създаване на заявление
                    </h1>
                </div>
                <div className="wrapper">
                    <hr />
                    <div className="main-holder">
                        <form autoComplete="off" role="presentation" id="application" onSubmit={handleCreate}>
                            <Application
                                ref={appRef}
                                editable={false}
                            />
                            <hr />
                            <div className="buttons">
                                {state.loading ?
                                    <img src={loader} alt="loading" />
                                    :
                                    <>
                                        <Link
                                            to="/outlets?current=1"
                                            className="btn-default"
                                        >
                                            Отказ
                                        </Link>
                                        <button type="button" onClick={handleCreate}>
                                            Запазване
                                        </button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Create