import React, { useRef } from 'react'
import Dropdown from '../../../../app/Dropdown'
import editIcon from '../../../../../assets/img/pages/icons/edit-field.jpg'
import { useFieldsContext } from '../../../../../contexts/FieldsContext';

function Index(props) {

    const fieldsContext = useFieldsContext()

    const original = props.original || null;
    const editable = props.editable || false;
    const readonly = false;
    const fields = props.fields;
    const setFields = props.setFields;
    const handleFieldChange = props.handleFieldChange;

    const companyStreetRef = useRef(null)
    const personStreetRef = useRef(null)


    // dropdown change
    const handleCompanyChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                company: newData
            }))
        }
    }

    const handleApplicantChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                person: newData
            }))
        }
    }

    const handleCompanyTownChange = newData => {
        companyStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                town_id: newData.id,
                // town: newData.id ? newData : (prev.company ? prev.company.town : '')
                town: newData
            }
        }))
    }

    const handleApplicantTownChange = newData => {
        personStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            person: {
                ...prev.person,
                town_id: newData.id,
                town: newData
            }
        }))
    }

    return (
        <div className="col">
            <h3>
                <span>
                    3
                </span>
                Данни за фирмата или лицето, подлежащо на проверка
            </h3>
            <div className="holder">

                <div className="row">

                    <label className="checkbox radio">
                        Фирма
                        <input autoComplete="off" role="presentation"
                            type="radio"
                            name="applicant_type"
                            id="applicant_type"
                            value="company"
                            checked={fields && fields.applicant_type === 'person' ? false : true}
                            onChange={handleFieldChange}
                        />
                        <span className="checkmark"></span>
                    </label>

                    <label className="checkbox radio">
                        Физ. лице
                        <input autoComplete="off" role="presentation"
                            type="radio"
                            name="applicant_type"
                            id="applicant_type"
                            value="person"
                            checked={fields && fields.applicant_type === 'person' ? true : false}
                            onChange={handleFieldChange}
                        />
                        <span className="checkmark"></span>
                    </label>

                </div>


                {!fields.applicant_type || fields.applicant_type === 'company'
                    ?
                    <div className="row">
                        <div className="col">
                            <div className="row">

                                <div className="col able-to-edit">
                                    <label htmlFor="company_name">
                                        Точно наименование на фирмата
                                    </label>
                                    {!readonly && editable && original && original.company && original.company.name && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        inputName="company[name]"
                                        inputValue={fields && fields.company && fields.company.name}
                                        inputIdName="company[id]"
                                        inputIdValue={fields && fields.company && fields.company.id}
                                        inputPlaceholder="Въведете име"
                                        inputDisabled={readonly || (editable && original && original.company && original.company.name) ? true : false}
                                        url="companies/find"
                                        onChange={handleCompanyChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="company_eik">
                                        ЕИК / Булстат
                                    </label>
                                    {!readonly && editable && original && original.company && original.company.eik && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="eik"
                                        name="company[eik]"
                                        type="text"
                                        placeholder="Въведете ЕИК / Булстат"
                                        value={fields && fields.company && fields.company.eik}
                                        readOnly={readonly || (editable && original && original.company && original.company.eik) ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>

                                {/* <div className="col able-to-edit">
                                            <label htmlFor="company_phone">
                                                Тел. номер
                                            </label>
                                            {editable && original && original.company && original.company.phone && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="company_phone"
                                                name="company[phone]"
                                                type="text"
                                                placeholder="Въведете тел. номер"
                                                value={fields && fields.company && fields.company.phone}
                                                readOnly={editable && original && original.company && original.company.phone ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div> */}

                                <div className="col able-to-edit">
                                    <label htmlFor="company_mol">
                                        МОЛ
                                    </label>
                                    {!readonly && editable && original && original.company && original.company.mol && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="mol"
                                        name="company[mol]"
                                        type="text"
                                        placeholder="Въведете МОЛ"
                                        value={fields && fields.company && fields.company.mol}
                                        readOnly={readonly || (editable && original && original.company && original.company.mol) ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>

                            <div className="row">

                                <div className="col able-to-edit">
                                    <label htmlFor="company_town">
                                        Населено място
                                    </label>
                                    {!readonly && editable && original && original.company && original.company.town && original.company.town && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        inputName="company[town]"
                                        inputValue={fields && fields.company && fields.company.town
                                            ? fields.company.town.id
                                                ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                : fields.company.town.name
                                            : ''}
                                        inputIdName="company[town_id]"
                                        inputIdValue={fields && fields.company && fields.company.town_id}
                                        inputPlaceholder="Изберете населено място"
                                        inputDisabled={readonly || (editable && original && original.company && original.company.town && original.company.town.id) ? true : false}
                                        url="towns/find"
                                        renderText={(data) => {
                                            return <>{data.kind} {data.name}</>
                                        }}
                                        onChange={handleCompanyTownChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="company_street">
                                        Улица
                                    </label>
                                    {!readonly && editable && original && original.company && original.company.street && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        ref={companyStreetRef}
                                        inputName="company[street]"
                                        inputValue={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                        inputIdName="company[street_id]"
                                        inputIdValue={fields && fields.company && fields.company.street_id}
                                        inputPlaceholder="Изберете улица"
                                        inputDisabled={readonly || (editable && original && original.company && original.company.street) ? true : false}
                                        url="streets/find"
                                        params={{
                                            town_id: fields && fields.company && fields.company.town_id
                                        }}
                                        renderText={(data) => {
                                            return <>{data.suff} {data.name}</>
                                        }}
                                    />
                                </div>

                                <div className="col">
                                    <div className="row">
                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="company_street_number">
                                                №
                                            </label>
                                            {!readonly && editable && original && original.company && original.company.street_number && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="company_street_number"
                                                name="company[street_number]"
                                                type="number"
                                                min="0"
                                                step="1"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.company && fields.company.street_number}
                                                readOnly={readonly || (editable && original && original.company && original.company.street_number) ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="company_block">
                                                Блок
                                            </label>
                                            {!readonly && editable && original && original.company && original.company.block && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="company_block"
                                                name="company[block]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.company && fields.company.block}
                                                readOnly={readonly || (editable && original && original.company && original.company.block) ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="company_entrance">
                                                Вход
                                            </label>
                                            {!readonly && editable && original && original.company && original.company.entrance && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="company_entrance"
                                                name="company[entrance]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.company && fields.company.entrance}
                                                readOnly={readonly || (editable && original && original.company && original.company.entrance) ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="company_floor">
                                                Етаж
                                            </label>
                                            {!readonly && editable && original && original.company && original.company.floor && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="company_floor"
                                                name="company[floor]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.company && fields.company.floor}
                                                readOnly={readonly || (editable && original && original.company && original.company.floor) ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="col">


                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="person_name">
                                        Трите имена на лицето
                                    </label>
                                    {!readonly && editable && fields && fields.person && fields.person.name && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}

                                    <Dropdown
                                        key="an"
                                        inputName="person[name]"
                                        inputValue={fields && fields.person && fields.person.name}
                                        inputIdName="person[id]"
                                        inputIdValue={fields && fields.person && fields.person.id}
                                        inputPlaceholder="Въведете име"
                                        inputDisabled={readonly || (editable && fields && fields.person && fields.person.name) ? true : false}
                                        url="persons/find"
                                        renderText={(data) => {
                                            return <>{data.name} {data.egn && `(ЕГН: ${data.egn})`}</>;
                                        }}
                                        renderInputText={(data) => {
                                            return <>{data.name}</>;
                                        }}
                                        onChange={handleApplicantChange}
                                    />

                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="person_egn">
                                        ЕГН
                                    </label>
                                    {!readonly && editable && original && original.person && original.person.egn && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        key="ae"
                                        id="person_egn"
                                        name="person[egn]"
                                        type="text"
                                        placeholder="Въведете EГН"
                                        value={fields && fields.person && fields.person.egn}
                                        readOnly={readonly || (editable && original && original.person && original.person.egn) ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="person_phone">
                                        Тел. номер
                                    </label>
                                    {!readonly && editable && original && original.person && original.person.phone && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="person_phone"
                                        name="person[phone]"
                                        type="text"
                                        placeholder="Въведете тел. номер"
                                        value={fields && fields.person && fields.person.phone}
                                        readOnly={readonly || (editable && original && original.person && original.person.phone) ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="applicant_town">
                                        Населено място
                                    </label>
                                    {!readonly && editable && fields && fields.person && fields.person.town && fields.person.town.id && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        inputName="person[town]"
                                        inputValue={fields && fields.person && fields.person.town
                                            ? fields.person.town.id
                                                ? `${fields.person.town.kind} ${fields.person.town.name}`
                                                : fields.person.town.name
                                            : ''}
                                        inputIdName="person[town_id]"
                                        inputIdValue={fields && fields.person && fields.person.town_id}
                                        inputPlaceholder="Изберете населено място"
                                        inputDisabled={readonly || (editable && fields && fields.person && fields.person.town && fields.person.town.id) ? true : false}
                                        url="towns/find"
                                        renderText={(data) => {
                                            return <>{data.kind} {data.name}</>;
                                        }}
                                        onChange={handleApplicantTownChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="person_street">
                                        Улица
                                    </label>
                                    {!readonly && editable && fields && fields.person && fields.person.street && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        ref={personStreetRef}
                                        inputName="person[street]"
                                        inputValue={fields && fields.person && fields.person.street ? `${fields.person.street.suff} ${fields.person.street.name}` : ''}
                                        inputIdName="person[street_id]"
                                        inputIdValue={fields && fields.person && fields.person.street_id}
                                        inputPlaceholder="Изберете улица"
                                        inputDisabled={readonly || (editable && fields && fields.person && fields.person.street) ? true : false}
                                        url="streets/find"
                                        params={{
                                            town_id: fields && fields.person && fields.person.town_id
                                        }}
                                        renderText={(data) => {
                                            return <>{data.suff} {data.name}</>;
                                        }}
                                    />
                                </div>

                                <div className="col">
                                    <div className="row">
                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="person_street_number">
                                                №
                                            </label>
                                            {!readonly && editable && original && original.person && original.person.street_number && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="person_street_number"
                                                name="person[street_number]"
                                                type="number"
                                                min="0"
                                                step="1"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.person && fields.person.street_number}
                                                readOnly={readonly || (editable && original && original.person && original.person.street_number) ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>


                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="person_block">
                                                Блок
                                            </label>
                                            {!readonly && editable && original && original.person && original.person.block && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="person_block"
                                                name="person[block]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.person && fields.person.block}
                                                readOnly={readonly || (editable && original && original.person && original.person.block) ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="person_entrance">
                                                Вход
                                            </label>
                                            {!readonly && editable && original && original.person && original.person.entrance && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="person_entrance"
                                                name="person[entrance]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.person && fields.person.entrance}
                                                readOnly={readonly || (editable && original && original.person && original.person.entrance) ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>


                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="person_floor">
                                                Етаж
                                                </label>
                                            {!readonly && editable && original && original.person && original.person.floor && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="person_floor"
                                                name="person[floor]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.person && fields.person.floor}
                                                readOnly={readonly || (editable && original && original.person && original.person.floor) ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Index