import React from 'react'
import procedureIcon from '../../../../assets/img/pages/icons/procedure.png'
import { Link } from 'react-router-dom'

function Index(props) {
    return (
        <div className="table x2">
            <div className="r head">
                <div className="c">
                    <b>КАТЕГОРИЯ</b>
                </div>
                <div className="c">
                    <b>ОБЩ БРОЙ ОБЕКТИ</b>
                </div>
            </div>

            {props.data.map((t, i) =>
                <React.Fragment key={i}>
                    <div className="r">
                        <div className="c">
                            <img src={procedureIcon} alt="procedure" />
                            <Link to={`/tourism/reports?group=objects&type_id=${t.id}&type=${t.name}`}>
                                <b>{t.name}</b>
                            </Link>
                        </div>
                        <div className="c">
                            <b>{t.count}</b>
                        </div>
                    </div>
                    {t.types.map((a, i) =>
                        <div className="r">
                            <div className="c sub">
                                <img src={procedureIcon} alt="procedure" />
                                <Link to={`/tourism/reports?group=objects&type_id=${t.id}&type=${t.name}&activity_id=${a.id}&activity=${a.name}`}>
                                    {a.name}
                                </Link>
                            </div>
                            <div className="c">
                                {a.count}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}

export default Index