import React, { useState, useEffect } from 'react'

function Steps(props) {
    let currentStep = props.getCurrent

    const [steps, setSteps] = useState({
        current: 1,
    })

    useEffect(() => {
        setSteps(steps => ({
            ...steps,
            current: currentStep,
        }))
    }, [currentStep])

    const handlePosition = e => {
        let position = e.target.getAttribute('data-position')
        setSteps(steps => ({
            ...steps,
            current: position,
        }))
        props.handleCurrent(position)
    }

    return (
        <div className="scroller">
            <ul className="steps with-scroller">
                <li
                    data-position="1"
                    className={[
                        props.firstStepFinish ? 'finished' : 'current',
                        currentStep == 1 ? 'active' : '',
                        props.firstStepFinish && !props.secondStepFinish ? 'current-is-next' : ''
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Заявление
                </span>
                </li>
                <li
                    data-position="2"
                    className={[
                        props.secondStepFinish ? 'finished' : '',
                        currentStep == 2 && props.firstStepFinish ? 'active' : '',
                        props.firstStepFinish && !props.secondStepFinish ? 'current' : '',
                        props.secondStepFinish && !props.thirdStepFinish ? 'current-is-next' : ''
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Протокол
                </span>
                </li>
                <li
                    data-position="3"
                    className={[
                        props.thirdStepFinish ? 'finished' : '',
                        currentStep == 3 && props.secondStepFinish ? 'active' : '',
                        props.secondStepFinish && !props.thirdStepFinish ? 'current' : '',
                        props.thirdStepFinish && !props.fourthStepFinish ? 'current-is-next' : ''
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Заповед
                </span>
                </li>
                <li
                    data-position="4"
                    className={[
                        props.fourthStepFinish ? 'finished' : '',
                        currentStep == 4 && props.thirdStepFinish ? 'active' : '',
                        props.thirdStepFinish && !props.fourthStepFinish ? 'current' : '',
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Временно удостоверение
                </span>
                </li>
                <li
                    data-position="5"
                    className={[
                        props.fifthStepFinish ? 'finished' : '',
                        currentStep == 5 && props.fourthStepFinish ? 'active' : '',
                        props.fourthStepFinish && !props.fifthStepFinish ? 'current' : '',
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Констативен протокол
                </span>
                </li>
                <li
                    data-position="6"
                    className={[
                        props.sixthStepFinish ? 'finished' : '',
                        currentStep == 6 && props.fifthStepFinish ? 'active' : '',
                        props.fifthStepFinish && !props.sixthStepFinish ? 'current' : '',
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Предложение
                </span>
                </li>
                <li
                    data-position="7"
                    className={[
                        props.seventhStepFinish ? 'finished' : '',
                        currentStep == 7 && props.sixthStepFinish ? 'active' : '',
                        props.sixthStepFinish && !props.seventhStepFinish ? 'current' : '',
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Заповед
                </span>
                </li>
            </ul>
        </div>
    )
}

export default Steps