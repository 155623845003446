import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'

function Objects(props, ref) {
    const [objects, setObjects] = useState({
        overlay: false,
        modal: false,
        data: []
    })

    useEffect(() => {

        if (typeof props.inputName === 'undefined') {
            alert('Missing inputName prop');
        }

        if (typeof props.data !== 'undefined') {
            setObjects(prev => ({
                ...prev,
                data: props.data
            }));
        }
    }, [props.data]);

    useImperativeHandle(ref, () => ({
        open: () => {
            setObjects(objects => ({
                ...objects,
                overlay: true,
            }))
            setTimeout(() => {
                setObjects(objects => ({
                    ...objects,
                    modal: true,
                }))
            }, 50)
        },
        close: () => {
            handleClose()
        }
    }))


    const handleClose = e => {

        e.preventDefault();

        setObjects(objects => ({
            ...objects,
            modal: false,
        }))
        setTimeout(() => {
            setObjects(objects => ({
                ...objects,
                overlay: false,
            }))
        }, 100)
    }

    const handleSelect = e => {
        let id = e.target.getAttribute('data-id');

        let data = objects.data.map(o => {
            if (o.object.id == id && o.selectable) {
                o.selected = !o.selected;
            }

            return o;
        });

        let selected = data.filter(o => {
            return o.selected == true;
        })

        setObjects(prev => ({
            ...prev,
            data: data
        }));

        if (typeof props.setSelected === 'function') {
            props.setSelected(selected);
        }

    }

    return (
        <div className={`${objects.overlay ? 'visible' : ''} overlay`}>
            <div className={`${objects.modal ? 'show' : ''} form-box`}>
                <h2>
                    Избиране на обекти
               </h2>
                <p>
                    Изберете обектите чрез маркиране
                </p>
                <hr />
                <div className="scroller" style={{ height: '200px' }}>
                    <div className="row">
                        <div className="col">
                            {objects.data.map(o =>
                                <label key={o.id} className={['row checkbox', o.selectable == false ? 'not-selectable' : ''].join(' ')}>
                                    {o.object.activity ? o.object.activity.name : ''}
                                    {o.object.town ? ` - ${o.object.town.kind} ${o.object.town.name}` : ''}
                                    {o.object.street ? ` ${o.object.street.suff} ${o.object.street.name}` : ''}
                                    {o.object.street_number ? ` № ${o.object.street_number}` : ''}
                                    {o.object.name ? ` - ${o.object.name}` : ''}
                                    &nbsp; - вх.№ {o.request_no} / {o.request_date} г.
                                    <input autoComplete="off" role="presentation"
                                        data-id={o.object.id}
                                        name={`${props.inputName}[objects][]`}
                                        value={o.procedure_id}
                                        type="checkbox"
                                        checked={o.selected}
                                        readOnly={o.selectable == false}
                                        onChange={handleSelect}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            )}
                        </div>
                    </div>
                </div>
                <hr />
                <div className="buttons">
                    <button type="button" onClick={handleClose}>
                        Затвори
                    </button>

                </div>
            </div>
        </div>
    )
}

export default forwardRef(Objects)