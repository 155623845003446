import React from 'react'

function Table(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>Име</th>
                    <th>EИК / Булстат</th>
                    <th>Седалище</th>
                    <th>Тел. номер</th>

                    <th className="options">Опции</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(s =>
                    <>
                        <tr key={'s-' + s.id} data-id={s.id}>

                            <td>
                                {s.name}
                            </td>
                            <td>
                                {s.eik}
                            </td>
                            <td>
                                {s.town
                                    ? [
                                        s.town.kind + ' ' + s.town.name,
                                        s.street ? s.street.suff + ' ' + s.street.name : '',
                                        s.street_number ? '№' + s.street_number : '',
                                        s.block ? 'бл. ' + s.block : '',
                                        s.entrance ? 'вх. ' + s.entrance : '',
                                        s.floor ? 'ет. ' + s.floor : '',
                                        s.apartment ? 'ап. ' + s.apartment : ''
                                    ].filter(el => el !== '').join(', ')

                                    : ''
                                }
                            </td>
                            <td>
                                {s.phone}
                            </td>
                            <td>
                                <button
                                    onClick={() => props.handleEdit(s.id)}
                                    className="btn-edit"
                                >
                                    Редактиране
                                </button>
                            </td>
                        </tr>

                    </>
                )}
            </tbody>
        </table>
    )
}

export default Table