import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import Api from '../../../../../../helpers/Api'


//partials
import FilesPanel from '../../../../../partials/FilesPanel'
import Application from '../../partials/shared/Application'
import { Texts } from '../../../../../../helpers/Texts'


//images
import protocolIcon from '../../../../../../assets/img/pages/icons/protocol.png'
import loader from '../../../../../.././assets/img/app/loader.svg'

//modals
import SuccessModal from '../../../../../modals/messages/Success'
import Attention from '../../../../../modals/messages/Attention'
import Question from '../../../../../modals/messages/Question'
import { useAuthContext } from '../../../../../../contexts/AuthContext'
import { useFieldsContext } from '../../../../../../contexts/FieldsContext'

function Index(props) {
    const history = useHistory()
    const auth = useAuthContext()

    //refs
    const appRef = useRef(null)
    const companyValidationRef = useRef(null)
    const SuccessModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const data = props.data
    const original = props.original
    const setData = props.setData
    const setOriginal = props.setOriginal
    const loading = props.loading
    const editable = props.editable || false

    const fieldsContext = useFieldsContext()

    const initialState = {
        validated: false,
        rewriteCompany: false,
    }

    const [application, setApplication] = useState({
        position: 1,
        loading: false,
        fields: initialState,
        modified: false,
    })

    const key = new URLSearchParams(window.location.search).get('_') || 'key'

    useEffect(() => {
        if (application.fields.validated) {
            setTimeout(() => {
                submitData()
            }, 100)
        }
    }, [application.fields.validated])

    useEffect(() => {
        let form = document.querySelector('.main-holder form');

        if (form) {
            form.addEventListener('change', fieldsListener);
        }

        return () => {
            if (form) {
                form.removeEventListener('change', fieldsListener)
            }
        }

    }, [])

    useLayoutEffect(() => {
        if (!auth.canWriteRequest() || data.closed) {
            let form = document.querySelector('.main-holder form');

            if (form) {
                setTimeout(() => {
                    form.querySelectorAll('input').forEach(i => {
                        i.setAttribute('readonly', true);
                    })
                    form.querySelectorAll('img.edit').forEach(i => {
                        i.style.display = 'none';
                    })
                    form.querySelectorAll('.MuiSelect-root').forEach(i => {
                        i.classList.add('disabled');
                    })
                })
            }

        }
    }, [])

    const fieldsListener = () => {
        if (!application.modified) {
            setApplication(prev => ({
                ...prev,
                modified: true
            }))
        }
    }

    const handleConfig = e => {
        e.preventDefault()

        let form = document.getElementById('application');

        setApplication(application => ({ ...application, loading: true }))

        let companyData = fieldsContext.simplifySerializedInputs(fieldsContext.serializeInputs(form, 'company'), 'company')

        if (!data.closed) {
            // валидация за фирмата
            fieldsContext.validateCompany(companyData)
                .then(res => {
                    setApplication(application => ({
                        ...application,
                        fields: {
                            ...application.fields,
                            validated: true
                        }
                    }))
                }).catch(res => {
                    // да се покаже попъп
                    companyValidationRef.current.open()

                    // да се изпълни при клик на Отказ бутон
                    companyValidationRef.current.onCancel(() => {
                        if (res.company) appRef.current.setCompany(res.company)

                        setApplication(application => ({
                            ...application,
                            fields: {
                                ...application.fields,
                                rewriteCompany: false
                            }
                        }))
                    })

                    companyValidationRef.current.onSuccess(() => {

                        setApplication(application => ({
                            ...application,
                            fields: {
                                ...application.fields,
                                rewriteCompany: true
                            }
                        }))
                    })

                    // да се изпълни при затваряне
                    companyValidationRef.current.onClose(() => {
                        setApplication(application => ({
                            ...application,
                            fields: {
                                ...application.fields,
                                validated: true
                            }
                        }))
                    })
                })
        } else {
            submitData();
        }
    }

    const submitData = () => {

        let form = document.getElementById('application');

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form)

        // id на процедурата
        data.append('id', props.data.id)

        data.append('trade_object_procedure_id', original.trade_object_procedure_id)

        if (!application.fields.rewriteCompany) {
            data.append('company[readonly]', true)
        }

        Api.post('procedures/outdoortrade/addedit', data)
            .then(res => {
                SuccessModalRef.current.open()

                if (res.data.id) {
                    props.refreshData();
                    history.push('?_=' + new Date().getTime())
                }
            }).catch(err => {
                if (err.response.status === 422) {
                    attentionModalRef.current.open(err.response.data.error)

                    let fields = err.response.data.fields;

                    if (typeof fields === 'object') {
                        Object.entries(fields).map(i => {
                            if (i[1] && typeof i[1] === 'object') {
                                Object.entries(i[1]).map(j => {
                                    if (j[1]) {
                                        let name = `${i[0]}[${j[0]}]`;

                                        let inputs = form.querySelectorAll("[name='" + name + "']");

                                        inputs.forEach(i => {
                                            i.classList.add('validate');
                                        });
                                    }
                                })
                            } else {
                                if (i[1]) {
                                    let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            }
                        });
                    }

                } else {
                    errorModalRef.current.open()
                }
            }).finally(() => {
                handleCancelModify()

                setApplication(application => ({
                    ...application,
                    fields: initialState,
                }))
            })
    }

    const handleCancelModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: false,
            loading: false
        }))
    }

    return (
        !loading && original && original.id &&
        <>
            <Question
                ref={companyValidationRef}
                secondaryMessage={Texts.companyValidation}
                cancelButtonText="Не"
                continueButtonText="Да"
            />

            <SuccessModal
                ref={SuccessModalRef}
                mainMessage='Заявлението беше запазено успешно!'
                secondaryMessage='Моля, попълнете всички полета, за да продължите към Констативен протокол.'
                canGenerate={data.can_generate_application}
            />

            <Attention
                ref={attentionModalRef}
                mainMessage='Моля, попълнете всички полета!'
                secondaryMessage="За да запазите заявлението, трябва да попълните всички полета."
            />

            <Attention
                ref={errorModalRef}
                mainMessage='Нещо се обърка!'
            />

            <h1 className="config-heading">
                <img src={protocolIcon} alt="protocol" />
                Заявление за търговия на открито
            </h1>
            <hr />

            <div className="main-holder">
                <form autoComplete="off" role="presentation" id="application" onSubmit={handleConfig}>
                    <Application
                        ref={appRef}
                        key={key}
                        data={data}
                        original={original}
                        editable={editable}
                        setData={setData}
                    />
                    <hr />
                    <div className="buttons">
                        {!data.closed && auth.canWriteRequest()
                            ?
                            application.loading ?
                                <img src={loader} alt="loading" />
                                :
                                application.modified ?
                                    <>
                                        <Link
                                            to={"/outlets/config/outdoor/" + props.original.id + '?_=' + new Date().getTime()}
                                            className="btn-default"
                                            onClick={handleCancelModify}
                                        >
                                            Отказ
                                        </Link>
                                        <button type="button" onClick={handleConfig}>
                                            Запази
                                        </button>
                                    </>
                                    :
                                    ''
                            :
                            ''
                        }
                    </div>
                </form>
                <FilesPanel
                    id={original.procedure_id}
                    generateFile={data.can_generate_application}
                    type="application"
                    allowEdit={!data.closed && auth.canWriteRequest()}
                    uploadedDocument={data.uploaded_signed_application}
                    refreshData={props.refreshData}
                />
            </div>

        </>
    )
}

export default Index