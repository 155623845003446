import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Api from '../../../helpers/Api'

//partials
import Steps from './steps/Nav'
import Application from './steps/application/Index'
import Protocol from './steps/protocol/Index'
import Certificate from './steps/certificate/Index'

//images
import protocolIcon from '../../../assets/img/pages/icons/protocol.png'
import { useAuthContext } from '../../../contexts/AuthContext'

function Config() {

    const auth = useAuthContext()
    const params = useParams()

    // id на процедурата
    const id = params.id

    // Стейта на навигацията е вдигнат едно ниво нагоре, за да може лесно да се променя
    const [config, setConfig] = useState({
        step: 1,
        firstStepFinish: false,
        secondStepFinish: false,
        thirdStepFinish: false,
    })

    const [procedure, setProcedure] = useState({
        data: null,
        original: null,
        loading: true,
        refresh: false,
        initial: true,
    })

    useEffect(() => loadProcedure(), [procedure.refresh])

    const handleCurrent = current => {
        let currentStep
        currentStep = current

        setConfig(config => ({
            ...config,
            step: currentStep,
        }))
    }

    //steps finish
    const handleFinish = (position, isFinished) => {
        if (position == 1) {
            setConfig(config => ({
                ...config,
                firstStepFinish: true,
            }))
        } else if (position == 2) {
            setConfig(config => ({
                ...config,
                firstStepFinish: true,
                secondStepFinish: true,
            }))
        } else if (position == 3) {
            setConfig(config => ({
                ...config,
                firstStepFinish: true,
                secondStepFinish: true,
                thirdStepFinish: isFinished,
            }))
        }
    }

    const loadProcedure = () => {
        Api.get(`/procedures/obligations/show/${id}`)
            .then(res => {

                if (auth.canWriteRequest()) {
                    if (procedure.initial) {
                        setConfig(config => ({
                            ...config,
                            step: res.data.actual_phase_id == 0 ? '3' : res.data.actual_phase_id
                        }))
                    }

                    if (res.data.actual_phase_id == 2) {
                        handleFinish(1, true)
                    }
                    else if (res.data.actual_phase_id == 3) {
                        handleFinish(1, true)
                        handleFinish(2, true)
                    }
                    else if (res.data.actual_phase_id == 0) {
                        handleFinish(1, true)
                        handleFinish(2, true)
                        handleFinish(3, true)
                    }

                } else {
                    setConfig(config => ({
                        ...config,
                        step: 2
                    }))
                }

                setProcedure(prev => ({
                    ...prev,
                    data: res.data,
                    original: res.data,
                    loading: false,
                    initial: false
                }))


            }).catch(err => {
                setProcedure(prev => ({
                    ...prev,
                    data: null,
                    original: null,
                    loading: false
                }))
            })
    }

    const setData = data => {
        setProcedure(prev => ({
            ...prev,
            data: data
        }))
    }

    const setOriginal = data => {
        setProcedure(prev => ({
            ...prev,
            data: data,
            original: data
        }))

        if (data.actual_phase_id == 2) {
            handleFinish(1, true)
        } else if (data.actual_phase_id == 3) {
            handleFinish(1, true)
            handleFinish(2, true)
        }
        else if (data.actual_phase_id == 0) {
            handleFinish(1, true)
            handleFinish(2, true)
            handleFinish(3, true)
        }

    }

    const refreshData = () => {
        setProcedure(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }))
    }

    return (
        <section id="outlets-config">
            {!procedure.loading &&
                <div className="wrapper top negative-margin">
                    {auth.canWriteRequest() &&
                        <Steps
                            typeId={procedure.original && procedure.original.request && procedure.original.request.type_id}
                            handleCurrent={handleCurrent}
                            getCurrent={config.step}
                            firstStepFinish={config.firstStepFinish}
                            secondStepFinish={config.secondStepFinish}
                            thirdStepFinish={config.thirdStepFinish}
                        />
                    }
                    {config.step == 1 ?
                        <Application
                            loading={procedure.loading}
                            data={procedure.data}
                            original={procedure.original}
                            editable={true}
                            setData={setData}
                            setOriginal={setOriginal}
                            refreshData={refreshData}
                            handleCurrent={handleCurrent}
                            handleFinish={handleFinish}
                        />
                        : config.step == 2 ?
                            <Protocol
                                loading={procedure.loading}
                                data={procedure.data}
                                original={procedure.original}
                                editable={true}
                                setData={setData}
                                setOriginal={setOriginal}
                                refreshData={refreshData}
                                handleCurrent={handleCurrent}
                                handleFinish={handleFinish}
                            />
                            :
                            <Certificate
                                loading={procedure.loading}
                                data={procedure.data}
                                original={procedure.original}
                                editable={true}
                                setData={setData}
                                setOriginal={setOriginal}
                                refreshData={refreshData}
                                handleCurrent={handleCurrent}
                                handleFinish={handleFinish}
                            />
                    }

                </div>
            }
        </section>
    )
}

export default Config