import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'

//material ui :(
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'

//images
import removeIcon from '../../assets/img/modals/remove-small.png'
import Api from '../../helpers/Api'
import * as Constants from '../../helpers/Constants'
import { Button } from '@material-ui/core'
import { useFieldsContext } from '../../contexts/FieldsContext'
import SelectPosition from '../modals/SelectPosition'
import editIcon from '../../assets/img/pages/icons/edit.png'
import editIcon2 from '../../assets/img/pages/icons/edit-field.jpg'

function SelectSign(props, ref) {

    const fieldsContext = useFieldsContext();

    const selectPositionModalRef = useRef(null);

    let inputReasonName;

    let mayorInput = props.mayorInput;
    let mayorInputPositionName;

    if (mayorInput) {
        if (mayorInput.includes(']')) {
            mayorInputPositionName = mayorInput.replace(']', '_position_id]');
            inputReasonName = mayorInput.replace(']', '_reason]');
        } else {
            mayorInputPositionName = mayorInput + '_position_id';
            inputReasonName = mayorInput + '_reason';
        }
    }

    let accountantInput = props.accountantInput;
    let accountantInputPositionName;

    if (accountantInput) {
        if (accountantInput.includes(']')) {
            accountantInputPositionName = accountantInput.replace(']', '_position_id]');
        } else {
            accountantInputPositionName = accountantInput + '_position_id';
        }
    }

    let agreedInput = props.agreedInput;
    let agreedInputPositionName;

    if (agreedInput) {
        if (agreedInput.includes(']')) {
            agreedInputPositionName = agreedInput.replace(']', '_position_id]');
        } else {
            agreedInputPositionName = agreedInput + '_position_id';
        }
    }

    let preparedInput = props.preparedInput;
    let preparedInputPositionName;

    if (preparedInput) {
        if (preparedInput.includes(']')) {
            preparedInputPositionName = preparedInput.replace(']', '_position_id]');
        } else {
            preparedInputPositionName = preparedInput + '_position_id';
        }
    }

    const [signs, setSigns] = useState({
        overlay: false,
        modal: false,
        mayor: null,
        accountant: null,
        prepared: null,
        agreed: null,
        selected: {},
        names: [],
        reason: null,
        refresh: false,
        openSelect: {
            mayor: false,
            accountant: false,
            agreed: false,
            prepared: false
        }
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }))

    useEffect(() => {

        if (props.allowMayorSign && !props.mayorInput) {
            alert('Missing input name!');
        }

        if (props.allowAccountantSign && !props.accountantInput) {
            alert('Missing input name!');
        }

        if (props.allowAgreedSign && !props.agreedInput) {
            alert('Missing input name!');
        }

        if (props.allowPreparedSign && !props.preparedInput) {
            alert('Missing input name!');
        }

        if (props.agreed) {
            let selected = {};

            props.agreed.map(p => {
                let id = Number(p.id);
                selected[id] = p;
            })

            setSigns(prev => ({
                ...prev,
                selected: selected
            }))
        }

        if (props.mayor) {
            setSigns(prev => ({
                ...prev,
                mayor: props.mayor
            }));

        }

        if (props.mayorReason) {
            setSigns(prev => ({
                ...prev,
                reason: props.mayorReason
            }));
        }

        if (props.accountant) {
            setSigns(prev => ({
                ...prev,
                accountant: props.accountant
            }));

        }

        if (props.prepared) {
            setSigns(prev => ({
                ...prev,
                prepared: props.prepared
            }));

        }


    }, [props]);

    useEffect(() => {
        Api.get('officials/find')
            .then(res => {
                setSigns(prev => ({
                    ...prev,
                    names: res.data
                }))
            })
    }, [signs.refresh]);

    useEffect(() => {

        if (props.defaultPreparedId) {
            let prepared = signs.prepared;

            if (!prepared) {
                prepared = signs.names.filter(u => Number(u.id) === Number(props.defaultPreparedId))[0];

                if (prepared) {
                    handleSelectChange('prepared', prepared.id);
                }
            }
        }

    }, [signs.names])


    const handleOpen = () => {
        setSigns(signs => ({
            ...signs,
            overlay: true,
        }))
        setTimeout(() => {
            setSigns(signs => ({
                ...signs,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setSigns(signs => ({
            ...signs,
            modal: false,
        }))
        setTimeout(() => {
            setSigns(signs => ({
                ...signs,
                overlay: false,
            }))
        }, 100)
    }

    const handleSelectChange = (k, v) => {
        let id = v;

        if (id) {
            let person = signs.names.filter(p => {
                return p.id == id;
            })[0];

            props.handleSelected(k, person);

            if (typeof props.handleModify === 'function') {
                props.handleModify();
            }
        }

    }

    const handleSelectClose = id => {
        setSigns(prev => ({
            ...prev,
            openSelect: {
                ...prev.openSelect,
                [id]: false
            }
        }))
    }

    const handleSelectOpen = id => {
        setSigns(prev => ({
            ...prev,
            openSelect: {
                ...prev.openSelect,
                [id]: true
            }
        }))
    }

    const handleChange = e => {
        let value = e.target.value
        let selected = {}

        value.map(val => {
            let id = Number(val);

            signs.names.map(p => {
                if (p.id === id) {
                    selected[id] = p;
                }
            })
        })

        // setSigns(signs => ({
        //     ...signs,
        //     selected: selected
        // }));

        props.handleSelected('agreed', Object.values(selected));

        if (typeof props.handleModify === 'function') {
            props.handleModify();
        }

    }

    const handleRemoveSelect = e => {
        let id = Number(e.target.getAttribute('data-id'))
        let selected = signs.selected

        delete selected[id]

        // setSigns(signs => ({
        //     ...signs,
        //     selected: selected
        // }));

        props.handleSelected('agreed', Object.values(selected));

        if (typeof props.handleModify === 'function') {
            props.handleModify();
        }
    }

    const handleAdd = id => {
        const modal = fieldsContext.addOfficialPersonRef.current;

        handleClose();
        handleSelectClose(id);

        let groupId = 0;

        switch (id) {
            case 'mayor':
                groupId = Constants.MAYORS;
                break;
            case 'agreed':
                groupId = Constants.AGREED;
                break;
            case 'accountant':
                groupId = Constants.ACCOUNTANTS;
                break;
            case 'prepared':
                groupId = Constants.PREPARED;
                break;
        }


        modal.open();

        if (groupId) {
            modal.setSelectedGroups([groupId]);
        }

        modal.onSuccess(data => {

            modal.close();

            setSigns(prev => ({
                ...prev,
                names: prev.names.concat(data)
                // refresh: new Date().getTime()
            }))

            let inThatGroup = data.groups.filter(g => g.id == groupId).length;

            if (inThatGroup) {
                if (id === 'agreed') {
                    let selected = signs.selected;

                    selected[data.id] = data;

                    props.handleSelected('agreed', Object.values(selected));
                } else {
                    props.handleSelected(id, data);
                }

                if (typeof props.handleModify === 'function') {
                    props.handleModify();
                }
            }

            handleOpen();
            // handleSelectOpen(id);
        })

        modal.onClose(() => {
            handleOpen();
        })
    }

    const handleShowPositions = (id, person) => {

        if (person._positions.length > 1) {
            handleSelectClose(id);
            handleClose();

            let modal = selectPositionModalRef.current;

            let positionId = person.position ? person.position.id : null;

            modal.open(person, positionId, person._positions);

            modal.onSelect(position => {
                person.position = position;

                if (id === 'agreed') {
                    let selected = signs.selected;

                    selected[person.id] = person;

                    // console.log(selected)

                    props.handleSelected('agreed', Object.values(selected));
                } else {
                    props.handleSelected(id, person);
                }

                if (typeof props.handleModify === 'function') {
                    props.handleModify();
                }

            });

            modal.onClose(() => {
                handleOpen();
                setTimeout(() => {
                    handleSelectOpen(id);
                }, 500);
            });
        }

    }

    const handleReason = e => {
        let val = e.target.value;

        setSigns(prev => ({
            ...prev,
            reason: val,
        }));
    }

    return (
        <>
            <SelectPosition
                ref={selectPositionModalRef}
            />

            <div className={`${signs.overlay ? 'visible' : ''} overlay`}>
                <div className={`${signs.modal ? 'show' : ''} form-box box-small`}>
                    <h2>
                        Подпис
                    </h2>
                    <p>
                        Изберете членове на подписване на документа
                    </p>
                    <hr />
                    {props.allowMayorSign &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="multiple-select">
                                        Кмет
                                </label>
                                    <FormControl variant="outlined">
                                        <Select
                                            open={signs.openSelect.mayor}
                                            onOpen={e => handleSelectOpen('mayor')}
                                            onClose={e => handleSelectClose('mayor')}
                                            onChange={e => handleSelectChange('mayor', e.target.value)}
                                            value={signs.mayor != null ? signs.mayor.id : ''}
                                            renderValue={() => signs.mayor ? signs.mayor.name : ''}
                                        >
                                            {signs.names.map(name =>
                                                name.groups.filter(g => g.id == Constants.MAYORS).length || (props.mayor && props.mayor.id == name.id)
                                                    ?
                                                    <MenuItem
                                                        key={name.id}
                                                        value={name.id}

                                                    >
                                                        <Checkbox
                                                            checked={signs.mayor && signs.mayor.id == name.id ? true : false}
                                                            value={name.id}
                                                        />
                                                        <div>
                                                            <p>{name.name}</p>
                                                            {name.position && name.position.id &&
                                                                <div className="sign-position" onClick={e => handleShowPositions('mayor', name)}>
                                                                    <i className={name._positions.length > 1 ? 'editable' : ''}>
                                                                        {signs.mayor && signs.mayor.id === name.id && signs.mayor.position ? signs.mayor.position.name : name.position.name}
                                                                    </i>
                                                                    {name._positions.length > 1 &&
                                                                        <span>
                                                                            <img src={editIcon} />
                                                                        </span>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </MenuItem>
                                                    :
                                                    ''
                                            )}

                                            <Button
                                                className="btn-add full-width"
                                                onClick={() => handleAdd('mayor')}
                                            >
                                                Добави нов служител
                                        </Button>

                                        </Select>
                                    </FormControl>

                                    <input
                                        type="hidden"
                                        name={mayorInput}
                                        value={signs.mayor ? signs.mayor.id : ''}
                                    />

                                    <input
                                        type="hidden"
                                        name={mayorInputPositionName}
                                        value={signs.mayor && signs.mayor.position ? signs.mayor.position.id : ''}
                                    />
                                </div>
                            </div>
                            {signs.mayor && !signs.mayor.mayor &&
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label>
                                            Основание
                                        </label>

                                        <input
                                            type="text"
                                            name={inputReasonName}
                                            value={signs.reason}
                                            onChange={handleReason}

                                        />
                                    </div>
                                </div>
                            }
                            <hr />

                        </>
                    }
                    {props.allowAccountantSign &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="multiple-select">
                                        Счетоводител
                                </label>

                                    <FormControl variant="outlined">
                                        <Select
                                            open={signs.openSelect.accountant}
                                            onOpen={e => handleSelectOpen('accountant')}
                                            onClose={e => handleSelectClose('accountant')}
                                            onChange={e => handleSelectChange('accountant', e.target.value)}
                                            value={signs.accountant != null ? signs.accountant.id : ''}
                                            renderValue={() => signs.accountant ? signs.accountant.name : ''}
                                        >
                                            {signs.names.map(name =>
                                                name.groups.filter(g => g.id == Constants.ACCOUNTANTS).length || (props.accountant && props.accountant.id == name.id)
                                                    ?
                                                    <MenuItem
                                                        key={name.id}
                                                        value={name.id}

                                                    >
                                                        <Checkbox
                                                            checked={signs.accountant && signs.accountant.id == name.id ? true : false}
                                                            value={name.id}
                                                        />
                                                        <div>
                                                            <p>{name.name}</p>
                                                            {name.position && name.position.id &&
                                                                <div className="sign-position" onClick={e => handleShowPositions('accountant', name)}>
                                                                    <i className={name._positions.length > 1 ? 'editable' : ''}>
                                                                        {signs.accountant && signs.accountant.id === name.id && signs.accountant.position ? signs.accountant.position.name : name.position.name}
                                                                    </i>
                                                                    {name._positions.length > 1 &&
                                                                        <span>
                                                                            <img src={editIcon} />
                                                                        </span>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </MenuItem>
                                                    :
                                                    ''
                                            )}

                                            <Button
                                                className="btn-add full-width"
                                                onClick={() => handleAdd('accountant')}
                                            >
                                                Добави нов служител
                                        </Button>

                                        </Select>
                                    </FormControl>

                                    <input
                                        type="hidden"
                                        name={accountantInput}
                                        value={signs.accountant ? signs.accountant.id : ''}
                                    />

                                    <input
                                        type="hidden"
                                        name={accountantInputPositionName}
                                        value={signs.accountant && signs.accountant.position ? signs.accountant.position.id : ''}
                                    />
                                </div>
                            </div>
                            <hr />
                        </>
                    }
                    {props.allowAgreedSign &&
                        <>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="multiple-select">
                                        Съгласували
                                    </label>
                                    <FormControl variant="outlined">
                                        <Select
                                            id="multiple-select"
                                            multiple
                                            open={signs.openSelect.agreed}
                                            onOpen={e => handleSelectOpen('agreed')}
                                            onClose={e => handleSelectClose('agreed')}
                                            onChange={handleChange}
                                            value={Object.keys(signs.selected).map(id => Number(id))}
                                            renderValue={() => Object.values(signs.selected).map(p => p.name).join(', ')}
                                        >
                                            {signs.names.map(name =>
                                                name.groups.filter(g => g.id == Constants.AGREED).length || signs.selected[name.id]
                                                    ?
                                                    <MenuItem
                                                        key={name.id}
                                                        value={name.id}

                                                    >
                                                        <Checkbox
                                                            checked={signs.selected[name.id] ? true : false}
                                                            value={name.id}
                                                        />
                                                        <div>
                                                            <p>{name.name}</p>
                                                            {name.position && name.position.id &&
                                                                <div className="sign-position" onClick={e => handleShowPositions('agreed', name)}>
                                                                    <i className={name._positions.length > 1 ? 'editable' : ''}>
                                                                        {signs.selected[name.id] && signs.selected[name.id].position ? signs.selected[name.id].position.name : name.position.name}
                                                                    </i>
                                                                    {name._positions.length > 1 &&
                                                                        <span>
                                                                            <img src={editIcon} />
                                                                        </span>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </MenuItem>
                                                    :
                                                    ''
                                            )}
                                            <Button
                                                className="btn-add full-width"
                                                onClick={() => handleAdd('agreed')}
                                            >
                                                Добави нов служител
                                            </Button>
                                        </Select>
                                    </FormControl>
                                    <ul className="selected-list">
                                        {Object.values(signs.selected).map(each =>

                                            <li key={'s-' + each.id}>
                                                <p>
                                                    {each.name}
                                                </p>
                                                <i>
                                                    {each.position.name}
                                                </i>
                                                <img
                                                    src={removeIcon}
                                                    className="remove-selected"
                                                    data-id={each.id}
                                                    onClick={handleRemoveSelect}
                                                />
                                                <input
                                                    type="hidden"
                                                    name={`${agreedInput}[]`}
                                                    value={each.id}
                                                />

                                                <input
                                                    type="hidden"
                                                    name={`${agreedInputPositionName}[]`}
                                                    value={each.position ? each.position.id : ''}
                                                />
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            <hr />
                        </>
                    }
                    {props.allowPreparedSign &&
                        <div className="row">
                            <div className="col">
                                <label htmlFor="multiple-select">
                                    {props.preparedLabel || 'Изготвил'}
                                </label>
                                <FormControl variant="outlined">
                                    <Select
                                        open={signs.openSelect.prepared}
                                        onOpen={e => handleSelectOpen('prepared')}
                                        onClose={e => handleSelectClose('prepared')}
                                        onChange={e => handleSelectChange('prepared', e.target.value)}
                                        value={signs.prepared != null ? signs.prepared.id : ''}
                                        renderValue={() => signs.prepared ? signs.prepared.name : ''}
                                    >
                                        {signs.names.map(name =>
                                            name.groups.filter(g => g.id == Constants.PREPARED).length || (props.prepared && props.prepared.id == name.id)
                                                ?
                                                <MenuItem
                                                    key={name.id}
                                                    value={name.id}

                                                >
                                                    <Checkbox
                                                        checked={signs.prepared && signs.prepared.id == name.id ? true : false}
                                                        value={name.id}
                                                    />
                                                    <div>
                                                        <p>{name.name}</p>
                                                        {name.position && name.position.id &&
                                                            <div className="sign-position" onClick={e => handleShowPositions('prepared', name)}>
                                                                <i className={name._positions.length > 1 ? 'editable' : ''}>
                                                                    {signs.prepared && signs.prepared.id === name.id && signs.prepared.position ? signs.prepared.position.name : name.position.name}
                                                                </i>
                                                                {name._positions.length > 1 &&
                                                                    <span>
                                                                        <img src={editIcon} />
                                                                    </span>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </MenuItem>
                                                :
                                                ''
                                        )}

                                        <Button
                                            className="btn-add full-width"
                                            onClick={() => handleAdd('prepared')}
                                        >
                                            Добави нов служител
                                        </Button>

                                    </Select>
                                </FormControl>

                                <input
                                    type="hidden"
                                    name={preparedInput}
                                    value={signs.prepared ? signs.prepared.id : ''}
                                />

                                <input
                                    type="hidden"
                                    name={preparedInputPositionName}
                                    value={signs.prepared && signs.prepared.position ? signs.prepared.position.id : ''}
                                />

                            </div>
                        </div>
                    }
                    <hr />
                    <div className="buttons">
                        <button onClick={handleClose}>
                            Затвори
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(SelectSign)