import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../contexts/AuthContext';

function Index(props) {

    const auth = useAuthContext();

    const [value, setValue] = useState([]);

    useEffect(() => {

        let v = [];
        let n = '';

        if (props.work_time_morning) {
            n = 'от ' + props.work_time_morning;

            if (props.work_time_afternoon_win) {
                n += ' лято до ' + (props.work_time_afternoon || '--:--');
                n += ' зима до ' + (props.work_time_afternoon_win || '--:--');
            } else {
                n += ' до ' + (props.work_time_afternoon || '--:--');
            }

            v.push(n);
        }

        if (props.work_time_morning_sat) {
            n = 'събота от ' + props.work_time_morning_sat;

            if (props.work_time_afternoon_sat_win) {
                n += ' лято до ' + (props.work_time_afternoon_sat || '--:--');
                n += ' зима до ' + (props.work_time_afternoon_sat_win || '--:--');
            } else {
                n += ' до ' + (props.work_time_afternoon_sat || '--:--');
            }

            v.push(n);
        }

        if (props.work_time_morning_sun) {
            n = 'неделя от ' + props.work_time_morning_sun;

            if (props.work_time_afternoon_sat_win) {
                n += ' лято до ' + (props.work_time_afternoon_sun || '--:--');
                n += ' зима до ' + (props.work_time_afternoon_sun_win || '--:--');
            } else {
                n += ' до ' + (props.work_time_afternoon_sun || '--:--');
            }

            v.push(n);
        }

        if (props.work_time_preholiday_morning) {
            n = 'предпразнични дни от ' + props.work_time_preholiday_morning;

            if (props.work_time_preholiday_afternoon_win) {
                n += ' лято до ' + (props.work_time_preholiday_afternoon || '--:--');
                n += ' зима до ' + (props.work_time_preholiday_afternoon_win || '--:--');
            } else {
                n += ' до ' + (props.work_time_preholiday_afternoon || '--:--');
            }

            v.push(n);
        }

        if (props.work_time_holiday_morning) {
            n = 'празнични дни от ' + props.work_time_holiday_morning;

            if (props.work_time_holiday_afternoon_win) {
                n += ' лято до ' + (props.work_time_holiday_afternoon || '--:--');
                n += ' зима до ' + (props.work_time_holiday_afternoon_win || '--:--');
            } else {
                n += ' до ' + (props.work_time_holiday_afternoon || '--:--');
            }

            v.push(n);
        }

        setValue(v);

    }, [props]);

    return (
        <>
            <label htmlFor="work_time">
                Работно време
            </label>
            <div className="row">
                {!auth.canWriteRequest() || props.readOnly ?
                    ''
                    :
                    <button
                        className="btn-add btn-add-simple"
                        onClick={e => props.handleShowWorkTime(e)}
                    >
                    </button>
                }

                <input autoComplete="off" role="presentation"
                    id="work_time"
                    name="work_time"
                    type="text"
                    value={value.join(' | ')}
                    placeholder={!props.hidePlaceholder ? "Добавете работно време..." : ""}
                    style={{ marginLeft: '10px' }}
                    readOnly={props.readOnly || (props.editable && value.length > 0) ? true : false}
                    onFocus={props.handleShowWorkTime}
                />
            </div>
        </>
    )
}

export default Index;