import React from 'react'

function Table(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>Име</th>
                    <th>Населено място</th>
                    {props.zones.map(z =>
                        <th>Зона {z.code}</th>
                    )}
                    <th className="options">Опции</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(s =>
                    <>
                        <tr key={'s-' + s.id} data-id={s.id}>

                            <td>
                                {s.suff} {s.name}
                            </td>

                            <td>
                                {s.town
                                    ? `${(s.town.kind || '')} ${s.town.name}`
                                    : ''
                                }
                            </td>
                            {props.zones.map(z =>
                                <td>
                                    {s.zones_keyed[z.code]
                                        ?
                                        `${s.zones_keyed[z.code].from_num} - ${s.zones_keyed[z.code].to_num}`
                                        :
                                        '-'}
                                </td>
                            )}
                            <td>
                                <button
                                    onClick={() => props.handleEdit(s.id)}
                                    className="btn-edit"
                                >Редактиране</button>
                            </td>
                        </tr>

                    </>
                )}
            </tbody>
        </table>
    )
}

export default Table