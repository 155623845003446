import React from 'react'
import Header from './components/app/Header'
import Footer from './components/app/Footer'
import { Switch, Route, useLocation, useHistory } from "react-router-dom"

//context
import { useFieldsContext } from './contexts/FieldsContext'
import { useAuthContext } from './contexts/AuthContext'

//partials
import Submenu from './components/app/Submenu'
import AdminSubmenu from './components/app/AdminSubmenu'

//routes
import ProtectedRoute from './routes/Protected'

//pages
import AdminIndex from './components/pages/administration/streets/Index'
import AdminCompanies from './components/pages/administration/companies/Index'
import AdminZones from './components/pages/administration/zones/Index'
import AdminOfficials from './components/pages/administration/officials/Index'
import AdminProfiles from './components/pages/administration/profiles/Index'

import Profile from './components/pages/user/Index'
import Login from './components/pages/user/Login'
import ForgottenPassword from './components/pages/user/ForgottenPassword'

import Outlets from './components/pages/outlets/Index'
import OutletsReports from './components/pages/outlets/reports/Reports'
import OutletsCreate from './components/pages/outlets/inside/Create'
import OutletsChange from './components/pages/outlets/inside/Change'

import OutletsCreateOutdoor from './components/pages/outlets/outdoor/Create'
import OutletsConfig from './components/pages/outlets/inside/Config'
import OutletsConfigOutdoor from './components/pages/outlets/outdoor/Config'

import TemporaryShops from './components/pages/temporary_shops/Index'
import TemporaryShopsReports from './components/pages/temporary_shops/reports/Reports'
import TemporaryShopsCreate from './components/pages/temporary_shops/Create'
import TemporaryShopsConfig from './components/pages/temporary_shops/Config'

import MobileFacilities from './components/pages/mobile_facilities/Index'
import MobileFacilitiesReports from './components/pages/mobile_facilities/reports/Reports'
import MobileFacilitiesCreate from './components/pages/mobile_facilities/Create'
import MobileFacilitiesConfig from './components/pages/mobile_facilities/Config'


import CloseProcedure from './components/pages/close/Create'
import CloseProcedureConfig from './components/pages/close/Edit'
import CancelProcedure from './components/pages/cancel/Create'
import CancelProcedureConfig from './components/pages/cancel/Edit'

import Tourism from './components/pages/tourism/Index'
import TourismReports from './components/pages/tourism/reports/Reports'
import TourismCreateRestaurant from './components/pages/tourism/restaurant/Create'
import TourismCreateHotel from './components/pages/tourism/hotel/Create'
import TourismCreateCertificate from './components/pages/tourism/certificate/Create'
import TourismConfigRestaurant from './components/pages/tourism/restaurant/Config'
import TourismConfigHotel from './components/pages/tourism/hotel/Config'
import TourismCreateChange from './components/pages/tourism/change/Create'
import TourismConfigChange from './components/pages/tourism/change/Config'
import TourismCreateClose from './components/pages/tourism/closure/Create'
import TourismConfigClose from './components/pages/tourism/closure/Config'

import Obligations from './components/pages/obligations/Index'
import ObligationCreate from './components/pages/obligations/Create'
import ObligationConfig from './components/pages/obligations/Config'

import AddOrEditOfficialPerson from './components/modals/AddOrEditOfficialPerson'


//styles
import './assets/css/app.css'
import './assets/css/buttons.css'
import './assets/css/fields.css'
import './assets/css/filters.css'
import './assets/css/tables.css'
import './assets/css/pages.css'
import './assets/css/modals.css'
import AddOrEditUser from './components/modals/AddOrEditUser'

function App() {
  const location = useLocation()
  const history = useHistory()
  const auth = useAuthContext()
  const fields = useFieldsContext();

  if (!auth.isLogged) {
    if (location.pathname !== '/login') {
      history.push('/login');
    }
  }

  if (location.pathname == '/') {
    let firstTab = document.querySelector('header nav a');

    if (firstTab) {
      firstTab.click();
    }
  }

  return (
    <div className="App">
      {auth && auth.isLogged && <Header />}
      <main>
        {auth && auth.isLogged &&
          (
            location.pathname.indexOf('administration') > -1
              ? <AdminSubmenu location={location} />
              : <Submenu location={location} />
          )
        }
        <Route render={({ location }) => (
          <Switch location={location}>

            <Route path="/" exact render={() => ""} />

            {/* admin panel */}
            <ProtectedRoute path="/administration" view={auth.isAdmin()} exact component={AdminIndex} />
            <ProtectedRoute path="/administration/companies" view={auth.isAdmin()} exact component={AdminCompanies} />
            <ProtectedRoute path="/administration/zones" view={auth.isAdmin()} exact component={AdminZones} />
            <ProtectedRoute path="/administration/officials" view={auth.isAdmin()} exact component={AdminOfficials} />
            <ProtectedRoute path="/administration/profiles" view={auth.isAdmin()} exact component={AdminProfiles} />

            {/* profile */}
            <Route path="/login" exact component={Login} />
            <Route path="/forgotten-password" exact component={ForgottenPassword} />
            <ProtectedRoute path="/profile" exact component={Profile} />

            {/* outlets */}
            <ProtectedRoute path="/outlets" view={auth.canViewTradeObjects()} exact component={Outlets} />
            <ProtectedRoute path="/outlets/reports" view={auth.canViewTradeObjects()} exact component={OutletsReports} />

            <ProtectedRoute path="/outlets/create" view={auth.canViewTradeObjects()} exact component={OutletsCreate} />
            <ProtectedRoute path="/outlets/create/outdoor/:id" view={auth.canViewTradeObjects()} exact component={OutletsCreateOutdoor} />

            <ProtectedRoute path="/outlets/change/:id" view={auth.canViewTradeObjects()} exact component={OutletsChange} />

            <ProtectedRoute path="/outlets/config/outdoor/:id" view={auth.canViewTradeObjects()} exact component={OutletsConfigOutdoor} />
            <ProtectedRoute path="/outlets/config/:id" view={auth.canViewTradeObjects()} exact component={OutletsConfig} />

            <ProtectedRoute path="/outlets/cancel/:type/:id" view={auth.canViewTradeObjects()} exact component={CancelProcedure} />
            <ProtectedRoute path="/outlets/cancel/edit/:type/:id" view={auth.canViewTradeObjects()} exact component={CancelProcedureConfig} />

            <ProtectedRoute path="/outlets/close/:type/:id" view={auth.canViewTradeObjects()} exact component={CloseProcedure} />
            <ProtectedRoute path="/outlets/close/edit/:type/:id" view={auth.canViewTradeObjects()} exact component={CloseProcedureConfig} />
            {/* outlets end */}

            {/* temporary-shops */}
            <ProtectedRoute path="/temporary-shops" view={auth.canViewTemporaryShops()} exact component={TemporaryShops} />
            <ProtectedRoute path="/temporary-shops/reports" view={auth.canViewTemporaryShops()} exact component={TemporaryShopsReports} />
            <ProtectedRoute path="/temporary-shops/create" view={auth.canViewTemporaryShops()} exact component={TemporaryShopsCreate} />
            <ProtectedRoute path="/temporary-shops/config/:id" view={auth.canViewTemporaryShops()} exact component={TemporaryShopsConfig} />
            <ProtectedRoute path="/temporary-shops/cancel/:type/:id" view={auth.canViewTemporaryShops()} exact component={CancelProcedure} />
            <ProtectedRoute path="/temporary-shops/cancel/edit/:type/:id" view={auth.canViewTemporaryShops()} exact component={CancelProcedureConfig} />
            <ProtectedRoute path="/temporary-shops/close/:type/:id" view={auth.canViewTemporaryShops()} exact component={CloseProcedure} />
            <ProtectedRoute path="/temporary-shops/close/edit/:type/:id" view={auth.canViewTemporaryShops()} exact component={CloseProcedureConfig} />
            {/* temporary-shops end */}



            {/* mobile facilities */}
            <ProtectedRoute path="/mobile-facilities/reports" view={auth.canViewMobileFacilities()} exact component={MobileFacilitiesReports} />
            <ProtectedRoute path="/mobile-facilities" view={auth.canViewMobileFacilities()} exact component={MobileFacilities} />
            <ProtectedRoute path="/mobile-facilities/create" view={auth.canViewMobileFacilities()} exact component={MobileFacilitiesCreate} />
            <ProtectedRoute path="/mobile-facilities/config/:id" view={auth.canViewMobileFacilities()} exact component={MobileFacilitiesConfig} />
            <ProtectedRoute path="/mobile-facilities/cancel/:type/:id" view={auth.canViewMobileFacilities()} exact component={CancelProcedure} />
            <ProtectedRoute path="/mobile-facilities/cancel/edit/:type/:id" view={auth.canViewMobileFacilities()} exact component={CancelProcedureConfig} />
            <ProtectedRoute path="/mobile-facilities/close/:type/:id" view={auth.canViewMobileFacilities()} exact component={CloseProcedure} />
            <ProtectedRoute path="/mobile-facilities/close/edit/:type/:id" view={auth.canViewMobileFacilities()} exact component={CloseProcedureConfig} />
            {/* mobile facilities end  */}


            {/* tourism */}
            <ProtectedRoute path="/tourism" view={auth.canViewCategorizations()} exact component={Tourism} />
            <ProtectedRoute path="/tourism/reports" view={auth.canViewCategorizations()} exact component={TourismReports} />
            <ProtectedRoute path="/tourism/create/restaurant" view={auth.canViewCategorizations()} exact component={TourismCreateRestaurant} />
            <ProtectedRoute path="/tourism/config/restaurant/:id" view={auth.canViewCategorizations()} exact component={TourismConfigRestaurant} />

            <ProtectedRoute path="/tourism/create/hotel" view={auth.canViewCategorizations()} exact component={TourismCreateHotel} />
            <ProtectedRoute path="/tourism/config/hotel/:id" view={auth.canViewCategorizations()} exact component={TourismConfigHotel} />

            <ProtectedRoute path="/tourism/change/create/:id" view={auth.canViewCategorizations()} exact component={TourismCreateChange} />
            <ProtectedRoute path="/tourism/change/config/:id" view={auth.canViewCategorizations()} exact component={TourismConfigChange} />

            <ProtectedRoute path="/tourism/close/create/:id" view={auth.canViewCategorizations()} exact component={TourismCreateClose} />
            <ProtectedRoute path="/tourism/close/config/:id" view={auth.canViewCategorizations()} exact component={TourismConfigClose} />
            <ProtectedRoute path="/tourism/cancel/:type/:id" view={auth.canViewCategorizations()} exact component={CancelProcedure} />
            <ProtectedRoute path="/tourism/cancel/edit/:type/:id" view={auth.canViewCategorizations()} exact component={CancelProcedureConfig} />

            <ProtectedRoute
              path="/tourism/create/certificate"
              exact
              component={TourismCreateCertificate}
            />

            {/* tourism end  */}

            {/* obligations */}
            <ProtectedRoute path="/obligations" view={auth.canViewObligations()} exact component={Obligations} />
            <ProtectedRoute path="/obligations/create/:type" view={auth.canViewObligations()} exact component={ObligationCreate} />
            <ProtectedRoute path="/obligations/config/:id" view={auth.canViewObligations()} exact component={ObligationConfig} />
            {/* obligations end */}

            {/* errors */}
            <Route path="*" render={() => { return (<>404</>) }} />

          </Switch>
        )} />
      </main>

      {/* modals */}
      <AddOrEditOfficialPerson
        ref={fields.addOfficialPersonRef}
      />

      <AddOrEditUser
        ref={fields.addUserRef}
      />

      {auth && auth.isLogged && <Footer />}
    </div>
  )
}

export default App
