import React from 'react'

function Table(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>Име</th>
                    <th>Потребителско име</th>
                    <th>Отдел</th>
                    <th>Позиция</th>
                    <th className="options">Опции</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(s =>
                    <React.Fragment key={'s-' + s.id}>
                        <tr data-id={s.id}>

                            <td>
                                {s.name}
                            </td>
                            <td>
                                {s.username}
                            </td>
                            <td>
                                {s.department ? s.department.name : ''}
                            </td>
                            <td>
                                {s.position ? s.position.name : ''}
                            </td>
                            <td>

                                <div className="row" style={{ margin: '0' }}>
                                    <div className="col">
                                        <button
                                            onClick={() => props.handleEdit(s.id)}
                                            className="btn-edit"
                                        >
                                            Редактиране
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button
                                            onClick={() => props.handleDelete(s.id)}
                                            className="btn-small"
                                        >
                                            Премахване
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </React.Fragment>
                )}
            </tbody>
        </table >
    )
}

export default Table