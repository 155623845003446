import React, { useState, useEffect } from 'react'

//partials
import Steps from '../partials/shared/Nav'
import Application from './steps/application/Index'
import Protocol from '../partials/shared/protocol/Index'
import ProcedureOrder from '../partials/shared/procedure_order/Index'
import TemporaryCertificate from '../partials/shared/temporary_certificate/Index'
import StatementProtocol from './steps/statement_protocol/Index'
import Suggestion from '../partials/shared/suggestion/Index'
import Order from '../partials/shared/order/Index'
import { useParams } from 'react-router-dom'
import Api from '../../../../helpers/Api'

function Config() {

    const params = useParams()

    // id на процедурата
    const id = params.id

    //Стейта на навигацията е вдигнат едно ниво нагоре, за да може лесно да се променя
    const [config, setConfig] = useState({
        step: 1,
        firstStepFinish: false,
        secondStepFinish: false,
        thirdStepFinish: false,
        fourthStepFinish: false,
        fifthStepFinish: false,
        sixthStepFinish: false,
        seventhStepFinish: false,
        eightStepFinish: false,
    })

    const [procedure, setProcedure] = useState({
        data: null,
        original: null,
        loading: true,
        refresh: false,
        initial: true,
    })

    useEffect(() => {
        loadProcedure()
    }, [procedure.refresh])

    const handleCurrent = current => {

        let currentStep
        currentStep = current

        setConfig(config => ({
            ...config,
            step: currentStep,
        }))
    }

    //steps finish
    const handleFinish = (position, finish) => {
        let isFinished
        isFinished = finish

        if (position == 1) {
            setConfig(config => ({
                ...config,
                firstStepFinish: isFinished,
            }))
        } else if (position == 2) {
            setConfig(config => ({
                ...config,
                secondStepFinish: isFinished,
            }))
        } else if (position == 3) {
            setConfig(config => ({
                ...config,
                thirdStepFinish: isFinished,
            }))
        } else if (position == 4) {
            setConfig(config => ({
                ...config,
                fourthStepFinish: isFinished,
            }))
        } else if (position == 5) {
            setConfig(config => ({
                ...config,
                fifthStepFinish: isFinished,
            }))
        } else if (position == 6) {
            setConfig(config => ({
                ...config,
                sixthStepFinish: isFinished,
            }))
        } else if (position == 7) {
            setConfig(config => ({
                ...config,
                seventhStepFinish: isFinished,
            }))
        }
    }

    const loadProcedure = () => {
        Api.get(`/procedures/categorization/show/${id}`)
            .then(res => {

                if (procedure.initial) {
                    setConfig(config => ({
                        ...config,
                        step: res.data.actual_phase_id == 0 ? 7 : res.data.actual_phase_id
                    }))
                }

                if (res.data.actual_phase_id == 2) {
                    handleFinish(1, true)
                } else if (res.data.actual_phase_id == 3) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                } else if (res.data.actual_phase_id == 4) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                    handleFinish(3, true)
                } else if (res.data.actual_phase_id == 5) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                    handleFinish(3, true)
                    handleFinish(4, true)
                } else if (res.data.actual_phase_id == 6) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                    handleFinish(3, true)
                    handleFinish(4, true)
                    handleFinish(5, true)
                } else if (res.data.actual_phase_id == 7) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                    handleFinish(3, true)
                    handleFinish(4, true)
                    handleFinish(5, true)
                    handleFinish(6, true)
                } else if (res.data.actual_phase_id == 0) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                    handleFinish(3, true)
                    handleFinish(4, true)
                    handleFinish(5, true)
                    handleFinish(6, true)
                    handleFinish(7, true)
                }

                setProcedure(prev => ({
                    ...prev,
                    data: res.data,
                    original: res.data,
                    loading: false,
                    initial: false
                }))


            }).catch(err => {
                setProcedure(prev => ({
                    ...prev,
                    data: null,
                    original: null,
                    loading: false
                }))
            })
    }

    const setData = data => {
        setProcedure(prev => ({
            ...prev,
            data: data
        }))
    }

    const setOriginal = data => {
        setProcedure(prev => ({
            ...prev,
            data: data,
            original: data
        }))

    }

    const refreshData = () => {
        setProcedure(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }))
    }

    return (
        <section id="outlets-outdoor-config">
            {!procedure.loading &&
                <div className="wrapper top negative-margin">
                    <Steps
                        handleCurrent={handleCurrent}
                        getCurrent={config.step}
                        firstStepFinish={config.firstStepFinish}
                        secondStepFinish={config.secondStepFinish}
                        thirdStepFinish={config.thirdStepFinish}
                        fourthStepFinish={config.fourthStepFinish}
                        fifthStepFinish={config.fifthStepFinish}
                        sixthStepFinish={config.sixthStepFinish}
                        seventhStepFinish={config.seventhStepFinish}
                    />
                    {config.step == 1 ?
                        <Application
                            loading={procedure.loading}
                            data={procedure.data}
                            original={procedure.original}
                            editable={true}
                            setData={setData}
                            setOriginal={setOriginal}
                            refreshData={refreshData}
                            handleCurrent={handleCurrent}
                            handleFinish={handleFinish}
                        />

                        :
                        config.step == 2 ?
                            <Protocol
                                loading={procedure.loading}
                                data={procedure.data}
                                original={procedure.original}
                                editable={true}
                                setData={setData}
                                setOriginal={setOriginal}
                                refreshData={refreshData}
                                handleCurrent={handleCurrent}
                                handleFinish={handleFinish}
                            />
                            :
                            config.step == 3 ?
                                <ProcedureOrder
                                    loading={procedure.loading}
                                    data={procedure.data}
                                    original={procedure.original}
                                    editable={true}
                                    setData={setData}
                                    setOriginal={setOriginal}
                                    refreshData={refreshData}
                                    handleCurrent={handleCurrent}
                                    handleFinish={handleFinish}
                                />
                                :
                                config.step == 4 ?
                                    <TemporaryCertificate
                                        loading={procedure.loading}
                                        data={procedure.data}
                                        original={procedure.original}
                                        editable={true}
                                        setData={setData}
                                        setOriginal={setOriginal}
                                        refreshData={refreshData}
                                        handleCurrent={handleCurrent}
                                        handleFinish={handleFinish}
                                    />
                                    :
                                    config.step == 5 ?
                                        <StatementProtocol
                                            loading={procedure.loading}
                                            data={procedure.data}
                                            original={procedure.original}
                                            editable={true}
                                            setData={setData}
                                            setOriginal={setOriginal}
                                            refreshData={refreshData}
                                            handleCurrent={handleCurrent}
                                            handleFinish={handleFinish}
                                        />
                                        :
                                        config.step == 6 ?
                                            <Suggestion
                                                loading={procedure.loading}
                                                data={procedure.data}
                                                original={procedure.original}
                                                editable={true}
                                                setData={setData}
                                                setOriginal={setOriginal}
                                                refreshData={refreshData}
                                                handleCurrent={handleCurrent}
                                                handleFinish={handleFinish}
                                            />
                                            :
                                            config.step == 7 ?
                                                <Order
                                                    loading={procedure.loading}
                                                    data={procedure.data}
                                                    original={procedure.original}
                                                    editable={true}
                                                    setData={setData}
                                                    setOriginal={setOriginal}
                                                    refreshData={refreshData}
                                                    handleCurrent={handleCurrent}
                                                    handleFinish={handleFinish}
                                                />
                                                :
                                                ''
                    }


                </div>
            }
        </section>
    )
}

export default Config