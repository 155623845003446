import React, { useState, useImperativeHandle, forwardRef } from 'react'

//images
import questionIcon from '../../../assets/img/modals/question.png'

function SignValidation(props, ref) {

    const [question, setQuestion] = useState({
        overlay: false,
        modal: false,
        applicant: null,
        regards: [],
        signs: {},
        onSuccess: null,
        onCancel: null,
        onClose: null,
    })

    useImperativeHandle(ref, () => ({
        open: (applicant, regards, signs) => {
            setQuestion(question => ({
                ...question,
                overlay: true,
                applicant: applicant,
                regards: regards,
                signs: signs
            }))
            setTimeout(() => {
                setQuestion(question => ({
                    ...question,
                    modal: true,
                }))
            }, 50)
        },
        close: () => {
            close();
        },
        onSuccess: fn => {
            setQuestion(question => ({
                ...question,
                onSuccess: fn
            }))
        },
        onCancel: fn => {
            setQuestion(question => ({
                ...question,
                onCancel: fn
            }))
        },
        onClose: fn => {
            setQuestion(question => ({
                ...question,
                onClose: fn
            }))
        }
    }))

    const close = () => {
        setQuestion(question => ({
            ...question,
            modal: false,
        }))

        setTimeout(() => {
            setQuestion(question => ({
                ...question,
                overlay: false,
            }))
        }, 100)

        if (typeof question.onClose === 'function') {
            question.onClose();
        }
    }

    const handleClose = () => {

        close();

        if (typeof props.onCancel === 'function') {
            props.onCancel();
        }

        if (typeof question.onCancel === 'function') {
            question.onCancel();
        }

    }

    const handleContinue = () => {

        if (typeof props.onSuccess === 'function') {
            props.onSuccess(true);
        }

        if (typeof question.onSuccess === 'function') {
            question.onSuccess(true);
        }


        close();
    }

    return (
        <div className={`${question.overlay ? 'visible' : ''} overlay`}>
            <div className={`${question.modal ? 'show' : ''} box box-large`} style={{ width: '1000px' }}>
                <img src={questionIcon} alt="question" />
                <h3>
                    Потвърждавате ли истинността на следните данни?
                </h3>

                <div style={{ marginTop: '25px', fontSize: '14px' }}>
                    {question.applicant &&
                        <div className="row center" style={{ textAlign: 'center', display: 'block' }}>
                            <span>Заявител: <b>{question.applicant.name}</b>,</span>
                            <br />
                            <span>ЕИК / ЕГН: <b>{question.applicant.eik || question.applicant.egn}</b></span>
                        </div>
                    }
                    <div className="row">
                        Констатации:
                    </div>
                    <div className="row">
                        <div className="col" style={{ width: '24px' }}>
                            <b>№</b>
                        </div>
                        <div className="col" style={{ width: '350px' }}>
                            <b>за наличие или липса</b>
                        </div>
                        <div className="col" style={{ width: '100px' }}>
                            <b>задължения</b>
                        </div>
                        <div className="col" style={{ width: '150px' }}>
                            <b>размер</b>
                        </div>
                        <div className="col" style={{ width: '250px' }}>
                            <b>произтичащи от</b>
                        </div>
                    </div>
                    {question.regards.filter(r => {
                        if (question.signs[r.id].date) {
                            return false
                        }

                        if (question.signs[r.id].has_obligation !== null) {
                            return true;
                        }

                        return false;
                    }).map((r, i) =>
                        <div className="row">
                            <div className="col" style={{ width: '24px' }}>
                                {i + 1}.
                            </div>
                            <div className="col" style={{ width: '350px' }}>
                                {r.name}
                            </div>
                            <div className="col" style={{ width: '100px' }}>
                                <b>{question.signs[r.id].has_obligation == 1 ? 'ИМА' : 'НЯМА'}</b>
                            </div>
                            <div className="col" style={{ width: '150px' }}>
                                {question.signs[r.id].has_obligation == 1
                                    ?
                                    <span>{Number(question.signs[r.id].obligation).toFixed(2)} лв.</span>
                                    :
                                    ''
                                }
                            </div>
                            <div className="col" style={{ width: '250px' }}>
                                {question.signs[r.id].reason}
                            </div>
                        </div>
                    )}
                </div>

                <div className="buttons">
                    <button onClick={handleClose}>
                        Не
                    </button>
                    <button className="btn-primary" onClick={handleContinue}>
                        Да
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(SignValidation)