import React, { useState, useEffect } from 'react'

import editIcon from '../../../../../assets/img/pages/icons/edit-field.jpg'
import { useFieldsContext } from '../../../../../contexts/FieldsContext'
import SignPanel from '../../../../partials/SignPanel'
import Ckeditor from '../../../../partials/Ckeditor';

function Fields(props) {
    const fieldsContext = useFieldsContext();

    const [fields, setFields] = useState({});

    const key = new URLSearchParams(window.location.search).get('_') || 'key';

    const original = props.original || null;
    const editable = props.editable || false;
    const type = props.type;

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original, key])

    return (
        <div className="panel">
            <div className="row">
                <div className="col" style={{ width: '30%' }}>
                    <h3>
                        <span>
                            1
                        </span>
                        До
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label>Г-н/Г-жа</label>
                                <input
                                    value={type === 'categorization'
                                        ? (fields.request && fields.request.agent_name)
                                        : !fields.applicant_type || fields.applicant_type === 'company'
                                            ? (fields.company && fields.company.mol)
                                            : (fields.applicant && fields.applicant.name)
                                    }
                                    readOnly
                                />
                            </div>
                        </div>
                        {fields && fields.company &&
                            <div className="row">
                                <div className="col">
                                    <label>Представляващ</label>
                                    <input
                                        value={fields && fields.company && fields.company.name}
                                        readOnly
                                    />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col">
                                <label>Адрес</label>
                                {fields && fields.company
                                    ?
                                    <input
                                        value={fields && fields.company && fields.company.town
                                            ? [
                                                fields.company.town ? `${fields.company.town.kind} ${fields.company.town.name}` : '',
                                                fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name} ${fields.company.street_number}` : '',
                                                fields.company.entrance ? `вх. ${fields.company.entrance}` : '',
                                                fields.company.apartment ? `ап. ${fields.company.apartment}` : ''
                                            ].filter(el => el !== '').join(', ')
                                            :
                                            ''
                                        }
                                        readOnly
                                    />
                                    :
                                    fields && fields.applicant
                                        ?
                                        <input
                                            value={fields && fields.applicant && fields.applicant.town
                                                ? [
                                                    fields.applicant.town ? `${fields.applicant.town.kind} ${fields.applicant.town.name}` : '',
                                                    fields.applicant.street ? `${fields.applicant.street.suff} ${fields.applicant.street.name} ${fields.applicant.street_number}` : '',
                                                    fields.applicant.entrance ? `вх. ${fields.applicant.entrance}` : '',
                                                    fields.applicant.apartment ? `ап. ${fields.applicant.apartment}` : ''
                                                ].filter(el => el !== '').join(', ')
                                                :
                                                ''
                                            }
                                            readOnly
                                        />

                                        :
                                        ''
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col" style={{ width: '70%' }}>
                    <h3>
                        <span>
                            2
                        </span>
                        Писмо
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <Ckeditor
                                    name="letter_text"
                                    value={fields && fields.letter_text ? fields.letter_text : fields.default_cancel_text}
                                    adkey={key}
                                    handleModify={props.handleModify}
                                />
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="row x2">
                <div className="col">
                    <SignPanel
                        panelName="letter_signature"
                        mayor={fields && fields.lettermayorsignature}
                        mayorInput="letter_signed_by_mayor"
                        mayorReason={fields && fields.letter_signed_by_mayor_reason}
                        prepared={fields && fields.letterpreparedsignature}
                        preparedInput="letter_prepared_by"
                        agreed={fields && fields.letter_commission_text}
                        agreedInput="letter_commission"
                        handleModify={props.handleModify}
                    />
                </div>
            </div>

        </div>
    )
}

export default Fields