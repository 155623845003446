import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import Api from '../../../../../helpers/Api'
import { Link, useHistory } from 'react-router-dom'

//context
import { useFieldsContext } from '../../../../../contexts/FieldsContext'

//partials
import FilesPanel from '../../../../partials/FilesPanel'
import Fields from './Fields'
import { Texts } from '../../../../../helpers/Texts'

//images
import protocolIcon from '../../../../../assets/img/pages/icons/protocol.png'
import loader from '../../../../../assets/img/app/loader.svg'

//modals
import Question from '../../../../modals/messages/Question'
import SignValidation from '../../../../modals/messages/SignValidation'
import SuccessModal from '../../../../modals/messages/Success'
import SignCompleted from '../../../../modals/messages/SignCompleted'
import Attention from '../../../../modals/messages/Attention'
import { useAuthContext } from '../../../../../contexts/AuthContext'

function Index(props) {

    const history = useHistory()
    const auth = useAuthContext()

    //refs
    const appRef = useRef(null)
    const companyValidationRef = useRef(null)
    const signValidationRef = useRef(null)
    const signCompletedRef = useRef(null)
    const SuccessModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const data = props.data
    const original = props.original
    const setData = props.setData
    const setOriginal = props.setOriginal
    const loading = props.loading
    const editable = props.editable || false

    const fieldsContext = useFieldsContext()

    const [application, setApplication] = useState({
        position: 1,
        loading: false,
        modified: false,
    })

    const key = new URLSearchParams(window.location.search).get('_') || 'key'

    useEffect(() => {
        let form = document.querySelector('.main-holder form');

        if (form) {
            form.addEventListener('change', fieldsListener);
        }

        return () => {
            if (form) {
                form.removeEventListener('change', fieldsListener)
            }
        }

    }, [])

    const fieldsListener = () => {
        if (!application.modified) {
            handleModify()
        }
    }

    const handleConfig = e => {
        e.preventDefault()

        if (auth.canWriteRequest()) {
            submitData();
        } else {
            let applicant = original.applicant_type === 'company'
                ? original.company
                : original.applicant;

            let regards = appRef.current.getRegards();
            let signs = appRef.current.getSigns();

            signValidationRef.current.open(
                applicant,
                regards,
                signs
            );

            signValidationRef.current.onSuccess(() => {
                submitData();
            });
        }

    }


    const submitData = () => {

        setApplication(application => ({ ...application, loading: true }));

        let form = document.getElementById('application');

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form)

        // id на процедурата
        data.append('id', props.data.id)

        Api.post('procedures/obligations/addedit', data)
            .then(res => {
                if (auth.canWriteRequest()) {
                    SuccessModalRef.current.open()

                    props.refreshData();
                    history.push('?_=' + new Date().getTime())
                } else {
                    signCompletedRef.current.open();

                    signCompletedRef.current.onClose(() => {
                        history.push('/obligations')
                    });
                }

            }).catch(err => {
                if (err.response.status === 422) {
                    attentionModalRef.current.open(err.response.data.error)

                    let fields = err.response.data.fields;

                    Object.entries(fields).map(i => {
                        if (i[1] && typeof i[1] === 'object') {
                            Object.entries(i[1]).map(j => {
                                if (j[1]) {
                                    let name = `${i[0]}[${j[0]}]`;

                                    let inputs = form.querySelectorAll("[name='" + name + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            })
                        } else {
                            if (i[1]) {
                                let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                inputs.forEach(i => {
                                    i.classList.add('validate');
                                });
                            }
                        }
                    });
                } else {
                    errorModalRef.current.open()
                }
            }).finally(() => {
                handleCancelModify()
            });
    }

    const handleModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: true
        }))
    }

    const handleCancelModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: false,
            loading: false
        }))
    }

    return (
        <>
            {!loading && original && original.id &&
                <>
                    <Question
                        ref={companyValidationRef}
                        secondaryMessage={Texts.companyValidation}
                        cancelButtonText="Не"
                        continueButtonText="Да"
                    />

                    <SignValidation
                        ref={signValidationRef}
                    />

                    <SuccessModal
                        ref={SuccessModalRef}
                        mainMessage="Протоколът беше запазен успешно!"
                        canGenerate={data.can_generate_protocol}
                    />

                    <SignCompleted
                        ref={signCompletedRef}
                    />

                    <Attention
                        ref={attentionModalRef}
                        mainMessage='Моля, попълнете всички полета!'
                        secondaryMessage="За да запазите протоколът, трябва да попълните всички полета."
                    />

                    <Attention
                        ref={errorModalRef}
                        mainMessage='Нещо се обърка!'
                    />

                    <h1 className="config-heading">
                        <img src={protocolIcon} alt="protocol" />
                        Протокол за наличие или липса на задължения
                    </h1>
                    <hr />
                    <div className="main-holder">
                        <form autoComplete="off" role="presentation" id="application" onSubmit={handleConfig}>
                            <Fields
                                ref={appRef}
                                key={key}
                                data={data}
                                original={original}
                                editable={editable}
                                setData={setData}
                                handleModify={handleModify}
                            />
                            <hr />
                            <div className="buttons">

                                {application.loading
                                    ?
                                    <img src={loader} alt="loading" />
                                    :
                                    application.modified ?
                                        <>
                                            <Link
                                                to={"/obligations/config/" + props.original.id + '?_=' + new Date().getTime()}
                                                className="btn-default"
                                                onClick={handleCancelModify}
                                            >
                                                Отказ
                                            </Link>
                                            <button type="button" onClick={handleConfig}>
                                                {auth.canWriteRequest()
                                                    ? 'Запази'
                                                    : 'Запази и подпиши'
                                                }
                                            </button>
                                        </>
                                        :
                                        ''
                                }

                            </div>
                        </form>
                        {auth.canWriteRequest() &&
                            <FilesPanel
                                id={original.procedure_id}
                                generateFile={data.can_generate_protocol}
                                type="protocol"
                                allowEdit={auth.canWriteRequest()}
                                uploadedDocument={data.uploaded_signed_protocol}
                                refreshData={props.refreshData}
                            />
                        }
                    </div>
                </>
            }
        </>
    )
}

export default Index