import React, { useEffect, useState } from 'react'
import Dropdown from '../../../app/Dropdown'
import Api from '../../../../helpers/Api';
import { useLocation } from 'react-router-dom';

function Filter(props) {

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [input, setInput] = useState({})

    const [types, setTypes] = useState({
        hotels: [],
        restaurants: []
    })

    const filterData = props.filterData;

    useEffect(() => {
        setInput(prev => ({
            ...prev,
            type_id: params.get('type_id') || '',
            activity_id: params.get('activity_id') || '',
            start_date: params.get('start_date') || '',
            end_date: params.get('end_date') || '',
            street: params.get('street') || '',
            company: params.get('company') || '',
        }))

    }, [location]);

    useEffect(() => {
        loadTypes();
    }, []);

    const loadTypes = () => {
        Api.get('procedures/categorization/types')
            .then(res => {
                setTypes(prev => ({
                    ...prev,
                    hotels: res.data.hotels,
                    restaurants: res.data.restaurants
                }));
            })
    }

    const handleObjectType = e => {
        let name = e.target.name;
        let val = e.target.value;

        setInput(prev => ({
            ...prev,
            [name]: val
        }))

        filterData(e, name, val, 0)
    }

    const handleInputChange = (e, timeout = 0) => {
        let name = e.target.name;
        let val = e.target.value;

        setInput(prev => ({
            ...prev,
            [name]: val
        }))

        filterData(e, name, val, timeout)
    }

    return (
        <form autoComplete="off" role="presentation" className="filter-standard">

            <div className="col">
                <span className="extra-label">
                    От
                </span>
                <input
                    name="start_date"
                    autoComplete="off" role="presentation"
                    type="date"
                    value={input.start_date}
                    onChange={handleInputChange}
                    style={{ marginRight: 0 }}
                />
            </div>
            <div className="col">
                <span className="extra-label">
                    До
                </span>
                <input
                    name="end_date"
                    autoComplete="off" role="presentation"
                    type="date"
                    value={input.end_date}
                    onChange={handleInputChange}
                    style={{ marginRight: 0 }}
                />
            </div>

            <select
                name="type_id"
                value={input.type_id ? input.type_id : ''}
                onChange={handleObjectType}
                style={{ width: '200px' }}
            >
                <option value="">Категория</option>
                <option value="restaurants">Заведения за хранене</option>
                <option value="hotels">Места за настаняване</option>
            </select>

            <select
                name="activity_id"
                value={input.activity_id ? input.activity_id : ''}
                onChange={handleObjectType}
                disabled={input.type_id === '' ? true : false}
                style={{ width: '200px' }}
            >
                <option value="">Тип</option>
                {input.type_id && types[input.type_id] && types[input.type_id].map(t =>
                    <option key={'type-' + t.id} value={t.id}>{t.name}</option>
                )}
            </select>

            <input autoComplete="off" role="presentation"
                name="street"
                type="search"
                className="field-big"
                value={input.street}
                placeholder="Улица"
                onChange={e => handleInputChange(e, 300)}
                onKeyUp={e => handleInputChange(e, 300)}
            />

            <input autoComplete="off" role="presentation"
                name="company"
                type="search"
                className="field-big"
                value={input.company}
                placeholder="Фирма"
                onChange={e => handleInputChange(e, 300)}
                onKeyUp={e => handleInputChange(e, 300)}
            />

            <div className="col">
                {/* <span className="extra-label">
                    Статус
                </span> */}
                <select
                    onChange={e => filterData(e, 'status', e.target.value, 0)}
                >
                    <option
                        value=""
                        selected={props.filter.status === ''}
                    >
                        Всички
                    </option>
                    <option
                        value="1"
                        selected={props.filter.status === '1'}
                    >
                        Активни
                    </option>
                    <option
                        value="0"
                        selected={props.filter.status === '0'}
                    >
                        Неактивни
                </option>
                </select>
            </div>

        </form>
    )
}

export default Filter