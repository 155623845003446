import React, { useState, forwardRef, useImperativeHandle, useEffect, useLayoutEffect } from 'react'

//context
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

//partials
import Dropdown from '../../../../../../app/Dropdown'
import * as Constants from '../../../../../../../helpers/Constants'
import Signature from '../../../../../../partials/Signature'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import { useAuthContext } from '../../../../../../../contexts/AuthContext'

function Fields(props) {
    const fieldsContext = useFieldsContext()
    const auth = useAuthContext()

    const fieldsData = {
        company: {}
    };

    const [fields, setFields] = useState(fieldsData);

    const original = props.original;

    useEffect(() => {
        if (original) setFields(original)
    }, [original]);

    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name + '_formatted';

        setFields(prev => ({
            ...prev,
            [name]: val
        }));
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col col-auto">
                            <h3>
                                <span>
                                    1
                                </span>
                                Номер и Дата
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="license_no">
                                            № разрешение
                                        </label>
                                        {fields && (fields.license_no || fields.generated_license_no) && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="license_no"
                                            name="license_no"
                                            type="text"
                                            placeholder="Въведете №..."
                                            className="field-medium"
                                            defaultValue={fields && fields.license_no ? fields.license_no : fields.generated_license_no}
                                            readOnly={fields && (fields.license_no || fields.generated_license_no) ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="license_date">
                                            Дата
                                        </label>
                                        {fields && fields.license_date_formatted && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="license_date"
                                            name="license_date"
                                            type="date"
                                            value={fields && fields.license_date_formatted ? fields.license_date_formatted : fieldsContext.today()}
                                            readOnly={fields && fields.license_date_formatted ? true : false}
                                            onChange={handleDateChange}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col">
                            <h3>
                                <span>
                                    2
                                </span>
                                Данни за фирмата заявител
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="company_name">
                                            Точно наименование на фирмата
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_name"
                                            type="text"
                                            value={fields && fields.company && fields.company.name}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="company_eik">
                                            ЕИК / Булстат
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_eik"
                                            type="text"
                                            value={fields && fields.company && fields.company.eik}
                                            readOnly
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="company_phone">
                                            Тел. номер
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.phone}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col">
                                        <label htmlFor="company_town">
                                            Населено място
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            id="company_town"
                                            value={fields && fields.company && fields.company.town
                                                ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <label htmlFor="company_street">
                                            Улица
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_street"
                                            value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto">
                                                <label htmlFor="company_street_number">
                                                    №
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_street_number"
                                                    value={fields && fields.company && fields.company.street_number}
                                                    className="field-small"
                                                    readOnly
                                                    min="0"
                                                    step="1"
                                                />
                                            </div>

                                            <div className="col col-auto">
                                                <label htmlFor="company_block">
                                                    Блок
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_block"
                                                    value={fields && fields.company && fields.company.block}
                                                    className="field-small"
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto">
                                                <label htmlFor="company_entrance">
                                                    Вход
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_entrance"
                                                    value={fields && fields.company && fields.company.entrance}
                                                    className="field-small"
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto">
                                                <label htmlFor="company_floor">
                                                    Етаж
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_floor"
                                                    value={fields && fields.company && fields.company.floor}
                                                    className="field-small"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="company_mol">
                                            МОЛ
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_mol"
                                            type="text"
                                            value={fields && fields.company && fields.company.mol}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col">

                                    </div>

                                    <div className="col">

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col col-auto">
                            <h3>
                                <span>
                                    3
                                </span>
                                Кмет
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <Signature
                                            name="signed_by"
                                            group_id={Constants.MAYORS}
                                            selected={fields && fields.signature}
                                            reason={fields && fields.signed_by_reason}
                                            handleModify={props.handleModify}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Fields;