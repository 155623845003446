import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'

//material ui :(
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'

//images
import removeIcon from '../../../../../assets/img/modals/remove-small.png'
import Api from '../../../../../helpers/Api'
import * as Constants from '../../../../../helpers/Constants'
import { Button } from '@material-ui/core'
import { useFieldsContext } from '../../../../../contexts/FieldsContext'
import SelectPosition from '../../../../modals/SelectPosition'
import editIcon from '../../../../../assets/img/pages/icons/edit.png'

function SelectSign(props, ref) {

    const fieldsContext = useFieldsContext();

    const selectPositionModalRef = useRef(null);

    const [signs, setSigns] = useState({
        overlay: false,
        modal: false,
        names: [],
        prepared: null,
        refresh: false,
        openSelect: {
            agreed: false,
            prepared: false
        }
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }))

    useEffect(() => {

        if (props.prepared) {
            setSigns(prev => ({
                ...prev,
                prepared: props.prepared
            }));

        }

    }, [props.prepared])

    useEffect(() => {
        Api.get('officials/find')
            .then(res => {

                setSigns(prev => ({
                    ...prev,
                    names: res.data,
                }))

            })
    }, [signs.refresh]);


    useEffect(() => {
        let prepared = signs.prepared;

        if (!prepared) {
            // Ваня
            prepared = signs.names.filter(u => u.id === 51)[0];

            if (prepared) {
                handleSelectChange('prepared', prepared.id);
            }
        }
    }, [signs.names])

    const handleOpen = () => {
        setSigns(signs => ({
            ...signs,
            overlay: true,
        }))
        setTimeout(() => {
            setSigns(signs => ({
                ...signs,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = e => {

        if (e) {
            e.preventDefault()
        }

        setSigns(signs => ({
            ...signs,
            modal: false,
        }))
        setTimeout(() => {
            setSigns(signs => ({
                ...signs,
                overlay: false,
            }))
        }, 100)
    }

    const handleSelectChange = (k, v) => {
        let id = v;

        if (id) {
            let person = signs.names.filter(p => {
                return p.id == id;
            })[0];

            props.handleSelected(k, person);

            if (typeof props.handleModify === 'function') {
                props.handleModify();
            }
        }

    }

    const handleSelectClose = id => {
        setSigns(prev => ({
            ...prev,
            openSelect: {
                ...prev.openSelect,
                [id]: false
            }
        }))
    }

    const handleSelectOpen = id => {
        setSigns(prev => ({
            ...prev,
            openSelect: {
                ...prev.openSelect,
                [id]: true
            }
        }))
    }


    const handleAdd = id => {
        const modal = fieldsContext.addOfficialPersonRef.current;

        handleClose();
        handleSelectClose(id);

        let groupId = 0;

        switch (id) {
            case 'mayor':
                groupId = Constants.MAYORS;
                break;
            case 'agreed':
                groupId = Constants.AGREED;
                break;
            case 'accountant':
                groupId = Constants.ACCOUNTANTS;
                break;
            case 'prepared':
                groupId = Constants.PREPARED;
                break;
        }

        modal.open();

        if (groupId) {
            modal.setSelectedGroups([groupId]);
        }

        modal.onSuccess(data => {

            modal.close();

            setSigns(prev => ({
                ...prev,
                names: prev.names.concat(data)
                // refresh: new Date().getTime()
            }))

            let inThatGroup = data.groups.filter(g => g.id == groupId).length;

            if (inThatGroup) {

                props.handleSelected(id, data);

                if (typeof props.handleModify === 'function') {
                    props.handleModify();
                }
            }

            handleOpen();
            // handleSelectOpen(id);
        })

        modal.onClose(() => {
            handleOpen();
        })
    }

    const handleShowPositions = (id, person) => {

        if (person._positions.length > 1) {
            handleSelectClose(id);
            handleClose();

            let modal = selectPositionModalRef.current;

            let positionId = person.position ? person.position.id : null;

            modal.open(person, positionId, person._positions);

            modal.onSelect(position => {
                person.position = position;

                props.handleSelected(id, person);

                if (typeof props.handleModify === 'function') {
                    props.handleModify();
                }
            });

            modal.onClose(() => {
                handleOpen();
                setTimeout(() => {
                    handleSelectOpen(id);
                }, 500);
            });
        }

    }

    return (
        <>
            <SelectPosition
                ref={selectPositionModalRef}
            />

            <div className={`${signs.overlay ? 'visible' : ''} overlay`}>
                <div className={`${signs.modal ? 'show' : ''} form-box box-small`}>
                    <h2>
                        Подпис
                    </h2>
                    <p>
                        Изберете членове на подписване на документа
                    </p>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <label htmlFor="multiple-select">
                                Съставил
                            </label>
                            <FormControl variant="outlined">
                                <Select
                                    open={signs.openSelect.prepared}
                                    onOpen={e => handleSelectOpen('prepared')}
                                    onClose={e => handleSelectClose('prepared')}
                                    onChange={e => handleSelectChange('prepared', e.target.value)}
                                    value={signs.prepared != null ? signs.prepared.id : ''}
                                    renderValue={() => signs.prepared ? signs.prepared.name : ''}
                                >
                                    {signs.names.map(name =>
                                        name.groups.filter(g => g.id == Constants.PREPARED).length || (props.prepared && props.prepared.id == name.id)
                                            ?
                                            <MenuItem
                                                key={name.id}
                                                value={name.id}

                                            >
                                                <Checkbox
                                                    checked={signs.prepared && signs.prepared.id == name.id ? true : false}
                                                    value={name.id}
                                                />
                                                <div>
                                                    <p>{name.name}</p>
                                                    {name.position && name.position.id &&
                                                        <div className="sign-position" onClick={e => handleShowPositions('prepared', name)}>
                                                            <i className={name._positions.length > 1 ? 'editable' : ''}>
                                                                {signs.prepared && signs.prepared.id === name.id && signs.prepared.position ? signs.prepared.position.name : name.position.name}
                                                            </i>
                                                            {name._positions.length > 1 &&
                                                                <span>
                                                                    <img src={editIcon} />
                                                                </span>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </MenuItem>
                                            :
                                            ''
                                    )}

                                    <Button
                                        className="btn-add full-width"
                                        onClick={() => handleAdd('prepared')}
                                    >
                                        Добави нов служител
                                </Button>

                                </Select>
                            </FormControl>

                            <input
                                type="hidden"
                                name="protocol[prepared_by]"
                                value={signs.prepared ? signs.prepared.id : ''}
                            />

                            <input
                                type="hidden"
                                name="protocol[prepared_by_position_id]"
                                value={signs.prepared && signs.prepared.position ? signs.prepared.position.id : ''}
                            />
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="col">
                            <label htmlFor="multiple-select">
                                Подписал
                        </label>
                            <FormControl variant="outlined">
                                <Select
                                    open={signs.openSelect.agreed}
                                    onOpen={e => handleSelectOpen('agreed')}
                                    onClose={e => handleSelectClose('agreed')}
                                    onChange={e => handleSelectChange('agreed', e.target.value)}
                                    value={props.agreed != null ? props.agreed.id : ''}
                                    renderValue={() => props.agreed ? props.agreed.name : ''}
                                >
                                    {signs.names.map(name =>
                                        name.groups.filter(g => g.id == Constants.AGREED).length || (props.agreed && props.agreed.id == name.id)
                                            ?
                                            <MenuItem
                                                key={name.id}
                                                value={name.id}

                                            >
                                                <Checkbox
                                                    checked={props.agreed && props.agreed.id == name.id ? true : false}
                                                    value={name.id}
                                                />
                                                <div>
                                                    <p>{name.name}</p>
                                                    {name.position && name.position.id &&
                                                        <div className="sign-position" onClick={e => handleShowPositions('agreed', name)}>
                                                            <i className={name._positions.length > 1 ? 'editable' : ''}>
                                                                {signs.agreed && signs.agreed.id === name.id && signs.agreed.position ? signs.agreed.position.name : name.position.name}
                                                            </i>
                                                            {name._positions.length > 1 &&
                                                                <span>
                                                                    <img src={editIcon} />
                                                                </span>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </MenuItem>
                                            :
                                            ''
                                    )}

                                    <Button
                                        className="btn-add full-width"
                                        onClick={() => handleAdd('agreed')}
                                    >
                                        Добави нов служител
                                </Button>

                                </Select>
                            </FormControl>

                            <input
                                type="hidden"
                                name="protocol[agreed_by]"
                                value={props.agreed ? props.agreed.id : ''}
                            />

                            <input
                                type="hidden"
                                name="protocol[agreed_by_position_id]"
                                value={props.agreed && props.agreed.position ? props.agreed.position.id : ''}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="buttons">
                        <button onClick={handleClose}>
                            Затвори
                    </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(SelectSign)