import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react'

//context
import { useStepsContext } from '../../../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import Dropdown from '../../../../../../app/Dropdown'

function Circumstances(props, ref) {
    const stepsContext = useStepsContext()
    const fieldsContext = useFieldsContext()

    const data = props.data;
    const original = props.original;
    // const fields = props.fields;
    // const setFields = props.setFields;
    const editable = props.editable || false;
    // const handleFieldChange = props.handleFieldChange;

    const ownerMunicipalityRef = useRef(null);
    const ownerTownRef = useRef(null);
    const ownerRegionRef = useRef(null);
    const ownerStreetRef = useRef(null);

    const workerMunicipalityRef = useRef(null);
    const workerTownRef = useRef(null);
    const workerRegionRef = useRef(null);
    const workerStreetRef = useRef(null);

    const fieldsData = {
        request: {},
        company: {},
        object: {},
        owner: {},
        worker: {}
    }

    const [fields, setFields] = useState(fieldsData)

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    const handleFieldChange = e => {

        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleRadioChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = Number(e.target.value)

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }
    }

    const handleOwnerTypeChange = e => {
        handleFieldChange(e);

        setFields(prev => ({
            ...prev,
            owner: {}
        }))
    }

    const handleWorkerTypeChange = e => {
        handleFieldChange(e);

        setFields(prev => ({
            ...prev,
            worker: {}
        }))
    }

    // owner
    const handleOwnerChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                owner: newData
            }))
        }
    }

    const handleOwnerCountryChange = newData => {
        ownerMunicipalityRef.current.reset()
        ownerTownRef.current.reset();
        ownerRegionRef.current.reset();
        ownerStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            owner: {
                ...prev.owner,
                country_id: newData.id,
                country: newData,
            }

        }))
    }

    const handleOwnerMunicipalityChange = newData => {
        ownerTownRef.current.reset();
        ownerRegionRef.current.reset();
        ownerStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            owner: {
                ...prev.owner,
                municipality_id: newData.id,
                municipality: newData,
            }


        }))
    }

    const handleOwnerTownChange = newData => {
        ownerStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            owner: {
                ...prev.owner,
                town_id: newData.id,
                town: newData,
            }

        }))
    }


    // worker
    const handleWorkerChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                worker: newData
            }))
        }
    }

    const handleWorkerCountryChange = newData => {
        workerMunicipalityRef.current.reset()
        workerTownRef.current.reset();
        workerRegionRef.current.reset();
        workerStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            worker: {
                ...prev.worker,
                country_id: newData.id,
                country: newData,
            }

        }))
    }

    const handleWorkerMunicipalityChange = newData => {
        workerTownRef.current.reset();
        workerRegionRef.current.reset();
        workerStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            worker: {
                ...prev.worker,
                municipality_id: newData.id,
                municipality: newData,
            }


        }))
    }

    const handleWorkerTownChange = newData => {
        workerStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            worker: {
                ...prev.worker,
                town_id: newData.id,
                town: newData,
            }

        }))
    }

    return (
        <div className="panel" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="row">
                <div className="col col-small">
                    <h3>
                        <span>
                            1
                        </span>
                        Търговецът намира ли се в производство по несъстоятелност или ликвидация?
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <label className="checkbox">
                                Да
                                <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="request[company_liquidation]"
                                    value="1"
                                    checked={fields && fields.request.company_liquidation == 1 ? true : false}
                                    onChange={handleRadioChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                Не
                                <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="request[company_liquidation]"
                                    value="0"
                                    checked={fields && fields.request.company_liquidation == 0 ? true : false}
                                    onChange={handleRadioChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Собственик на обекта
                    </h3>
                    <div className="holder" name="owner">
                        <div className="row">
                            <label className="checkbox">
                                Заявителят
                                <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="request[applicant_is_owner]"
                                    value="1"
                                    checked={fields && fields.request.applicant_is_owner == 1 ? true : false}
                                    onChange={handleRadioChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="row">
                            <label className="checkbox">
                                Друго лице
                                <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="request[applicant_is_owner]"
                                    value="0"
                                    checked={fields && fields.request.applicant_is_owner == 0 ? true : false}
                                    onChange={handleRadioChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        {fields && fields.request.applicant_is_owner == 0 &&
                            <>
                                <br />
                                <div className="row">
                                    <div className="col">
                                        <label className="checkbox">
                                            Фирма
                                            <input autoComplete="off" role="presentation" 
                                                type="radio"
                                                name="request[owner_type]"
                                                value="company"
                                                checked={fields && fields.request.owner_type !== 'person' ? true : false}
                                                onChange={handleOwnerTypeChange}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="col">
                                        <label className="checkbox">
                                            Физическо лице
                                            <input autoComplete="off" role="presentation" 
                                                type="radio"
                                                name="request[owner_type]"
                                                value="person"
                                                checked={fields && fields.request.owner_type === 'person' ? true : false}
                                                onChange={handleOwnerTypeChange}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                                {fields && fields.request.owner_type === 'person'
                                    ?
                                    <div className="row for-choice">
                                        <div className="col able-to-edit">
                                            <label htmlFor="owner_name">
                                                Име, презиме, фамилия
                                            </label>
                                            {editable && fields && fields.owner && fields.owner.name && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <Dropdown
                                                inputName="owner[name]"
                                                inputValue={fields && fields.owner && fields.owner.name}
                                                inputIdName="owner[id]"
                                                inputIdValue={fields && fields.owner && fields.owner.id}
                                                inputPlaceholder="Въведете име"
                                                inputDisabled={editable && fields && fields.owner && fields.owner.name ? true : false}
                                                url="persons/find"
                                                renderText={(data) => {
                                                    return <>{data.name} {data.egn ? `(ЕГН: ${data.egn})` : ''}</>
                                                }}
                                                renderInputText={(data) => data.name}
                                                onChange={handleOwnerChange}
                                            />
                                        </div>
                                        <div className="col able-to-edit">
                                            <label htmlFor="owner_egn">
                                                ЕГН
                                            </label>
                                            {editable && fields && fields.owner && fields.owner.egn && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation" 
                                                id="egn"
                                                name="owner[egn]"
                                                type="text"
                                                placeholder="Въведете ЕГН"
                                                value={fields && fields.owner && fields.owner.egn ? fields.owner.egn : ''}
                                                readOnly={editable && fields && fields.owner && fields.owner.egn ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="row for-choice">
                                        <div className="col able-to-edit">
                                            <label htmlFor="owner_name">
                                                Наименование
                                            </label>
                                            {editable && fields && fields.owner && fields.owner.name && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <Dropdown
                                                inputName="owner[name]"
                                                inputValue={fields && fields.owner && fields.owner.name}
                                                inputIdName="owner[id]"
                                                inputIdValue={fields && fields.owner && fields.owner.id}
                                                inputPlaceholder="Въведете име"
                                                inputDisabled={editable && fields && fields.owner && fields.owner.name ? true : false}
                                                url="companies/find"
                                                onChange={handleOwnerChange}
                                            />
                                        </div>

                                        <div className="col able-to-edit">
                                            <label htmlFor="owner_eik">
                                                ЕИК
                                            </label>
                                            {editable && fields && fields.owner && fields.owner.eik && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation" 
                                                id="eik"
                                                name="owner[eik]"
                                                type="text"
                                                placeholder="Въведете ЕИК / Булстат"
                                                value={fields && fields.owner && fields.owner.eik ? fields.owner.eik : ''}
                                                readOnly={editable && fields && fields.owner && fields.owner.eik ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                    </div>

                                }

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="owner_country">
                                            Държава
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.country && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            inputName="owner[country]"
                                            inputValue={fields && fields.owner && fields.owner.country ? fields.owner.country.name : ''}
                                            inputIdName="owner[country_id]"
                                            inputIdValue={fields && fields.owner && fields.owner.country_id}
                                            inputPlaceholder="Изберете държава"
                                            inputDisabled={editable && fields && fields.owner && fields.owner.country ? true : false}
                                            url="countries/find"
                                            onChange={handleOwnerCountryChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="owner_municipality">
                                            Община
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.municipality && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={ownerMunicipalityRef}
                                            inputName="owner[municipality]"
                                            inputValue={fields && fields.owner && fields.owner.municipality ? fields.owner.municipality.name : ''}
                                            inputIdName="owner[municipality_id]"
                                            inputIdValue={fields && fields.owner && fields.owner.municipality_id}
                                            inputPlaceholder="Изберете община"
                                            inputDisabled={editable && fields && fields.owner && fields.owner.municipality ? true : false}
                                            url="municipalities/find"
                                            params={{
                                                country_id: fields && fields.owner && fields.owner.country_id
                                            }}
                                            onChange={handleOwnerMunicipalityChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="owner_town">
                                            Населено място
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.town && fields.owner.town && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={ownerTownRef}
                                            inputName="owner[town]"
                                            inputValue={fields && fields.owner && fields.owner.town
                                                ? fields.owner.town.id
                                                    ? `${fields.owner.town.kind} ${fields.owner.town.name}`
                                                    : fields.owner.town.name
                                                : ''}
                                            inputIdName="owner[town_id]"
                                            inputIdValue={fields && fields.owner && fields.owner.town_id}
                                            inputPlaceholder="Изберете населено място"
                                            inputDisabled={editable && fields && fields.owner && fields.owner.town && fields.owner.town.id ? true : false}
                                            url="towns/find"
                                            params={{
                                                munic_id: fields && fields.owner && fields.owner.municipality && fields.owner.municipality.id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.kind} {data.name}</>;
                                            }}
                                            onChange={handleOwnerTownChange}
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="owner_postcode">
                                            Пощенски код
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.postcode && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="owner_postcode"
                                            name="owner[postcode]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            value={fields && fields.owner && fields.owner.postcode ? fields.owner.postcode : ''}
                                            readOnly={editable && fields && fields.owner && fields.owner.postcode ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="owner_region">
                                            Район/квартал
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.region && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={ownerRegionRef}
                                            inputName="owner[region]"
                                            inputValue={fields && fields.owner && fields.owner.region ? fields.owner.region.name : ''}
                                            inputIdName="owner[region_id]"
                                            inputIdValue={fields && fields.owner && fields.owner.region_id}
                                            inputPlaceholder="Изберете район/квартал"
                                            inputDisabled={editable && fields && fields.owner && fields.owner.region ? true : false}
                                            url="regions/find"
                                            params={{
                                                town_id: fields && fields.owner && fields.owner.town_id
                                            }}

                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="owner_street">
                                            Улица
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.street && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={ownerStreetRef}
                                            inputName="owner[street]"
                                            inputValue={fields && fields.owner && fields.owner.street ? `${fields.owner.street.suff} ${fields.owner.street.name}` : ''}
                                            inputIdName="owner[street_id]"
                                            inputIdValue={fields && fields.owner && fields.owner.street_id}
                                            inputPlaceholder="Изберете улица"
                                            inputDisabled={editable && fields && fields.owner && fields.owner.street ? true : false}
                                            url="streets/find"
                                            params={{
                                                town_id: fields && fields.owner && fields.owner.town_id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.suff} {data.name}</>;
                                            }}
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="owner_street_number">
                                            №
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.street_number && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="owner_street_number"
                                            name="owner[street_number]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.owner && fields.owner.street_number ? fields.owner.street_number : ''}
                                            readOnly={editable && fields && fields.owner && fields.owner.street_number ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="owner_block">
                                            Блок
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.block && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="owner_block"
                                            name="owner[block]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.owner && fields.owner.block ? fields.owner.block : ''}
                                            readOnly={editable && fields && fields.owner && fields.owner.block ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="owner_floor">
                                            Етаж
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.floor && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="owner_floor"
                                            name="owner[floor]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.owner && fields.owner.floor ? fields.owner.floor : ''}
                                            readOnly={editable && fields && fields.owner && fields.owner.floor ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="owner_apartment">
                                            Апартамент
                                        </label>
                                        {editable && fields && fields.owner && fields.owner.apartment && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="owner_apartment"
                                            name="owner[apartment]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.owner && fields.owner.apartment ? fields.owner.apartment : ''}
                                            readOnly={editable && fields && fields.owner && fields.owner.apartment ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Лице, извършващо дейност в обекта
                    </h3>
                    <div className="holder" name="worker">
                        <div className="row">
                            <label className="checkbox">
                                Заявителят
                                <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="request[applicant_is_worker]"
                                    value="1"
                                    checked={fields && fields.request.applicant_is_worker == 1 ? true : false}
                                    onChange={handleRadioChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="row">
                            <label className="checkbox">
                                Друго лице
                                <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="request[applicant_is_worker]"
                                    value="0"
                                    checked={fields && fields.request.applicant_is_worker == 0 ? true : false}
                                    onChange={handleRadioChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        {fields && fields.request.applicant_is_worker == 0 &&
                            <>
                                <br />
                                <div className="row">
                                    <div className="col">
                                        <label className="checkbox">
                                            Фирма
                                            <input autoComplete="off" role="presentation" 
                                                type="radio"
                                                name="request[worker_type]"
                                                value="company"
                                                checked={fields && fields.request.worker_type !== 'person' ? true : false}
                                                onChange={handleWorkerTypeChange}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="col">
                                        <label className="checkbox">
                                            Физическо лице
                                            <input autoComplete="off" role="presentation" 
                                                type="radio"
                                                name="request[worker_type]"
                                                value="person"
                                                checked={fields && fields.request.worker_type === 'person' ? true : false}
                                                onChange={handleWorkerTypeChange}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                                {fields && fields.request.worker_type === 'person'
                                    ?
                                    <div className="row for-choice">
                                        <div className="col able-to-edit">
                                            <label htmlFor="worker_name">
                                                Име, презиме, фамилия
                                            </label>
                                            {editable && fields && fields.worker && fields.worker.name && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <Dropdown
                                                inputName="worker[name]"
                                                inputValue={fields && fields.worker && fields.worker.name}
                                                inputIdName="worker[id]"
                                                inputIdValue={fields && fields.worker && fields.worker.id}
                                                inputPlaceholder="Въведете име"
                                                inputDisabled={editable && fields && fields.worker && fields.worker.name ? true : false}
                                                url="persons/find"
                                                renderText={(data) => {
                                                    return <>{data.name} {data.egn ? `(ЕГН: ${data.egn})` : ''}</>
                                                }}
                                                renderInputText={(data) => data.name}
                                                onChange={handleWorkerChange}
                                            />
                                        </div>
                                        <div className="col able-to-edit">
                                            <label htmlFor="worker_egn">
                                                ЕГН
                                            </label>
                                            {editable && fields && fields.worker && fields.worker.egn && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation" 
                                                id="egn"
                                                name="worker[egn]"
                                                type="text"
                                                placeholder="Въведете ЕГН"
                                                value={fields && fields.worker && fields.worker.egn ? fields.worker.egn : ''}
                                                readOnly={editable && fields && fields.worker && fields.worker.egn ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="row for-choice">
                                        <div className="col able-to-edit">
                                            <label htmlFor="worker_name">
                                                Наименование
                                            </label>
                                            {editable && fields && fields.worker && fields.worker.name && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <Dropdown
                                                inputName="worker[name]"
                                                inputValue={fields && fields.worker && fields.worker.name}
                                                inputIdName="worker[id]"
                                                inputIdValue={fields && fields.worker && fields.worker.id}
                                                inputPlaceholder="Въведете име"
                                                inputDisabled={editable && fields && fields.worker && fields.worker.name ? true : false}
                                                url="companies/find"
                                                onChange={handleWorkerChange}
                                            />
                                        </div>

                                        <div className="col able-to-edit">
                                            <label htmlFor="worker_eik">
                                                ЕИК
                                            </label>
                                            {editable && fields && fields.worker && fields.worker.eik && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation" 
                                                id="eik"
                                                name="worker[eik]"
                                                type="text"
                                                placeholder="Въведете ЕИК / Булстат"
                                                value={fields && fields.worker && fields.worker.eik ? fields.worker.eik : ''}
                                                readOnly={editable && fields && fields.worker && fields.worker.eik ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                    </div>

                                }

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="worker_country">
                                            Държава
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.country && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            inputName="worker[country]"
                                            inputValue={fields && fields.worker && fields.worker.country ? fields.worker.country.name : ''}
                                            inputIdName="worker[country_id]"
                                            inputIdValue={fields && fields.worker && fields.worker.country_id}
                                            inputPlaceholder="Изберете държава"
                                            inputDisabled={editable && fields && fields.worker && fields.worker.country ? true : false}
                                            url="countries/find"
                                            onChange={handleWorkerCountryChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="worker_municipality">
                                            Община
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.municipality && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={workerMunicipalityRef}
                                            inputName="worker[municipality]"
                                            inputValue={fields && fields.worker && fields.worker.municipality ? fields.worker.municipality.name : ''}
                                            inputIdName="worker[municipality_id]"
                                            inputIdValue={fields && fields.worker && fields.worker.municipality_id}
                                            inputPlaceholder="Изберете община"
                                            inputDisabled={editable && fields && fields.worker && fields.worker.municipality ? true : false}
                                            url="municipalities/find"
                                            params={{
                                                country_id: fields && fields.worker && fields.worker.country_id
                                            }}
                                            onChange={handleWorkerMunicipalityChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="worker_town">
                                            Населено място
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.town && fields.worker.town && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={workerTownRef}
                                            inputName="worker[town]"
                                            inputValue={fields && fields.worker && fields.worker.town
                                                ? fields.worker.town.id
                                                    ? `${fields.worker.town.kind} ${fields.worker.town.name}`
                                                    : fields.worker.town.name
                                                : ''}
                                            inputIdName="worker[town_id]"
                                            inputIdValue={fields && fields.worker && fields.worker.town_id}
                                            inputPlaceholder="Изберете населено място"
                                            inputDisabled={editable && fields && fields.worker && fields.worker.town && fields.worker.town.id ? true : false}
                                            url="towns/find"
                                            params={{
                                                munic_id: fields && fields.worker && fields.worker.municipality && fields.worker.municipality.id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.kind} {data.name}</>;
                                            }}
                                            onChange={handleWorkerTownChange}
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="worker_postcode">
                                            Пощенски код
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.postcode && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="worker_postcode"
                                            name="worker[postcode]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            value={fields && fields.worker && fields.worker.postcode ? fields.worker.postcode : ''}
                                            readOnly={editable && fields && fields.worker && fields.worker.postcode ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="worker_region">
                                            Район/квартал
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.region && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={workerRegionRef}
                                            inputName="worker[region]"
                                            inputValue={fields && fields.worker && fields.worker.region ? fields.worker.region.name : ''}
                                            inputIdName="worker[region_id]"
                                            inputIdValue={fields && fields.worker && fields.worker.region_id}
                                            inputPlaceholder="Изберете район/квартал"
                                            inputDisabled={editable && fields && fields.worker && fields.worker.region ? true : false}
                                            url="regions/find"
                                            params={{
                                                town_id: fields && fields.worker && fields.worker.town_id
                                            }}

                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="worker_street">
                                            Улица
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.street && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={workerStreetRef}
                                            inputName="worker[street]"
                                            inputValue={fields && fields.worker && fields.worker.street ? `${fields.worker.street.suff} ${fields.worker.street.name}` : ''}
                                            inputIdName="worker[street_id]"
                                            inputIdValue={fields && fields.worker && fields.worker.street_id}
                                            inputPlaceholder="Изберете улица"
                                            inputDisabled={editable && fields && fields.worker && fields.worker.street ? true : false}
                                            url="streets/find"
                                            params={{
                                                town_id: fields && fields.worker && fields.worker.town_id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.suff} {data.name}</>;
                                            }}
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="worker_street_number">
                                            №
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.street_number && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="worker_street_number"
                                            name="worker[street_number]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.worker && fields.worker.street_number ? fields.worker.street_number : ''}
                                            readOnly={editable && fields && fields.worker && fields.worker.street_number ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="worker_block">
                                            Блок
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.block && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="worker_block"
                                            name="worker[block]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.worker && fields.worker.block ? fields.worker.block : ''}
                                            readOnly={editable && fields && fields.worker && fields.worker.block ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="worker_floor">
                                            Етаж
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.floor && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="worker_floor"
                                            name="worker[floor]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.worker && fields.worker.floor ? fields.worker.floor : ''}
                                            readOnly={editable && fields && fields.worker && fields.worker.floor ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="worker_apartment">
                                            Апартамент
                                        </label>
                                        {editable && fields && fields.worker && fields.worker.apartment && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation" 
                                            id="worker_apartment"
                                            name="worker[apartment]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.worker && fields.worker.apartment ? fields.worker.apartment : ''}
                                            readOnly={editable && fields && fields.worker && fields.worker.apartment ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            4
                        </span>
                        Декларация за истинност
                    </h3>
                    <div className="holder">
                        <label className="checkbox">
                            Декларирам, че посочените данни са верни.
                            Известно ми е, че за деклариране на неверни данни нося наказателна отговорност по чл. 313 от НК.
                            <input autoComplete="off" role="presentation" 
                                type="checkbox"
                                name="choice"
                            />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Circumstances, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})