import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'

function SelectPosition(props, ref) {
    const [state, setState] = useState({
        overlay: false,
        modal: false,
        data: [],
        person: null,
        selected: null,
        onSelect: null,
        onClose: null
    })

    useImperativeHandle(ref, () => ({
        open: (person, selected, positions) => {

            let data = [];

            if (Array.isArray(positions)) {
                data = positions;
            }

            setState(state => ({
                ...state,
                overlay: true,
                data: data,
                selected: selected,
                person: person
            }))
            setTimeout(() => {
                setState(state => ({
                    ...state,
                    modal: true,
                }))
            }, 50)
        },
        close: () => {
            handleClose()
        },
        onSelect: fn => {
            setState(prev => ({
                ...prev,
                onSelect: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))


    const handleClose = () => {

        setState(state => ({
            ...state,
            modal: false,
        }))
        setTimeout(() => {
            setState(state => ({
                ...state,
                overlay: false,
            }))
        }, 100)

        if (typeof state.onClose === 'function') {
            state.onClose();
        }

    }


    const handleSelect = position => {
        if (typeof state.onSelect === 'function') {
            state.onSelect(position);
        }

        setState(prev => ({
            ...prev,
            selected: position.id
        }))

        handleClose();
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`${state.modal ? 'show' : ''} form-box box-large`}>
                <h2>
                    Избор на позиция за подпис от {state.person ? state.person.name : ''}
                </h2>
                <p>
                    Изберете позиция чрез маркиране
                </p>
                <hr />

                {state.data.map(p =>
                    <div key={p.id} className="row" onClick={() => handleSelect(p.position)}>
                        <div className="col">
                            <label className="checkbox">
                                {p.position.name}
                                <input
                                    type="checkbox"
                                    checked={state.selected === p.position.id}
                                />
                                <span className="checkmark"></span>
                            </label>

                        </div>
                    </div>
                )}

                <hr />
                <div className="buttons">
                    <button type="button" onClick={handleClose}>
                        Затвори
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(SelectPosition)