import React from 'react'
import { daysOfWeek } from '../../helpers/Days';
import { useAuthContext } from '../../contexts/AuthContext';

function Index(props) {

    const auth = useAuthContext();

    return (
        <>
            <label htmlFor="free_days">
                Почивни дни
            </label>
            <div className="row">
                {!auth.canWriteRequest() || props.readOnly ?
                    ''
                    :
                    <button
                        className="btn-add btn-add-simple"
                        onClick={e => props.handleShowFreeDays(e)}
                    >
                    </button>
                }
                <input autoComplete="off" role="presentation"

                    id="free_days"
                    type="text"
                    placeholder={!props.hidePlaceholder ? "Добавете почивни дни..." : ""}
                    style={{ marginLeft: '10px' }}
                    readOnly={props.readOnly || (props.editable && props.free_days && props.free_days.length > 0) ? true : false}
                    value={daysOfWeek.filter(d => {
                        if (props.free_days) {
                            return props.free_days.includes(Number(d.id));
                        }
                        return false;
                    }).map(d => {
                        return ` ${d.name}`;
                    })}
                    onFocus={props.handleShowFreeDays}
                />
            </div>

        </>
    )
}

export default Index;