import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react'

//context
import { useStepsContext } from '../../../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'

function Documents(props, ref) {
    const stepsContext = useStepsContext()
    const fieldsContext = useFieldsContext()

    const data = props.data;
    const original = props.original;
    // const fields = props.fields;
    // const setFields = props.setFields;
    const editable = props.editable || false;
    // const handleFieldChange = props.handleFieldChange;

    const fieldsData = {
        request: {},
    }

    const [fields, setFields] = useState(fieldsData)

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    const handleFieldChange = e => {

        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleCheckboxChange = e => {
        let name = e.target.name
        let checked = e.target.checked;

        let nameSecondPart = name.split('[')[1].split(']')[0]

        setFields(prev => ({
            ...prev,
            request: {
                ...prev.request,
                [nameSecondPart]: checked
            }
        }))

    }
    return (
        <div className="panel" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            1
                        </span>
                        Приложени документи
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <label className="checkbox">
                                Копие от договора за наем или от друг договор, от който е видно, че са налице условия лицето да извършва съответната туристическа дейност в обекта
                                <input autoComplete="off" role="presentation" 

                                    type="checkbox"
                                    name="request[doc_loan]"
                                    value="1"
                                    checked={fields && fields.request && fields.request.doc_loan ? true : false}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="row">
                            <label className="checkbox">
                                Копие на документ, удостоверяващ въвеждането на обекта в експлоатация, или друг документ, удостоверяващ законността на обекта, при спазване изискванията на Закона за устройство на територията
                                <input autoComplete="off" role="presentation" 
                                    type="checkbox"
                                    name="request[doc_exploitation]"
                                    value="1"
                                    checked={fields && fields.request && fields.request.doc_exploitation ? true : false}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="row">
                            <label className="checkbox">
                                Изрично пълномощно в оригинал, когато се подава от пълномощник
                                <input autoComplete="off" role="presentation" 
                                    type="checkbox"
                                    name="request[doc_proxy]"
                                    value="1"
                                    checked={fields && fields.request && fields.request.doc_proxy ? true : false}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="row">
                            <label className="checkbox">
                                Други
                                <input autoComplete="off" role="presentation" 
                                    type="checkbox"
                                    name="request[doc_has_others]"
                                    value="1"
                                    checked={fields && fields.request && fields.request.doc_has_others ? true : false}
                                    onChange={handleCheckboxChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        {fields && fields.request && fields.request.doc_has_others ?
                            <div className="row">
                                <div className="col">
                                    <label>
                                        Описание на други документи
                                </label>
                                    <textarea
                                        name="request[doc_other]"
                                        placeholder="Въведете описание на други документи..."
                                        defaultValue={fields && fields.request ? fields.request.doc_other : ''}
                                    >
                                    </textarea>
                                </div>
                            </div>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Documents, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})