import React, { useRef, useEffect, useState } from 'react'

//modals
import ObjectsModal from '../../../../../modals/Objects'
import Api from '../../../../../../helpers/Api';
import { useAuthContext } from '../../../../../../contexts/AuthContext';


function Objects(props) {

    const auth = useAuthContext()

    const [objects, setObjects] = useState({
        list: [],
        selected: []
    });

    const objectsModalRef = useRef(null)

    useEffect(() => {
        if (props.procedure_id) {
            Api.get('procedures/temptrade/orderpossibleprocedures?procedure_id=' + props.procedure_id)
                .then(res => {

                    let selected = res.data.filter(e => {
                        return e.selected == true;
                    });

                    setObjects(prev => ({
                        ...prev,
                        list: res.data,
                        selected: selected
                    }));
                });
        }

    }, [props.procedure_id]);

    const handleObjects = () => {
        objectsModalRef.current.open()
    }

    const setSelected = data => {
        setObjects(prev => ({
            ...prev,
            selected: data
        }));
    }

    return (
        <>
            <ObjectsModal
                ref={objectsModalRef}
                inputName="order"
                data={objects.list}
                setSelected={setSelected}
            />
            <h3>
                <span>
                    3
                </span>
                Свързани заявления
            </h3>
            <div className="holder">
                <ul className="col scroller" style={{ maxHeight: '300px' }}>
                    {objects.selected.map(o =>
                        <li key={o.object.id}>
                            {o.object.activity ? o.object.activity.name : ''}
                            {o.object.town ? ` - ${o.object.town.kind} ${o.object.town.name}` : ''}
                            {o.object.street ? ` ${o.object.street.suff} ${o.object.street.name}` : ''}
                            {o.object.street_number ? ` № ${o.object.street_number}` : ''}
                            {o.object.name ? ` - ${o.object.name}` : ''}
                            &nbsp; - вх.№ {o.request_no} / {o.request_date} г.
                        </li>
                    )}
                </ul>
                {auth.canWriteDocuments() &&
                    <>
                        <hr />
                        <div className="buttons">
                            <button type="button" onClick={handleObjects}>
                                Промяна
                            </button>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default Objects