import React, { useState, forwardRef, useEffect } from 'react'

//context
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

import Dropdown from '../../../../../../app/Dropdown'
import * as Constants from '../../../../../../../helpers/Constants'
import Signature from '../../../../../../partials/Signature'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'

function Fields(props, ref) {
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        object: {},
    }

    const [fields, setFields] = useState(fieldsData)

    const editable = props.editable || false
    const original = props.original

    useEffect(() => {
        if (original) setFields(original)
    }, [original])

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }
    }

    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name + '_formatted';

        setFields(prev => ({
            ...prev,
            [name]: val
        }));
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col col-auto">
                            <h3>
                                <span>
                                    1
                                </span>
                                Номер и Дата
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="request_no">
                                            № разрешение
                                        </label>
                                        {fields && (fields.license_no || fields.generated_license_no) && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="license_no"
                                            name="license_no"
                                            type="text"
                                            placeholder="Въведете №"
                                            className="field-medium"
                                            defaultValue={fields && fields.license_no ? fields.license_no : fields.generated_license_no}
                                            readOnly={fields && (fields.license_no || fields.generated_license_no) ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="license_date">
                                            Дата
                                            </label>
                                        {original && original.license_date && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="license_date"
                                            name="license_date"
                                            type="date"
                                            value={fields && fields.license_date_formatted ? fields.license_date_formatted : fieldsContext.today()}
                                            readOnly={original && original.license_date ? true : false}
                                            onChange={handleDateChange}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col">
                            <h3>
                                <span>
                                    2
                                </span>
                                Данни за фирмата заявител
                            </h3>
                            <div className="holder">

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="company_name">
                                            Точно наименование на фирмата
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.company && fields.company.name}
                                            readOnly={true}
                                        />

                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_eik">
                                            ЕИК / Булстат
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.company && fields.company.eik}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_phone">
                                            Тел. номер
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.company && fields.company.phone}
                                            // readOnly={editable && fields && fields.company && fields.company.phone ? true : false}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_town">
                                            Населено място
                                    </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.company && fields.company.town
                                                ? fields.company.town.id
                                                    ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                    : fields.company.town.name
                                                : ''}
                                            readOnly={true}
                                        />

                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_street">
                                            Улица
                                    </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                            readOnly={true}
                                        />

                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_street_number">
                                                    №
                                            </label>
                                                <input autoComplete="off" role="presentation"
                                                    value={fields && fields.company && fields.company.street_number}
                                                    className="field-small"
                                                    readOnly={true}
                                                    min="0"
                                                    step="1"
                                                />

                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_entrance">
                                                    Вход
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    value={fields && fields.company && fields.company.entrance}
                                                    className="field-small"
                                                    readOnly={true}
                                                />

                                            </div>


                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_block">
                                                    Блок
                                                </label>

                                                <input autoComplete="off" role="presentation"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.block}
                                                    readOnly={editable && fields && fields.company && fields.company.block ? true : false}
                                                    readOnly={true}
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_floor">
                                                    Етаж
                                                </label>

                                                <input autoComplete="off" role="presentation"
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.floor}
                                                    // readOnly={editable && fields && fields.company && fields.company.floor ? true : false}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="company_mol">
                                            МОЛ
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_mol"
                                            type="text"
                                            value={fields && fields.company && fields.company.mol}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col">

                                    </div>

                                    <div className="col">

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    3
                                </span>
                                Данни за обекта
                            </h3>
                            <div className="holder">

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_town">
                                            Населено място
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.town
                                                ? `${fields.object.town.kind} ${fields.object.town.name}`
                                                : ''}
                                            readOnly={true}
                                        />

                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_street">
                                            Улица
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="object_street_number">
                                                    №
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    value={fields && fields.object && fields.object.street_number}
                                                    readOnly={true}
                                                    min="0"
                                                    step="1"
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="object_entrance">
                                                    Вход
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    value={fields && fields.object && fields.object.entrance}
                                                    readOnly={true}
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="object_block">
                                                    Блок
                                            </label>
                                                <input autoComplete="off" role="presentation"
                                                    value={fields && fields.object && fields.object.block}
                                                    readOnly={true}
                                                />

                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="object_floor">
                                                    Етаж
                                            </label>
                                                <input autoComplete="off" role="presentation"
                                                    value={fields && fields.object && fields.object.floor}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_name">
                                            Наименование
                                    </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.name}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_phone">
                                            Тел. номер
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.phone}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="object_type">
                                                    Вид на търговския обект
                                            </label>
                                                <input autoComplete="off" role="presentation"
                                                    value={fields && fields.object && fields.object.type && fields.object.type.name}
                                                    readOnly={true}
                                                />

                                            </div>


                                        </div>
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_activity">
                                            Предмет на дейност
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.activity && fields.object.activity.name}
                                            readOnly={true}
                                        />

                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    4
                                </span>
                                Разполагане
                            </h3>
                            <div className="holder">
                                <div className="row">

                                    <div className="col col-auto able-to-edit">
                                        <label>
                                            На
                                        </label>
                                        {editable && original && original.license_text && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            name="license_text"
                                            defaultValue={fields && fields.license_text ? fields.license_text : fields.request_equipment_type}
                                            readOnly={editable && original && original.license_text ? true : false}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="license_period_from">
                                            Срок
                                        </label>
                                        <div className="row period">
                                            <div className="extra-holder">
                                                <span className="extra-label">
                                                    От
                                            </span>
                                                {editable && original && original.license_period_from && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="license_period_from"
                                                    type="date"
                                                    className="field-medium"
                                                    name="license_period_from"
                                                    defaultValue={fields && fields.license_period_from_formatted ? fields.license_period_from_formatted : fields.request_period_from_formatted}
                                                    readOnly={editable && original && original.license_period_from ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                            <div className="extra-holder">
                                                <span className="extra-label">
                                                    До
                                                </span>
                                                {editable && original && original.license_period_to && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    type="date"
                                                    name="license_period_to"
                                                    className="field-medium"
                                                    defaultValue={fields && fields.license_period_to_formatted ? fields.license_period_to_formatted : fields.request_period_to_formatted}
                                                    readOnly={editable && original && original.license_period_to ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="area">
                                            Площ
                                        </label>
                                        <div className="row extra-holder">
                                            <div className="extra-holder">
                                                {editable && original && original.license_area_size && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    name="license_area_size"
                                                    id="license_area_size"
                                                    type="text"
                                                    className="field-small"
                                                    defaultValue={fields && fields.license_area_size ? fields.license_area_size : fields.request_area_size}
                                                    readOnly={editable && original && original.license_area_size ? true : false}
                                                />
                                                <input autoComplete="off" role="presentation" type="hidden" />
                                            </div>
                                            <span className="extra-label">
                                                кв.м.
                                            </span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col col-auto">
                            <h3>
                                <span>
                                    5
                                </span>
                                Одобрил
                            </h3>
                            <div className="holder" name="license_signed_by_name">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <Signature
                                            name="license_signed_by"
                                            group_id={Constants.MAYORS}
                                            selected={fields && fields.licensesignature}
                                            reason={fields && fields.license_signed_by_reason}
                                            handleModify={props.handleModify}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
            </div >
        </div >
    )
}

export default React.memo(forwardRef(Fields), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})