import React, { useState, useEffect } from 'react'

import editIcon from '../../../../../assets/img/pages/icons/edit-field.jpg'
import { useFieldsContext } from '../../../../../contexts/FieldsContext'
import WorkTimeInput from '../../../../partials/WorkTime'
import FreeDaysInput from '../../../../partials/FreeDays'

function Fields(props) {
    const fieldsContext = useFieldsContext()

    const [fields, setFields] = useState({})

    const original = props.original || null
    const editable = props.editable || false

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    return (
        <div className="panel">
            <div className="row">

                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и Дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col col-auto able-to-edit">
                                <label htmlFor="close_no">
                                    Входящ №
                                </label>
                                {editable && fields && fields.request_no && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request_no"
                                    name="request_no"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={editable && fields ? fields.request_no : ''}
                                    readOnly={editable && fields && fields.request_no ? true : false}
                                />
                            </div>
                        </div>

                        <div className="row">

                            <div className="col col-auto able-to-edit">
                                <label htmlFor="request_date">
                                    Дата
                                </label>
                                {editable && fields && fields.request_date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request_date"
                                    name="request_date"
                                    type="date"
                                    className="field-medium"
                                    defaultValue={editable && fields ? fields.request_date_formatted : ''}
                                    readOnly={editable && fields && fields.request_date ? true : false}
                                />
                            </div>

                        </div>
                        <div className="row">

                            <div className="col col-auto able-to-edit">
                                <label htmlFor="close_date">
                                    Дата на прекратяване
                                </label>
                                {editable && fields && fields.close_date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="close_date"
                                    name="close_date"
                                    type="date"
                                    className="field-medium"
                                    defaultValue={editable && fields ? fields.close_date_formatted : ''}
                                    readOnly={editable && fields && fields.close_date ? true : false}
                                />
                            </div>

                        </div>

                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Данни за заявителя
                    </h3>

                    <div className="holder">
                        {!fields.applicant_type || fields.applicant_type === 'company'
                            ?
                            <div className="col">

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_name">
                                            Точно наименование на фирмата
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            id="company_name"
                                            value={fields && fields.company && fields.company.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_eik">
                                            ЕИК / Булстат
                                                </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_eik"
                                            type="text"
                                            value={fields && fields.company && fields.company.eik}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_phone">
                                            Тел. номер
                                                </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.phone}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_town">
                                            Населено място
                                                </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.town
                                                ? fields.company.town.id
                                                    ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                    : fields.company.town.name
                                                : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_street">
                                            Улица
                                                </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_street_number">
                                                    №
                                                        </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_street_number"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.street_number}
                                                    readOnly
                                                    min="0"
                                                    step="1"
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_entrance">
                                                    Вход
                                                        </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_entrance"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.entrance}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_block">
                                                    Блок
                                                        </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_block"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.block}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_floor">
                                                    Етаж
                                                        </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_floor"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.floor}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="company_mol">
                                            МОЛ
                                                </label>

                                        <input autoComplete="off" role="presentation"
                                            id="mol"
                                            type="text"
                                            value={fields && fields.company && fields.company.mol}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col">

                                    </div>

                                    <div className="col">

                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col">


                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_name">
                                            Трите имена на лицето
                                                </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_name"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_egn">
                                            ЕГН
                                                </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_egn"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.egn}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_phone">
                                            Тел. номер
                                                </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_phone"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.phone}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_town">
                                            Населено място
                                                </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_town"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.town
                                                ? fields.applicant.town.id
                                                    ? `${fields.applicant.town.kind} ${fields.applicant.town.name}`
                                                    : fields.applicant.town.name
                                                : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_street">
                                            Улица
                                                </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_street"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.street ? `${fields.applicant.street.suff} ${fields.applicant.street.name}` : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="applicant_street_number">
                                                    №
                                                        </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_street_number"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    className="field-small"
                                                    value={fields && fields.applicant && fields.applicant.street_number}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="applicant_entrance">
                                                    Вход
                                                        </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_entrance"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.applicant && fields.applicant.entrance}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="applicant_block">
                                                    Блок
                                                        </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_block"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.applicant && fields.applicant.block}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="applicant_floor">
                                                    Етаж
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_floor"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.applicant && fields.applicant.floor}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>

            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Данни за обекта
                    </h3>
                    <div className="holder">

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_town">
                                    Населено място
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_town"
                                    type="text"
                                    value={fields && fields.object && fields.object.town
                                        ? fields.object.town.id
                                            ? `${fields.object.town.kind} ${fields.object.town.name}`
                                            : fields.object.town.name
                                        : ''}
                                    readOnly
                                />
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_region">
                                            Район/квартал
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_region"
                                            type="text"
                                            value={fields && fields.object && fields.object.region && fields.object.region.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_upi">
                                            УПИ
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields.object && fields.object.upi}
                                            className="field-medium"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col able-to-edit">
                                <label htmlFor="object_street">
                                    Улица
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_street"
                                    type="text"
                                    value={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                    readOnly
                                />
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_street_number">
                                            №
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_street_number"
                                            type="text"
                                            min="0"
                                            step="1"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.street_number}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_entrance">
                                            Вход
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_entrance"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.entrance}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_block">
                                            Блок
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_block"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.block}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_floor">
                                            Етаж
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_floor"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.floor}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_name">
                                    Наименование
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_name"
                                    type="text"
                                    value={fields && fields.object && fields.object.name}
                                    readOnly
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_phone">
                                    Тел. номер
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_phone"
                                    type="text"
                                    value={fields && fields.object && fields.object.phone}
                                    readOnly
                                />
                            </div>
                            <div className="col able-to-edit">
                                <label htmlFor="object_type">
                                    Вид на търговския обект
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_type"
                                    type="text"
                                    value={fields && fields.object && fields.object.type && fields.object.type.name}
                                    readOnly
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_activity">
                                    Предмет на дейност
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_activity"
                                    type="text"
                                    value={fields && fields.object && fields.object.activity && fields.object.activity.name}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_owner">
                                    Собственик
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_owner"
                                    type="text"
                                    value={fields && fields.object && fields.object.owner}
                                    readOnly
                                />
                            </div>
                            <div className="col able-to-edit"></div>
                            <div className="col able-to-edit"></div>
                            <div className="col able-to-edit"></div>
                        </div>


                    </div>
                </div>

            </div>

        </div>
    )
}

export default Fields