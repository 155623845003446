import React, { useState, useImperativeHandle, forwardRef } from 'react'

//images
import removeIcon from '../../../assets/img/modals/remove.png'

function Remove(props, ref) {

    const [remove, setRemove] = useState({
        overlay: false,
        modal: false,
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            setRemove(remove => ({
                ...remove,
                overlay: true,
            }))
            setTimeout(() => {
                setRemove(remove => ({
                    ...remove,
                    modal: true,
                }))
            }, 50)
        },
        close: () => {
            handleClose()
        }
    }))

    const handleClose = () => {
        setRemove(remove => ({
            ...remove,
            modal: false,
        }))

        setTimeout(() => {
            setRemove(remove => ({
                ...remove,
                overlay: false,
            }))
        }, 100)
    }

    const handleRemove = () => {
        props.callbackData('Избраният елемент е премахнат успешно')
        handleClose()
    }

    return (
        <div className={`${remove.overlay ? 'visible' : ''} overlay`}>
            <div className={`${remove.modal ? 'show' : ''} box`}>
                <img src={removeIcon} alt="remove" />
                <h3>
                    {props.mainMessage}
                </h3>
                <p>
                    {props.secondaryMessage}
                </p>
                <div className="buttons">
                    <button onClick={handleClose}>
                        Отказ
                    </button>
                    <button className="btn-primary" onClick={handleRemove}>
                        Изтриване
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Remove)