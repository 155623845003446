import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Dropdown from '../app/Dropdown'
import Api from '../../helpers/Api';

import ZonePricesOutdoorRow from './ZonePricesOutdoorRow';
import ZonePricesTempTradeRow from './ZonePricesTempTradeRow';


//modals
import SuccessModal from './messages/Success'
//temporary
import AttentionModal from './messages/Attention'

function ZonePrices(props, ref) {

    //refs
    const form = useRef(null)
    const SuccessModalRef = useRef(null)
    const AttentionModalRef = useRef(null)

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        id: null,
        zone: null,
        prices: {},
        loading: false,
        refresh: false
    })

    const [fields, setFields] = useState({})

    useEffect(() => {

        setFields({})

        if (state.id) {
            Api.get('zones/show/' + state.id)
                .then(res => {

                    let outdoorPrices = res.data.prices.filter(p => {
                        return p.procedure_type_id == 2;
                    });

                    let tempTradePrices = res.data.prices.filter(p => {
                        return p.procedure_type_id == 3;
                    });

                    let tempTradePricesGroup = {};

                    tempTradePrices.map(p => {

                        if (!tempTradePricesGroup[p.date_formatted]) {
                            tempTradePricesGroup[p.date_formatted] = {};
                        }

                        if (!tempTradePricesGroup[p.date_formatted][p.type_id]) {
                            tempTradePricesGroup[p.date_formatted][p.type_id] = {};
                        }

                        tempTradePricesGroup[p.date_formatted][p.type_id] = p;

                    });

                    let prices = {
                        outdoor: outdoorPrices,
                        temptrade: tempTradePricesGroup
                    };

                    setState(prev => ({
                        ...prev,
                        zone: res.data,
                        prices: prices,
                        loading: false
                    }))

                    setFields(res.data);
                });
        }
    }, [state.id, state.refresh]);

    useImperativeHandle(ref, () => ({
        open: id => {

            setState(prev => ({
                ...prev,
                id: id,
                refresh: new Date().getTime(),
                loading: true
            }))

            setFields({})

            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }))

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault();

        let data = new FormData(form.current);

        if (state.id) {
            data.append('id', state.id);
        }

        Api.post('zones/set-prices', data)
            .then(res => {
                SuccessModalRef.current.open()

                setTimeout(() => {
                    props.refreshData()
                    handleClose()
                }, 3300)

            }).catch(err => {
                AttentionModalRef.current.open()
            });
    }

    const handleAddPrice = type => {
        const el = document.getElementById(type);
        const exampleRow = el.getElementsByClassName('example')[0];
        const rows = el.getElementsByTagName('tbody')[0];
        const buttonRow = rows.getElementsByClassName('last')[0];

        if (rows && exampleRow) {
            let newRow = exampleRow.cloneNode(true);

            newRow.classList.remove('example');
            newRow.classList.remove('hidden');

            newRow.getElementsByClassName('delete')[0].addEventListener('click', handleRemovePrice);

            rows.insertBefore(newRow, buttonRow);
        }
    }

    const handleRemovePrice = e => {
        const row = e.target.parentNode.parentNode;
        row.remove();
    }

    return (
        <form autoComplete="off" role="presentation"  ref={form}  className={`${state.overlay ? 'visible' : ''} overlay`} onSubmit={handleSubmit}>
            <SuccessModal
                ref={SuccessModalRef}
                mainMessage={'Промените бяха запазени успешно!'}
            />
            <AttentionModal
                ref={AttentionModalRef}
                mainMessage={'Имате непопълнени полета!'}
                secondaryMessage={'Моля, попълнете всички полета, за да продължите.'}
            />
            {!state.loading &&
                <div className={`${state.modal ? 'show' : ''} form-box box-large`}>
                    <h2>
                        Цени за зона {state.zone && state.zone.code}
                    </h2>
                    <p>
                        {/*  */}
                    </p>
                    <hr />

                    <div className="scroller" style={{ padding: '10px' }}>
                        ТЪРГОВИЯ НА ОТКРИТО В ПРИЛЕЖАЩАТА ТЕРИТОРИЯ НА ТЪРГОВСКИ ОБЕКТИ
                        <div className="row">
                            <div className="col" style={{ width: '100%' }}>
                                <table id="outdoor" className="left" style={{ width: '100%', margin: '10px 0' }}>
                                    <thead>
                                        <tr>
                                            <th>
                                                От дата
                                            </th>
                                            <th>
                                                кв. / месец
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.prices.outdoor && state.prices.outdoor.map(p =>
                                            <ZonePricesOutdoorRow
                                                key={p.id}
                                                data={p}
                                                handleRemovePrice={handleRemovePrice}
                                            />
                                        )}
                                        <ZonePricesOutdoorRow
                                            example={true}
                                            hidden={state.prices.outdoor && state.prices.outdoor.length ? true : false}
                                            handleRemovePrice={handleRemovePrice}
                                        />
                                        <tr className="last">
                                            <td colSpan="3">
                                                <button
                                                    class="btn-add btn-add-simple"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        handleAddPrice('outdoor')
                                                    }}
                                                ></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        ТЪРГОВИЯ НА ОТКРИТО НА МЕСТА ЗА ВРЕМЕННА ТЪРГОВИЯ, ПРОМОЦИИ И СЪБИТИЯ ОТ РЕКЛАМЕН ХАРАКТЕР
                        <div className="row">
                            <div className="col" style={{ width: '100%' }}>
                                <table id="temptrade" className="left" style={{ width: '100%', margin: '10px 0' }}>
                                    <thead>
                                        <tr>
                                            <th>
                                                От дата
                                            </th>
                                            <th>
                                                кв. / месец
                                            </th>
                                            <th>
                                                кв. / ден
                                            </th>
                                            <th>
                                                кв. / ден (атракции)
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.prices.temptrade && Object.entries(state.prices.temptrade).map((p, i) =>
                                            <ZonePricesTempTradeRow
                                                key={i}
                                                date={p[0]}
                                                data={p[1]}
                                                handleRemovePrice={handleRemovePrice}
                                            />
                                        )}
                                        <ZonePricesTempTradeRow
                                            example={true}
                                            hidden={state.prices.temptrade && Object.values(state.prices.temptrade).length ? true : false}
                                            handleRemovePrice={handleRemovePrice}
                                        />
                                        <tr className="last">
                                            <td colSpan="5">
                                                <button
                                                    class="btn-add btn-add-simple"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        handleAddPrice('temptrade')
                                                    }}
                                                ></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="buttons">
                        <span className="btn-default" onClick={handleClose}>
                            Отказ
                        </span>
                        <button type="button" onClick={handleSubmit}>
                            Запази
                        </button>
                    </div>
                </div>
            }
        </form>
    )
}

export default forwardRef(ZonePrices)