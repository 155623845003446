import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from 'react'

//context
import { useStepsContext } from '../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../contexts/FieldsContext'

//images
import editIcon from '../../../../../assets/img/pages/icons/edit-field.jpg'

//partials
import Dropdown from '../../../../app/Dropdown'
import WeekHours from '../../../../modals/WeekHours'
import FreeDays from '../../../../modals/FreeDays'
import FreeDaysInput from '../../../../partials/FreeDays'
import WorkTimeInput from '../../../../partials/WorkTime'
import Periods from '../../../../partials/Periods'
import { useAuthContext } from '../../../../../contexts/AuthContext'

function Application(props, ref) {
    const auth = useAuthContext()
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        object: {},
    }

    const [fields, setFields] = useState(fieldsData)

    const editable = props.editable || false
    const original = props.original

    //refs

    const objectRegionRef = useRef(null)
    const objectStreetRef = useRef(null)
    const objectActivityRef = useRef(null)
    const companyStreetRef = useRef(null)
    const applicantStreetRef = useRef(null)

    useImperativeHandle(ref, () => ({
        setCompany: data => setCompany(data),
        setCompanyId: id => setCompanyId(id),
        setApplicant: data => setApplicant(data),
        setApplicantId: id => setApplicantId(id),
        setObjectId: id => setObjectId(id)
    }))

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleCheckboxChange = e => {
        let name = e.target.name
        let checked = e.target.checked;

        setFields(prev => ({
            ...prev,
            [name]: checked
        }))
    }

    // dropdown change
    const handleCompanyChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                company: newData
            }))
        }
    }

    const setCompany = newData => {
        setFields(prev => ({
            ...prev,
            company: newData
        }))
    }

    const setCompanyId = id => {
        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                id: id
            }
        }))
    }

    const setApplicant = newData => {
        setFields(prev => ({
            ...prev,
            applicant: newData
        }))
    }

    const setApplicantId = id => {
        setFields(prev => ({
            ...prev,
            applicant: {
                ...prev.applicant,
                id: id
            }
        }))
    }

    const handleApplicantChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                applicant: newData
            }))
        }
    }

    const handleCompanyTownChange = newData => {
        companyStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                town_id: newData.id,
                // town: newData.id ? newData : (prev.company ? prev.company.town : '')
                town: newData
            }
        }))
    }

    const handleApplicantTownChange = newData => {
        applicantStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            applicant: {
                ...prev.applicant,
                town_id: newData.id,
                // town: newData.id ? newData : (prev.applicant ? prev.applicant.town : '')
                town: newData
            }
        }))
    }

    const setObjectId = id => {
        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                id: id
            }
        }))
    }

    const handleObjectTownChange = newData => {
        objectStreetRef.current.reset()
        objectRegionRef.current.reset()

        // console.log(newData)

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                town_id: newData.id,
                // town: newData.id ? newData : (prev.object ? prev.object.town : '')
                town: newData
            }
        }))
    }

    const handleObjectTypeChange = newData => {
        objectActivityRef.current.reset()

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                type_id: newData.id,
                // town: newData.id ? newData : (prev.object ? prev.object.town : '')
                type: newData
            }
        }))
    }


    return (
        <>

            <div className="panel">
                <div className="row">
                    <div className="col col-auto">
                        <h3>
                            <span>
                                1
                            </span>
                            Номер и Дата
                        </h3>
                        <div className="holder">
                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="request_no">
                                        Входящ №
                                    </label>
                                    {editable && fields && fields.request_no && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="request_no"
                                        name="request_no"
                                        type="text"
                                        placeholder="Въведете №"
                                        className="field-medium"
                                        defaultValue={editable && fields ? fields.request_no : ''}
                                        readOnly={editable && fields && fields.request_no ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="request_date">
                                        Дата
                                    </label>
                                    {editable && fields && fields.request_date && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="request_date"
                                        name="request_date"
                                        type="date"
                                        defaultValue={editable && fields && fields.request_date_formatted ? fields.request_date_formatted : fieldsContext.today()}
                                        readOnly={editable && fields && fields.request_date ? true : false}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* step 2 */}
                    <div className="col">
                        <h3>
                            <span>
                                2
                            </span>
                            Данни за заявителя
                        </h3>
                        <div className="holder">

                            {auth.canWriteRequest() &&
                                <div className="row">

                                    <label className="checkbox radio">
                                        Фирма
                                    <input autoComplete="off" role="presentation"
                                            type="radio"
                                            name="applicant_type"
                                            id="applicant_type"
                                            value="company"
                                            checked={fields && fields.applicant_type === 'person' ? false : true}
                                            onChange={handleFieldChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>

                                    <label className="checkbox radio">
                                        Физ. лице
                                    <input autoComplete="off" role="presentation"
                                            type="radio"
                                            name="applicant_type"
                                            id="applicant_type"
                                            value="person"
                                            checked={fields && fields.applicant_type === 'person' ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                        <span className="checkmark"></span>
                                    </label>

                                </div>
                            }
                            {!fields.applicant_type || fields.applicant_type === 'company'
                                ?
                                <div className="row">
                                    <div className="col">

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_name">
                                                    Точно наименование на фирмата
                                                </label>
                                                {editable && fields && fields.company && fields.company.name && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    key="cn"
                                                    inputName="company[name]"
                                                    inputValue={fields && fields.company && fields.company.name}
                                                    inputIdName="company[id]"
                                                    inputIdValue={fields && fields.company && fields.company.id}
                                                    inputPlaceholder="Въведете име"
                                                    inputDisabled={editable && fields && fields.company && fields.company.name ? true : false}
                                                    url="companies/find"
                                                    onChange={handleCompanyChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_eik">
                                                    ЕИК / Булстат
                                                </label>
                                                {editable && original && original.company && original.company.eik && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    key="ce"
                                                    id="eik"
                                                    name="company[eik]"
                                                    type="text"
                                                    placeholder="Въведете ЕИК / Булстат"
                                                    value={fields && fields.company && fields.company.eik}
                                                    readOnly={editable && original && original.company && original.company.eik ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_phone">
                                                    Тел. номер
                                                </label>
                                                {editable && original && original.company && original.company.phone && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="company_phone"
                                                    name="company[phone]"
                                                    type="text"
                                                    placeholder="Въведете тел. номер"
                                                    value={fields && fields.company && fields.company.phone}
                                                    readOnly={editable && original && original.company && original.company.phone ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_town">
                                                    Населено място
                                                </label>
                                                {editable && fields && fields.company && fields.company.town && fields.company.town && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    inputName="company[town]"
                                                    inputValue={fields && fields.company && fields.company.town
                                                        ? fields.company.town.id
                                                            ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                            : fields.company.town.name
                                                        : ''}
                                                    inputIdName="company[town_id]"
                                                    inputIdValue={fields && fields.company && fields.company.town_id}
                                                    inputPlaceholder="Изберете населено място"
                                                    inputDisabled={editable && fields && fields.company && fields.company.town && fields.company.town.id ? true : false}
                                                    url="towns/find"
                                                    renderText={(data) => {
                                                        return <>{data.kind} {data.name}</>;
                                                    }}
                                                    onChange={handleCompanyTownChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_street">
                                                    Улица
                                                </label>
                                                {editable && fields && fields.company && fields.company.street && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={companyStreetRef}
                                                    inputName="company[street]"
                                                    inputValue={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                                    inputIdName="company[street_id]"
                                                    inputIdValue={fields && fields.company && fields.company.street_id}
                                                    inputPlaceholder="Изберете улица"
                                                    inputDisabled={editable && fields && fields.company && fields.company.street ? true : false}
                                                    url="streets/find"
                                                    params={{
                                                        town_id: fields && fields.company && fields.company.town_id
                                                    }}
                                                    renderText={(data) => {
                                                        return <>{data.suff} {data.name}</>;
                                                    }}
                                                />
                                            </div>

                                            <div className="col">
                                                <div className="row">
                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="company_street_number">
                                                            №
                                                        </label>
                                                        {editable && original && original.company && original.company.street_number && <img
                                                            src={editIcon}
                                                            alt="edit"
                                                            className="edit"
                                                            onClick={e => fieldsContext.handleEdit(e)}
                                                        />}
                                                        <input autoComplete="off" role="presentation"
                                                            id="company_street_number"
                                                            name="company[street_number]"
                                                            type="number"
                                                            min="0"
                                                            step="1"
                                                            placeholder=""
                                                            className="field-small"
                                                            value={fields && fields.company && fields.company.street_number}
                                                            readOnly={editable && original && original.company && original.company.street_number ? true : false}
                                                            onChange={handleFieldChange}
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="company_entrance">
                                                            Вход
                                                        </label>
                                                        {editable && original && original.company && original.company.entrance && <img
                                                            src={editIcon}
                                                            alt="edit"
                                                            className="edit"
                                                            onClick={e => fieldsContext.handleEdit(e)}
                                                        />}
                                                        <input autoComplete="off" role="presentation"
                                                            id="company_entrance"
                                                            name="company[entrance]"
                                                            type="text"
                                                            placeholder=""
                                                            className="field-small"
                                                            value={fields && fields.company && fields.company.entrance}
                                                            readOnly={editable && original && original.company && original.company.entrance ? true : false}
                                                            onChange={handleFieldChange}
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="company_block">
                                                            Блок
                                                        </label>
                                                        {editable && original && original.company && original.company.block && <img
                                                            src={editIcon}
                                                            alt="edit"
                                                            className="edit"
                                                            onClick={e => fieldsContext.handleEdit(e)}
                                                        />}
                                                        <input autoComplete="off" role="presentation"
                                                            id="company_block"
                                                            name="company[block]"
                                                            type="text"
                                                            placeholder=""
                                                            className="field-small"
                                                            value={fields && fields.company && fields.company.block}
                                                            readOnly={editable && original && original.company && original.company.block ? true : false}
                                                            onChange={handleFieldChange}
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="company_floor">
                                                            Етаж
                                                        </label>
                                                        {editable && original && original.company && original.company.floor && <img
                                                            src={editIcon}
                                                            alt="edit"
                                                            className="edit"
                                                            onClick={e => fieldsContext.handleEdit(e)}
                                                        />}
                                                        <input autoComplete="off" role="presentation"
                                                            id="company_floor"
                                                            name="company[floor]"
                                                            type="text"
                                                            placeholder=""
                                                            className="field-small"
                                                            value={fields && fields.company && fields.company.floor}
                                                            readOnly={editable && original && original.company && original.company.floor ? true : false}
                                                            onChange={handleFieldChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="company_mol">
                                                    МОЛ
                                                </label>
                                                {editable && original && original.company && original.company.mol && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="mol"
                                                    name="company[mol]"
                                                    type="text"
                                                    placeholder="Въведете МОЛ"
                                                    value={fields && fields.company && fields.company.mol}
                                                    readOnly={editable && original && original.company && original.company.mol ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">

                                            </div>

                                            <div className="col able-to-edit">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col">


                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_name">
                                                    Трите имена на лицето
                                            </label>
                                                {editable && fields && fields.applicant && fields.applicant.name && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}

                                                <Dropdown
                                                    key="an"
                                                    inputName="applicant[name]"
                                                    inputValue={fields && fields.applicant && fields.applicant.name}
                                                    inputIdName="applicant[id]"
                                                    inputIdValue={fields && fields.applicant && fields.applicant.id}
                                                    inputPlaceholder="Въведете име"
                                                    inputDisabled={editable && fields && fields.applicant && fields.applicant.name ? true : false}
                                                    url="persons/find"
                                                    renderText={(data) => {
                                                        return <>{data.name} {data.egn && `(ЕГН: ${data.egn})`}</>;
                                                    }}
                                                    renderInputText={(data) => {
                                                        return <>{data.name}</>;
                                                    }}
                                                    onChange={handleApplicantChange}
                                                />

                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_egn">
                                                    ЕГН
                                                </label>
                                                {editable && original && original.applicant && original.applicant.egn && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    key="ae"
                                                    id="applicant_egn"
                                                    name="applicant[egn]"
                                                    type="text"
                                                    placeholder="Въведете EГН"
                                                    value={fields && fields.applicant && fields.applicant.egn}
                                                    readOnly={editable && original && original.applicant && original.applicant.egn ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_phone">
                                                    Тел. номер
                                                </label>
                                                {editable && original && original.applicant && original.applicant.phone && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_phone"
                                                    name="applicant[phone]"
                                                    type="text"
                                                    placeholder="Въведете тел. номер"
                                                    value={fields && fields.applicant && fields.applicant.phone}
                                                    readOnly={editable && original && original.applicant && original.applicant.phone ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_town">
                                                    Населено място
                                            </label>
                                                {editable && fields && fields.applicant && fields.applicant.town && fields.applicant.town.id && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    inputName="applicant[town]"
                                                    inputValue={fields && fields.applicant && fields.applicant.town
                                                        ? fields.applicant.town.id
                                                            ? `${fields.applicant.town.kind} ${fields.applicant.town.name}`
                                                            : fields.applicant.town.name
                                                        : ''}
                                                    inputIdName="applicant[town_id]"
                                                    inputIdValue={fields && fields.applicant && fields.applicant.town_id}
                                                    inputPlaceholder="Изберете населено място"
                                                    inputDisabled={editable && fields && fields.applicant && fields.applicant.town && fields.applicant.town.id ? true : false}
                                                    url="towns/find"
                                                    renderText={(data) => {
                                                        return <>{data.kind} {data.name}</>;
                                                    }}
                                                    onChange={handleApplicantTownChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_street">
                                                    Улица
                                            </label>
                                                {editable && fields && fields.applicant && fields.applicant.street && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={applicantStreetRef}
                                                    inputName="applicant[street]"
                                                    inputValue={fields && fields.applicant && fields.applicant.street ? `${fields.applicant.street.suff} ${fields.applicant.street.name}` : ''}
                                                    inputIdName="applicant[street_id]"
                                                    inputIdValue={fields && fields.applicant && fields.applicant.street_id}
                                                    inputPlaceholder="Изберете улица"
                                                    inputDisabled={editable && fields && fields.applicant && fields.applicant.street ? true : false}
                                                    url="streets/find"
                                                    params={{
                                                        town_id: fields && fields.applicant && fields.applicant.town_id
                                                    }}
                                                    renderText={(data) => {
                                                        return <>{data.suff} {data.name}</>;
                                                    }}
                                                />
                                            </div>

                                            <div className="col">
                                                <div className="row">
                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="applicant_street_number">
                                                            №
                                                        </label>
                                                        {editable && original && original.applicant && original.applicant.street_number && <img
                                                            src={editIcon}
                                                            alt="edit"
                                                            className="edit"
                                                            onClick={e => fieldsContext.handleEdit(e)}
                                                        />}
                                                        <input autoComplete="off" role="presentation"
                                                            id="applicant_street_number"
                                                            name="applicant[street_number]"
                                                            type="number"
                                                            min="0"
                                                            step="1"
                                                            placeholder=""
                                                            className="field-small"
                                                            value={fields && fields.applicant && fields.applicant.street_number}
                                                            readOnly={editable && original && original.applicant && original.applicant.street_number ? true : false}
                                                            onChange={handleFieldChange}
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="applicant_entrance">
                                                            Вход
                                                        </label>
                                                        {editable && original && original.applicant && original.applicant.entrance && <img
                                                            src={editIcon}
                                                            alt="edit"
                                                            className="edit"
                                                            onClick={e => fieldsContext.handleEdit(e)}
                                                        />}
                                                        <input autoComplete="off" role="presentation"
                                                            id="applicant_entrance"
                                                            name="applicant[entrance]"
                                                            type="text"
                                                            placeholder=""
                                                            className="field-small"
                                                            value={fields && fields.applicant && fields.applicant.entrance}
                                                            readOnly={editable && original && original.applicant && original.applicant.entrance ? true : false}
                                                            onChange={handleFieldChange}
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="applicant_block">
                                                            Блок
                                                    </label>
                                                        {editable && original && original.applicant && original.applicant.block && <img
                                                            src={editIcon}
                                                            alt="edit"
                                                            className="edit"
                                                            onClick={e => fieldsContext.handleEdit(e)}
                                                        />}
                                                        <input autoComplete="off" role="presentation"
                                                            id="applicant_block"
                                                            name="applicant[block]"
                                                            type="text"
                                                            placeholder=""
                                                            className="field-small"
                                                            value={fields && fields.applicant && fields.applicant.block}
                                                            readOnly={editable && original && original.applicant && original.applicant.block ? true : false}
                                                            onChange={handleFieldChange}
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="applicant_floor">
                                                            Етаж
                                                        </label>
                                                        {editable && original && original.applicant && original.applicant.floor && <img
                                                            src={editIcon}
                                                            alt="edit"
                                                            className="edit"
                                                            onClick={e => fieldsContext.handleEdit(e)}
                                                        />}
                                                        <input autoComplete="off" role="presentation"
                                                            id="applicant_floor"
                                                            name="applicant[floor]"
                                                            type="text"
                                                            placeholder=""
                                                            className="field-small"
                                                            value={fields && fields.applicant && fields.applicant.floor}
                                                            readOnly={editable && original && original.applicant && original.applicant.floor ? true : false}
                                                            onChange={handleFieldChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col">
                        <h3>
                            <span>
                                3
                            </span>
                            Данни за обекта
                        </h3>
                        <div className="holder">

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_town">
                                        Населено място
                                    </label>
                                    {editable && fields && fields.object && fields.object.town && fields.object.town.id && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        inputName="object[town]"
                                        inputValue={fields && fields.object && fields.object.town
                                            ? fields.object.town.id
                                                ? `${fields.object.town.kind} ${fields.object.town.name}`
                                                : fields.object.town.name
                                            : ''}
                                        inputIdName="object[town_id]"
                                        inputIdValue={fields && fields.object && fields.object.town_id}
                                        inputPlaceholder="Изберете населено място"
                                        inputDisabled={editable && fields && fields.object && fields.object.town && fields.object.town.id ? true : false}
                                        url="towns/find"
                                        renderText={(data) => {
                                            return <>{data.kind} {data.name}</>;
                                        }}
                                        onChange={handleObjectTownChange}
                                    />
                                </div>

                                <div className="col">
                                    <div className="row">
                                        <div className="col able-to-edit">
                                            <label htmlFor="object_region">
                                                Район/квартал
                                            </label>
                                            {editable && original && original.object && original.object.region && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <Dropdown
                                                ref={objectRegionRef}
                                                inputName="object[region]"
                                                inputValue={fields && fields.object && fields.object.region ? fields.object.region.name : ''}
                                                inputIdName="object[region_id]"
                                                inputIdValue={fields && fields.object && fields.object.region_id}
                                                inputPlaceholder="Изберете район/квартал"
                                                inputDisabled={editable && original && original.object && original.object.region ? true : false}
                                                url="regions/find"
                                                params={{
                                                    town_id: fields && fields.object && fields.object.town_id
                                                }}

                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="object_upi">
                                                УПИ
                                            </label>
                                            {editable && original && original.object && original.object.upi && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                name="object[upi]"
                                                value={fields.object && fields.object.upi}
                                                className="field-medium"
                                                readOnly={editable && original && original.object && original.object.upi ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_street">
                                        Улица
                                    </label>
                                    {editable && fields && fields.object && fields.object.street && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        ref={objectStreetRef}
                                        inputName="object[street]"
                                        inputValue={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                        inputIdName="object[street_id]"
                                        inputIdValue={fields && fields.object && fields.object.street_id}
                                        inputPlaceholder="Изберете улица"
                                        inputDisabled={editable && fields && fields.object && fields.object.street ? true : false}
                                        url="streets/find"
                                        params={{
                                            town_id: fields && fields.object && fields.object.town_id
                                        }}
                                        renderText={(data) => {
                                            return <>{data.suff} {data.name}</>;
                                        }}
                                    />
                                </div>

                                <div className="col">
                                    <div className="row">
                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="object_street_number">
                                                №
                                            </label>
                                            {editable && original && original.object && original.object.street_number && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="object_street_number"
                                                name="object[street_number]"
                                                type="number"
                                                min="0"
                                                step="1"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.object && fields.object.street_number}
                                                readOnly={editable && original && original.object && original.object.street_number ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="object_entrance">
                                                Вход
                                            </label>
                                            {editable && original && original.object && original.object.entrance && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="object_entrance"
                                                name="object[entrance]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.object && fields.object.entrance}
                                                readOnly={editable && original && original.object && original.object.entrance ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>


                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="object_block">
                                                Блок
                                            </label>
                                            {editable && original && original.object && original.object.block && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="object_block"
                                                name="object[block]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.object && fields.object.block}
                                                readOnly={editable && original && original.object && original.object.block ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="object_floor">
                                                Етаж
                                        </label>
                                            {editable && original && original.object && original.object.floor && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="object_floor"
                                                name="object[floor]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.object && fields.object.floor}
                                                readOnly={editable && original && original.object && original.object.floor ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_name">
                                        Допълнително пояснение
                                    </label>
                                    {editable && original && original.object && original.object.name && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="object_name"
                                        name="object[name]"
                                        type="text"
                                        placeholder="Въведете описание"
                                        value={fields && fields.object && fields.object.name}
                                        readOnly={editable && original && original.object && original.object.name ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="object_phone">
                                        Тел. номер
                                    </label>
                                    {editable && original && original.object && original.object.phone && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="object_phone"
                                        name="object[phone]"
                                        type="text"
                                        placeholder="Въведете тел. номер"
                                        value={fields && fields.object && fields.object.phone}
                                        readOnly={editable && original && original.object && original.object.phone ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                                <div className="col able-to-edit">
                                    <label htmlFor="object_type">
                                        Вид на търговския обект
                                </label>
                                    {editable && fields && fields.object && fields.object.type && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        inputName="object[type]"
                                        inputValue={fields && fields.object && fields.object.type && fields.object.type.name}
                                        inputIdName="object[type_id]"
                                        inputIdValue={fields && fields.object && fields.object.type_id}
                                        inputPlaceholder="Изберете вид"
                                        inputDisabled={editable && fields && fields.object && fields.object.type ? true : false}
                                        url="objects/types"
                                        onChange={handleObjectTypeChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="object_activity">
                                        Предмет на дейност
                                    </label>
                                    {editable && fields && fields.object && fields.object.activity && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        ref={objectActivityRef}
                                        inputName="object[activity]"
                                        inputValue={fields && fields.object && fields.object.activity && fields.object.activity.name}
                                        inputIdName="object[activity_id]"
                                        inputIdValue={fields && fields.object && fields.object.activity_id}
                                        inputPlaceholder="Изберете вид"
                                        inputDisabled={editable && fields && fields.object && fields.object.activity ? true : false}
                                        url="objects/activities"
                                        params={{
                                            group_id: fields && fields.object && fields.object.type_id
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_owner">
                                        Собственик
                                    </label>
                                    {editable && original.object && original.object.owner && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="object_owner"
                                        name="object[owner]"
                                        type="text"
                                        placeholder="Въведете собственик"
                                        value={fields && fields.object && fields.object.owner ? fields.object.owner : ''}
                                        readOnly={editable && original.object && original.object.owner ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                                <div className="col able-to-edit"></div>
                                <div className="col able-to-edit"></div>
                                <div className="col able-to-edit"></div>
                            </div>



                            <input autoComplete="off" role="presentation" type="hidden" name="object[id]" value={
                                fields && fields.object && fields.object.id
                            } />


                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="request_equipment_type">
                                        Разполагане на вид съоръжение
                                            </label>
                                    {editable && fields && fields.request_equipment_type && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        id="request_equipment_type"
                                        name="request_equipment_type"
                                        defaultValue={fields && fields.request_equipment_type}
                                        readOnly={editable && fields && fields.request_equipment_type}
                                    />
                                </div>

                                <div className="col">
                                    <div className="row">
                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="area" id="request_area_size">
                                                Площ
                                            </label>
                                            <div className="row extra-holder">
                                                <div className="extra-holder">
                                                    {editable && fields && fields.request_area_size && <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        className="edit"
                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                    />}
                                                    <input autoComplete="off" role="presentation"
                                                        id="request_area_size"
                                                        type="text"
                                                        className="field-small"
                                                        name="request_area_size"
                                                        defaultValue={fields && fields.request_area_size}
                                                        readOnly={editable && fields && fields.request_area_size}
                                                    />

                                                    <input autoComplete="off" role="presentation"
                                                        type="hidden"
                                                    />
                                                </div>
                                                <span className="extra-label">
                                                    кв.м.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <input autoComplete="off" role="presentation" type="hidden" name="object[id]" value={
                                    fields && fields.object && fields.object.id
                                } />
                            </div>

                            <div className="row">
                                <Periods
                                    periods={fields && fields.request_periods}
                                    inputNameFrom="request_period_from"
                                    inputNameTo="request_period_to"
                                    allowEdit={auth.canWriteRequest()}
                                />
                            </div>
                        </div>
                    </div>

                </div>


                <div className="row">
                    <div className="col">
                        <h3>
                            <span>
                                4
                            </span>
                            Допълнителни документи
                        </h3>
                        <div className="holder">
                            <div className="row x2">
                                <div className="col col-auto">
                                    <h3>
                                        4.1. Актуално удостоверение за вписване в търговския регистър
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit">
                                            <label htmlFor="reg_certificate">
                                                Удостоверение
                                            </label>
                                            {fields && fields.reg_certificate && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="reg_certificate"
                                                name="reg_certificate"
                                                // className="field-medium"
                                                defaultValue={fields && fields.reg_certificate}
                                                readOnly={fields && fields.reg_certificate ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col col-auto">
                                    <h3>
                                        4.2. Идентификация ЕИК /БУЛСТАТ/.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit">
                                            <label>
                                                ЕИК
                                            </label>
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                // id="reg_eik_no"
                                                // name="reg_eik_no"
                                                // className="field-medium"
                                                defaultValue={fields && fields.company && fields.company.eik}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row x2">
                                <div className="col col-auto">
                                    <h3>
                                        4.3. Документи за право на ползване на терена, където ще се осъществява дейността:
                                        Договор за наем или документ за собственост на терена или обекта, към който ще се
                                        разполагат съоръженията.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_ownership_no">
                                                № разрешение
                                            </label>
                                            {editable && fields && fields.reg_ownership_no && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="reg_ownership_no"
                                                name="reg_ownership_no"
                                                className="field-medium"
                                                defaultValue={fields && fields.reg_ownership_no}
                                                readOnly={editable && fields && fields.reg_ownership_no ? true : false}
                                            />
                                        </div>
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_ownership_date">
                                                Дата
                                            </label>
                                            {editable && fields && fields.reg_ownership_date && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                id="reg_ownership_date"
                                                name="reg_ownership_date"
                                                defaultValue={fields && fields.reg_ownership_date_formatted}
                                                readOnly={editable && fields && fields.reg_ownership_date ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col col-auto">
                                    <h3>
                                        4.4. За търговски обекти на територията на град Велико Търново намиращи се в
                                        защитени територии за опазване на културното наследство се издава след писмено
                                        становище съгласно чл. 83 от Закон за културното наследство.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="others_license_no">
                                                № документ
                                            </label>
                                            {editable && fields && fields.others_license_no && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="others_license_no"
                                                name="others_license_no"
                                                className="field-medium"
                                                defaultValue={fields && fields.others_license_no}
                                                readOnly={editable && fields && fields.others_license_no ? true : false}
                                            />
                                        </div>
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="others_license_date">
                                                Дата
                                            </label>
                                            {editable && fields && fields.others_license_date && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                id="others_license_date"
                                                name="others_license_date"
                                                defaultValue={fields && fields.others_license_date_formatted}
                                                readOnly={editable && fields && fields.others_license_date ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row x2">
                                <div className="col col-auto">
                                    <h3>
                                        4.5. Скица с виза от Главен архитект на Община Велико Търново /при необходимост/.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_sketch_no">
                                                № разрешение
                                            </label>
                                            {editable && fields && fields.reg_sketch_no && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="reg_sketch_no"
                                                name="reg_sketch_no"
                                                className="field-medium"
                                                defaultValue={fields && fields.reg_sketch_no}
                                                readOnly={editable && fields && fields.reg_sketch_no ? true : false}
                                            />
                                        </div>
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_sketch_date">
                                                Дата
                                            </label>
                                            {editable && fields && fields.reg_sketch_date && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                id="reg_sketch_date"
                                                name="reg_sketch_date"
                                                defaultValue={fields && fields.reg_sketch_date_formatted}
                                                readOnly={editable && fields && fields.reg_sketch_date ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col col-auto">
                                    <h3>
                                        4.6. Документ за собственост на преместваемото съоръжение/при необходимост/.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_equipment_ownership_no">
                                                № документ
                                            </label>
                                            {editable && fields && fields.reg_equipment_ownership_no && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="reg_equipment_ownership_no"
                                                name="reg_equipment_ownership_no"
                                                className="field-medium"
                                                defaultValue={fields && fields.reg_equipment_ownership_no}
                                                readOnly={editable && fields && fields.reg_equipment_ownership_no ? true : false}
                                            />
                                        </div>
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="others_license_date">
                                                Дата
                                            </label>
                                            {editable && fields && fields.reg_equipment_ownership_date && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                id="reg_equipment_ownership_date"
                                                name="reg_equipment_ownership_date"
                                                defaultValue={fields && fields.reg_equipment_ownership_date_formatted}
                                                readOnly={editable && fields && fields.reg_equipment_ownership_date ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(Application)