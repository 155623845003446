import React, { useState, useEffect } from 'react'
import deleteIcon from '../../assets/img/pages/icons/delete.png'
import editIcon from '../../assets/img/pages/icons/edit-field.jpg'
import { useFieldsContext } from '../../contexts/FieldsContext';

function Index(props) {

    const allowEdit = typeof props.allowEdit !== 'undefined' ? Boolean(props.allowEdit) : false;

    const fieldsContext = useFieldsContext();

    const [state, setState] = useState([]);

    useEffect(() => {

        let periods = props.periods;

        if (!Array.isArray(periods) || periods.length === 0) {
            setState([{
                id: Math.random().toString(),
                from_formatted: '',
                to_formatted: ''
            }]);
        } else {
            setState(periods);
        }

        console.log(periods)

    }, [props.periods]);

    const addLicensePeriod = e => {
        e.preventDefault();

        let periods = state;

        periods = periods.concat({
            id: Math.random().toString(),
            from_formatted: '',
            to_formatted: ''
        });

        setState(periods);
    }

    const handleRemoveLicensePeriod = id => {

        let periods = state.filter((p, i) => {
            return id != i;
        });

        setState(periods);
    }

    return (
        <div className="col col-auto able-to-edit">


            {state.length
                ?
                state.map((period, i) =>
                    <div key={'period-' + i} className={['row period'].join(' ')}>
                        <div className="col">
                            <label>
                                Срок от
                            </label>
                            {/* {isNaN(parseInt(period.id)) &&
                                <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />
                            } */}
                            <input autoComplete="off" role="presentation"
                                type="date"
                                name={`${props.inputNameFrom}[]`}
                                defaultValue={period.from_formatted}
                            // readOnly={isNaN(parseInt(period.id)) ? true : false}
                            />
                        </div>
                        <div className="col">
                            <label>
                                Срок до
                            </label>
                            {/* {isNaN(parseInt(period.id)) &&
                                <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />
                            } */}
                            <input autoComplete="off" role="presentation"
                                type="date"
                                name={`${props.inputNameTo}[]`}
                                defaultValue={period.to_formatted}
                            // readOnly={isNaN(parseInt(period.id)) ? true : false}
                            />
                        </div>
                        {allowEdit &&
                            <div className="extra-holder">
                                <img
                                    className="remove-period"
                                    src={deleteIcon}
                                    alt="delete"
                                    onClick={() => handleRemoveLicensePeriod(i)}
                                    style={{ opacity: i === 0 ? '0' : '1', cursor: 'pointer', marginTop: '18px' }}
                                />
                            </div>
                        }
                    </div>
                )
                :
                ''
            }

            {allowEdit &&
                <div className="row">
                    <button
                        className="btn-add"
                        onClick={addLicensePeriod}
                    >
                        Добавете срок
                </button>
                </div>
            }

        </div>
    )
}

export default Index