import React, { useState, useEffect, useRef } from 'react'

//partials
import Application from './steps/application/Application'
import Success from '../../../modals/messages/Success'
import Attention from '../../../modals/messages/Attention'
import Question from '../../../modals/messages/Question'
import { useParams, Link } from 'react-router-dom'
import Api from '../../../../helpers/Api'

import addIcon from '../../../../assets/img/pages/icons/add.png'
import loader from '../../../../assets/img/app/loader.svg'
import { useAuthContext } from '../../../../contexts/AuthContext'

function Create(props) {

    const auth = useAuthContext();
    const params = useParams();

    if (!auth.canWriteRequest()) {
        props.history.push('/tourism');
    }

    // id на процедурата
    const id = params.id

    const formRef = useRef(null)
    const appRef = useRef(null);
    const successModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const [procedure, setProcedure] = useState({
        data: null,
        original: null,
        loading: true,
        initial: true,
    });

    const initialState = {
        validated: false,
        loading: false,
        rewriteCompany: true,
    }

    const [state, setState] = useState(initialState);

    useEffect(() => {
        loadProcedure();
    }, [])

    const loadProcedure = () => {
        Api.get(`/procedures/categorization/show/${id}`)
            .then(res => {

                setProcedure(prev => ({
                    ...prev,
                    data: res.data,
                    original: res.data,
                    loading: false,
                    initial: false
                }));

            }).catch(err => {
                setProcedure(prev => ({
                    ...prev,
                    data: null,
                    original: null,
                    loading: false
                }));
            })
    }


    const handleCreate = e => {
        e.preventDefault();

        let form = formRef.current;

        setState(prev => ({
            ...prev,
            loading: true
        }))

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form);

        data.append('parent_id', id);

        Api.post('procedures/categorization/close', data)
            .then(res => {
                if (res.data.id) {
                    successModalRef.current.open();

                    successModalRef.current.onClose(() => {
                        props.history.push('/tourism?current=1&id=' + res.data.id);
                    })
                }
            }).catch(err => {
                if (err.response.status === 422) {
                    attentionModalRef.current.open(err.response.data.error)

                    let fields = err.response.data.fields;

                    Object.entries(fields).map(i => {
                        if (i[1] && typeof i[1] === 'object') {
                            Object.entries(i[1]).map(j => {
                                if (j[1]) {
                                    let name = `${i[0]}[${j[0]}]`;

                                    let inputs = form.querySelectorAll("[name='" + name + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            })
                        } else {
                            if (i[1]) {
                                let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                inputs.forEach(i => {
                                    i.classList.add('validate');
                                });
                            }
                        }
                    });

                } else {
                    errorModalRef.current.open()
                }
            }).finally(() => {
                setState(initialState);
                stopLoading()
            });
    }

    const stopLoading = () => {
        setState(prev => ({
            ...prev,
            loading: false
        }))
    }


    return (
        !procedure.loading &&
        <>

            <Attention
                ref={attentionModalRef}
                mainMessage='Моля, попълнете всички полета!'
                secondaryMessage="За да запазите заявлението, трябва да попълните всички полета."
            />

            <Attention
                ref={errorModalRef}
                mainMessage='Нещо се обърка!'
            />

            <Success
                ref={successModalRef}
                mainMessage='Заявлението е запазено!'
            />

            <section>
                <div className="wrapper top negative-margin">
                    <h1>
                        <img src={addIcon} alt="add" />
                        Създаване на заявление за прекратяване на дейност
                    </h1>
                </div>

                <div className="wrapper">
                    <hr />
                    <div className="main-holder">
                        <form autoComplete="off" role="presentation" ref={formRef} onSubmit={handleCreate}>
                            <Application
                                ref={appRef}
                                data={procedure.data}
                                original={procedure.original}
                                editable={false}
                            />
                            <hr />
                            <div className="buttons">
                                {state.loading ?
                                    <img src={loader} alt="loading" />
                                    :
                                    <>
                                        <Link
                                            to="/tourism?current=1"
                                            className="btn-default">
                                            Отказ
                                        </Link>
                                        <button type="button" onClick={handleCreate}>
                                            Запазване
                                        </button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Create