import React from 'react'

function Table(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>Име</th>
                    <th>Позиция</th>

                    <th className="options">Опции</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(s =>
                    <>
                        <tr key={'s-' + s.id} data-id={s.id}>

                            <td>
                                {s.name}
                            </td>
                            <td>
                                {s.position ? s.position.name : ''}
                            </td>

                            <td>

                                <button
                                    onClick={() => props.handleEdit(s.id)}
                                    className="btn-edit"
                                >
                                    Редактиране
                                </button>

                            </td>
                        </tr>

                    </>
                )}
            </tbody>
        </table>
    )
}

export default Table