import React from 'react'
import Dropdown from '../app/Dropdown'
import deleteIcon from '../../assets/img/pages/icons/delete.png'




function Index(props) {
    return (
        <>
            <div className="row" style={{ marginBottom: '7px' }}>
                <div className="col full-width">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label>Позиция {props.i}</label>
                        {props.deletable &&
                            <label
                                style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', cursor: 'pointer' }}
                                onClick={() => props.handleRemovePosition(props.data.position.id)}
                            >
                                <img
                                    className="delete"
                                    src={deleteIcon}
                                    alt="delete"
                                    style={{ margin: '0' }}

                                />
                                <span style={{ fontSize: '12px' }}>премахни</span>
                            </label>
                        }
                    </div>
                    <Dropdown
                        inputName="position[]"
                        inputIdName="position_id[]"
                        inputValue={props.data.position.name}
                        inputIdValue={props.data.position.id}
                        url="officials/positions"
                    />

                </div>

            </div>

            <div className="row" style={{ marginBottom: '7px' }}>

                <div className="col">
                    <label className="checkbox" style={{ fontSize: '12px' }}>
                        По подразбиране
                            <input
                            type="checkbox"
                            checked={props.data.default}
                            onChange={() => props.handleDefault(props.data.position.id)}
                        />

                        <input type="hidden" name="default_position[]" value={props.data.default ? 1 : 0} />
                        <span className="checkmark"></span>
                    </label>
                </div>

            </div>
        </>
    )
}

export default Index;