import React, { useRef } from 'react'
import { Redirect } from 'react-router-dom'

//modals
import Success from '../../modals/messages/Success'
import Question from '../../modals/messages/Question'
import Remove from '../../modals/messages/Remove'
import Commission from '../../modals/Commission'

//partials
import Filter from './partials/Filter'
import Table from './partials/Table'
import Pagination from './partials/Pagination'
import Upload from './partials/Upload'
import Dropdown from '../../app/Dropdown'

//images
import profileIcon from '../../../assets/img/app/icons/profile.png'
import loader from '../../../assets/img/app/loader.svg'

function Index() {
    // return <Redirect to="/" />
    //refs
    const modalSuccessRef = useRef(null)
    const modalQuestionRef = useRef(null)
    const modalRemoveRef = useRef(null)
    const modalCommissionRef = useRef(null)

    const handleSuccess = () => {
        modalSuccessRef.current.open()
    }

    const handleQuestion = () => {
        modalQuestionRef.current.open()
    }
    const handleQuestionData = e => {
        alert(e)
    }

    const handleRemove = () => {
        modalRemoveRef.current.open()
    }
    const handleRemoveData = e => {
        alert(e)
    }

    const handleCommission = () => {
        modalCommissionRef.current.open()
    }
    return (
        <section>
            <Success
                ref={modalSuccessRef}
                mainMessage="Промените са запазени успешно!"
            />
            <Question
                ref={modalQuestionRef}
                mainMessage="Сигурни ли сте, че искате да продължите?"
                secondaryMessage="Продължвайте ще имате възможност да се върнете на същия екран."
                cancelButtonText="Отказ"
                continueButtonText="Продължи"
                callbackData={handleQuestionData}
            />

            <Remove
                ref={modalRemoveRef}
                mainMessage="Сигурни ли сте, че искате да изтриете заявлението?"
                secondaryMessage="Изтритите от Вас данни не биват архивирани."
                callbackData={handleRemoveData}
            />
            <Commission
                ref={modalCommissionRef}
            />

            <div className="wrapper top negative-margin">
                <h1>
                    <img src={profileIcon} alt="profile" />
                    Личен профил
                </h1>
            </div>

            <div className="wrapper">

                <Dropdown
                    inputName="owner"
                    inputIdName="owner_id"
                    filter={true}
                    data={[
                        {
                            id: 1,
                            name: 'alabala'
                        }, {
                            id: 2,
                            name: 'hristo'
                        },
                        {
                            id: 3,
                            name: 'gogo'
                        }
                    ]}
                />

                <Dropdown
                    inputName="company_type"
                    inputValue="АПАРТАМЕНТ ЗА ГОСТИ"
                    inputIdName="company_type_id"
                    inputIdValue="46"
                    inputPlaceholder="test"
                    url="objects/types"
                />

                <button onClick={handleQuestion}>
                    Отказ
                </button>
                <img src={loader} alt="loading" />
                <button type="submit" onClick={handleSuccess}>
                    Запазване
                </button>
                <button className="btn-add" onClick={handleRemove}>
                    Ново заявление
                </button>
                <button className="btn-filter" onClick={handleCommission}>
                    Цена
                </button>
                <button className="btn-filter btn-filter-active">
                    Дата
                </button>
                <button className="btn-action">
                    Генерирай разрешение
                </button>
                <button className="btn-upload-file">
                    Прикачи нов
                </button>
                <hr />
                <div className="col">
                    <div className="col">
                        <label htmlFor="name">
                            Име
                        </label>
                        <input autoComplete="off" role="presentation"
                            id="name"
                            type="text"
                            placeholder="Въведет име..."
                            className="validate"
                        />
                    </div>
                    <input autoComplete="off" role="presentation"
                        type="search"
                        placeholder="Въведете текст за търсене..."
                    />
                    <select>
                        <option>
                            Заетост
                        </option>
                    </select>
                    <input autoComplete="off" role="presentation" type="date" />

                    <label className="checkbox">Задача
                        <input autoComplete="off" role="presentation" type="checkbox" />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <hr />
                <Filter />
                <Table />
                <Pagination />
                <div className="panel">
                    <h3>
                        Тестово заглавие
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="name">
                                    Име
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="name"
                                    type="text"
                                    placeholder="Въведет име..."
                                />
                            </div>
                            <div className="col">
                                <label htmlFor="name">
                                    Име
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="name"
                                    type="text"
                                    placeholder="Въведет име..."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel row">
                    <div className="col">
                        <h3>
                            Данни на наемателя
                        </h3>
                        <div className="holder">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="name">
                                        Име
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="name">
                                        Име
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="name">
                                        Име
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="name">
                                        Работно време
                                    </label>
                                    <div className="extra-holder">
                                        <span className="extra-label">
                                            От
                                        </span>
                                        <input autoComplete="off" role="presentation"
                                            id="name"
                                            type="text"
                                            placeholder="06:00"
                                            className="field-small"

                                        />
                                        <span className="extra-label">
                                            До
                                        </span>
                                        <input autoComplete="off" role="presentation"
                                            id="name"
                                            type="text"
                                            placeholder="06:00"
                                            className="field-small"
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <label htmlFor="name">
                                        Срок
                                    </label>
                                    <div className="extra-holder">
                                        <span className="extra-label">
                                            От
                                        </span>
                                        <input autoComplete="off" role="presentation"
                                            id="name"
                                            type="date"
                                            placeholder="06:00"
                                            className="field-medium"

                                        />
                                        <span className="extra-label">
                                            До
                                        </span>
                                        <input autoComplete="off" role="presentation"
                                            id="name"
                                            type="date"
                                            placeholder="06:00"
                                            className="field-medium"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="name">
                                        Срок
                                    </label>
                                    <div className="extra-holder">
                                        <span className="extra-label">
                                            От
                                        </span>
                                        <input autoComplete="off" role="presentation"
                                            id="name"
                                            type="date"
                                            placeholder="06:00"
                                            className="field-medium"

                                        />
                                        <span className="extra-label">
                                            До
                                        </span>
                                        <input autoComplete="off" role="presentation"
                                            id="name"
                                            type="date"
                                            placeholder="06:00"
                                            className="field-medium"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-small">
                        <h3>
                            Данни на наемателя
                        </h3>
                        <div className="holder">
                            <div className="row x2">
                                <div className="col">
                                    <label htmlFor="name">
                                        Име
                                </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="name">
                                        Име
                                </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                            </div>
                        </div>

                        <Upload />
                    </div>
                </div>

                <div className="panel row">
                    <div className="col col-small">
                        <h3>
                            Данни на наемателя
                </h3>
                        <div className="holder">
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="name">
                                        Точно наименование на фирмата
                        </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="name">
                                        Седалище
                        </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="name">
                                        ЕИК / Булстат
                        </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="name">
                                        Адрес
                        </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                                <div className="col">
                                    <label htmlFor="name">
                                        Телефон
                        </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="name">
                                        Трите имена на лицето
                        </label>
                                    <input autoComplete="off" role="presentation"
                                        id="name"
                                        type="text"
                                        placeholder="Въведет име..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <h3>
                            Данни за обекта
                </h3>
                        <div className="holder">
                            <div className="row x2">
                                <div className="col">

                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="name">
                                                Адрес на търговския обект
                                </label>
                                            <input autoComplete="off" role="presentation"
                                                id="name"
                                                type="text"
                                                placeholder="Въведет име..."
                                            />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="name">
                                                Телефон(GSM)
                                </label>
                                            <input autoComplete="off" role="presentation"
                                                id="name"
                                                type="text"
                                                placeholder="Въведет име..."
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="name">
                                                Данни за собственик на обекта
                                </label>
                                            <input autoComplete="off" role="presentation"
                                                id="name"
                                                type="text"
                                                placeholder="Въведет име..."
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="name">
                                                Вид на обекта
                                            </label>
                                            <input autoComplete="off" role="presentation"
                                                id="name"
                                                type="text"
                                                placeholder="Въведет име..."
                                            />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="name">
                                                Предмет на дейност
                                            </label>
                                            <input autoComplete="off" role="presentation"
                                                id="name"
                                                type="text"
                                                placeholder="Въведет име..."
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="name">
                                                Тестово поле
                                            </label>
                                            <input autoComplete="off" role="presentation"
                                                id="name"
                                                type="text"
                                                placeholder="Въведет име..."
                                            />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="name">
                                                Тестово поле
                                            </label>
                                            <input autoComplete="off" role="presentation"
                                                id="name"
                                                type="text"
                                                placeholder="Въведет име..."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



        </section>
    )
}

export default Index