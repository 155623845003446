import React, { useState } from 'react'
import { Link, Redirect, useHistory } from 'react-router-dom'

//context
import { useAuthContext } from '../../../contexts/AuthContext'

//helpers
import Api from '../../../helpers/Api'

//styles
import '../../../assets/css/login.css'

//images
import houseIcon from '../../../assets/img/pages/icons/house.png'
import logo from '../../../assets/img/pages/login/logo.png'
import loader from '../../../assets/img/app/loader.svg'

function Login() {
    const auth = useAuthContext()
    const history = useHistory()

    const [login, setLogin] = useState({
        validate: false,
        loading: false,
    })

    if (auth.isLogged) {
        history.replace('/')
    }

    const handleLogin = e => {
        e.preventDefault()

        let data = new FormData(e.target);
        setLogin(login => ({
            ...login,
            loading: true,
            validate: false,
        }))

        Api.post('login', data)
            .then(res => {

                auth.login(res.data, true)

                setLogin(login => ({
                    ...login,
                    loading: false,
                    validate: false,
                }))

                history.push('/')

            })
            .catch(err => {
                console.log(err)
                setLogin(login => ({
                    ...login,
                    loading: false,
                    validate: true,
                }))
            })
    }

    return (
        <section id="login">
            <div className="wrapper">
                <img src={logo} className="logo" alt="Veliko Tarnovo" />
                <form autoComplete="off" role="presentation" onSubmit={handleLogin}>
                    <img src={houseIcon} alt="house" />
                    <h1>
                        Система за управление
                        <br />
                        на собствеността
                    </h1>
                    <div className="row">
                        <div className="col full-width">
                            <label htmlFor="username">
                                Потребителско име
                            </label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                placeholder="Въведете потребителско име"
                                id="username"
                                name="username"
                                className={login && login.validate && 'validate'}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col full-width">
                            <label htmlFor="password">
                                Парола
                            </label>
                            <input autoComplete="off" role="presentation"
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Въведете парола"
                                className={login && login.validate && 'validate'}
                            />
                        </div>
                    </div>
                    {/* <div className="row space-between">
                        <label htmlFor="remember" className="checkbox">
                            Запомни ме
                            <input autoComplete="off" role="presentation"
                                type="checkbox"
                                name="remember"
                                id="remember"
                            />
                            <span className="checkmark"></span>
                        </label>

                    </div> */}

                    <div className="buttons">
                        {login.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <button type="submit">
                                Вход
                            </button>
                        }
                    </div>
                </form>
            </div>
        </section>
    )
}
export default Login