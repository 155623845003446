import React, { useRef, useState, useEffect } from 'react'

//modals
import SelectSign from './SelectSign'
import { useAuthContext } from '../../../../../contexts/AuthContext';

function SignPanel(props) {

    const auth = useAuthContext()

    //refs
    const signsRef = useRef(null)

    const [state, setState] = useState({
        prepared: null,
        agreed: null
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            prepared: props.prepared,
            agreed: props.agreed
        }))
    }, [props]);

    const handleSelectSignModal = e => {
        e.preventDefault()

        signsRef.current.open()
    }

    const handleSelected = (k, v) => {
        setState(prev => ({
            ...prev,
            [k]: v
        }));
    }

    return (
        <>
            <SelectSign
                ref={signsRef}
                prepared={state.prepared}
                agreed={state.agreed}
                handleSelected={handleSelected}
                handleModify={props.handleModify}
            />

            <h3>
                <span>
                    4
                </span>
                Подпис
            </h3>
            <div className="holder" name="protocol_signature">

                <div className="row">

                    <ul className="col">

                        <li className="row">
                            <div className="col">
                                <span className="extra-label">
                                    Съставил
                            </span>
                                <p>
                                    {state.prepared ? state.prepared.name : '-'}
                                </p>
                                <i>
                                    {state.prepared && state.prepared.position ? state.prepared.position.name : '-'}
                                </i>
                            </div>
                        </li>

                        <li className="row">
                            <div className="col">
                                <span className="extra-label">
                                    Подписал
                                </span>
                                <p>
                                    {state.agreed ? state.agreed.name : '-'}
                                </p>
                                <i>
                                    {state.agreed && state.agreed.position ? state.agreed.position.name : '-'}
                                </i>
                            </div>
                        </li>
                    </ul>


                </div>
                {auth.canWriteRequest() &&
                    <div className="buttons">
                        <button onClick={handleSelectSignModal}>
                            Промяна
                    </button>
                    </div>
                }
            </div>
        </>
    )
}

export default React.memo(SignPanel, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
})