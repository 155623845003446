import React, { createContext, useContext, useMemo, useRef } from "react";
import Api from "../helpers/Api";

export const FieldsContext = createContext({});

const Fields = props => {

    const addOfficialPersonRef = useRef(null);
    const addUserRef = useRef(null);

    const handleEdit = e => {
        let field = e.target.parentElement.querySelector('input');

        if (!field) {
            field = e.target.parentElement.querySelector('select');
        }

        if (!field) {
            field = e.target.parentElement.querySelector('textarea');
        }


        e.target.style.display = 'none';

        if (field) {
            field.removeAttribute('readonly')
        }
    }

    const validateCompany = async data => {
        const res = await Api.post('companies/validate', data);
        return new Promise((resolve, reject) => {
            if (res.data.found && !res.data.fields_match) {
                return reject(res.data);
            }
            return resolve(res.data);
        });
    }

    const validatePerson = async data => {
        const res = await Api.post('persons/validate', data);
        return new Promise((resolve, reject) => {
            if (res.data.found && !res.data.fields_match) {
                return reject(res.data);
            }
            return resolve(res.data);
        });
    }

    const serializeInputs = (target, name) => {
        let elements = target.elements;
        let serialized = [];

        for (let i = 0; i < elements.length; i++) {

            let f = elements[i];

            if (
                !f.name ||
                f.disabled ||
                f.type === 'file' ||
                f.type === 'reset' ||
                f.type === 'submit' ||
                f.type === 'button'
            ) {
                continue;
            }

            if (name && !f.name.includes(name)) continue;

            if (f.type === 'select-multiple') {
                for (let n = 0; n < f.options.length; n++) {
                    if (!f.options[n].selected) continue;
                    serialized.push(encodeURIComponent(f.name) + "=" + encodeURIComponent(f.options[n].value));
                }
            }

            else if ((f.type !== 'checkbox' && f.type !== 'radio') || f.checked) {
                serialized.push(encodeURIComponent(f.name) + "=" + encodeURIComponent(f.value));
            }
        }

        return serialized.join('&');

    }

    const simplifySerializedInputs = (string, search) => {

        let arr = decodeURIComponent(string).split('&');
        let serialized = [];

        for (let i = 0; i < arr.length; i++) {
            let nameVal = arr[i].split('=');

            let name = nameVal[0];
            let value = nameVal[1];

            if (name.includes('[') && name.includes(search)) {
                name = name.replace(search, '');
                name = name.replace('[', '');
                name = name.replace(']', '');
            }

            serialized.push(encodeURIComponent(name) + "=" + encodeURIComponent(value));
        }

        return serialized.join('&');
    }

    const today = () => {
        let date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;

        let today = year + "-" + month + "-" + day;

        return today;
    }

    const addOfficialPerson = () => {
        addOfficialPersonRef.current.open();
    }

    const fields = useMemo(() => ({
        handleEdit,
        validateCompany,
        validatePerson,
        serializeInputs,
        simplifySerializedInputs,
        today,
        addOfficialPersonRef,
        addOfficialPerson,
        addUserRef,
    }), []);

    return <FieldsContext.Provider value={fields} {...props} />;
}

export const useFieldsContext = () => useContext(FieldsContext);

export default Fields;