import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { useFieldsContext } from '../../../../../contexts/FieldsContext'
import editIcon from '../../../../../assets/img/pages/icons/edit-field.jpg'
import Dropdown from '../../../../app/Dropdown'
import * as Constants from '../../../../../helpers/Constants'
import Signature from '../../../../partials/Signature'
import { useAuthContext } from '../../../../../contexts/AuthContext'
import Api from '../../../../../helpers/Api'

function Fields(props, ref) {
    const auth = useAuthContext()
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        person: {},
        protocol: {
            signs_keyed: {}
        }
    }

    const [fields, setFields] = useState(fieldsData)

    const original = props.original || null
    const editable = props.editable || false

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }


    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                            </span>
                            Номер и Дата
                        </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="certificate_no">
                                    № удостоверение
                                </label>
                                {editable && original && ((original.certificate && original.certificate.no) || original.certificate_gno) && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="certificate_no"
                                    name="certificate[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={editable && fields && fields.certificate && fields.certificate.no ? fields.certificate.no : fields.certificate_gno}
                                    readOnly={editable && ((original.certificate && original.certificate.no) || original.certificate_gno) ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="certificate_date">
                                    Дата
                                    </label>
                                {editable && original && original.certificate && original.certificate.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="certificate_date"
                                    name="certificate[date]"
                                    type="date"
                                    className="field-medium"
                                    defaultValue={editable && fields && fields.certificate ? fields.certificate.date_formatted : ''}
                                    readOnly={editable && original && original.certificate && original.certificate.date ? true : false}

                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* step 2 */}
                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Данни за заявителя
                    </h3>
                    <div className="holder">
                        {!fields.applicant_type || fields.applicant_type === 'company'
                            ?
                            <div className="col">

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_name">
                                            Точно наименование на фирмата
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            id="company_name"
                                            value={fields && fields.company && fields.company.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_eik">
                                            ЕИК / Булстат
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_eik"
                                            type="text"
                                            value={fields && fields.company && fields.company.eik}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_phone">
                                            Тел. номер
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.phone}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_town">
                                            Населено място
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.town
                                                ? fields.company.town.id
                                                    ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                    : fields.company.town.name
                                                : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_street">
                                            Улица
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_street_number">
                                                    №
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_street_number"
                                                    type="text"
                                                    min="0"
                                                    step="1"
                                                    value={fields && fields.company && fields.company.street_number}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_entrance">
                                                    Вход
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_entrance"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.entrance}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_block">
                                                    Блок
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_block"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.block}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_floor">
                                                    Етаж
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_floor"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.floor}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="company_mol">
                                            МОЛ
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            id="mol"
                                            type="text"
                                            value={fields && fields.company && fields.company.mol}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col">

                                    </div>

                                    <div className="col">

                                    </div>
                                </div>

                            </div>
                            :
                            <div className="col">


                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="person_name">
                                            Трите имена на лицето
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_name"
                                            type="text"
                                            value={fields && fields.person && fields.person.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="person_egn">
                                            ЕГН
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_egn"
                                            type="text"
                                            value={fields && fields.person && fields.person.egn}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="person_phone">
                                            Тел. номер
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_phone"
                                            type="text"
                                            value={fields && fields.person && fields.person.phone}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="person_town">
                                            Населено място
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_town"
                                            type="text"
                                            value={fields && fields.person && fields.person.town
                                                ? fields.person.town.id
                                                    ? `${fields.person.town.kind} ${fields.person.town.name}`
                                                    : fields.person.town.name
                                                : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="person_street">
                                            Улица
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="person_street"
                                            type="text"
                                            value={fields && fields.person && fields.person.street ? `${fields.person.street.suff} ${fields.person.street.name}` : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="person_street_number">
                                                    №
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="person_street_number"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    className="field-small"
                                                    value={fields && fields.person && fields.person.street_number}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="person_entrance">
                                                    Вход
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="person_entrance"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.person && fields.person.entrance}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="person_block">
                                                    Блок
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="person_block"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.person && fields.person.block}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="person_floor">
                                                    Етаж
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="person_floor"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.person && fields.person.floor}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col col-auto">
                    <h3>
                        <span>
                            4
                        </span>
                        Актуалност на данните
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="up_to_date">
                                    Към дата
                                </label>
                                {editable && original && original.certificate && original.certificate.up_to_date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="up_to_date"
                                    name="certificate[up_to_date]"
                                    type="date"
                                    className="field-medium"
                                    defaultValue={editable && fields && fields.certificate ? fields.certificate.up_to_date_formatted : ''}
                                    readOnly={editable && original && original.certificate && original.certificate.up_to_date ? true : false}

                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col col-auto able-to-edit">
                    <h3>
                        <span>
                            5
                        </span>
                        Подпис
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <Signature
                                    name="certificate[signed_by]"
                                    group_id={Constants.DIRECTION_BF}
                                    selected={fields && fields.certificate && fields.certificate.signature}
                                    handleModify={props.handleModify}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>



        </div>

    )
}

export default React.memo(React.forwardRef(Fields), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})