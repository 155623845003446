import React from 'react'

//images
import pdfIcon from '../../../../../assets/img/pages/icons/pdf.png'

function FilesPanel() {
    return (
        <div className="files-panel">
            <h2>
                Документи
            </h2>

            <h3>
                Генериране на заявление
            </h3>
            <div className="holder">
                <ul className="uploaded-files col">
                    <li>
                        <span>
                            22-12-2020
                        </span>
                        <img src={pdfIcon} alt="pdf" />
                        <a href="#" className="underline">
                            Тестов файл.pdf
                       </a>
                    </li>
                    <li>
                        <span>
                            22-12-2020
                        </span>
                        <img src={pdfIcon} alt="pdf" />
                        <a href="#" className="underline removed">
                            По-голям тестов файл файл файл файл файл файл_файл файл... файл_тестов.pdf
                        </a>
                    </li>
                </ul>
                <hr />
                <div className="buttons">
                    <button className="btn-upload-file">
                        Генериране
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FilesPanel