import React, { useState, useEffect } from 'react'

function Select(props) {

    const [value, setValue] = useState({
        value: 2
    });

    // useEffect(() => {
    //     if (props.value) {
    //         setValue(prev => ({
    //             ...prev,
    //             value: props.value
    //         }));
    //     }
    // }, [props.value]);

    const onChange = e => {

        let val = e.target.value;

        setValue(prev => ({
            ...prev,
            value: val
        }));
    }

    return (
        <select
            name={props.name}
            onChange={e => onChange(e)}

            value={value.value}
        >
            {props.options.map((o, i) =>
                <option key={i} value={o}>{o}</option>
            )}
        </select>
    )
}

export default React.memo(Select);