import React, { useState, useEffect } from 'react'
import { useFieldsContext } from '../../../../../contexts/FieldsContext';
import { FormControl, Select, MenuItem, Checkbox, Button } from '@material-ui/core';
import Api from '../../../../../helpers/Api';
import { useAuthContext } from '../../../../../contexts/AuthContext';

function Index(props) {

    const auth = useAuthContext();
    const fieldsContext = useFieldsContext();

    const [state, setState] = useState({
        names: [],
        refresh: false,
        open: false,
        disabled: false,
        selected: props.selected || null
    });

    useEffect(() => {
        // console.log(props.selected)

        let names = state.names;
        let selected = state.selected;

        if (Array.isArray(props.users)) {
            names = props.users;
            selected = names.filter(u => {
                return u.id == props.selected
            })[0];

            // console.log(selected)
        }

        setState(prev => ({
            ...prev,
            selected: selected,
            names: names
        }))


    }, [props.selected, props.users]);

    useEffect(() => {
        setState(prev => ({
            ...prev,
            disabled: props.disabled
        }))
    }, [props.disabled])

    const handleSelectClose = () => {
        setState(prev => ({
            ...prev,
            open: false
        }))
    }

    const handleSelectOpen = () => {
        setState(prev => ({
            ...prev,
            open: true
        }))
    }

    const handleSelectChange = e => {
        let id = e.target.value;

        if (id) {
            let person = state.names.filter(p => {
                return p.id == id;
            })[0];

            setState(prev => ({
                ...prev,
                selected: person
            }))

            if (typeof props.handleModify === 'function') {
                props.handleModify();
            }

            if (typeof props.handleChange === 'function') {
                props.handleChange(person);
            }
        }

    }

    const handleAdd = () => {
        const modal = fieldsContext.addUserRef.current;

        handleSelectClose();

        modal.open();

        // permission id 5 => достъп до удостоверения за наличие / липса на задължения
        modal.setSelectedPermissions([5]);

        modal.setRole(2);

        if (props.department_id) {
            modal.setDepartment(props.department_id);
        }

        modal.onSuccess(person => {

            modal.close();

            let inThatGroup = true;

            if (props.department_id) {
                inThatGroup = person.department_id = props.department_id;
            }

            let rows = state.names;

            if (inThatGroup) {
                rows = rows.concat(person);
            }

            let selected = inThatGroup ? person : state.selected;

            setState(prev => ({
                ...prev,
                names: rows,
                selected: selected,
                // refresh: new Date().getTime()
            }))

            // handleSelectOpen();

            if (typeof props.handleModify === 'function') {
                props.handleModify();
            }

            if (typeof props.handleChange === 'function') {
                props.handleChange(selected);
            }

        })
    }

    return (
        <>
            <FormControl variant="outlined">
                <Select
                    open={state.open}
                    onOpen={handleSelectOpen}
                    onClose={handleSelectClose}
                    onChange={handleSelectChange}
                    value={state.selected ? state.selected.id : ''}
                    renderValue={() => state.selected ? `${state.selected.name}${state.selected.position ? (', ' + state.selected.position.name.toLowerCase()) : ''} ${state.selected.department ? state.selected.department.name : ''}` : ''}
                    displayEmpty={true}
                    style={{ width: '300px' }}
                    disabled={state.disabled}
                >
                    {state.names.length === 0
                        ?
                        <p style={{ padding: '15px', fontSize: '13px' }}>Все още няма добавени служители</p>
                        :
                        state.names.map(name =>

                            <MenuItem
                                key={name.id}
                                value={name.id}

                            >
                                <Checkbox
                                    checked={state.selected && state.selected.id == name.id ? true : false}
                                    value={name.id}
                                />
                                {name.name}, {name.position ? name.position.name.toLowerCase() : ''} {name.department ? name.department.name : ''}
                            </MenuItem>

                        )}
                    {auth.canManageUsers() &&
                        <Button
                            className="btn-add full-width"
                            onClick={handleAdd}
                        >
                            Добави
                    </Button>
                    }

                </Select>
            </FormControl>

            <input autoComplete="off" role="presentation"
                type="hidden"
                name="protocol[signs][user_id][]"
                value={state.selected ? state.selected.id : ''}
            />
        </>
    )
}

export default Index