import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { useFieldsContext } from '../../../../../contexts/FieldsContext'
import editIcon from '../../../../../assets/img/pages/icons/edit-field.jpg'
import Dropdown from '../../../../app/Dropdown'
import * as Constants from '../../../../../helpers/Constants'
import Signature from '../../../../partials/Signature'
import { useAuthContext } from '../../../../../contexts/AuthContext'
import Api from '../../../../../helpers/Api'
import Applicant from './Applicant'

function Fields(props, ref) {
    const auth = useAuthContext()
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        person: {}
    }

    const [fields, setFields] = useState(fieldsData)

    const original = props.original || null
    const editable = props.editable || false

    useImperativeHandle(ref, () => ({
        setCompany: data => setCompany(data),
        setCompanyId: id => setCompanyId(id),
        setPerson: data => setPerson(data),
        setPersonId: id => setPersonId(id),
    }))

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const setCompany = newData => {
        setFields(prev => ({
            ...prev,
            company: newData
        }))
    }

    const setCompanyId = id => {
        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                id: id
            }
        }))
    }

    const setPerson = newData => {
        setFields(prev => ({
            ...prev,
            person: newData
        }))
    }

    const setPersonId = id => {
        setFields(prev => ({
            ...prev,
            person: {
                ...prev.person,
                id: id
            }
        }))
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                            </span>
                            Номер и Дата
                        </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="request_no">
                                    Входящ №
                                </label>
                                {editable && original && original.request && original.request.no && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request_no"
                                    name="request[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={editable && fields && fields.request ? fields.request.no : ''}
                                    readOnly={editable && original.request && original.request.no ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="request_date">
                                    Дата
                                    </label>
                                {editable && original && original.request && original.request.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request_date"
                                    name="request[date]"
                                    type="date"
                                    className="field-medium"
                                    defaultValue={editable && fields && fields.request ? fields.request.date_formatted : ''}
                                    readOnly={editable && original && original.request && original.request.date ? true : false}

                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* step 2 */}
                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        От
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label>
                                    Трите имена на лицето
                                </label>
                                {editable && original && original.request && original.request.applicant && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="applicant"
                                    name="request[applicant]"
                                    type="text"
                                    defaultValue={editable && fields && fields.request ? fields.request.applicant : ''}
                                    readOnly={editable && original.request && original.request.applicant ? true : false}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <Applicant
                    original={original}
                    editable={editable}
                    fields={fields}
                    setFields={setFields}
                    handleFieldChange={handleFieldChange}
                />
            </div>

            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            4
                        </span>
                        Брой документи
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="doc_count">
                                    Въведете брой
                                </label>
                                {editable && original && original.request && original.request.doc_count && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="doc_count"
                                    name="request[doc_count]"
                                    type="number"
                                    min="1"
                                    step="1"
                                    placeholder="Въведете брой"
                                    className="field-medium"
                                    defaultValue={editable && fields && fields.request ? fields.request.doc_count : 1}
                                    readOnly={editable && original.request && original.request.doc_count ? true : false}
                                />
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    )
}

export default React.memo(React.forwardRef(Fields), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})