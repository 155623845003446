import React, { useState, useImperativeHandle, forwardRef } from 'react'

//images
import questionIcon from '../../../assets/img/modals/question.png'

function Question(props, ref) {

    const [question, setQuestion] = useState({
        overlay: false,
        modal: false,
        onSuccess: null,
        onCancel: null,
        onClose: null,
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            setQuestion(question => ({
                ...question,
                overlay: true,
            }))
            setTimeout(() => {
                setQuestion(question => ({
                    ...question,
                    modal: true,
                }))
            }, 50)
        },
        close: () => {
            close();
        },
        onSuccess: fn => {
            setQuestion(question => ({
                ...question,
                onSuccess: fn
            }))
        },
        onCancel: fn => {
            setQuestion(question => ({
                ...question,
                onCancel: fn
            }))
        },
        onClose: fn => {
            setQuestion(question => ({
                ...question,
                onClose: fn
            }))
        }
    }))

    const close = () => {
        setQuestion(question => ({
            ...question,
            modal: false,
        }))

        setTimeout(() => {
            setQuestion(question => ({
                ...question,
                overlay: false,
            }))
        }, 100)

        if (typeof question.onClose === 'function') {
            question.onClose();
        }
    }

    const handleClose = () => {

        close();

        if (typeof props.onCancel === 'function') {
            props.onCancel();
        }

        if (typeof question.onCancel === 'function') {
            question.onCancel();
        }

    }

    const handleContinue = () => {

        if (typeof props.onSuccess === 'function') {
            props.onSuccess(true);
        }

        if (typeof question.onSuccess === 'function') {
            question.onSuccess(true);
        }


        close();
    }

    return (
        <div className={`${question.overlay ? 'visible' : ''} overlay`}>
            <div className={`${question.modal ? 'show' : ''} box`}>
                <img src={questionIcon} alt="question" />
                <h3>
                    {props.mainMessage}
                </h3>
                <p>
                    {props.secondaryMessage}
                </p>
                <div className="buttons">
                    <button onClick={handleClose}>
                        {props.cancelButtonText}
                    </button>
                    <button className="btn-primary" onClick={handleContinue}>
                        {props.continueButtonText}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Question)