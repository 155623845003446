import React from 'react'
import { useHistory } from 'react-router-dom'

//images
import procedureIcon from '../../../../assets/img/pages/icons/procedure.png'
import { useAuthContext } from '../../../../contexts/AuthContext';
import ProcedureOptions from './ProcedureOptions';

function TableProcedures(props) {

    const auth = useAuthContext();
    const history = useHistory();

    const handleEdit = e => {
        let currentUrl = e.target.parentElement.getAttribute('data-url')

        if (currentUrl) {
            history.push(currentUrl)
        }
    }

    return (
        props.data ?
            <table>
                <thead>
                    <tr>
                        <th style={{ width: '200px' }}>Входящ номер / дата</th>
                        <th style={{ width: '200px' }}>Сертификат номер / дата</th>
                        <th>Заявител</th>
                        {props.tabGroup === 'current' &&
                            <>
                                <th>Фирма / Физ. лице</th>
                                <th>ЕИК / ЕГН</th>
                            </>
                        }
                        <th>Статус</th>
                        {/* <th className="options">Опции</th> */}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(p =>
                        p.id &&

                        <tr key={'p-' + p.id} onClick={handleEdit} data-url={

                            '/obligations/config/' + p.id
                        }>
                            <td style={{ width: '200px' }}>
                                <img src={procedureIcon} alt="procedure" />
                                {p.request && p.request.no ?
                                    <>
                                        №{p.request.no}/{p.request.date_dmy ? p.request.date_dmy : '-'}
                                    </>
                                    :
                                    '-'
                                }
                            </td>

                            <td style={{ width: '200px' }}>
                                {p.certificate && p.certificate.no ?
                                    <>
                                        №{p.certificate.no}/{p.certificate.date_dmy ? p.certificate.date_dmy : '-'}
                                    </>
                                    :
                                    '-'
                                }
                            </td>

                            <td>
                                {p.request ? p.request.applicant : ''}
                            </td>

                            {props.tabGroup === 'current' &&
                                <>
                                    <td>
                                        {p.applicant_type === 'company' ? p.company.name : p.person.name}
                                    </td>
                                    <td>
                                        {p.applicant_type === 'company' ? p.company.eik : p.person.egn}
                                    </td>
                                </>
                            }

                            {auth.canWriteRequest()
                                ?
                                <td className={`current-step ${p.actual_phase_id == 0 ? 'finished-steps' : ''}`}>
                                    {p.actual_phase_id > 0 ? `${p.actual_phase_id}.` : ''} {p.phase}
                                </td>
                                :
                                <td className={`current-step ${p.signed_by_user ? 'finished-steps' : ''}`}>
                                    {!p.signed_by_user ? 'Чака подпис' : 'Подписано'}
                                </td>
                            }
                            {/* 
                            <td className="options">
                                {p.deletable &&
                                    <ProcedureOptions
                                        id={p.procedure_id}
                                        deletable={p.deletable}
                                        refreshData={props.refreshData}
                                    />
                                }
                            </td> */}
                        </tr>
                    )}
                </tbody>
            </table>
            : ''
    )
}

export default TableProcedures;
