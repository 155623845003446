import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Api from '../../../../helpers/Api'

//partials
import Table from './partials/Table'
import Filter from './partials/Filter'
import Pagination from '../../../partials/Pagination'
import AddOrEditStreet from '../../../modals/AddOrEditStreet'

//images
import administrationIcon from '../../../../assets/img/app/icons/administration.png'
import loader from '../../../../assets/img/app/loader.svg'
import { useAuthContext } from '../../../../contexts/AuthContext'

let timeout;

function Index() {

    const auth = useAuthContext();
    const location = useLocation();
    const history = useHistory();

    if (!auth.isAdmin()) {
        history.push('/');
    }

    const searchParams = new URLSearchParams(location.search);

    const modalRef = useRef(null);

    const [data, setData] = useState({
        items: [],
        zones: [],
        types: [],
        towns: [],
        pages: 1,
        page: searchParams.get('page') || 1,
        total: 0,
        loading: true,
        refresh: false,
        filter: {
            name: searchParams.get('name') || '',
            type_id: searchParams.get('type_id') || '',
            town_id: searchParams.get('town_id') || '',
            sort: searchParams.get('sort') || ''
        }
    });

    useEffect(() => {
        loadData();
    }, [data.refresh, data.page, data.filter]);

    useEffect(() => {

        let url = location.pathname;

        Object.entries(data.filter).forEach(p => {
            if (p[1]) {
                url += (url.indexOf('?') > -1 ? '&' : '?') + (p[0] + '=' + p[1]);
            }
        });

        url += (url.indexOf('?') > -1 ? '&' : '?') + 'page=' + data.page;

        history.replace(url);

    }, [data.filter, data.page]);

    useEffect(() => {
        loadTowns();
    }, [data.filter]);

    useEffect(() => {
        loadTypes()
    }, []);

    useEffect(() => {
        return clearTimeout(timeout);
    }, []);

    const loadData = () => {
        setData(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'streets/list'
            + '?name=' + data.filter.name
            + '&type_id=' + data.filter.type_id
            + '&town_id=' + data.filter.town_id
            + '&sort=' + data.filter.sort
            + '&page=' + data.page

        Api.get(url)
            .then(res => {
                setData(prev => ({
                    ...prev,
                    items: res.data.items,
                    zones: res.data.zones,
                    pages: res.data.pages,
                    total: res.data.total,
                    loading: false
                }));
            });
    }

    const loadTowns = () => {

        let url = 'towns/withstreets'
            + '?name=' + data.filter.name
            + '&type_id=' + data.filter.type_id

        Api.get(url)
            .then(res => {
                setData(prev => ({
                    ...prev,
                    towns: res.data
                }));
            });
    }

    const loadTypes = () => {
        Api.get('streets/types')
            .then(res => {
                setData(prev => ({
                    ...prev,
                    types: res.data
                }))
            })
    }

    const refreshData = () => {
        setData(prev => ({
            ...prev,
            refresh: new Date().getTime(),
            loading: true,
            page: 1,
            total: 0,
            items: []
        }))
    }

    const filterData = (e, name, val, timeoutDuration = 0) => {

        e.preventDefault();

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            setData(prev => ({
                ...prev,
                page: 1,
                filter: {
                    ...prev.filter,
                    [name]: val
                }
            }));
        }, timeoutDuration);

    }

    const handlePage = page => {
        setData(prev => ({ ...prev, page: page.selected + 1 }));
    }

    const handleAdd = () => {
        modalRef.current.open();
    }

    const handleEdit = (id) => {
        modalRef.current.edit(id);
    }

    return (
        <>
            <AddOrEditStreet
                ref={modalRef}
                types={data.types}
                zones={data.zones}
                refreshData={refreshData}
            />

            <section>
                <div className="wrapper top row">
                    <h1>
                        Улици ({data.total})
                </h1>
                    <button className="btn-add" onClick={handleAdd}>
                        Нова улица
                    </button>
                </div>

                <div className="wrapper">
                    <div className="row">
                        <Filter
                            filter={data.filter}
                            types={data.types}
                            towns={data.towns}
                            filterData={filterData}
                        />
                    </div>

                    {data.loading
                        ?
                        <img src={loader} alt="loading" />
                        : data.items.length === 0
                            ? <p>Няма намерена информация.</p>
                            :
                            <>
                                <div className="row">
                                    <Table
                                        data={data.items}
                                        zones={data.zones}
                                        handleEdit={handleEdit}
                                        refreshData={refreshData}
                                    />
                                </div>
                                <div className="row">
                                    <Pagination
                                        page={data.page}
                                        pages={data.pages}
                                        handlePage={handlePage}
                                    />
                                </div>
                            </>
                    }
                </div>

            </section>
        </>
    )
}

export default Index