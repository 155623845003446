import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

function Filter(props) {

    const location = useLocation();

    const sort = new URLSearchParams(location.search).get('sort');

    const filterData = props.filterData;

    return (
        <form autoComplete="off" role="presentation" className="filter-standard">
            <div className="col">
                <input autoComplete="off" role="presentation"
                    type="search"
                    className="field-large"
                    defaultValue={props.filter.name}
                    placeholder="Улица"
                    onKeyUp={(e) => filterData(e, 'name', e.target.value, 300)}
                    onChange={(e) => filterData(e, 'name', e.target.value, 300)}
                />
            </div>
            <div className="col">
                <label>Тип</label>
                <select
                    onChange={(e) => filterData(e, 'type_id', e.target.value)}
                >
                    <option value=''></option>
                    {props.types.map(t =>
                        <option
                            value={t.id}
                            selected={props.filter.type_id == t.id ? true : false}
                        >
                            {t.name}
                        </option>
                    )}
                </select>
            </div>
            <div className="col">
                <label>Населено място</label>
                <select
                    onChange={(e) => filterData(e, 'town_id', e.target.value)}
                >
                    <option value=''></option>
                    {props.towns.map(t =>
                        <option
                            value={t.id}
                            selected={props.filter.town_id == t.id ? true : false}
                        >
                            {t.kind} {t.name} {t.municipality ? `, общ. ${t.municipality.name}` : ''}
                        </option>
                    )}
                </select>
            </div>
            <div className="sort">
                <p>
                    Сортиране по:
                </p>
                <button
                    className={['btn-filter', 'btn-filter-active', sort == 'name-desc' ? 'filter-down' : ''].join(' ')}
                    onClick={(e) => filterData(e, 'sort', (sort === 'name-asc' ? 'name-desc' : 'name-asc'))}
                >
                    Име
                </button>
            </div>
        </form>
    )
}

export default Filter