import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'

//context
import { useStepsContext } from '../../../../../../contexts/StepsContext'

//partials
import FilesPanel from '../../../../../partials/FilesPanel'
import SuccessModal from '../../../../../modals/messages/Success'
import Attention from '../../../../../modals/messages/Attention'
import Question from '../../../../../modals/messages/Question'
import Api from '../../../../../../helpers/Api'

//images
import protocolIcon from '../../../../../../assets/img/pages/icons/protocol.png'
import loader from '../../../../../../assets/img/app/loader.svg'
import { useHistory, Link } from 'react-router-dom'
import { useFieldsContext } from '../../../../../../contexts/FieldsContext'
import Application from './Application'
import { useAuthContext } from '../../../../../../contexts/AuthContext'


function Index(props) {
    const auth = useAuthContext()
    const history = useHistory()

    //refs
    const formRef = useRef(null)
    const appRef = useRef(null)
    const companyValidationRef = useRef(null)
    const SuccessModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const data = props.data
    const original = props.original
    const setData = props.setData
    const setOriginal = props.setOriginal
    const loading = props.loading
    const editable = props.editable || false
    const key = new URLSearchParams(window.location.search).get('_') || 'key'

    const [application, setApplication] = useState({
        position: 7,
        finished: false,
        loading: false,
        modified: false,
    })

    useEffect(() => {
        let form = document.querySelector('.main-holder form');

        if (form) {
            form.addEventListener('change', fieldsListener);
        }

        return () => {
            form.removeEventListener('change', fieldsListener)
        }

    }, [])

    useLayoutEffect(() => {
        if (!auth.canWriteRequest()) {
            let form = document.querySelector('.main-holder form');

            if (form) {
                setTimeout(() => {
                    form.querySelectorAll('input, select').forEach(i => {
                        i.setAttribute('readonly', true);
                    })
                    form.querySelectorAll('img.edit').forEach(i => {
                        i.style.display = 'none';
                    })
                    form.querySelectorAll('.MuiSelect-root, label').forEach(i => {
                        i.classList.add('disabled');
                    })
                })
            }

        }
    }, [])

    const fieldsListener = () => {
        if (!application.modified) {
            handleModify()
        }
    }

    const handleConfig = e => {
        e.preventDefault()

        setApplication(application => ({ ...application, loading: true }))

        let form = formRef.current;

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form)

        // id на процедурата
        data.append('id', props.data.id)

        // тип на процедурата
        data.append('categorization_type_id', original.categorization_type_id)

        Api.post('procedures/categorization/addedit', data)
            .then(res => {
                SuccessModalRef.current.open()

                if (res.data.id) {
                    props.refreshData();
                    history.push('?_=' + new Date().getTime())
                }

            })
            .catch(err => {
                if (err.response.status === 422) {
                    attentionModalRef.current.open(err.response.data.error)

                    let fields = err.response.data.fields;

                    Object.entries(fields).map(i => {
                        if (i[1] && typeof i[1] === 'object') {
                            Object.entries(i[1]).map(j => {
                                if (j[1]) {
                                    let name = `${i[0]}[${j[0]}]`;

                                    let inputs = form.querySelectorAll("[name='" + name + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            })
                        } else {
                            if (i[1]) {
                                let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                inputs.forEach(i => {
                                    i.classList.add('validate');
                                });
                            }
                        }
                    });
                } else {
                    errorModalRef.current.open()
                }
            }).finally(() => {
                handleCancelModify()
            });
    }

    const handleModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: true
        }))
    }

    const handleCancelModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: false,
            loading: false
        }))
    }

    return (
        !loading && original && original.id &&
        <>
            <SuccessModal
                ref={SuccessModalRef}
                mainMessage='Заявлението беше запазено успешно!'
                secondaryMessage='Моля, попълнете всички полета, за да продължите.'
                canGenerate={data.can_generate_application}
            />

            <Attention
                ref={attentionModalRef}
                mainMessage='Моля, попълнете всички полета!'
                secondaryMessage="За да запазите заявлението, трябва да попълните всички полета."
            />

            <Attention
                ref={errorModalRef}
                mainMessage='Нещо се обърка!'
            />
            <h1 className="config-heading">
                <img src={protocolIcon} alt="protocol" />
                Заявление
            </h1>
            <hr />
            <div className="main-holder">
                <form autoComplete="off" role="presentation" ref={formRef} onSubmit={handleConfig}>

                    <Application
                        key={key}
                        ref={appRef}
                        data={data}
                        original={original}
                        editable={editable}
                        setData={setData}
                        handleModify={handleModify}
                    />
                    <hr />
                    <div className="buttons">
                        {auth.canWriteRequest()
                            ?
                            application.loading
                                ?
                                <img src={loader} alt="loading" />
                                :
                                application.modified ?
                                    <>
                                        <Link
                                            to={"/tourism/close/config/" + props.original.id + '?_=' + new Date().getTime()}
                                            className="btn-default"
                                            onClick={handleCancelModify}
                                        >
                                            Отказ
                                    </Link>
                                        <button type="button" onClick={handleConfig}>
                                            Запази
                                    </button>
                                    </>
                                    :
                                    ''
                            :
                            ''
                        }
                    </div>
                </form>
                <FilesPanel
                    id={original.procedure_id}
                    generateFile={data.can_generate_application}
                    type="application"
                    allowEdit={auth.canWriteRequest()}
                    uploadedDocument={data.uploaded_signed_application}
                    refreshData={props.refreshData}
                />
            </div>
        </>
    )
}

export default Index