import React from 'react'
import procedureIcon from '../../../../assets/img/pages/icons/procedure.png'
import { Link } from 'react-router-dom'

function Index(props) {
    return (
        <div className="table x2">
            <div className="r head">
                <div className="c">
                    <b>ЗОНА</b>
                </div>
                <div className="c">
                    <b>ОБЩ БРОЙ ОБЕКТИ</b>
                </div>
            </div>

            {props.data.map((t, i) =>
                <React.Fragment key={i}>
                    <div className="r">
                        <div className="c">
                            {t.name}
                        </div>
                        <div className="c">
                            {t.count}
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default Index