import React, { useState, forwardRef, useEffect } from 'react'

//context
import { useStepsContext } from '../../../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

import Dropdown from '../../../../../../app/Dropdown';
import * as Constants from '../../../../../../../helpers/Constants'
import Signature from '../../../../../../partials/Signature'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'

function Fields(props, ref) {
    const stepsContext = useStepsContext()
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        object: {},
    }

    const [fields, setFields] = useState(fieldsData);

    const editable = props.editable || false;
    const original = props.original;

    useEffect(() => {
        if (original) {
            setFields(original);
        }
    }, [original]);

    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name + '_formatted';

        setFields(prev => ({
            ...prev,
            [name]: val
        }));
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col col-auto">
                            <h3>
                                <span>
                                    1
                                </span>
                                Дата
                            </h3>
                            <div className="holder">

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="request_date">
                                            Дата
                                            </label>
                                        {original && original.declaration_date && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="declaration_date"
                                            name="declaration_date"
                                            type="date"
                                            value={fields && fields.declaration_date_formatted}
                                            readOnly={original && original.declaration_date ? true : false}
                                            onChange={handleDateChange}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col">
                            <h3>
                                <span>
                                    2
                                </span>
                                Декларатор
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="">
                                            Имена
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            defaultValue={fields && fields.company.mol}
                                            readOnly={true}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    4
                                </span>
                                Заплащане /срокове и размер/
                            </h3>
                            <div className="holder monthly-prices">

                                {fields.declaration_calculated_prices && fields.declaration_calculated_prices.length && fields.declaration_calculated_prices.map(m =>
                                    <div className="row">
                                        <p className="centered-bold-p">
                                            За месец {m.name}:
                                        </p>
                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="declaration_price_date">
                                                Дата
                                            </label>
                                            <div className="extra-holder">
                                                <span className="extra-label">
                                                    До
                                                </span>
                                                {m.deadline && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    name={`declaration_price_date[${m.month}]`}
                                                    type="date"
                                                    id="declaration_price_date"
                                                    defaultValue={m.deadline}
                                                    readOnly={m.deadline ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="declaration_price">
                                                Цена
                                            </label>
                                            <div className="row extra-holder">
                                                <div className="extra-holder">
                                                    {m.month && <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        className="edit"
                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                    />}
                                                    <input autoComplete="off" role="presentation"
                                                        name={`declaration_price[${m.month}]`}
                                                        type="number"
                                                        id="declaration_price"
                                                        min="0"
                                                        step="0.01"
                                                        className="field-medium"
                                                        defaultValue={m.price}
                                                        readOnly={m.price ? true : false}
                                                    />

                                                    <input autoComplete="off" role="presentation" type="hidden" />
                                                </div>
                                                <span className="extra-label">
                                                    лв
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>

                    </div>
                </div>
                <div className="col col-auto">

                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    3
                            </span>
                                Обект
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="name8">
                                            Ползвана площ на открито
                                    </label>
                                        <div className="extra-holder">
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                className="field-meduim"
                                                value={fields && fields.license_area_size}
                                                readOnly
                                            />
                                            <span className="extra-label">
                                                кв.м.
                                            </span>
                                            <input autoComplete="off" role="presentation" type="hidden" />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-auto">
                            <h3>
                                <span>
                                    5
                            </span>
                            Служител
                        </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <Signature
                                            name="declaration_signed_by"
                                            group_id={Constants.EMPLOYEES}
                                            selected={fields && fields.declarationsignature}
                                            handleModify={props.handleModify}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Fields)