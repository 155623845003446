export const daysOfWeek = [
    {
        id: 1,
        name: 'Понеделник'
    },
    {
        id: 2,
        name: 'Вторник'
    },
    {
        id: 3,
        name: 'Сряда'
    },
    {
        id: 4,
        name: 'Четвъртък'
    },
    {
        id: 5,
        name: 'Петък'
    },
    {
        id: 6,
        name: 'Събота'
    },
    {
        id: 7,
        name: 'Неделя'
    },
];