import React from 'react'
import { Link } from 'react-router-dom'

//images
import prevIcon from '../../../../assets/img/pages/icons/prev-page.png'
import nextIcon from '../../../../assets/img/pages/icons/next-page.png'

function Pagination() {
    return (
        <ul className="pagination">
            <li>
                <Link>
                    <img src={prevIcon} alt="next" />
                </Link>
            </li>
            <li className="current-page">
                <Link>
                    1
                </Link>
            </li>
            <li>
                <Link>
                    2
                </Link>
            </li>
            <li>
                <Link>
                    3
                </Link>
            </li>
            <li>
                <Link>
                    <img src={nextIcon} alt="next" />
                </Link>
            </li>
        </ul>
    )
}

export default Pagination