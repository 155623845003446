import React from 'react'

function Applicant() {

    const handleOtherContactData = e => {
        let otherData = document.getElementById('use-other')
        let hiddenRow = document.getElementsByClassName('hidden-row')

        if (e.target == otherData) {
            for (let i = 0; i < hiddenRow.length; i++) hiddenRow[i].classList.add('visible-row')
        } else {
            for (let i = 0; i < hiddenRow.length; i++) hiddenRow[i].classList.remove('visible-row')
        }
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и Дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="name">
                                    Входящ №
                                </label>
                                <input autoComplete="off" role="presentation" 
                                    name="name"
                                    type="text"
                                    placeholder="Въведет №..."
                                    className="field-medium"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label htmlFor="name1">
                                    Дата
                                </label>
                                <input autoComplete="off" role="presentation" 
                                    name="name1"
                                    type="date"

                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Данни на фирмата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="test1">
                                    Име на фирма
                                </label>
                                <input autoComplete="off" role="presentation"  type="text" placeholder="Въведете име..." />
                            </div>

                            <div className="col">
                                <label htmlFor="test1">
                                    Име, презиме и фамилия на представляващия
                                </label>
                                <input autoComplete="off" role="presentation"  type="text" placeholder="Въведете имена..." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Данни за доставка
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label htmlFor="test1">
                                    Адрес за доставка
                                </label>
                                <input autoComplete="off" role="presentation"  type="text" placeholder="Въведете адрес..." />
                            </div>

                            <div className="col">
                                <label htmlFor="test1">
                                    Телефон
                                </label>
                                <input autoComplete="off" role="presentation"  type="text" placeholder="Въведете тел..." className="field-medium" />
                            </div>
                        </div>

                        <div className="row">
                            <label className="checkbox">
                                Лично в Общински център за услуги и нформация на гражданите
                                <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="choice"
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <div className="row">
                            <label className="checkbox">
                                Чрез куриер(като услугата се заплаща)
                                    <input autoComplete="off" role="presentation" 
                                    type="radio"
                                    name="choice"
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Applicant