import React, { useRef, useState, useEffect } from 'react'

//modals
import CommisionModal from '../../../../../../modals/Commission'


function Commission(props) {
    const commisionModalRef = useRef(null)

    const [state, setState] = useState([]);

    useEffect(() => {
        if (props.data) {
            setState(props.data);
        }
    }, [props.data]);

    const handleCommision = () => {
        commisionModalRef.current.open()
    }
    return (
        <>
            <CommisionModal
                ref={commisionModalRef}
                inputName="protocol[commission]"
                selected={state}
                setSelected={setState}
            />
            <h3>
                <span>
                    3
                </span>
                Комисия
            </h3>
            <div className="holder" name="protocol_commission">
                <ul className="col">
                    {state.length > 0 ?

                        state.map(p =>
                            <li>
                                {p.name}
                                <i>
                                    {p.position && p.position.name}
                                </i>
                            </li>
                        )
                        :
                        <li className="centered">
                            Няма избрана комисия
                        </li>
                    }
                </ul>
                <hr />
                <div className="buttons">
                    <button type="button" onClick={handleCommision}>
                        {state.length > 0 ?
                            'Промяна на комисията'
                            :
                            'Избор на комисия'
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export default Commission