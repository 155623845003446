import React from 'react'
import procedureIcon from '../../../../assets/img/pages/icons/procedure.png'
import { Link } from 'react-router-dom'

function Index(props) {
    return (
        <div className="table x3">
            <div className="r head">
                <div className="c">
                    <b>ЗОНА</b>
                </div>
                <div className="c">
                    <b>ОБЩА БРОЙ ОБЕКТИ</b>
                </div>
                <div className="c">
                    <b>ОБЩА ОТДАДЕНА ПЛОЩ</b>
                </div>
            </div>

            {props.data.items.map((t, i) =>
                <React.Fragment key={i}>
                    <div className="r">
                        <div className="c">
                            {t.name}
                        </div>
                        <div className="c">
                            {t.count}
                        </div>
                        <div className="c">
                            {t.area} кв.м.
                        </div>
                    </div>
                </React.Fragment>
            )}

            <div className="r">
                <div className="c">
                    <b>ОБЩО</b>
                </div>
                <div className="c">
                    {props.data.total_count}
                </div>
                <div className="c">
                    {props.data.total_area} кв.м.
                </div>
            </div>
        </div>
    )
}

export default Index