import React, { useState, useEffect } from 'react'

//partials
import Steps from './Nav'
import { useParams } from 'react-router-dom'
import Api from '../../../../helpers/Api'
import Application from './steps/application/Index'
import Order from './steps/order/Index'

function Config() {

    const params = useParams()

    // id на процедурата
    const id = params.id

    //Стейта на навигацията е вдигнат едно ниво нагоре, за да може лесно да се променя
    const [config, setConfig] = useState({
        step: 1,
        firstStepFinish: false,
        secondStepFinish: false,
        thirdStepFinish: false,
        fourthStepFinish: false,
        fifthStepFinish: false,
        sixthStepFinish: false,
        seventhStepFinish: false,
        eightStepFinish: false,
    })

    const [procedure, setProcedure] = useState({
        data: null,
        original: null,
        loading: true,
        refresh: false,
        initial: true,
    })

    useEffect(() => {
        loadProcedure()
    }, [procedure.refresh])

    const handleCurrent = current => {

        let currentStep
        currentStep = current

        setConfig(config => ({
            ...config,
            step: currentStep,
        }))
    }

    //steps finish
    const handleFinish = (position, finish) => {
        let isFinished
        isFinished = finish

        if (position == 1) {
            setConfig(config => ({
                ...config,
                firstStepFinish: isFinished,
            }))
        } else if (position == 2) {
            setConfig(config => ({
                ...config,
                secondStepFinish: isFinished,
            }))
        } else if (position == 3) {
            setConfig(config => ({
                ...config,
                thirdStepFinish: isFinished,
            }))
        }
    }

    const loadProcedure = () => {
        Api.get(`/procedures/categorization/show/${id}`)
            .then(res => {

                if (procedure.initial) {
                    setConfig(config => ({
                        ...config,
                        step: res.data.actual_phase_id == 0 ? 2 : res.data.actual_phase_id
                    }))
                }

                if (res.data.actual_phase_id == 2) {
                    handleFinish(1, true)
                } else if (res.data.actual_phase_id == 0) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                }

                setProcedure(prev => ({
                    ...prev,
                    data: res.data,
                    original: res.data,
                    loading: false,
                    initial: false
                }))


            }).catch(err => {
                setProcedure(prev => ({
                    ...prev,
                    data: null,
                    original: null,
                    loading: false
                }))
            })
    }

    const setData = data => {
        setProcedure(prev => ({
            ...prev,
            data: data
        }))
    }

    const setOriginal = data => {
        setProcedure(prev => ({
            ...prev,
            data: data,
            original: data
        }))

    }

    const refreshData = () => {
        setProcedure(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }))
    }

    return (
        <section id="tourism-close-config">
            {!procedure.loading &&
                <div className="wrapper top negative-margin">
                    <Steps
                        handleCurrent={handleCurrent}
                        getCurrent={config.step}
                        firstStepFinish={config.firstStepFinish}
                        secondStepFinish={config.secondStepFinish}
                        thirdStepFinish={config.thirdStepFinish}
                    />

                    {config.step == 1
                        ?
                        <Application
                            data={procedure.data}
                            original={procedure.original}
                            loading={procedure.loading}
                            editable={true}
                            setData={setData}
                            setOriginal={setOriginal}
                            refreshData={refreshData}
                        />
                        :
                        config.step == 2
                            ?
                            <Order
                                data={procedure.data}
                                original={procedure.original}
                                loading={procedure.loading}
                                editable={true}
                                setData={setData}
                                setOriginal={setOriginal}
                                refreshData={refreshData}
                            />
                            :
                            ''
                    }
                </div>
            }
        </section>
    )
}

export default Config