import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu'
import { Link } from 'react-router-dom'

//images
import menuIcon from '../../../../assets/img/pages/icons/table-menu.png'
import editIcon from '../../../../assets/img/app/icons/edit.png'
import closeIcon from '../../../../assets/img/pages/icons/close.png'
import outdoorIcon from '../../../../assets/img/pages/icons/stall.png'
import { useAuthContext } from '../../../../contexts/AuthContext';

function Options(props) {

    const auth = useAuthContext()

    const [table, setTable] = useState({
        options: null,
    })

    const handleTableMenu = e => {
        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        })
    }

    const handleClose = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        })
    }

    return (
        <>
            {auth.canWriteRequest() && !props.hasClosingProcedure && props.changeable &&
                <>
                    <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />
                    <Menu
                        id="simple-menu"
                        anchorEl={table.options}
                        keepMounted
                        open={Boolean(table.options)}
                        onClose={handleClose}
                    >

                        {props.phaseId == 0 &&
                            <Link
                                to={`/outlets/change/${props.id}`}
                                className="menu-option">
                                <img src={editIcon} alt="Edit" />
                                Промяна в обстоятелствата
                            </Link>
                        }

                        {props.phaseId == 0 &&
                            <Link
                                to={`/outlets/create/outdoor/${props.id}`}
                                className="menu-option">
                                <img src={outdoorIcon} alt="Autdoor" />
                                     Регистрация на търговия на открито
                             </Link>
                        }

                        {props.phaseId == 0 &&
                            <Link
                                to={`/outlets/close/tradeobject/${props.id}`}
                                className="menu-option">
                                <img src={closeIcon} alt="Close" />
                                Прекратяване
                            </Link>
                        }

                    </Menu>
                </>
            }
        </>
    )
}

export default Options;