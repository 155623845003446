import React, { useState, useRef } from 'react'
import Menu from '@material-ui/core/Menu'
import { Link, useHistory } from 'react-router-dom'

//images
import menuIcon from '../../assets/img/pages/icons/table-menu.png'
import editIcon from '../../assets/img/app/icons/edit.png'
import closeIcon from '../../assets/img/pages/icons/close.png'
import { useAuthContext } from '../../contexts/AuthContext'
import Api from '../../helpers/Api';
import Question from '../modals/messages/Question'

function ProcedureOptions(props) {

    const auth = useAuthContext()
    const history = useHistory()

    let url;

    if (props.type === 'tradeobject' || props.type === 'outdoortrade') {
        url = 'outlets';
    } else if (props.type === 'temptrade') {
        if (props.typeId == 3) {
            url = 'temporary-shops';
        } else {
            url = 'mobile-facilities';
        }
    } else if (props.type === 'categorization') {
        url = 'tourism';
    }

    const questionModalRef = useRef();

    const [table, setTable] = useState({
        options: null,
    });

    const handleTableMenu = e => {
        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        })
    }

    const handleCloseMenu = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        })
    }

    const handleDelete = () => {

        handleCloseMenu();

        questionModalRef.current.open();

        questionModalRef.current.onSuccess(() => {
            handleDestroy();
        })

    }

    const handleDestroy = () => {
        if (props.procedureId) {
            Api.post('procedures/delete', {
                id: props.procedureId
            }).then(res => {
                if (res.data.success) {
                    if (typeof props.refreshData === 'function') {
                        props.refreshData();
                    }
                }
            })
        }
    }

    const handleClose = () => {
        if (props.type === 'categorization') {
            history.push(url + '/close/create/' + props.id);
        } else {
            history.push(url + '/close/' + props.type + '/' + props.id);
        }

    }

    const handleCancel = () => {
        history.push(url + '/cancel/' + props.type + '/' + props.id);
    }

    return (
        (auth.canWriteRequest() && (props.closeable || props.cancelable || props.deletable)) &&
        <>

            <Question
                ref={questionModalRef}
                mainMessage="Да се изтрие ли процедурата?"
                cancelButtonText="Не"
                continueButtonText="Да"
            />

            <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />
            <Menu
                id="simple-menu"
                anchorEl={table.options}
                keepMounted
                open={Boolean(table.options)}
                onClose={handleCloseMenu}
            >

                {props.closeable &&
                    <span
                        className="menu-option"
                        onClick={handleClose}
                    >
                        <img src={closeIcon} alt="Edit" />
                        Прекратяване
                    </span>
                }

                {props.cancelable &&
                    <span
                        className="menu-option"
                        onClick={handleCancel}
                    >
                        <img src={closeIcon} alt="Edit" />
                        Отказ
                    </span>
                }

                {props.deletable &&
                    <span
                        className="menu-option"
                        onClick={handleDelete}
                    >
                        <img src={closeIcon} alt="Edit" />
                        Изтриване
                    </span>
                }

            </Menu>
        </>
    )
}

export default ProcedureOptions;