import React from 'react'
import { useLocation } from 'react-router-dom';

function Filter(props) {

    const location = useLocation();

    const sort = new URLSearchParams(location.search).get('sort');

    const filterData = props.filterData;

    return (
        <form autoComplete="off" role="presentation" className="filter-standard">
            {/* <input autoComplete="off" role="presentation"
                type="search"
                className="field-big"
                defaultValue={props.filter.protocolNo}
                placeholder="Протокол №"
                onChange={e => filterData(e, 'protocolNo', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'protocolNo', e.target.value, 300)}
            /> */}
            <input autoComplete="off" role="presentation"
                type="search"
                className="field-big"
                defaultValue={props.filter.requestNo}
                placeholder="Входящ №"
                onChange={e => filterData(e, 'requestNo', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'requestNo', e.target.value, 300)}
            />
            <input autoComplete="off" role="presentation"
                type="search"
                className="field-big"
                defaultValue={props.filter.certificateNo}
                placeholder="Сертификат №"
                onChange={e => filterData(e, 'certificateNo', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'certificateNo', e.target.value, 300)}
            />

            <input autoComplete="off" role="presentation"
                type="search"
                className="field-big"
                defaultValue={props.tabGroup === 'companies' ? props.filter.company : props.filter.applicant}
                placeholder="Фирма / Физ. лице"
                onChange={e => filterData(e, 'applicant', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'applicant', e.target.value, 300)}
            />

            <input autoComplete="off" role="presentation"
                type="search"
                className="field-medium"
                defaultValue={props.filter.street}
                placeholder="ЕИК/ЕГН"
                onChange={e => filterData(e, 'egn', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'egn', e.target.value, 300)}
            />
            <div className="sort">
                <p>
                    Сортиране по:
                </p>
                <button
                    className={['btn-filter', sort === 'date-asc' || sort === 'date-desc' ? 'btn-filter-active' : '', sort == 'date-desc' ? 'filter-down' : ''].join(' ')}
                    onClick={(e) => filterData(e, 'sort', (sort === 'date-asc' ? 'date-desc' : 'date-asc'))}
                >
                    Дата
                </button>
            </div>
        </form>
    )
}

export default Filter