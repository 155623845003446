import React from 'react'
import { Link } from 'react-router-dom'

//styles
import '../../assets/css/submenu.css'

//images
import streetIcon from '../../assets/img/app/icons/administration/street.png'
import companyIcon from '../../assets/img/app/icons/administration/company.png'
import zoneIcon from '../../assets/img/app/icons/administration/zone.png'
import businessmanIcon from '../../assets/img/app/icons/administration/businessman.png'
import userIcon from '../../assets/img/app/icons/administration/user.png'
import { useAuthContext } from '../../contexts/AuthContext'

function AdminSubmenu(props) {

    const location = props.location;
    const auth = useAuthContext();

    // Съдържа първия параметър в URL-a
    let mainDirectory = props.location.pathname.split('/')[1]

    return (
        <section id="submenu">
            <nav className="wrapper">
                <Link
                    to={`/${mainDirectory}`}
                    className={location.pathname === '/administration' ? 'active' : ''
                    }>
                    <img src={streetIcon} alt="Streets" />
                    Улици
                </Link>
                <Link
                    to={`/${mainDirectory}/zones`}
                    className={location.pathname === '/administration/zones' ? 'active' : ''
                    }>
                    <img src={zoneIcon} alt="Zones" />
                    Зони
                </Link>
                <Link
                    to={`/${mainDirectory}/companies`}
                    className={location.pathname === '/administration/companies' ? 'active' : ''
                    }>
                    <img src={companyIcon} alt="Company" />
                    Фирми
                </Link>
                {/* <Link
                    to={`/${mainDirectory}/persons`}
                    className={location.pathname === '/administration/persons' ? 'active' : ''
                    }>
                    <img src={businessmanIcon} alt="Persons" />
                    Физ. лица
                </Link>
               */}
                <Link
                    to={`/${mainDirectory}/officials`}
                    className={location.pathname === '/administration/officials' ? 'active' : ''
                    }>
                    <img src={businessmanIcon} alt="Persons" />
                    Официални лица
                </Link>
                {auth.canManageUsers() &&
                    <Link
                        to={`/${mainDirectory}/profiles`}
                        className={location.pathname === '/administration/profiles' ? 'active' : ''
                        }>
                        <img src={userIcon} alt="Users" />
                    Потребители
                </Link>
                }
            </nav>
        </section>
    )
}

export default AdminSubmenu