import React, { forwardRef, useEffect, useState } from 'react'

//context
import { useStepsContext } from '../../../../../../../contexts/StepsContext'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'

//partials
import Commission from './Commission'
import Objects from './Objects'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

function Fields(props) {

    const fieldsContext = useFieldsContext();

    const editable = props.editable || false;
    const original = props.original;
    const data = props.data;

    const fieldsData = {
        protocol: {},
    }

    const [fields, setFields] = useState(fieldsData)

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                            </span>
                            Дата и №
                        </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="protocol_no">
                                    № протокол
                                </label>
                                {fields && ((fields.protocol && fields.protocol.no) || fields.protocol_gno) && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="protocol"
                                    name="protocol[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={fields && fields.protocol ? fields.protocol.no : fields.protocol_gno}
                                    readOnly={fields && ((fields.protocol && fields.protocol.no) || fields.protocol_gno) ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="protocol">
                                    Дата
                                </label>
                                {fields && fields.protocol && fields.protocol.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="protocol_date"
                                    name="protocol[date]"
                                    type="date"
                                    defaultValue={fields && fields.protocol && fields.protocol.date_formatted}
                                    readOnly={fields && fields.protocol && fields.protocol.date ? true : false}

                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <Objects
                        procedure_id={fields.id}
                    />
                </div>

                <div className="col">
                    <Commission
                        data={fields.protocol ? fields.protocol.commission_text : []}
                    />
                </div>
            </div>
        </div>
    )
}

export default React.memo(Fields, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
});