import React, { useState, useImperativeHandle, forwardRef } from 'react'

import { Texts } from '../../../helpers/Texts'

//images
import attentionIcon from '../../../assets/img/modals/attention.png'

function Question(props, ref) {

    const [attention, setAttention] = useState({
        overlay: false,
        modal: false,
        message: ''
    })

    useImperativeHandle(ref, () => ({
        open: (msg = '') => {
            setAttention(attention => ({
                ...attention,
                overlay: true,
                message: msg
            }))
            setTimeout(() => {
                setAttention(attention => ({
                    ...attention,
                    modal: true,
                }))
            }, 50)
        },
        close: () => {
            handleClose();
        },
    }))

    const handleClose = e => {
        e.preventDefault()
        setAttention(attention => ({
            ...attention,
            modal: false,
        }))

        setTimeout(() => {
            setAttention(attention => ({
                ...attention,
                overlay: false,
            }))
        }, 100)

    }

    return (
        <div className={`${attention.overlay ? 'visible' : ''} overlay`}>
            <div className={`${attention.modal ? 'show' : ''} box`}>
                <img src={attentionIcon} alt="attention" />
                <h3>
                    {
                        attention.message
                            ? Texts.validations[attention.message] ? Texts.validations[attention.message].main : ''
                            : props.mainMessage
                    }
                </h3>
                <p>
                    {
                        attention.message
                            ? Texts.validations[attention.message] ? Texts.validations[attention.message].secondary : ''
                            : props.secondaryMessage
                    }
                </p>
                <div className="buttons">
                    <button className="btn-primary" onClick={handleClose}>
                        Разбрах
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Question)