import React from 'react'
import TableProcedures from './TableProcedures'
import Options from './Options'

//images
import buildingIcon from '../../../../assets/img/pages/icons/building.png'

function Table(props) {

    const handleToggleRow = rowId => {
        let rows = document.querySelectorAll('tr')

        for (let i = 0; i < rows.length; i++) {
            if (rows[i].getAttribute('data-row') == rowId) {
                if (document.querySelector(`tr[data-row="${rowId}"]`).classList.contains('active')) {
                    document.querySelector(`tr[data-row="${rowId}"]`).classList.remove('active')
                } else rows[i].classList.add('active')
            }
        }

    }

    return (
        <table>
            <thead>
                <tr>

                    <th>
                        Обект
                    </th>
                    <th>
                        Адрес
                    </th>
                    <th>
                        Заявител
                    </th>
                    {/* <th className="options">Опции</th> */}
                </tr>
            </thead>
            <tbody>
                {props.data.map(p =>
                    <React.Fragment key={'p-' + p.group.id}>
                        <tr className={p.is_expiring ? 'expiring' : ''}>

                            <td onClick={() => handleToggleRow(p.group.id)}>
                                <img src={buildingIcon} alt="Building" />
                                {p.group.type ? p.group.type.name : ''} {p.group.name}
                            </td>

                            <td onClick={() => handleToggleRow(p.group.id)}>
                                {p.group && p.group.town
                                    ? [
                                        `${p.group.town.kind} ${p.group.town.name}`,
                                        p.group.upi ? `УПИ ${p.group.upi}` : '',
                                        p.group.region ? `кв. ${p.group.region.name}` : '',
                                        p.group.street
                                            ? p.group.street.suff + ' ' + p.group.street.name + ' ' + (p.group.street_number || '')
                                            : ''
                                    ].filter(el => el != '').join(', ')
                                    : ''}
                            </td>

                            <td onClick={() => handleToggleRow(p.group.id)}>
                                {p.items[0].applicant_type === 'company'
                                    ?
                                    p.items[0].company.name
                                    :
                                    p.items[0].applicant.name
                                }
                            </td>
                            {/* <td>
                                <Options
                                    id={p.id}
                                    // has_closing_procedure={p.has_closing_procedure}
                                    refreshData={props.refreshData}
                                />
                            </td> */}
                        </tr>

                        <tr className={["collapsable", props.selected && p.items.filter(i => i.id == props.selected).length ? 'active' : ''].join(' ')} data-row={p.group.id}>
                            {p.items.length > 0 ?
                                <td colSpan="9">
                                    <TableProcedures
                                        tabGroup={props.tabGroup}
                                        data={p.items}
                                        selected={props.selected}
                                        refreshData={props.refreshData}
                                    />
                                </td>
                                :
                                <td className="no-procedures">
                                    Няма процедури.
                                </td>
                            }
                        </tr>

                    </React.Fragment>
                )}
            </tbody>
        </table>
    )
}

export default Table