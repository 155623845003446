import React, { useState, useEffect, useMemo } from 'react'
import Api from '../../../../helpers/Api'
import loader from '../../../../assets/img/app/loader.svg'
import style from '../../../../assets/css/reports.css'
import { Link, useLocation, useHistory } from 'react-router-dom';
import Filter from './Filter'
import Zones from './Zones'
import Pagination from '../../../partials/Pagination'

let timeout;

function Index() {

    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const tabGroup = searchParams.get('group') || 'zones';

    const [state, setState] = useState({
        summary: [],
        zones: [],
        objects: [],
        pages: 1,
        page: searchParams.get('page') || 1,
        total: 0,
        loading: true,
        refresh: false,
        filter: {
            upi: searchParams.get('upi') || '',
            region: searchParams.get('region') || '',
            street: searchParams.get('street') || '',
            company: searchParams.get('company') || '',
            type_id: searchParams.get('type_id') || '',
            type: searchParams.get('type') || '',
            activity_id: searchParams.get('activity_id') || '',
            activity: searchParams.get('activity') || '',
            start_date: searchParams.get('start_date') || '',
            end_date: searchParams.get('end_date') || '',
            status: searchParams.get('status') || '',
        }
    });

    useEffect(() => {
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                upi: searchParams.get('upi') || '',
                region: searchParams.get('region') || '',
                street: searchParams.get('street') || '',
                company: searchParams.get('company') || '',
                type_id: searchParams.get('type_id') || '',
                type: searchParams.get('type') || '',
                activity_id: searchParams.get('activity_id') || '',
                activity: searchParams.get('activity') || '',
                start_date: searchParams.get('start_date') || '',
                end_date: searchParams.get('end_date') || '',
                status: searchParams.get('status') || '',
            }
        }))
    }, [tabGroup]);

    useEffect(() => {
        loadReports();

        let url = location.pathname + '?group=' + tabGroup;

        Object.entries(state.filter).forEach(p => {
            if (p[1]) url += '&' + (p[0] + '=' + p[1])
        })

        url += '&page=' + state.page

        history.replace(url)

    }, [state.refresh, state.filter, state.page]);


    useEffect(() => {
        return clearTimeout(timeout)
    }, [])

    const loadReports = () => {

        setState(prev => ({
            ...prev,
            loading: true,
        }));

        let url = 'procedures/temptrade/reports/' + tabGroup
            + '?temptrade_type_id=4'
            + '&company=' + state.filter.company
            + '&upi=' + state.filter.upi
            + '&region=' + state.filter.region
            + '&street=' + state.filter.street
            + '&type_id=' + state.filter.type_id
            + '&activity_id=' + state.filter.activity_id
            + '&start_date=' + state.filter.start_date
            + '&end_date=' + state.filter.end_date
            + '&status=' + state.filter.status
            + '&page=' + state.page;


        Api.get(url)
            .then(res => {

                setState(prev => ({
                    ...prev,
                    zones: res.data,
                }));
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    loading: false
                }));
            });
    }

    const filterData = (e, name, val, timeoutDuration = 0) => {
        if (e) {
            e.preventDefault()
        }

        clearTimeout(timeout)

        timeout = setTimeout(() => {
            setState(prev => ({
                ...prev,
                page: 1,
                filter: {
                    ...prev.filter,
                    [name]: val
                }
            }))
        }, timeoutDuration)

    }

    const handlePage = page => setState(prev => ({ ...prev, page: page.selected + 1 }))


    return (
        <section id="reports">
            <div className="wrapper top row">
                <h1>
                    Отдадена площ
                </h1>
            </div>
            <div className="wrapper">
                {/* <div className="tabs">
                    <div className={['tab', tabGroup === 'categories' ? 'active' : ''].join(' ')}>
                        <Link to="/outlets/reports?group=categories">
                            Обобщена
                        </Link>
                    </div>
                    <div className={['tab', tabGroup === 'zones' ? 'active' : ''].join(' ')}>
                        <Link to="/outlets/reports?group=zones">
                            По зони
                        </Link>
                    </div>
                    <div className={['tab', tabGroup === 'objects' ? 'active' : ''].join(' ')}>
                        <Link to="/outlets/reports?group=objects">
                            По обекти
                        </Link>
                    </div>
                </div> */}

                <div className="row">
                    <Filter
                        filter={state.filter}
                        filterData={filterData}
                        setState={setState}
                    />
                </div>

                {state.loading
                    ?
                    <img src={loader} alt="loading" />
                    :

                    tabGroup === 'zones'
                        ?
                        state.zones.length === 0
                            ?
                            <p className="no-info">Няма налични данни</p>
                            :
                            <div className="row">
                                <Zones data={state.zones} />
                            </div>

                        :
                        ''

                }
                <div className="row">
                    <Pagination
                        page={state.page}
                        pages={state.pages}
                        handlePage={handlePage}
                    />
                </div>
            </div>
        </section>
    )
}

export default Index