import React from 'react';

import deleteIcon from '../../../../../../../assets/img/pages/icons/delete.png';

function EmployeeRow(props) {
    return (
        <div className="row bottom-aligned-margin-p">
            <input autoComplete="off" role="presentation"  type="hidden" name="request[personal][position_id][]" value={props.data.position_id} />

            <p>
                {props.data.position}
            </p>

            <div className="col col-auto">
                <label>
                    Бр. лица
                </label>
                <input autoComplete="off" role="presentation" 
                    name="request[personal][persons][]"
                    type="text"
                    className="field-small"
                    defaultValue={props.data.persons}
                />
            </div>

            <div className="col col-auto">
                <label>
                    Образование
                </label>
                <select
                    name="request[personal][education_id][]"
                    defaultValue={props.data.education_id}
                >
                    <option disabled>
                        Избор
                    </option>
                    {Object.entries(props.educationGrades).map((e, i) =>
                        <option key={i} value={e[0]}>{e[1]}</option>
                    )}
                </select>
            </div>

            <div className="col col-auto">
                <label className="checkbox">
                    Квалиф. и преквал. курсове и/или стаж за управителя
                    <input autoComplete="off" role="presentation" 
                        type="checkbox"
                        name="request[personal][qualification][]"
                        defaultChecked={props.data.qualification ? true : false}
                        value="1"
                    />
                    <span className="checkmark"></span>
                </label>
            </div>

            <div className="col col-auto">
                <label>
                    Езикова квалификация
                </label>
                <select
                    name="request[personal][language_qualification_id][]"
                    defaultValue={props.data.language_qualification_id}
                >
                    <option disabled>
                        Избор
                    </option>
                    {Object.entries(props.langQualifications).map((e, i) =>
                        <option key={i} value={e[0]}>{e[1]}</option>
                    )}
                </select>
            </div>

            <div className="col extra-holder" style={{ width: '50px', marginTop: 0 }}>
                {props.deletable &&
                    <img
                        src={deleteIcon}
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.handleRemovePerson(props.data.id)}
                    />
                }
            </div>

        </div>
    )
}

export default EmployeeRow;