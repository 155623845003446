import React, { useState, forwardRef, useEffect, useRef } from 'react'

//context
import { useStepsContext } from '../../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../../contexts/FieldsContext'

//images
import editIcon from '../../../../../../assets/img/pages/icons/edit-field.jpg'

//partials
import FreeDaysInput from '../../../../../partials/FreeDays'
import WorkTimeInput from '../../../../../partials/WorkTime'
import Dropdown from '../../../../../app/Dropdown'
import * as Constants from '../../../../../../helpers/Constants'
import Signature from '../../../../../partials/Signature'

function Fields(props, ref) {
    const stepsContext = useStepsContext()
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        object: {},
    }

    const [fields, setFields] = useState(fieldsData);

    const editable = props.editable || false
    const original = props.original

    useEffect(() => {
        if (original) setFields(original)
    }, [original])

    return (
        <div className="panel">
            <div className="row">

                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                            </span>
                            Номер и Дата
                        </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="request_no">
                                    № разрешение
                                </label>
                                {fields && (fields.license_no || fields.generated_license_no) && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="license_no"
                                    name="license_no"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={fields && fields.license_no ? fields.license_no : fields.generated_license_no}
                                    readOnly={fields && (fields.license_no || fields.generated_license_no) ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="license_date">
                                    Дата
                                </label>
                                {fields && fields.license_date_formatted && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="license_date"
                                    name="license_date"
                                    type="date"
                                    defaultValue={fields && fields.license_date_formatted ? fields.license_date_formatted : fieldsContext.today()}
                                    readOnly={fields && fields.license_date ? true : false}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Данни за заявителя
                     </h3>
                    <div className="holder">
                        {!fields.applicant_type || fields.applicant_type === 'company'
                            ?
                            <div className="col">

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_name">
                                            Точно наименование на фирмата
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            id="company_name"
                                            value={fields && fields.company && fields.company.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_eik">
                                            ЕИК / Булстат
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_eik"
                                            type="text"
                                            value={fields && fields.company && fields.company.eik}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_phone">
                                            Тел. номер
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.phone}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_town">
                                            Населено място
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.town
                                                ? fields.company.town.id
                                                    ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                    : fields.company.town.name
                                                : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_street">
                                            Улица
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            type="text"
                                            value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_street_number">
                                                    №
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_street_number"
                                                    type="text"
                                                    min="0"
                                                    step="1"
                                                    value={fields && fields.company && fields.company.street_number}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_entrance">
                                                    Вход
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_entrance"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.entrance}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_block">
                                                    Блок
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_block"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.block}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="company_floor">
                                                    Етаж
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_floor"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.company && fields.company.floor}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="company_mol">
                                            МОЛ
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            id="mol"
                                            type="text"
                                            value={fields && fields.company && fields.company.mol}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col">

                                    </div>

                                    <div className="col">

                                    </div>
                                </div>

                            </div>
                            :
                            <div className="col">


                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_name">
                                            Трите имена на лицето
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_name"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_egn">
                                            ЕГН
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_egn"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.egn}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_phone">
                                            Тел. номер
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_phone"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.phone}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_town">
                                            Населено място
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_town"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.town
                                                ? fields.applicant.town.id
                                                    ? `${fields.applicant.town.kind} ${fields.applicant.town.name}`
                                                    : fields.applicant.town.name
                                                : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="applicant_street">
                                            Улица
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="applicant_street"
                                            type="text"
                                            value={fields && fields.applicant && fields.applicant.street ? `${fields.applicant.street.suff} ${fields.applicant.street.name}` : ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="applicant_street_number">
                                                    №
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_street_number"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    className="field-small"
                                                    value={fields && fields.applicant && fields.applicant.street_number}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="applicant_entrance">
                                                    Вход
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_entrance"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.applicant && fields.applicant.entrance}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="applicant_block">
                                                    Блок
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_block"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.applicant && fields.applicant.block}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="applicant_floor">
                                                    Етаж
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_floor"
                                                    type="text"
                                                    className="field-small"
                                                    value={fields && fields.applicant && fields.applicant.floor}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            4
                        </span>
                        Данни за обекта
                    </h3>
                    <div className="holder">

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_town">
                                    Населено място
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_town"
                                    type="text"
                                    value={fields && fields.object && fields.object.town
                                        ? fields.object.town.id
                                            ? `${fields.object.town.kind} ${fields.object.town.name}`
                                            : fields.object.town.name
                                        : ''}
                                    readOnly
                                />
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_region">
                                            Район/квартал
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_region"
                                            type="text"
                                            value={fields && fields.object && fields.object.region && fields.object.region.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_upi">
                                            УПИ
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields.object && fields.object.upi}
                                            className="field-medium"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col able-to-edit">
                                <label htmlFor="object_street">
                                    Улица
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_street"
                                    type="text"
                                    value={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                    readOnly
                                />
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_street_number">
                                            №
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_street_number"
                                            type="text"
                                            min="0"
                                            step="1"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.street_number}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_entrance">
                                            Вход
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_entrance"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.entrance}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_block">
                                            Блок
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_block"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.block}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_floor">
                                            Етаж
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_floor"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.floor}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_name">
                                    Наименование
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_name"
                                    type="text"
                                    value={fields && fields.object && fields.object.name}
                                    readOnly
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_phone">
                                    Тел. номер
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_phone"
                                    type="text"
                                    value={fields && fields.object && fields.object.phone}
                                    readOnly
                                />
                            </div>
                            <div className="col able-to-edit">
                                <label htmlFor="object_type">
                                    Вид на търговския обект
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_type"
                                    type="text"
                                    value={fields && fields.object && fields.object.type && fields.object.type.name}
                                    readOnly
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_activity">
                                    Предмет на дейност
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_activity"
                                    type="text"
                                    value={fields && fields.object && fields.object.activity && fields.object.activity.name}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_owner">
                                    Собственик
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_owner"
                                    type="text"
                                    value={fields && fields.object && fields.object.owner}
                                    readOnly
                                />
                            </div>
                            <div className="col able-to-edit"></div>
                            <div className="col able-to-edit"></div>
                            <div className="col able-to-edit"></div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <WorkTimeInput
                                    work_time_morning={fields.work_time_morning}
                                    work_time_afternoon={fields.work_time_afternoon}
                                    work_time_afternoon_win={fields.work_time_afternoon_win}
                                    work_time_morning_sat={fields.work_time_morning_sat}
                                    work_time_afternoon_sat={fields.work_time_afternoon_sat}
                                    work_time_afternoon_sat_win={fields.work_time_afternoon_sat_win}
                                    work_time_morning_sun={fields.work_time_morning_sun}
                                    work_time_afternoon_sun={fields.work_time_afternoon_sun}
                                    work_time_afternoon_sun_win={fields.work_time_afternoon_sun_win}
                                    work_time_preholiday_morning={fields.work_time_preholiday_morning}
                                    work_time_preholiday_afternoon={fields.work_time_preholiday_afternoon}
                                    work_time_preholiday_afternoon_win={fields.work_time_preholiday_afternoon_win}
                                    work_time_holiday_morning={fields.work_time_holiday_morning}
                                    work_time_holiday_afternoon={fields.work_time_holiday_afternoon}
                                    work_time_holiday_afternoon_win={fields.work_time_holiday_afternoon_win}
                                    readOnly={true}
                                    hidePlaceholder={true}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <FreeDaysInput
                                    readOnly={true}
                                    free_days={fields.free_days}
                                    hidePlaceholder={true}
                                />
                            </div>

                        </div>

                        <div className="row">

                            <div className="col able-to-edit">

                                <label htmlFor="license_text">
                                    Вид съоръжения
                                        </label>
                                {fields && fields.license_text && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    id="license_text"
                                    name="license_text"
                                    value={fields && fields.license_text ? fields.license_text : fields.request_equipment_type}
                                    readOnly={fields && fields.license_text ? true : false}
                                />
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="area" id="license_area_size">
                                            Площ
                                        </label>
                                        <div className="row extra-holder">
                                            <div className="extra-holder">
                                                {fields && (fields.license_area_size || fields.request_area_size) && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="license_area_size"
                                                    type="text"
                                                    className="field-small"
                                                    name="license_area_size"
                                                    defaultValue={fields && fields.license_area_size ? fields.license_area_size : fields.request_area_size}
                                                    readOnly={fields && (fields.license_area_size || fields.request_area_size) ? true : false}
                                                />

                                                <input autoComplete="off" role="presentation"
                                                    type="hidden"
                                                />
                                            </div>
                                            <span className="extra-label">
                                                кв.м.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>






                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col col-auto">
                                        <div className="row period">
                                            <div className="col able-to-edit">
                                                <label>
                                                    Срок от
                                       </label>
                                                {fields && fields.license_period_from_formatted && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="license_period_from"
                                                    type="date"
                                                    name="license_period_from"
                                                    defaultValue={fields && fields.license_period_from_formatted ? fields.license_period_from_formatted : fields.request_period_from_formatted}
                                                    readOnly={fields && fields.license_period_from_formatted}
                                                />
                                            </div>
                                            <div className="col able-to-edit">
                                                <label>
                                                    Срок до
                                        </label>
                                                {fields && fields.license_period_to_formatted && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="license_period_to"
                                                    type="date"
                                                    name="license_period_to"
                                                    defaultValue={fields && fields.license_period_to_formatted ? fields.license_period_to_formatted : fields.request_period_to_formatted}
                                                    readOnly={fields && fields.license_period_to_formatted}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col able-to-edit col-auto">
                                        <label htmlFor="license_sketch_no">
                                            Скица с вх. №
                                        </label>
                                        {editable && fields && fields.license_sketch_no && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            id="license_sketch_no"
                                            name="license_sketch_no"
                                            className="field-medium"
                                            defaultValue={fields && fields.license_sketch_no}
                                            readOnly={editable && fields && fields.license_sketch_no ? true : false}
                                        />
                                    </div>
                                    <div className="col able-to-edit col-auto">
                                        <label htmlFor="license_sketch_date">
                                            Дата
                                        </label>
                                        {editable && fields && fields.license_sketch_date && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            type="date"
                                            id="license_sketch_date"
                                            name="license_sketch_date"
                                            defaultValue={fields && fields.license_sketch_date_formatted}
                                            readOnly={editable && fields && fields.license_sketch_date ? true : false}
                                        />
                                    </div>
                                    <div className="col able-to-edit col-auto">
                                        <label>
                                            Населено място
                                        </label>
                                        {editable && fields && fields.license_sketch_town_id && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            inputName="license_sketch_town"
                                            inputIdName="license_sketch_town_id"
                                            inputValue={fields && fields.licensesketchtown
                                                ? fields.licensesketchtown.id
                                                    ? `${fields.licensesketchtown.kind} ${fields.licensesketchtown.name}`
                                                    : fields.licensesketchtown.name
                                                : ''}
                                            inputIdValue={fields && fields.license_sketch_town_id}
                                            inputDisabled={editable && fields && fields.licensesketchtown ? true : false}
                                            url="towns/find"
                                            renderText={(data) => {
                                                return <>{data.kind} {data.name}</>;
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col col-auto">
                    <h3>
                        <span>
                            5
                            </span>
                            Подпис
                        </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <Signature
                                    name="license_signed_by"
                                    group_id={Constants.MAYORS}
                                    selected={fields && fields.licensesignature}
                                    reason={fields && fields.license_signed_by_reason}
                                    handleModify={props.handleModify}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default forwardRef(Fields)