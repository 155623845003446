import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Dropdown from '../app/Dropdown'
import Api from '../../helpers/Api';

//modals
import SuccessModal from './messages/Success'
//temporary
import AttentionModal from './messages/Attention'

import AddOrEditOfficialPersonPositionRow from './AddOrEditOfficialPersonPositionRow'

function AddOrEditOfficialPerson(props, ref) {

    //refs
    const formRef = useRef(null)
    const SuccessModalRef = useRef(null)
    const AttentionModalRef = useRef(null)

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        id: null,
        person: null,
        positions: [],
        groups: [],
        selectedGroups: {},
        loading: false,
        refresh: false,
        onSuccess: null,
        onClose: null,
    })

    const [fields, setFields] = useState({})

    useEffect(() => {

        setFields({});

        if (state.id) {
            Api.get('officials/show/' + state.id)
                .then(res => {

                    let selectedGroups = {};

                    res.data.groups.map(g => {
                        selectedGroups[g.id] = 1;
                    })

                    setState(prev => ({
                        ...prev,
                        person: res.data,
                        selectedGroups: selectedGroups,
                        positions: res.data._positions,
                        loading: false
                    }));

                    if (res.data._positions.length == 0) {
                        handleAddPosition(null, true);
                    }

                    setFields(res.data);
                });
        }

    }, [state.id, state.refresh]);

    useEffect(() => {
        loadGroups();
    }, []);

    const loadGroups = () => {
        Api.get('officials/groups/list')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    groups: res.data
                }))
            });
    }

    useImperativeHandle(ref, () => ({
        open: () => {

            setState(prev => ({
                ...prev,
                id: null,
                person: null,
                selectedGroups: {},
                positions: [],
                onSuccess: null,
                onClose: null,
            }));

            setFields({});

            if (state.groups.length === 0) {
                loadGroups();
            }

            handleOpen();

            handleAddPosition(null, true);

        },
        edit: id => {
            setState(prev => ({
                ...prev,
                id: id,
                refresh: new Date().getTime(),
                positions: [],
                selectedGroups: {},
                loading: true,
                onSuccess: null
            }));

            setFields({});

            handleOpen();
        },
        close: () => {
            handleClose();
        },
        setSelectedGroups: ids => {
            setSelectedGroups(ids);
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }));
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }));
        }
    }))

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const handleSubmit = e => {
        e.preventDefault();

        let data = new FormData(formRef.current);

        if (state.id) {
            data.append('id', state.id);
        }

        Api.post('officials/addedit', data)
            .then(res => {

                if (typeof props.refreshData === 'function') {
                    props.refreshData();
                }

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                } else {
                    SuccessModalRef.current.open()

                    SuccessModalRef.current.onClose(() => {
                        handleClose();
                    })
                }

            }).catch(err => {
                AttentionModalRef.current.open()
            });
    }

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['));
        let name = e.target.name;
        let val = e.target.value;

        if (isObj) {

            let nameFirstPart = name.split('[')[0];
            let nameSecondPart = name.split('[')[1].split(']')[0];

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }));
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleCheckboxChange = e => {
        let val = e.target.value;

        const selected = state.selectedGroups;

        if (selected[val]) {
            delete selected[val];
        } else {
            selected[val] = 1;
        }

        setState(prev => ({
            ...prev,
            selectedGroups: selected
        }))

    }

    const setSelectedGroups = ids => {
        let selectedGroups = {};

        if (Array.isArray(ids)) {
            ids.map(g => {
                selectedGroups[g] = 1;
            })
        }

        setState(prev => ({
            ...prev,
            selectedGroups: selectedGroups
        }))
    }

    const handleAddPosition = (e, isDefault = false) => {

        if (e) {
            e.preventDefault();
        }

        let pos = {
            position: {
                id: new Date().getTime(),
                name: '',
            },
            default: isDefault
        }

        setState(prev => ({
            ...prev,
            positions: prev.positions.concat(pos)
        }));
    }

    const handleRemovePosition = id => {

        let positions = state.positions;

        positions = positions.filter(p => {
            return p.position.id !== id;
        });


        setState(prev => ({
            ...prev,
            positions: positions
        }));

    }

    const handleDefault = id => {

        let positions = state.positions.map(p => {
            p.default = false;

            if (p.position.id === id) {
                p.default = true;
            }

            return p;
        });

        setState(prev => ({
            ...prev,
            positions: positions
        }));

    }

    return (
        <form autoComplete="off" role="presentation" ref={formRef} className={`${state.overlay ? 'visible' : ''} overlay`} onSubmit={handleSubmit}>
            <SuccessModal
                ref={SuccessModalRef}
                mainMessage={'Промените бяха запазени успешно!'}
            />
            <AttentionModal
                ref={AttentionModalRef}
                mainMessage={'Имате непопълнени полета!'}
                secondaryMessage={'Моля, попълнете всички полета, за да продължите.'}
            />
            {!state.loading &&
                <div className={`${state.modal ? 'show' : ''} form-box`} style={{ width: '900px' }}>
                    <h2>
                        {state.id ? 'Редактиране на официално лице' : 'Добавяне на официално лице'}
                    </h2>
                    <p>
                        {/*  */}
                    </p>
                    <hr />

                    <div className="row x2">

                        <div className="col">
                            <div className="row">
                                <div className="col full-width">
                                    <label>Име</label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        name="name"
                                        value={fields.name ? fields.name : ''}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>

                            {/* <div style={{ maxHeight: '200px', overflowY: 'auto' }}> */}
                            {state.positions.map((p, i) =>
                                <React.Fragment key={i}>
                                    <hr className="small" />

                                    <AddOrEditOfficialPersonPositionRow
                                        i={i + 1}
                                        data={p}
                                        // deletable={i !== 0}
                                        deletable={true}
                                        handleDefault={handleDefault}
                                        handleRemovePosition={handleRemovePosition}
                                    />
                                </React.Fragment>
                            )}
                            <hr className="small" />

                            <div className="row">
                                <button
                                    className="btn-add"
                                    onClick={handleAddPosition}
                                >
                                    Добави позиция
                                </button>
                            </div>
                            {/* </div> */}
                        </div>


                        <div className="col">
                            <div className="row">
                                <div className="col center">
                                    <label>Да се показва в следните групи при подпис:</label>
                                    <br />
                                    <div className="row">
                                        <div className="col scroller">
                                            {state.groups.map(g =>
                                                <div key={'group-' + g.id} className="row">
                                                    <label className="checkbox">{g.name}
                                                        <input autoComplete="off" role="presentation"
                                                            type="checkbox"
                                                            name="groups[]"
                                                            value={g.id}
                                                            checked={state.selectedGroups[g.id] ? true : false}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>





                    <div className="buttons">
                        <span className="btn-default" onClick={handleClose}>
                            Отказ
                        </span>
                        <button type="button" onClick={handleSubmit}>
                            Запази
                        </button>
                    </div>
                </div>
            }
        </form>
    )
}

export default forwardRef(AddOrEditOfficialPerson)