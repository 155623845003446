import React, { forwardRef, useEffect, useState } from 'react'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext';
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import Dropdown from '../../../../../../app/Dropdown';
import * as Constants from '../../../../../../../helpers/Constants'
import Signature from '../../../../../../partials/Signature'

function Fields(props) {
    const fieldsContext = useFieldsContext();

    const editable = props.editable || false;
    const original = props.original;
    const data = props.data;

    const fieldsData = {
        request: {},
        order: {},
        object: {},
        company: {},
        owner: {}
    }

    const [fields, setFields] = useState(fieldsData)

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    const handleFieldChange = e => {

        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name
        let nameFirstPart = name.split('[')[0]

        setFields(prev => ({
            ...prev,
            [nameFirstPart]: {
                ...prev[nameFirstPart],
                date_formatted: val
            }
        }));
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="order_no">
                                    № заповед
                                </label>
                                {fields && ((fields.order && fields.order.no) || (fields.findingsprotocol && fields.findingsprotocol.no)) && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="order"
                                    name="order[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    value={fields && fields.order ? fields.order.no : (fields.findingsprotocol ? fields.findingsprotocol.no : '')}
                                    readOnly={fields && ((fields.order && fields.order.no) || (fields.findingsprotocol && fields.findingsprotocol.no)) ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="order_date">
                                    Дата
                                </label>
                                {fields && fields.order && fields.order.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="order_date"
                                    name="order[date]"
                                    type="date"
                                    value={fields && fields.order && fields.order.date_formatted ? fields.order.date_formatted : fieldsContext.today()}
                                    readOnly={fields && fields.order && fields.order.date ? true : false}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Данни за обекта
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Наименование
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object ? `${fields.object.type ? fields.object.type.name : ''} ${fields.object.name}` : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>
                                    Адрес
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object
                                        ? (
                                            fields.object.town ?
                                                (
                                                    (`${fields.object.town.kind} ${fields.object.town.name} `)
                                                    + (fields.object.region ? `кв. ${fields.object.region.name} ` : '')
                                                    + (fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name} ${fields.object.street_number} ` : '')
                                                    + (fields.object.block ? `бл. ${fields.object.block} ` : '')
                                                    + (fields.object.floor ? `ет. ${fields.object.floor} ` : '')
                                                    + (fields.object.apartment ? `ап. ${fields.object.apartment} ` : '')
                                                )
                                                :
                                                ''
                                        )
                                        : ''}
                                    readOnly
                                />
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Данни на собственика
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Име
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? fields.owner.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Населено място
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? (fields.owner.town ? `${fields.owner.town.kind} ${fields.owner.town.name}` : '') : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Улица/Булевард
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? (fields.owner.street ? `${fields.owner.street.suff} ${fields.owner.street.name} ${fields.owner.street_number}` : '') : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Булстат/ЕИК/ЕГН
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? (fields.request.owner_type == 'company' ? fields.owner.eik : fields.owner.egn) : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            4
                        </span>
                        Данни на стопанина
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Име
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.company ? fields.company.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Населено място
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.company ? (fields.company.town ? `${fields.company.town.kind} ${fields.company.town.name}` : '') : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Улица/Булевард
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.company ? (fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name} ${fields.company.street_number}` : '') : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Булстат/ЕИК
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.company ? fields.company.eik : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            5
                        </span>
                        Допълнителни данни
                    </h3>

                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit col-auto">
                                <label>
                                    Категория
                                </label>
                                {editable && original && original.order && original.order.category_id && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <select
                                    name="order[category_id]"
                                    value={fields && fields.order && fields.order.category_id ? fields.order.category_id : (fields.object ? fields.object.category_id : '')}
                                    readOnly={editable && original && original.order && original.order.category_id ? true : false}
                                    onChange={handleFieldChange}
                                >

                                    <option value="1">
                                        Една звезда
                                </option>
                                    <option value="2">
                                        Две звезди
                                </option>
                                    <option value="3">
                                        Три звезди
                                </option>
                                    <option value="4">
                                        Четири звезди
                                </option>
                                    <option value="5">
                                        Пет звезди
                                </option>
                                </select>
                            </div>

                            <div className="col col-auto able-to-edit">
                                <label>
                                    Капацитет
                                </label>
                                {fields && fields.order && fields.order.capacity && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    name="order[capacity]"
                                    defaultValue={fields.order ? fields.order.capacity : ''}
                                    className="field-big"
                                    readOnly={fields.order && fields.order.capacity ? true : false}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col col-auto able-to-edit">
                                <label>
                                    Период
                                </label>
                                {fields && fields.order && fields.order.validation && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <div className="extra-holder">
                                    <span className="extra-label">
                                        До
                                    </span>
                                    <input autoComplete="off" role="presentation"
                                        type="date"
                                        name="order[validation]"
                                        defaultValue={fields.order ? fields.order.validation_formatted : ''}
                                        readOnly={fields.order && fields.order.validation ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col col-auto">
                    <h3>
                        <span>
                            6
                        </span>
                       Подпис
                    </h3>
                    <div className="holder" name="order[signed]">
                        <div className="col able-to-edit">
                            <Signature
                                name="order[signed_by]"
                                group_id={Constants.MAYORS}
                                selected={fields && fields.order && fields.order.signature}
                                reason={fields && fields.order && fields.order.signed_by_reason}
                                handleModify={props.handleModify}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default React.memo(Fields, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
});