import React, { useEffect, useState } from 'react'
import Dropdown from '../../../app/Dropdown'
import { useLocation } from 'react-router-dom';

let timeout;

function Filter(props) {

    const location = useLocation();
    const params = new URLSearchParams(location.search);


    const filterData = props.filterData;
    const setState = props.setState;

    const [input, setInput] = useState({})

    useEffect(() => {
        setInput(prev => ({
            ...prev,
            type_id: params.get('type_id') || '',
            activity_id: params.get('activity_id') || '',
            start_date: params.get('start_date') || '',
            end_date: params.get('end_date') || '',
            street: params.get('street') || '',
            company: params.get('company') || '',
        }))

    }, [location]);

    const handleType = data => {
        let val = data.id ? data.id : '';

        if (data.name === '' || (val && val != props.filter.type_id)) {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        type_id: val,
                        type: data.name
                    }
                }));
            }, 300);
        }

    }

    const handleActivity = data => {
        let val = data.id ? data.id : '';

        if (data.name === '' || (val && val != props.filter.activity_id)) {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setState(prev => ({
                    ...prev,
                    filter: {
                        ...prev.filter,
                        activity_id: val,
                        activity: data.name
                    }
                }));
            }, 300);
        }

    }

    const handleInputChange = (e, timeout = 0) => {
        let name = e.target.name;
        let val = e.target.value;

        setInput(prev => ({
            ...prev,
            [name]: val
        }))

        filterData(e, name, val, timeout)
    }

    return (
        <form autoComplete="off" role="presentation" className="filter-standard">

            <div className="col">
                <span className="extra-label">
                    От
                </span>
                <input
                    name="start_date"
                    autoComplete="off" role="presentation"
                    type="date"
                    value={input.start_date}
                    onChange={handleInputChange}
                    style={{ marginRight: 0 }}
                />
            </div>
            <div className="col">
                <span className="extra-label">
                    До
                </span>
                <input
                    name="end_date"
                    autoComplete="off" role="presentation"
                    type="date"
                    value={input.end_date}
                    onChange={handleInputChange}
                    style={{ marginRight: 0 }}
                />
            </div>

            <Dropdown
                inputName="type"
                inputIdName="type_id"
                // inputValue={props.filter.type}
                inputIdValue={props.filter.type_id}
                inputPlaceholder="Вид"
                url="objects/types"
                onChange={handleType}
            />

            <Dropdown
                inputName="activity"
                inputIdName="activity_id"
                // inputValue={props.filter.activity}
                inputIdValue={props.filter.activity_id}
                inputPlaceholder="Предмет на дейност"
                url="objects/activities"
                params={{
                    group_id: props.filter.type_id
                }}
                onChange={handleActivity}
            />

            <input autoComplete="off" role="presentation"
                name="street"
                type="search"
                className="field-big"
                value={input.street}
                placeholder="Улица"
                onChange={e => handleInputChange(e, 300)}
                onKeyUp={e => handleInputChange(e, 300)}
            />

            <input autoComplete="off" role="presentation"
                name="company"
                type="search"
                className="field-big"
                value={input.company}
                placeholder="Фирма"
                onChange={e => handleInputChange(e, 300)}
                onKeyUp={e => handleInputChange(e, 300)}
            />

            <div className="col">
                {/* <span className="extra-label">
                    Статус
                </span> */}
                <select
                    onChange={e => filterData(e, 'status', e.target.value, 0)}
                >
                    <option
                        value=""
                        selected={props.filter.status === ''}
                    >
                        Всички
                </option>
                    <option
                        value="1"
                        selected={props.filter.status === '1'}
                    >
                        Активни
                </option>
                    <option
                        value="0"
                        selected={props.filter.status === '0'}
                    >
                        Неактивни
                </option>
                </select>
            </div>

        </form>
    )
}

export default Filter