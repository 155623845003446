import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Dropdown from '../app/Dropdown'
import Api from '../../helpers/Api';

//modals
import SuccessModal from './messages/Success'
//temporary
import AttentionModal from './messages/Attention'

function AddOrEditCompany(props, ref) {

    //refs
    const form = useRef(null)
    const streetRef = useRef(null);
    const SuccessModalRef = useRef(null)
    const AttentionModalRef = useRef(null)

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        id: null,
        zone: null,
        loading: false,
        refresh: false
    })

    const [fields, setFields] = useState({})

    useEffect(() => {

        setFields({})

        if (state.id) {
            Api.get('zones/show/' + state.id)
                .then(res => {
                    setState(prev => ({
                        ...prev,
                        zone: res.data,
                        loading: false
                    }))

                    setFields(res.data);
                });
        }
    }, [state.id, state.refresh]);

    useImperativeHandle(ref, () => ({
        open: () => {

            setState(prev => ({
                ...prev,
                id: null,
                company: null,
            }))

            setFields({})

            handleOpen()
        },
        edit: id => {

            setState(prev => ({
                ...prev,
                id: id,
                refresh: new Date().getTime(),
                loading: true
            }))

            setFields({})

            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }))

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault();

        let data = new FormData(form.current);

        if (state.id) {
            data.append('id', state.id);
        }

        Api.post('zones/addedit', data)
            .then(res => {
                SuccessModalRef.current.open()

                if (typeof props.refreshData === 'function') {
                    props.refreshData()
                }

                setTimeout(() => {
                    handleClose()
                }, 3300)

            }).catch(err => {
                AttentionModalRef.current.open()
            });
    }

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['));
        let name = e.target.name;
        let val = e.target.value;

        if (isObj) {

            let nameFirstPart = name.split('[')[0];
            let nameSecondPart = name.split('[')[1].split(']')[0];

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }));
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    return (
        <form autoComplete="off" role="presentation"  ref={form}  className={`${state.overlay ? 'visible' : ''} overlay`} onSubmit={handleSubmit}>
            <SuccessModal
                ref={SuccessModalRef}
                mainMessage={'Промените бяха запазени успешно!'}
            />
            <AttentionModal
                ref={AttentionModalRef}
                mainMessage={'Имате непопълнени полета!'}
                secondaryMessage={'Моля, попълнете всички полета, за да продължите.'}
            />
            {!state.loading &&
                <div className={`${state.modal ? 'show' : ''} form-box`}>
                    <h2>
                        {state.id ? 'Редактиране на зона' : 'Добавяне на зона'}
                    </h2>
                    <p>
                        {/*  */}
                    </p>
                    <hr />

                    <div className="row center">
                        <div className="col">
                            <label>Име</label>
                            <input autoComplete="off" role="presentation" 
                                type="text"
                                name="text"
                                value={fields.text ? fields.text : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                        <div className="col">
                            <label>Код</label>
                            <input autoComplete="off" role="presentation" 
                                type="text"
                                name="code"
                                value={fields.code ? fields.code : ''}
                                onChange={handleFieldChange}
                            />
                        </div>

                    </div>

                    <div className="buttons">
                        <span className="btn-default" onClick={handleClose}>
                            Отказ
                        </span>
                        <button type="button" onClick={handleSubmit}>
                            Запази
                        </button>
                    </div>
                </div>
            }
        </form>
    )
}

export default forwardRef(AddOrEditCompany)