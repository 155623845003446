import React from 'react'

function Filter() {
    return (
        <form autoComplete="off" role="presentation"   className="filter-standard">
            <input autoComplete="off" role="presentation"  type="search" className="field-big" />
            <select>
                <option>
                    Заетост
                        </option>
            </select>
            <div className="col">
                <span className="extra-label">
                    От
                        </span>
                <input autoComplete="off" role="presentation"  type="date" />
            </div>
            <div className="col">
                <span className="extra-label">
                    До
                </span>
                <input autoComplete="off" role="presentation"  type="date" />
            </div>
            <div className="sort">
                <p>
                    Сортиране по:
                        </p>
                <button className="btn-filter">
                    Цена
                        </button>
                <button className="btn-filter btn-filter-active">
                    Дата
                        </button>
            </div>
        </form>
    )
}

export default Filter