import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Api from '../../../../helpers/Api'

//partials
import Table from './partials/Table'
import Filter from './partials/Filter'
import Pagination from '../../../partials/Pagination'
import AddOrEditOfficialPerson from '../../../modals/AddOrEditOfficialPerson'

//images
import administrationIcon from '../../../../assets/img/app/icons/administration.png'
import loader from '../../../../assets/img/app/loader.svg'
import { useAuthContext } from '../../../../contexts/AuthContext'

let timeout;

function Index() {

    const auth = useAuthContext();
    const location = useLocation();
    const history = useHistory();

    if (!auth.isAdmin()) {
        history.push('/');
    }

    const searchParams = new URLSearchParams(location.search);

    const modalRef = useRef(null);

    const [data, setData] = useState({
        items: [],
        pages: 1,
        page: searchParams.get('page') || 1,
        total: 0,
        loading: false,
        refresh: false,
        filter: {
            name: searchParams.get('name') || '',
            sort: searchParams.get('sort') || ''
        }
    });

    useEffect(() => {
        loadData();
    }, [data.refresh, data.page, data.filter]);

    useEffect(() => {

        let url = location.pathname;

        Object.entries(data.filter).forEach(p => {
            if (p[1]) {
                url += (url.indexOf('?') > -1 ? '&' : '?') + (p[0] + '=' + p[1]);
            }
        });

        url += (url.indexOf('?') > -1 ? '&' : '?') + 'page=' + data.page;

        history.replace(url);

    }, [data.filter, data.page]);

    const loadData = () => {
        setData(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'officials/find'
            + '?name=' + data.filter.name
            + '&page=' + data.page
            + '&paginate=1';

        Api.get(url)
            .then(res => {
                setData(prev => ({
                    ...prev,
                    items: res.data.items,
                    pages: res.data.pages,
                    total: res.data.total,
                    loading: false
                }));
            });
    }

    const refreshData = () => {
        setData(prev => ({
            ...prev,
            refresh: new Date().getTime(),
            loading: true,
            total: 0,
            items: []
        }))
    }

    const handlePage = page => {
        setData(prev => ({ ...prev, page: page.selected + 1 }));
    }

    const handleAdd = () => {
        modalRef.current.open();
    }

    const handleEdit = (id) => {
        modalRef.current.edit(id);
    }

    return (
        <>
            <AddOrEditOfficialPerson
                ref={modalRef}
                refreshData={refreshData}
            />

            <section>
                <div className="wrapper top row">
                    <h1>
                        Официални лица ({data.total})
                </h1>
                    <button className="btn-add" onClick={handleAdd}>
                        Добавяне
                    </button>
                </div>

                <div className="wrapper">

                    {data.loading
                        ?
                        <img src={loader} alt="loading" />
                        : data.items.length === 0
                            ? <p>Няма намерена информация.</p>
                            :
                            <>
                                <div className="row">
                                    <Table
                                        data={data.items}
                                        handleEdit={handleEdit}
                                        refreshData={refreshData}
                                    />
                                </div>
                                <div className="row">
                                    <Pagination
                                        page={data.page}
                                        pages={data.pages}
                                        handlePage={handlePage}
                                    />
                                </div>

                            </>
                    }
                </div>

            </section>
        </>
    )
}

export default Index