import React, { useState, forwardRef, useEffect } from 'react'

//context
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

//partials
import Commission from './Commission'
import Dropdown from '../../../../../../app/Dropdown'
import * as Constants from '../../../../../../../helpers/Constants'
import Signature from '../../../../../../partials/Signature'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'

function Fields(props, ref) {
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        object: {},
    }

    const [fields, setFields] = useState(fieldsData)

    const editable = props.editable || false
    const original = props.original

    useEffect(() => {
        setFields(original)
    }, [original])

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }
    }

    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name + '_formatted';

        setFields(prev => ({
            ...prev,
            [name]: val
        }));
    }

    const handleOwnerChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        let isMunicProperty = val == 1;
        let data = original;

        data.is_munic_property = isMunicProperty;

        props.setOriginal(data);

        handleFieldChange(e);
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и Дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="protocol_no">
                                    № протокол
                                </label>
                                {fields && (fields.protocol_no || fields.generated_protocol_no) && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="protocol_no"
                                    name="protocol_no"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={fields && fields.protocol_no ? fields.protocol_no : fields.generated_protocol_no}
                                    readOnly={fields && (fields.protocol_no || fields.generated_protocol_no) ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col able-to-edit col-auto">
                                <label htmlFor="protocol_date">
                                    Дата
                                </label>
                                {original && original.protocol_date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="protocol_date"
                                    name="protocol_date"
                                    type="date"
                                    value={fields && fields.protocol_date_formatted ? fields.protocol_date_formatted : fieldsContext.today()}
                                    readOnly={original && original.protocol_date ? true : false}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            2
                            </span>
                            Данни за фирмата заявител
                        </h3>
                    <div className="holder">

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="company_name">
                                    Точно наименование на фирмата
                                </label>

                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.name}
                                    readOnly={true}
                                />

                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="company_eik">
                                    ЕИК / Булстат
                                </label>

                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.eik}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="company_phone">
                                    Тел. номер
                                </label>

                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.phone}
                                    // readOnly={editable && fields && fields.company && fields.company.phone ? true : false}
                                    readOnly={true}
                                />
                            </div>
                        </div>

                        <div className="row">

                            <div className="col able-to-edit">
                                <label htmlFor="company_town">
                                    Населено място
                                    </label>
                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.town
                                        ? fields.company.town.id
                                            ? `${fields.company.town.kind} ${fields.company.town.name}`
                                            : fields.company.town.name
                                        : ''}
                                    readOnly={true}
                                />

                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="company_street">
                                    Улица
                                    </label>
                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                    readOnly={true}
                                />

                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_street_number">
                                            №
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.company && fields.company.street_number}
                                            className="field-small"
                                            readOnly={true}
                                            min="0"
                                            step="1"
                                        />

                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_entrance">
                                            Вход
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.company && fields.company.entrance}
                                            className="field-small"
                                            readOnly={true}
                                        />

                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_block">
                                            Блок
                                            </label>

                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.company && fields.company.block}
                                            readOnly={editable && fields && fields.company && fields.company.block ? true : false}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_floor">
                                            Етаж
                                            </label>

                                        <input autoComplete="off" role="presentation"
                                            className="field-small"
                                            value={fields && fields.company && fields.company.floor}
                                            // readOnly={editable && fields && fields.company && fields.company.floor ? true : false}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="company_mol">
                                    МОЛ
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="company_mol"
                                    type="text"
                                    value={fields && fields.company && fields.company.mol}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col">

                            </div>

                            <div className="col">

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Данни за обекта
                    </h3>
                    <div className="holder">

                        <div className="row">
                            <div className="col able-to-edit">
                                <label>
                                    Собственост
                                </label>
                                {original && original.protocol_property_type_id && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <select
                                    name="protocol_property_type_id"
                                    value={fields && fields.protocol_property_type_id ? fields.protocol_property_type_id : ''}
                                    readOnly={fields && fields.protocol_property_type_id ? true : false}
                                    onChange={handleOwnerChange}
                                >
                                    <option value="1">
                                        Общинска собственост
                                    </option>
                                    <option value="2">
                                        Частна собственост
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_town">
                                    Населено място
                                </label>

                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.object && fields.object.town
                                        ? `${fields.object.town.kind} ${fields.object.town.name}`
                                        : ''}
                                    readOnly={true}
                                />

                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_street">
                                    Улица
                                </label>
                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_street_number">
                                            №
                                            </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.street_number}
                                            readOnly={true}
                                            min="0"
                                            step="1"
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_entrance">
                                            Вход
                                            </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.entrance}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_block">
                                            Блок
                                            </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.block}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_floor">
                                            Етаж
                                            </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.floor}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_name">
                                    Наименование
                                    </label>
                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.object && fields.object.name}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_phone">
                                    Тел. номер
                                        </label>
                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.object && fields.object.phone}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_type">
                                            Вид на търговския обект
                                            </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.object && fields.object.type && fields.object.type.name}
                                            readOnly={true}
                                        />

                                    </div>


                                </div>
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_activity">
                                    Предмет на дейност
                                </label>
                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.object && fields.object.activity && fields.object.activity.name}
                                    readOnly={true}
                                />

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Определение
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit col-auto">
                                <label htmlFor="protocol_area_size">
                                    A. Пред търговския обект може да бъде заета
                               </label>
                                <div className="row extra-holder">
                                    <div className="extra-holder">
                                        {fields && fields.protocol_area_size && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            type="number"
                                            name="protocol_area_size"
                                            className="field-small"
                                            id="protocol_area_size"
                                            defaultValue={fields && fields.protocol_area_size ? fields.protocol_area_size : fields.request_area_size}
                                            readOnly={fields && fields.protocol_area_size ? true : false}
                                        />
                                        <input autoComplete="off" role="presentation" type="hidden" />
                                    </div>
                                    <span className="extra-label">
                                        кв.м.
                                    </span>
                                </div>
                            </div>

                            <div className="col able-to-edit col-auto">
                                <label htmlFor="protocol_approved_desc">
                                    площ, която да се използва за:
                               </label>
                                {fields && fields.protocol_approved_desc && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    id="protocol_approved_desc"
                                    name="protocol_approved_desc"
                                    defaultValue={fields && fields.protocol_approved_desc}
                                    readOnly={fields && fields.protocol_approved_desc ? true : false}
                                />
                            </div>

                            <div className="col able-to-edit col-auto">
                                <label htmlFor="protocol_declined_desc">
                                    Б. Пред търговския обект не може да се ползва частна площ, поради:
                                </label>
                                {fields && fields.protocol_declined_desc && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    name="protocol_declined_desc"
                                    id="protocol_declined_desc"
                                    defaultValue={fields && fields.protocol_declined_desc}
                                    readOnly={fields && fields.protocol_declined_desc ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Commission
                        readOnly={original.closed}
                        data={fields ? fields.protocol_commission_text : []}
                    />
                </div>

                <div className="col col-auto">
                    <h3>
                        <span>
                            5
                        </span>
                        Одобрил
                    </h3>
                    <div className="holder" name="protocol_approved_by_name">
                        <div className="row">
                            <div className="col able-to-edit">
                                <Signature
                                    name="protocol_approved_by"
                                    group_id={Constants.ARCHITECTS}
                                    selected={fields && fields.protocolsignature}
                                    handleModify={props.handleModify}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default React.memo(forwardRef(Fields), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});