import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Dropdown from '../app/Dropdown'
import Api from '../../helpers/Api';

//modals
import SuccessModal from '../modals/messages/Success'
//temporary
import AttentionModal from '../modals/messages/Attention'

function AddOrEditStreet(props, ref) {
    //refs
    const form = useRef(null)
    const SuccessModalRef = useRef(null)
    const AttentionModalRef = useRef(null)

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        id: null,
        street: null,
        loading: false,
        refresh: false
    })

    const [fields, setFields] = useState({});

    useEffect(() => {
        if (state.id) {
            Api.get('streets/show/' + state.id)
                .then(res => {
                    setState(prev => ({
                        ...prev,
                        street: res.data,
                        loading: false
                    }))

                    let f = res.data;

                    let zoneFrom = {};
                    let zoneTo = {};

                    res.data.zones.map(z => {
                        if (!zoneFrom[z.zone_code]) {
                            zoneFrom[z.zone_code] = '';
                            zoneTo[z.zone_code] = '';
                        }

                        zoneFrom[z.zone_code] = z.from_num;
                        zoneTo[z.zone_code] = z.to_num;
                    });

                    // console.log(zoneFrom);

                    f.zone_from = zoneFrom;
                    f.zone_to = zoneTo;

                    setFields(f);
                });
        }
    }, [state.id, state.refresh]);

    useImperativeHandle(ref, () => ({
        open: () => {

            setState(prev => ({
                ...prev,
                id: null,
                street: null,
            }))

            setFields({});

            handleOpen()
        },
        edit: id => {

            setState(prev => ({
                ...prev,
                id: id,
                refresh: new Date().getTime(),
                loading: true
            }))

            setFields({});

            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }))

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault();

        let data = new FormData(form.current);

        if (state.id) {
            data.append('id', state.id);
        }

        Api.post('streets/addedit', data)
            .then(res => {
                SuccessModalRef.current.open()

                if (typeof props.refreshData === 'function') {
                    props.refreshData()
                }

                setTimeout(() => {
                    handleClose()
                }, 3300)

            }).catch(err => {
                AttentionModalRef.current.open()
            });
    }

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['));
        let name = e.target.name;
        let val = e.target.value;

        if (isObj) {

            let nameFirstPart = name.split('[')[0];
            let nameSecondPart = name.split('[')[1].split(']')[0];

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }));
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    return (
        <form autoComplete="off" role="presentation" ref={form} className={`${state.overlay ? 'visible' : ''} overlay`} onSubmit={handleSubmit}>
            <SuccessModal
                ref={SuccessModalRef}
                mainMessage={'Промените бяха запазени успешно!'}
            />
            <AttentionModal
                ref={AttentionModalRef}
                mainMessage={'Имате непопълнени полета!'}
                secondaryMessage={'Моля, попълнете всички полета, за да продължите.'}
            />

            {!state.loading &&
                <div className={`${state.modal ? 'show' : ''} form-box`}>
                    <h2>
                        {state.id ? `${state.street.suff} "${state.street.name}"` : 'Добавяне на улица'}
                    </h2>
                    <p>
                        Избиране на зони, в които попада улицата
                </p>
                    <hr />

                    <div className="row center">
                        <div className="col">
                            <label>Тип</label>
                            {props.types.length &&
                                <Dropdown
                                    inputName="type"
                                    inputValue={state.id ? fields.suff : ''}
                                    inputIdName="type_id"
                                    inputIdValue={state.id ? fields.type_id : ''}
                                    inputPlaceholder="Изберете тип"
                                    data={props.types}
                                    filter={true}
                                />
                            }
                        </div>

                        <div className="col">
                            <label>Име</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="name"
                                value={fields.name ? fields.name : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                    </div>

                    <div className="row center">
                        <div className="col">
                            <label>Населено място</label>
                            <Dropdown
                                inputName="town"
                                inputValue={state.id ? (fields.town && fields.town.name) : 'Велико Търново'}
                                inputIdName="town_id"
                                inputIdValue={state.id ? fields.town_id : 494}
                                inputPlaceholder="Изберете населено място"
                                url="towns/find"
                                renderText={(data) => {
                                    return <>{data.kind} {data.name}{data.municipality ? `, общ. ${data.municipality.name}` : ''}{data.district ? `, обл. ${data.district.name}` : ''}</>;
                                }}
                            />
                        </div>

                        <div className="col">
                            <label>Код</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="code"
                                value={fields.code ? fields.code : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className="scroller">
                        {props.zones.map(z =>
                            <div key={'z-' + z.id} className="extra-holder row center">
                                <div className="col">
                                    ЗОНА {z.code}
                                </div>
                                <div className="col">
                                    <div className="extra-holder">
                                        <label>от №</label>
                                        <input autoComplete="off" role="presentation"
                                            type="number"
                                            min="1"
                                            step="1"
                                            name={'zone_from[' + z.id + ']'}
                                            className="field-small"
                                            value={state.id ?
                                                fields.zone_from && fields.zone_from[z.id]
                                                    ? fields.zone_from[z.id]
                                                    : ''
                                                : ''}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="extra-holder">
                                        <label>до №</label>
                                        <input autoComplete="off" role="presentation"
                                            type="number"
                                            min="1"
                                            step="1"
                                            name={'zone_to[' + z.id + ']'}
                                            className="field-small"
                                            value={state.id ?
                                                fields.zone_to && fields.zone_to[z.id]
                                                    ? fields.zone_to[z.id]
                                                    : ''
                                                : ''}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <hr />
                    <div className="buttons">
                        <span className="btn-default" onClick={handleClose}>
                            Отказ
                    </span>
                        <button type="button" onClick={handleSubmit}>
                            Запази
                    </button>
                    </div>
                </div>
            }
        </form>
    )
}

export default forwardRef(AddOrEditStreet)