import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Api from '../../helpers/Api'
import * as Constants from '../../helpers/Constants'
import { useFieldsContext } from '../../contexts/FieldsContext'
import SelectPosition from './SelectPosition'
import editIcon from '../../assets/img/pages/icons/edit.png'

function Commission(props, ref) {

    const fieldsContext = useFieldsContext();

    const selectPositionModalRef = useRef(null);

    let inputName = props.inputName;
    let inputPositionName;

    if (inputName.includes(']')) {
        inputPositionName = inputName.replace(']', '_position_id]');
    } else {
        inputPositionName = inputName + '_position_id';
    }

    const [commision, setCommision] = useState({
        overlay: false,
        modal: false,
        selected: {},
        data: [],
        original: [],
        refresh: false
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }))

    useEffect(() => {

        if (!props.inputName) {
            alert('Missing input name!');
        }

        Api.get('officials/find?group_id=' + Constants.AGREED)
            .then(res => {
                setCommision(prev => ({
                    ...prev,
                    original: res.data,
                    data: res.data
                }))
            })
    }, [commision.refresh]);

    useEffect(() => {

        if (Array.isArray(props.selected)) {
            let selected = {};

            props.selected.map(s => {
                selected[s.id] = 1;
            });

            let data = commision.original.map(p => {

                let s = props.selected.filter(o => o.id === p.id)[0];

                if (s) {
                    p.position = s.position;
                }

                return p;

            });

            setCommision(prev => ({
                ...prev,
                selected: selected,
                data: data
            }))
        }

    }, [props.selected, commision.original]);

    const handleOpen = () => {
        setCommision(commision => ({
            ...commision,
            overlay: true,
        }))
        setTimeout(() => {
            setCommision(commision => ({
                ...commision,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = (e) => {

        if (e) {
            e.preventDefault();
        }

        setCommision(commision => ({
            ...commision,
            modal: false,
        }))
        setTimeout(() => {
            setCommision(commision => ({
                ...commision,
                overlay: false,
            }))
        }, 100)
    }

    const handleSelect = id => {

        let selected = commision.selected;

        if (selected[id]) {
            delete selected[id];
        } else {
            selected[id] = 1;
        }

        setCommision(prev => ({
            ...prev,
            selected: selected
        }))

        // let person = commision.data.filter(p => {
        //     return p.id === id;
        // })[0];

        let selectedExtended = [];

        commision.data.map(p => {
            if (selected[p.id]) {
                selectedExtended.push(p);
            }
        });

        if (typeof props.setSelected === 'function') {
            props.setSelected(selectedExtended);
        }


    }

    const handleAdd = e => {
        const modal = fieldsContext.addOfficialPersonRef.current;

        handleClose(e);

        modal.open();

        modal.setSelectedGroups([Constants.AGREED]);

        modal.onSuccess(person => {

            modal.close();

            let inThatGroup = person.groups.filter(g => g.id == Constants.AGREED).length;

            setCommision(prev => ({
                ...prev,
                data: inThatGroup ? prev.data.concat(person) : prev.data,
                // refresh: new Date().getTime()
            }))

            let selected = commision.selected;

            if (inThatGroup) {
                selected[person.id] = 1;
            }

            let selectedExtended = [];

            commision.data.map(p => {
                if (selected[p.id]) {
                    selectedExtended.push(p);
                }
            });

            if (typeof props.setSelected === 'function') {
                props.setSelected(selectedExtended);
            }

            handleOpen();
        })

        modal.onClose(() => {
            handleOpen();
        })
    }

    const handleShowPositions = person => {

        if (person._positions.length > 1) {
            handleClose();

            let modal = selectPositionModalRef.current;

            let positionId = person.position.id;

            modal.open(person, positionId, person._positions);

            modal.onSelect(position => {
                person.position = position;

                let selected = commision.selected;
                let selectedExtended = [];

                commision.data.map(p => {
                    if (selected[p.id]) {
                        if (p.id === person.id) {
                            p = person;
                        }

                        selectedExtended.push(p);
                    }
                });

                setCommision(prev => ({
                    ...prev,
                    selected: {
                        ...prev.selected,
                        [person.id]: person
                    }
                }));

                if (typeof props.setSelected === 'function') {
                    props.setSelected(selectedExtended);
                }
            });

            modal.onClose(() => {
                handleOpen();
            })
        }

    }

    return (
        <>
            <SelectPosition
                ref={selectPositionModalRef}
            />

            <div className={`${commision.overlay ? 'visible' : ''} overlay`}>
                <div className={`${commision.modal ? 'show' : ''} form-box`}>
                    <h2>
                        Назначаване на комисия
               </h2>
                    <p>
                        Изберете членове на комисията чрез маркиране
                </p>
                    <hr />
                    <div className="scroller">
                        <div className="col">
                            {commision.data.map(p =>
                                <>
                                    <div className="row" key={p.id} style={{ margin: 0 }}>
                                        <label className="row checkbox">

                                            <div>
                                                {p.name}

                                                {p.position && p.position.id &&
                                                    <div className="sign-position" onClick={e => handleShowPositions(p)}>
                                                        <i className={p._positions.length > 1 ? 'editable' : ''}>
                                                            {p.position.name}
                                                        </i>

                                                        {p._positions.length > 1 &&
                                                            <i>
                                                                <img src={editIcon} />
                                                            </i>
                                                        }
                                                    </div>
                                                }
                                            </div>

                                            <input
                                                type="checkbox"
                                                name={`${inputName}[]`}
                                                value={p.id}
                                                checked={commision.selected[Number(p.id)] ? true : false}
                                                onChange={() => handleSelect(p.id)}
                                            />

                                            <input
                                                type="checkbox"
                                                name={`${inputPositionName}[]`}
                                                value={p.position.id}
                                                checked={commision.selected[Number(p.id)] ? true : false}
                                                hidden
                                            />

                                            <span
                                                className="checkmark"
                                                style={{ top: '50%', transform: 'translate(0, -50%)' }}
                                            ></span>
                                        </label>

                                    </div>

                                    <hr className="small" />
                                </>
                            )}
                        </div>
                        <div className="row">
                            <button className="btn-add" onClick={handleAdd}>Добави нов служител</button>
                        </div>
                    </div>
                    <hr />
                    <div className="buttons">
                        <button onClick={handleClose}>
                            Затвори
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(forwardRef(Commission), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
})