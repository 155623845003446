import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Dropdown from '../app/Dropdown'
import Api from '../../helpers/Api';

//modals
import SuccessModal from './messages/Success'
//temporary
import AttentionModal from './messages/Attention'
import { useAuthContext } from '../../contexts/AuthContext';

function AddOrEditUser(props, ref) {

    const auth = useAuthContext()

    //refs
    const formRef = useRef(null)
    const SuccessModalRef = useRef(null)
    const AttentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        id: null,
        person: null,
        roles: [],
        permissions: [],
        departments: [],
        selectedPermissions: {},
        loading: false,
        refresh: false,
        onSuccess: null,
        onClose: null,
    })

    const initialFields = {
        role_id: 2,
        department_id: ''
    };

    const [fields, setFields] = useState(initialFields)

    useEffect(() => {

        setFields(initialFields)

        if (state.id) {
            Api.get('auth/show/' + state.id)
                .then(res => {

                    let selectedPermissions = {};

                    if (Array.isArray(res.data.permissions)) {
                        res.data.permissions.map(g => {
                            selectedPermissions[g] = 1;
                        })
                    }

                    setState(prev => ({
                        ...prev,
                        person: res.data,
                        selectedPermissions: selectedPermissions,
                        loading: false
                    }))

                    setFields(res.data);
                });
        }

    }, [state.id, state.refresh]);

    useEffect(() => {
        loadRoles();
        loadPermissions();
        loadDepartments();
    }, []);

    const loadPermissions = () => {
        Api.get('auth/permissions')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    permissions: res.data
                }))
            })
    }

    const loadRoles = () => {
        Api.get('auth/roles')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    roles: res.data
                }))
            })
    }

    const loadDepartments = () => {
        Api.get('auth/departments')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    departments: res.data
                }))
            })
    }

    useImperativeHandle(ref, () => ({
        open: () => {
            setState(prev => ({
                ...prev,
                id: null,
                person: null,
                selectedPermissions: {},
                onSuccess: null,
                onClose: null,
            }));

            setFields(initialFields);

            loadData();

            handleOpen();
        },
        edit: id => {
            setState(prev => ({
                ...prev,
                id: id,
                refresh: new Date().getTime(),
                selectedPermissions: {},
                loading: true,
                onSuccess: null
            }));

            setFields(initialFields);

            loadData();

            handleOpen();
        },
        close: () => {
            handleClose()
        },
        setDepartment: id => {
            setFields(prev => ({
                ...prev,
                department_id: id
            }))
        },
        setRole: id => {
            setFields(prev => ({
                ...prev,
                role_id: id
            }))
        },
        setSelectedPermissions: ids => {
            setSelectedPermissions(ids)
        },
        onSuccess: fn => {
            setState(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onClose: fn => {
            setState(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const loadData = () => {
        if (state.roles.length === 0) {
            loadRoles();
        }

        if (state.permissions.length === 0) {
            loadPermissions();
        }

        if (state.departments.length === 0) {
            loadDepartments();
        }
    }

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)

        if (typeof state.onClose === 'function') {
            state.onClose();
        }
    }

    const setSelectedPermissions = ids => {
        let selectedPermissions = {};

        if (Array.isArray(ids)) {
            ids.map(g => {
                selectedPermissions[g] = 1;
            })
        }

        setState(prev => ({
            ...prev,
            selectedPermissions: selectedPermissions
        }))
    }

    const handleSubmit = e => {
        e.preventDefault();

        let form = formRef.current;

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form);

        if (state.id) {
            data.append('id', state.id);
        }

        Api.post('auth/addedit', data)
            .then(res => {

                auth.refresh();

                if (typeof props.refreshData === 'function') {
                    props.refreshData();
                }

                if (typeof state.onSuccess === 'function') {
                    state.onSuccess(res.data);
                } else {
                    SuccessModalRef.current.open();

                    SuccessModalRef.current.onClose(() => {
                        handleClose();
                    })
                }

            }).catch(err => {
                if (err.response.status === 422) {

                    let errName = Object.values(err.response.data.errors)[0][0];

                    AttentionModalRef.current.open(errName);

                    Object.entries(err.response.data.errors).map(i => {
                        let input = form.querySelector("[name='" + i[0] + "']");

                        if (input) {
                            input.classList.add('validate');
                        }
                    });

                } else {
                    errorModalRef.current.open()
                }
            });
    }

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['));
        let name = e.target.name;
        let val = e.target.value;

        if (isObj) {

            let nameFirstPart = name.split('[')[0];
            let nameSecondPart = name.split('[')[1].split(']')[0];

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }));
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleCheckboxChange = e => {
        let val = e.target.value;

        const selected = state.selectedPermissions;

        if (selected[val]) {
            delete selected[val];
        } else {
            selected[val] = 1;
        }

        setState(prev => ({
            ...prev,
            selectedPermissions: selected
        }))

    }

    return (
        <form autoComplete="off" role="presentation" ref={formRef} className={`${state.overlay ? 'visible' : ''} overlay`} onSubmit={handleSubmit}>
            <SuccessModal
                ref={SuccessModalRef}
                mainMessage={'Данните бяха запазени успешно!'}
            />

            <AttentionModal
                ref={AttentionModalRef}
                mainMessage={'Имате непопълнени полета!'}
                secondaryMessage={'Моля, попълнете всички полета, за да продължите.'}
            />

            <AttentionModal
                ref={errorModalRef}
                mainMessage='Нещо се обърка!'
            />

            {!state.loading &&
                <div className={`${state.modal ? 'show' : ''} form-box`} style={{ width: '1000px' }}>
                    <h2>
                        {state.id ? 'Редактиране на потребител' : 'Добавяне на потребител'}
                    </h2>
                    <p>
                        {/*  */}
                    </p>
                    <hr />

                    <div className="row x2" style={{ marginBottom: '0' }}>
                        <div className="col">
                            <div className="row x2">
                                <div className="col">
                                    <label>Име</label>
                                    <input
                                        autoComplete="off" role="presentation"
                                        type="text"
                                        name="name"
                                        value={fields.name ? fields.name : ''}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                                <div className="col">
                                    <label>Потребителско име</label>
                                    <input
                                        autoComplete="off" role="presentation"
                                        type="text"
                                        name="username"
                                        value={fields.username ? fields.username : ''}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>

                            {!state.id &&

                                <div className="row x2">
                                    <div className="col">
                                        <label>Парола</label>
                                        <input
                                            autoComplete="off" role="presentation"
                                            type="password"
                                            name="password"
                                        />
                                    </div>
                                    <div className="col">
                                        <label>Повтерете паролата</label>
                                        <input
                                            autoComplete="off" role="presentation"
                                            type="password"
                                            name="password_confirmation"
                                        />
                                    </div>
                                </div>

                            }

                            <div className="row x2">
                                <div className="col">
                                    <label>Отдел / Дирекция</label>
                                    <select
                                        name="department_id"
                                        value={fields.department_id}
                                        onChange={handleFieldChange}
                                    >
                                        {state.departments.map(d =>
                                            <option key={'d-' + d.id} value={d.id}>
                                                {d.name}
                                            </option>
                                        )}
                                    </select>
                                </div>

                                <div className="col">
                                    <label>Позиция</label>

                                    <Dropdown
                                        inputName="position_name"
                                        inputIdName="position_id"
                                        inputValue={fields && fields.position ? fields.position.name : ''}
                                        inputIdValue={fields ? fields.position_id : ''}
                                        url="officials/positions"
                                    />

                                </div>
                            </div>

                            <div className="row center">
                                <div className="col full-width">
                                    <label>Роля</label>
                                    <select
                                        name="role_id"
                                        value={fields.role_id}
                                        onChange={handleFieldChange}
                                    >
                                        {state.roles.map(r =>
                                            <option key={'r-' + r.id} value={r.id}>
                                                {r.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="row center" style={{ marginBottom: '0' }}>
                                <div className="col scroller" style={{ maxHeight: '450px' }}>
                                    {state.permissions.filter(g => g.roles.includes(Number(fields.role_id))).map((g, i) =>
                                        <React.Fragment key={'group-' + i}>
                                            <div className="row">
                                                <h6>{g.name}</h6>
                                            </div>
                                            {g.list.map(p =>
                                                <div key={'p-' + p.id} className="row">
                                                    <label className="checkbox">{p.name}
                                                        <input autoComplete="off" role="presentation"
                                                            type="checkbox"
                                                            name="permissions[]"
                                                            value={p.id}
                                                            checked={state.selectedPermissions[p.id] ? true : false}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="buttons">
                        <span className="btn-default" onClick={handleClose}>
                            Отказ
                        </span>
                        <button type="button" onClick={handleSubmit}>
                            Запази
                        </button>
                    </div>
                </div>
            }
        </form>
    )
}

export default forwardRef(AddOrEditUser)