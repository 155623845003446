import React, { useEffect } from 'react'

//images
import buildingIcon from '../../../../assets/img/pages/icons/building.png'

function Index(props) {


    return (
        <table>
            <thead>
                <tr>

                    <th>
                        Лиценз №
                    </th>
                    <th>
                        Дата
                    </th>
                    <th>
                        Валидност
                    </th>
                    <th>
                        Име на обекта
                    </th>
                    <th>
                        Адрес на обекта
                    </th>
                    <th>
                        Име на фирма
                    </th>
                    <th>
                        Адрес на фирма
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(p =>
                    <React.Fragment key={'p-' + p.object.id}>
                        <tr>

                            <td>
                                {p.license_no}
                            </td>

                            <td>
                                {p.license_date}
                            </td>

                            <td>
                                {p.license_validation}
                            </td>

                            <td>
                                {p.object && p.object.name ?
                                    p.object.name
                                    :
                                    '-'
                                }
                            </td>

                            <td>
                                {p.object && p.object.town
                                    ? [
                                        p.object.town.name,
                                        p.object.street
                                            ? p.object.street.suff + ' ' + p.object.street.name + ' ' + p.object.street_number
                                            : '',
                                        p.zone
                                            ? `Зона ${p.zone.code}`
                                            :
                                            ''
                                    ].filter(el => el != '').join(', ')
                                    : ''}
                            </td>

                            <td>
                                {p.company && p.company.name ?
                                    p.company.name
                                    :
                                    '-'
                                }
                            </td>

                            <td>
                                {p.company && p.company.town
                                    ? [
                                        p.company.town.name,
                                        p.company.street
                                            ? p.company.street.suff + ' ' + p.company.street.name + ' ' + p.company.street_number
                                            : ''
                                    ].filter(el => el != '').join(', ')
                                    : ''}
                            </td>
                        </tr>


                    </React.Fragment>
                )}
            </tbody>
        </table>
    )
}

export default Index