import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu'
import { Link } from 'react-router-dom'

//images
import menuIcon from '../../../../assets/img/pages/icons/table-menu.png'
import editIcon from '../../../../assets/img/app/icons/edit.png'
import closeIcon from '../../../../assets/img/pages/icons/close.png'
import outdoorIcon from '../../../../assets/img/pages/icons/stall.png'

function Options(props) {

    const [table, setTable] = useState({
        options: null,
    })

    const handleTableMenu = e => {
        setTable(table => {
            return {
                ...table,
                options: e.currentTarget
            }
        })
    }

    const handleClose = () => {
        setTable(table => {
            return {
                ...table,
                options: null
            }
        })
    }

    return (
        <>
            {!props.hasClosingProcedure &&
                <>
                    <img src={menuIcon} alt="menu" className="menu" onClick={handleTableMenu} />
                    <Menu
                        id="simple-menu"
                        anchorEl={table.options}
                        keepMounted
                        open={Boolean(table.options)}
                        onClose={handleClose}
                    >

                        <Link
                            to={`/tourism/change/create/${props.id}`}
                            className="menu-option">
                            <img src={editIcon} alt="Edit" />
                            Промяна в обстоятелствата
                        </Link>

                        <Link
                            to={`/tourism/close/create/${props.id}`}
                            className="menu-option">
                            <img src={closeIcon} alt="Close" />
                            Прекратяване
                        </Link>


                    </Menu>
                </>
            }
        </>
    )
}

export default Options;