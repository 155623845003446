import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'

//context
import { useStepsContext } from '../../../../../../contexts/StepsContext'

//partials
import FilesPanel from '../../../../../partials/FilesPanel'
import Fields from './partials/Fields'

//images
import protocolIcon from '../../../../../../assets/img/pages/icons/protocol.png'
import Api from '../../../../../../helpers/Api'
import { Link, useHistory } from 'react-router-dom'
import loader from '../../../../../../assets/img/app/loader.svg'

//modals
import SuccessModal from '../../../../../modals/messages/Success'
import Attention from '../../../../../modals/messages/Attention'
import { useAuthContext } from '../../../../../../contexts/AuthContext'

function Index(props) {

    const history = useHistory()
    const auth = useAuthContext()


    //refs
    const SuccessModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const data = props.data
    const original = props.original
    const setOriginal = props.setOriginal
    const loading = props.loading

    const [application, setApplication] = useState({
        loading: false,
        modified: false,
    })

    const key = new URLSearchParams(window.location.search).get('_') || 'key'

    useEffect(() => {
        let form = document.querySelector('.main-holder form');

        if (form) {
            form.addEventListener('change', fieldsListener);
        }

        return () => {
            if (form) {
                form.removeEventListener('change', fieldsListener)
            }
        }

    }, [])

    useLayoutEffect(() => {
        if (!auth.canWriteDocuments() || data.closed) {
            let form = document.querySelector('.main-holder form');

            if (form) {
                setTimeout(() => {
                    form.querySelectorAll('input').forEach(i => {
                        i.setAttribute('readonly', true);
                    })
                    form.querySelectorAll('img.edit').forEach(i => {
                        i.style.display = 'none';
                    })
                    form.querySelectorAll('.MuiSelect-root').forEach(i => {
                        i.classList.add('disabled');
                    })
                })
            }

        }
    }, [])

    const fieldsListener = () => {
        if (!application.modified) {
            handleModify()
        }
    }

    const handleConfig = e => {
        e.preventDefault()

        setApplication(application => ({ ...application, loading: true }))

        let form = document.querySelector('.main-holder form');

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form)

        // id на процедурата
        data.append('id', props.data.id)

        Api.post('procedures/tradeobjects/addedit', data)
            .then(res => {
                SuccessModalRef.current.open()

                if (res.data.id) {
                    setOriginal(res.data)
                }
            }).catch(err => {
                if (err.response.status === 422) {
                    attentionModalRef.current.open(err.response.data.error)

                    let fields = err.response.data.fields;

                    if (typeof fields === 'object') {
                        Object.entries(fields).map(i => {
                            if (i[1] && typeof i[1] === 'object') {
                                Object.entries(i[1]).map(j => {
                                    if (j[1]) {
                                        let name = `${i[0]}[${j[0]}]`;

                                        let inputs = form.querySelectorAll("[name='" + name + "']");

                                        inputs.forEach(i => {
                                            i.classList.add('validate');
                                        });
                                    }
                                })
                            } else {
                                if (i[1]) {
                                    let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            }
                        });
                    }

                } else {
                    errorModalRef.current.open()
                }
            }).finally(() => {
                handleCancelModify()
            })
    }

    const handleModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: true
        }))
    }

    const handleCancelModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: false,
            loading: false
        }))
    }

    return (
        !loading && original && original.id &&
        <>
            <SuccessModal
                ref={SuccessModalRef}
                mainMessage='Разрешението беше запазено успешно!'
                secondaryMessage='Моля, попълнете всички полета, за да генерирате документа.'
                canGenerate={data.can_generate_license}
            />

            <Attention
                ref={attentionModalRef}
                mainMessage='Моля, попълнете всички полета!'
                secondaryMessage="За да запазите разрешението, трябва да попълните всички полета."
            />

            <Attention
                ref={errorModalRef}
                mainMessage='Нещо се обърка!'
            />
            <h1 className="config-heading">
                <img src={protocolIcon} alt="protocol" />
                Разрешително за обект
            </h1>
            <hr />

            <div className="main-holder">
                <form autoComplete="off" role="presentation" onSubmit={handleConfig}>
                    <Fields
                        key={key}
                        data={data}
                        original={original}
                        handleModify={handleModify}
                    />
                    <hr />
                    <div className="buttons">
                        {!data.closed && auth.canWriteDocuments()
                            ?
                            application.loading
                                ?
                                <img src={loader} alt="loading" />
                                :
                                application.modified ?
                                    <>
                                        <Link
                                            to={"/outlets/config/" + props.original.id + '?_=' + new Date().getTime()}
                                            className="btn-default"
                                            onClick={handleCancelModify}
                                        >
                                            Отказ
                                        </Link>
                                        <button type="button" onClick={handleConfig}>
                                            Запази
                                        </button>
                                    </>
                                    :
                                    ''
                            :
                            ''
                        }
                    </div>
                </form>
                <FilesPanel
                    id={original.procedure_id}
                    generateFile={data.can_generate_license}
                    type="permission"
                    allowEdit={!data.closed && auth.canWriteDocuments()}
                    uploadedDocument={data.uploaded_signed_license}
                    refreshData={props.refreshData}
                />
            </div>

        </>
    )
}

export default Index