import React from 'react'

function Upload() {
    return (
        <>
            <h3>
                Прикачване на файлове
            </h3>
            <div className="holder">
                <ul className="uploaded-files col">
                    <li>
                        Тестов файл.pdf
                    </li>
                    <li>
                        По-голям тестов файл файл файл файл файл файл_файл файл... файл_тестов.pdf
                    </li>
                </ul>
                <hr />
                <div className="buttons">
                    <button className="btn-upload-file">
                        Прикачи нов
                        <input autoComplete="off" role="presentation"  type="file" />
                    </button>
                </div>
            </div>
        </>
    )
}

export default Upload