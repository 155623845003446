import React, { useEffect, useState, useRef } from 'react'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext';
import Api from '../../../../../../../helpers/Api';

import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import loader from '../../../../../../../assets/img/app/loader.svg'
import EmployeeRow from './EmployeeRow';
import { useAuthContext } from '../../../../../../../contexts/AuthContext';


function EmployeesData(props) {

    const auth = useAuthContext()
    const fieldsContext = useFieldsContext();

    const data = props.data;
    const original = props.original;
    const fields = props.fields;
    const setFields = props.setFields;
    const editable = props.editable || false;
    const handleFieldChange = props.handleFieldChange;

    const personAddSelect = useRef(null);

    const [personal, setPersonal] = useState({
        positions: [],
        educationGrades: [],
        langQualifications: [],
        list: original && original.request && original.request.personal ? original.request.personal : [],
        loading: true,
    });

    useEffect(() => {
        Api.get('procedures/categorization/personalqualificationchoices')
            .then(res => {
                setPersonal(prev => ({
                    ...prev,
                    positions: res.data.restaurant_positions,
                    educationGrades: res.data.education_grades,
                    langQualifications: res.data.lang_qualifications
                }));
            }).finally(() => {
                setPersonal(prev => ({
                    ...prev,
                    loading: false
                }))
            });
    }, []);

    const handleAddPerson = e => {
        e.preventDefault();

        let positionId = personAddSelect.current.value;
        let position = personal.positions[positionId];

        let person = {
            id: Math.random().toString(),
            position_id: positionId,
            position: position,
            persons: 1
        };

        setPersonal(prev => ({
            ...prev,
            list: prev.list.concat(person)
        }));

    }

    const handleRemovePerson = id => {

        let filtered = personal.list.filter(p => {
            return p.id != id;
        });

        setPersonal(prev => ({
            ...prev,
            list: filtered
        }));
    }

    return (
        <div className="panel" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    1
                                </span>
                                Общ брой персонал
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="personal_count">
                                            Общ брой
                                        </label>
                                        {editable && fields && fields.request && fields.request.personal_count != null && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="personal_count"
                                            name="request[personal_count]"
                                            type="number"
                                            className="field-medium"
                                            defaultValue={fields && fields.request ? fields.request.personal_count : ''}
                                            readOnly={editable && fields && fields.request && fields.request.personal_count != null ? true : false}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="personal_fullyear_count">
                                            Целогодишно заети
                                        </label>
                                        {editable && fields && fields.request && fields.request.personal_fullyear_count != null && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="personal_fullyear_count"
                                            name="request[personal_fullyear_count]"
                                            type="number"
                                            className="field-medium"
                                            defaultValue={fields && fields.request ? fields.request.personal_fullyear_count : ''}
                                            readOnly={editable && fields && fields.request && fields.request.personal_fullyear_count != null ? true : false}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="personal_season_count">
                                            Сезонно заети
                                        </label>
                                        {editable && fields && fields.request && fields.request.personal_season_count != null && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="personal_season_count"
                                            name="request[personal_season_count]"
                                            type="number"
                                            className="field-medium"
                                            defaultValue={fields && fields.request ? fields.request.personal_season_count : ''}
                                            readOnly={editable && fields && fields.request && fields.request.personal_season_count != null ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col">

                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Справка за образование, езикова квалификация и стаж
                    </h3>
                    <div className="holder">
                        {personal.loading
                            ? <img src={loader} alt="loading" />
                            :
                            personal.list.length === 0
                                ?
                                <p>Няма информация</p>
                                :
                                personal.list.map((p, i) => {
                                    return <React.Fragment key={i}>
                                        <EmployeeRow
                                            positions={personal.positions}
                                            educationGrades={personal.educationGrades}
                                            langQualifications={personal.langQualifications}
                                            data={p}
                                            deletable={i !== 0}
                                            handleRemovePerson={handleRemovePerson}
                                        />

                                        {i !== personal.list.length - 1 && <hr />}
                                    </React.Fragment>
                                })
                        }

                        {auth.canWriteRequest() &&
                            <>
                                <hr />
                                <div className="row">
                                    <div className="col col-auto">
                                        <select ref={personAddSelect} style={{ width: '250px', maxWidth: '250px' }}>
                                            {Object.entries(personal.positions).map(p =>
                                                <option key={'p-' + p[0]} value={p[0]}>
                                                    {p[1]}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col col-auto">
                                        <button
                                            className="btn-add"
                                            onClick={handleAddPerson}
                                        >
                                            Добавяне
                                        </button>
                                    </div>
                                </div>
                            </>
                        }

                    </div>
                </div>
            </div>
        </div >
    )
}

export default React.memo((EmployeesData), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})