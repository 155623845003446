import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react'

//context
import { useStepsContext } from '../../../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import Dropdown from '../../../../../../app/Dropdown';

function Applicant(props, ref) {

    const fieldsContext = useFieldsContext();

    const data = props.data;
    const original = props.original;
    const fields = props.fields;
    const setFields = props.setFields;
    const editable = props.editable || false;
    const handleFieldChange = props.handleFieldChange;

    const fieldsData = {
        object: {},
    }

    // const [fields, setFields] = useState(fieldsData)

    // useEffect(() => {
    //     if (original) {
    //         setFields(original)
    //     }
    // }, [original])


    // const handleFieldChange = e => {

    //     let isObj = Boolean(e.target.name.includes('['))
    //     let name = e.target.name
    //     let val = e.target.value

    //     if (isObj) {
    //         let nameFirstPart = name.split('[')[0]
    //         let nameSecondPart = name.split('[')[1].split(']')[0]

    //         setFields(prev => ({
    //             ...prev,
    //             [nameFirstPart]: {
    //                 ...prev[nameFirstPart],
    //                 [nameSecondPart]: val,
    //             }
    //         }))
    //     } else {
    //         setFields(prev => ({
    //             ...prev,
    //             [name]: val
    //         }))
    //     }

    // }

    const companyMunicipalityRef = useRef(null);
    const companyTownRef = useRef(null);
    const companyRegionRef = useRef(null);
    const companyStreetRef = useRef(null);
    const addrMunicipalityRef = useRef(null);
    const addrTownRef = useRef(null);
    const addrRegionRef = useRef(null);
    const addrStreetRef = useRef(null);

    // dropdown change
    const handleCompanyChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                company: newData
            }))
        }
    }

    const handleCompanyCountryChange = newData => {
        companyMunicipalityRef.current.reset()
        companyTownRef.current.reset();
        companyRegionRef.current.reset();
        companyStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                country_id: newData.id,
                country: newData,
            }

        }))
    }

    const handleCompanyMunicipalityChange = newData => {
        companyTownRef.current.reset();
        companyRegionRef.current.reset();
        companyStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                municipality_id: newData.id,
                municipality: newData,
            }


        }))
    }

    const handleCompanyTownChange = newData => {
        companyStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                town_id: newData.id,
                town: newData,
            }

        }))
    }

    const handleCompanyRegionChange = newData => {
        // todo
    }

    const handleAddrCountryChange = newData => {
        addrMunicipalityRef.current.reset()
        addrTownRef.current.reset();
        addrRegionRef.current.reset();
        addrStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            request: {
                ...prev.request,
                addr_country_id: newData.id,
                country: newData,
            }
        }))
    }

    const handleAddrMunicipalityChange = newData => {
        addrTownRef.current.reset();
        addrRegionRef.current.reset();
        addrStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            request: {
                ...prev.request,
                addr_municipality_id: newData.id,
                municipality: newData,
            }
        }))
    }

    const handleAddrTownChange = newData => {
        addrStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            request: {
                ...prev.request,
                addr_town_id: newData.id,
                town: newData,
            }

        }))
    }

    const handleAddrChange = e => {
        let name = e.target.name;
        let value = Number(e.target.value);

        setFields(prev => ({
            ...prev,
            [name]: value
        }));
    }

    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name
        let nameFirstPart = name.split('[')[0]

        setFields(prev => ({
            ...prev,
            [nameFirstPart]: {
                ...prev[nameFirstPart],
                date_formatted: val
            }
        }));
    }

    return (
        <div className="panel" style={{ display: props.show ? 'block' : 'none' }}>

            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и Дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="request_no">
                                    Входящ №
                                </label>
                                {editable && original && original.request && original.request.no && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request_no"
                                    name="request[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    value={fields && fields.request ? fields.request.no : ''}
                                    readOnly={editable && original && original.request && original.request.no ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="request_date">
                                    Дата
                                </label>
                                {editable && original && original.request && original.request.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request_date"
                                    name="request[date]"
                                    type="date"
                                    value={fields && fields.request && fields.request.date_formatted ? fields.request.date_formatted : fieldsContext.today()}
                                    readOnly={editable && original && original.request && original.request.date ? true : false}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            1
                        </span>
                        Заявител
                    </h3>
                    <div className="holder">
                        <div className="row">

                            <div className="col able-to-edit">
                                <label htmlFor="company_name">
                                    Наименование на търговеца
                                </label>
                                {editable && original && original.company && original.company.name && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <Dropdown
                                    inputName="company[name]"
                                    inputValue={fields && fields.company && fields.company.name}
                                    inputIdName="company[id]"
                                    inputIdValue={fields && fields.company && fields.company.id}
                                    inputPlaceholder="Въведете име"
                                    inputDisabled={editable && original && original.company && original.company.name ? true : false}
                                    url="companies/find"
                                    onChange={handleCompanyChange}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="company_eik">
                                    ЕИК / Булстат
                                </label>
                                {editable && original && original.company && original.company.eik && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="eik"
                                    name="company[eik]"
                                    type="text"
                                    placeholder="Въведете ЕИК / Булстат"
                                    value={fields && fields.company && fields.company.eik ? fields.company.eik : ''}
                                    readOnly={editable && original && original.company && original.company.eik ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                    Адрес на заявителя
                    </h3>
                    <div className="holder">
                        <div className="row x2">

                            <div className="col">

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_country">
                                            Държава
                                        </label>
                                        {editable && original && original.company && original.company.country && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            inputName="company[country]"
                                            inputValue={fields && fields.company && fields.company.country ? fields.company.country.name : 'България'}
                                            inputIdName="company[country_id]"
                                            inputIdValue={fields && fields.company && fields.company.country_id ? fields.company.country_id : 1}
                                            inputPlaceholder="Изберете държава"
                                            inputDisabled={editable && original && original.company && original.company.country ? true : false}
                                            url="countries/find"
                                            onChange={handleCompanyCountryChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_municipality">
                                            Община
                                        </label>
                                        {editable && original && original.company && original.company.municipality && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={companyMunicipalityRef}
                                            inputName="company[municipality]"
                                            inputValue={fields && fields.company && fields.company.municipality ? fields.company.municipality.name : ''}
                                            inputIdName="company[municipality_id]"
                                            inputIdValue={fields && fields.company && fields.company.municipality_id}
                                            inputPlaceholder="Изберете община"
                                            inputDisabled={editable && original && original.company && original.company.municipality ? true : false}
                                            url="municipalities/find"
                                            params={{
                                                country_id: fields && fields.company && fields.company.country_id
                                            }}
                                            onChange={handleCompanyMunicipalityChange}
                                        />
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_town">
                                            Населено място
                                        </label>
                                        {editable && original && original.company && original.company.town && original.company.town && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={companyTownRef}
                                            inputName="company[town]"
                                            inputValue={fields && fields.company && fields.company.town
                                                ? fields.company.town.id
                                                    ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                    : fields.company.town.name
                                                : ''}
                                            inputIdName="company[town_id]"
                                            inputIdValue={fields && fields.company && fields.company.town_id}
                                            inputPlaceholder="Изберете населено място"
                                            inputDisabled={editable && original && original.company && original.company.town && original.company.town.id ? true : false}
                                            url="towns/find"
                                            params={{
                                                munic_id: fields && fields.company && fields.company.municipality && fields.company.municipality.id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.kind} {data.name}</>;
                                            }}
                                            onChange={handleCompanyTownChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_postcode">
                                            Пощенски код
                                        </label>
                                        {editable && original && original.company && original.company.postcode && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="company_postcode"
                                            name="company[postcode]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.company && fields.company.postcode ? fields.company.postcode : ''}
                                            readOnly={editable && original && original.company && original.company.postcode ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_region">
                                            Район/квартал
                                        </label>
                                        {editable && original && original.company && original.company.region && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={companyRegionRef}
                                            inputName="company[region]"
                                            inputValue={fields && fields.company && fields.company.region ? fields.company.region.name : ''}
                                            inputIdName="company[region_id]"
                                            inputIdValue={fields && fields.company && fields.company.region_id}
                                            inputPlaceholder="Изберете район/квартал"
                                            inputDisabled={editable && original && original.company && original.company.region ? true : false}
                                            url="regions/find"
                                            params={{
                                                town_id: fields && fields.company && fields.company.town_id
                                            }}

                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_street">
                                            Булевард/площад/улица
                                        </label>
                                        {editable && original && original.company && original.company.street && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={companyStreetRef}
                                            inputName="company[street]"
                                            inputValue={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                            inputIdName="company[street_id]"
                                            inputIdValue={fields && fields.company && fields.company.street_id}
                                            inputPlaceholder="Изберете улица"
                                            inputDisabled={editable && original && original.company && original.company.street ? true : false}
                                            url="streets/find"
                                            params={{
                                                town_id: fields && fields.company && fields.company.town_id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.suff} {data.name}</>;
                                            }}
                                        />
                                    </div>

                                </div>

                            </div>

                            <div className="col">

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="company_phone">
                                            Тел. номер
                                        </label>
                                        {editable && original && original.company && original.company.phone && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="company_phone"
                                            name="company[phone]"
                                            type="text"
                                            placeholder="Въведете тел. номер"
                                            value={fields && fields.company && fields.company.phone ? fields.company.phone : ''}
                                            readOnly={editable && original && original.company && original.company.phone ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="company_fax">
                                            Факс
                                        </label>
                                        {editable && original && original.company && original.company.fax && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="company_fax"
                                            name="company[fax]"
                                            type="text"
                                            placeholder="Въведете факс"
                                            value={fields && fields.company && fields.company.fax ? fields.company.fax : ''}
                                            readOnly={editable && original && original.company && original.company.fax ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="company_email">
                                            Е-поща
                                        </label>
                                        {editable && original && original.company && original.company.email && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="company_email"
                                            name="company[email]"
                                            type="text"
                                            placeholder="Въведете е-поща"
                                            value={fields && fields.company && fields.company.email ? fields.company.email : ''}
                                            readOnly={editable && original && original.company && original.company.email ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_street_number">
                                            №
                                        </label>
                                        {editable && original && original.company && original.company.street_number && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="company_street_number"
                                            name="company[street_number]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.company && fields.company.street_number ? fields.company.street_number : ''}
                                            readOnly={editable && original && original.company && original.company.street_number ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_block">
                                            Блок
                                        </label>
                                        {editable && original && original.company && original.company.block && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="company_block"
                                            name="company[block]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.company && fields.company.block ? fields.company.block : ''}
                                            readOnly={editable && original && original.company && original.company.block ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_entrance">
                                            Вход
                                        </label>
                                        {editable && original && original.company && original.company.entrance && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="company_entrance"
                                            name="company[entrance]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.company && fields.company.entrance ? fields.company.entrance : ''}
                                            readOnly={editable && original && original.company && original.company.entrance ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_floor">
                                            Етаж
                                        </label>
                                        {editable && original && original.company && original.company.floor && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="company_floor"
                                            name="company[floor]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.company && fields.company.floor ? fields.company.floor : ''}
                                            readOnly={editable && original && original.company && original.company.floor ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_apartment">
                                            Апартамент
                                        </label>
                                        {editable && original && original.company && original.company.apartment && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="company_apartment"
                                            name="company[apartment]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.company && fields.company.apartment ? fields.company.apartment : ''}
                                            readOnly={editable && original && original.company && original.company.apartment ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Адрес за кореспонденция, електронна поща или факс
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <label className="checkbox">
                                Съвпадат с посочените в т. 2
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name="addr_same_as_company_addr"
                                    value="1"
                                    checked={fields.addr_same_as_company_addr == 1 ? true : false}
                                    onChange={handleAddrChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        <div className="row">
                            <label className="checkbox">
                                Други
                                <input autoComplete="off" role="presentation"
                                    type="radio"
                                    name="addr_same_as_company_addr"
                                    value="0"
                                    id="use-other"
                                    checked={fields.addr_same_as_company_addr == 0 ? true : false}
                                    onChange={handleAddrChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>

                        {fields.addr_same_as_company_addr == 0 &&
                            <>
                                <div className="row">

                                    <div className="col">

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="addr_country">
                                                    Държава
                                                </label>
                                                {editable && original && original.request && original.request.addr_country_id && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    inputName="request[addr_country]"
                                                    inputValue={fields && fields.request && fields.request.country ? fields.request.country.name : 'България'}
                                                    inputIdName="request[addr_country_id]"
                                                    inputIdValue={fields && fields.request && fields.request.addr_country_id ? fields.request.addr_country_id : 1}
                                                    inputPlaceholder="Изберете държава"
                                                    inputDisabled={editable && original && original.request && original.request.addr_country_id ? true : false}
                                                    url="countries/find"
                                                    onChange={handleAddrCountryChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="addr_municipality">
                                                    Община
                                                </label>
                                                {editable && original && original.request && original.request.addr_municipality_id && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={addrMunicipalityRef}
                                                    inputName="request[addr_municipality]"
                                                    inputValue={fields && fields.request && fields.request.municipality ? fields.request.municipality.name : ''}
                                                    inputIdName="request[addr_municipality_id]"
                                                    inputIdValue={fields && fields.request && fields.request.addr_municipality_id}
                                                    inputPlaceholder="Изберете община"
                                                    inputDisabled={editable && original && original.request && original.request.addr_municipality_id ? true : false}
                                                    url="municipalities/find"
                                                    params={{
                                                        country_id: fields && fields.request && fields.request.country_id
                                                    }}
                                                    onChange={handleAddrMunicipalityChange}
                                                />
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="addr_town">
                                                    Населено място
                                                </label>
                                                {editable && original && original.request && original.request.town && original.request.addr_town_id && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={addrTownRef}
                                                    inputName="request[addr_town]"
                                                    inputValue={fields && fields.request && fields.request.town
                                                        ? fields.request.town.id
                                                            ? `${fields.request.town.kind} ${fields.request.town.name}`
                                                            : fields.request.town.name
                                                        : ''}
                                                    inputIdName="request[addr_town_id]"
                                                    inputIdValue={fields && fields.request && fields.request.addr_town_id}
                                                    inputPlaceholder="Изберете населено място"
                                                    inputDisabled={editable && original && original.request && original.request.addr_town_id ? true : false}
                                                    url="towns/find"
                                                    params={{
                                                        munic_id: fields && fields.request && fields.request.municipality && fields.request.municipality.id
                                                    }}
                                                    renderText={(data) => {
                                                        return <>{data.kind} {data.name}</>;
                                                    }}
                                                    onChange={handleAddrTownChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="addr_postcode">
                                                    Пощенски код
                                                </label>
                                                {editable && original && original.request && original.request.addr_postcode && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="addr_postcode"
                                                    name="request[addr_postcode]"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.request ? fields.request.addr_postcode : ''}
                                                    readOnly={editable && original && original.request && original.request.addr_postcode ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                        </div>

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="addr_region">
                                                    Район/квартал
                                                </label>
                                                {editable && original && original.request && original.request.addr_region_id && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={addrRegionRef}
                                                    inputName="request[addr_region]"
                                                    inputValue={fields && fields.request && fields.request.region ? fields.request.region.name : ''}
                                                    inputIdName="request[addr_region_id]"
                                                    inputIdValue={fields && fields.request && fields.request.addr_region_id}
                                                    inputPlaceholder="Изберете район/квартал"
                                                    inputDisabled={editable && original && original.request && original.request.addr_region_id ? true : false}
                                                    url="regions/find"
                                                    params={{
                                                        town_id: fields && fields.request && fields.request.addr_town_id
                                                    }}

                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="addr_street">
                                                    Булевард/площад/улица
                                                </label>
                                                {editable && original && original.request && original.request.addr_street_id && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <Dropdown
                                                    ref={addrStreetRef}
                                                    inputName="request[addr_street]"
                                                    inputValue={fields && fields.request && fields.request.street ? `${fields.request.street.suff} ${fields.request.street.name}` : ''}
                                                    inputIdName="request[addr_street_id]"
                                                    inputIdValue={fields && fields.request && fields.request.addr_street_id}
                                                    inputPlaceholder="Изберете улица"
                                                    inputDisabled={editable && original && original.request && original.request.addr_street_id ? true : false}
                                                    url="streets/find"
                                                    params={{
                                                        town_id: fields && fields.request && fields.request.addr_town_id
                                                    }}
                                                    renderText={(data) => {
                                                        return <>{data.suff} {data.name}</>;
                                                    }}
                                                />
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col">

                                        <div className="row">
                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="addr_street_number">
                                                    №
                                                </label>
                                                {editable && original && original.request && original.request.addr_street_number && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="request[addr_street_number]"
                                                    name="request[addr_street_number]"
                                                    type="number"
                                                    min="0"
                                                    step="1"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.request ? fields.request.addr_street_number : ''}
                                                    readOnly={editable && original && original.request && original.request.addr_street_number ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="addr_block">
                                                    Блок
                                                </label>
                                                {editable && original && original.request && original.request.addr_block && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="addr_block"
                                                    name="request[addr_block]"
                                                    type="text"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.request ? fields.request.addr_block : ''}
                                                    readOnly={editable && original && original.request && original.request.addr_block ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="addr_floor">
                                                    Етаж
                                                </label>
                                                {editable && original && original.request && original.request.addr_floor && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="addr_floor"
                                                    name="request[addr_floor]"
                                                    type="text"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.request ? fields.request.addr_floor : ''}
                                                    readOnly={editable && original && original.request && original.request.addr_floor ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col col-auto able-to-edit">
                                                <label htmlFor="addr_apartment">
                                                    Апартамент
                                                </label>
                                                {editable && original && original.request && original.request.addr_apartment && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="addr_apartment"
                                                    name="request[addr_apartment]"
                                                    type="text"
                                                    placeholder=""
                                                    className="field-small"
                                                    value={fields && fields.request ? fields.request.addr_apartment : ''}
                                                    readOnly={editable && original && original.request && original.request.addr_apartment ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="addr_phone">
                                                    Тел. номер
                                                </label>
                                                {editable && original && original.request && original.request.addr_phone && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="addr_phone"
                                                    name="request[addr_phone]"
                                                    type="text"
                                                    placeholder="Въведете тел. номер"
                                                    value={fields && fields.request ? fields.request.addr_phone : ''}
                                                    readOnly={editable && original && original.request && original.request.addr_phone ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="addr_fax">
                                                    Факс
                                                </label>
                                                {editable && original && original.request && original.request.addr_fax && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="addr_fax"
                                                    name="request[addr_fax]"
                                                    type="text"
                                                    placeholder="Въведете факс"
                                                    value={fields && fields.request ? fields.request.addr_fax : ''}
                                                    readOnly={editable && original && original.request && original.request.addr_fax ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="addr_email">
                                                    Е-поща
                                                </label>
                                                {editable && original && original.request && original.request.addr_email && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />}
                                                <input autoComplete="off" role="presentation"
                                                    id="addr_email"
                                                    name="request[addr_email]"
                                                    type="text"
                                                    placeholder="Въведете е-поща"
                                                    value={fields && fields.request ? fields.request.addr_email : ''}
                                                    readOnly={editable && original && original.request && original.request.addr_email ? true : false}
                                                    onChange={handleFieldChange}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            4
                        </span>
                        Представителство на заявителя
                    </h3>
                    <div className="holder">
                        <div className="col">
                            <div className="row">

                                <div className="col able-to-edit">
                                    <label htmlFor="agent_name">
                                        Име, презиме, фамилия
                                    </label>
                                    {editable && original && original.request && original.request.agent_name && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="agent_name"
                                        name="request[agent_name]"
                                        type="text"
                                        placeholder="Въведете име..."
                                        defaultValue={fields && fields.request && fields.request.agent_name ? fields.request.agent_name : ''}
                                        readOnly={editable && original && original.request && original.request.agent_name ? true : false}
                                    // onChange={handleFieldChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="agent_position">
                                        Качество на представляващия
                                    </label>
                                    {editable && original && original.request && original.request.agent_position && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <select
                                        name="request[agent_position]"
                                        value={fields && fields.request && fields.request.agent_position ? fields.request.agent_position : ''}
                                        readOnly={editable && original && original.request && original.request.agent_position ? true : false}
                                        onChange={handleFieldChange}
                                    >
                                        <option>
                                            Избор
                                        </option>
                                        <option value="Управител">
                                            Управител
                                        </option>
                                        <option value="Изпълнителен директор">
                                            Изпълнителен директор
                                        </option>
                                        <option value="Помощник">
                                            Помощник
                                        </option>
                                    </select>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col col-auto able-to-edit">
                                    <label htmlFor="agent_phone">
                                        Телефон/факс
                                    </label>
                                    {editable && original && original.request && original.request.agent_phone && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="agent_phone"
                                        name="request[agent_phone]"
                                        type="text"
                                        placeholder="Въведете телефон/факс..."
                                        className="field-medium"
                                        defaultValue={fields && fields.request && fields.request.agent_phone ? fields.request.agent_phone : ''}
                                        readOnly={editable && original && original.request && original.request.agent_phone ? true : false}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="agent_email">
                                        Е-поща
                                    </label>
                                    {editable && original && original.request && original.request.agent_email && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="agent_email"
                                        name="request[agent_email]"
                                        type="text"
                                        placeholder="Въведете е-поща..."
                                        className="field-medium"
                                        defaultValue={fields && fields.request && fields.request.agent_email ? fields.request.agent_email : ''}
                                        readOnly={editable && original && original.request && original.request.agent_email ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div >
    )
}

export default React.memo(Applicant, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})