import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Api from '../../../../helpers/Api'

//partials
import Table from './partials/Table'
import Filter from './partials/Filter'
import Pagination from '../../../partials/Pagination'
import AddOrEditZone from '../../../modals/AddOrEditZone'

//images
import administrationIcon from '../../../../assets/img/app/icons/administration.png'
import loader from '../../../../assets/img/app/loader.svg'
import ZonePrices from '../../../modals/ZonePrices'
import { useAuthContext } from '../../../../contexts/AuthContext'

let timeout;

function Index() {

    const auth = useAuthContext();
    const location = useLocation();
    const history = useHistory();

    if (!auth.isAdmin()) {
        history.push('/');
    }

    const modalRef = useRef(null);
    const modalPricesRef = useRef(null);

    const [data, setData] = useState({
        items: [],
        total: 0,
        loading: true,
        refresh: false,
        filter: {

        }
    });

    useEffect(() => {
        loadData();
    }, [data.refresh]);

    const loadData = () => {
        setData(prev => ({
            ...prev,
            loading: true
        }));

        let url = 'zones/find';

        Api.get(url)
            .then(res => {
                setData(prev => ({
                    ...prev,
                    items: res.data,
                    total: res.data.length,
                    loading: false
                }));
            });
    }

    const refreshData = () => {
        setData(prev => ({
            ...prev,
            refresh: new Date().getTime(),
            loading: true,
            total: 0,
            items: []
        }))
    }

    const handleAdd = () => {
        modalRef.current.open();
    }

    const handleEdit = (id) => {
        modalRef.current.edit(id);
    }

    const handleShowPrices = (id) => {
        modalPricesRef.current.open(id);
    }

    return (
        <>
            <AddOrEditZone
                ref={modalRef}
                refreshData={refreshData}
            />

            <ZonePrices
                ref={modalPricesRef}
                refreshData={refreshData}
            />

            <section>
                <div className="wrapper top row">
                    <h1>
                        Зони ({data.total})
                </h1>
                    <button className="btn-add" onClick={handleAdd}>
                        Нова зона
                    </button>
                </div>

                <div className="wrapper">

                    {data.loading
                        ?
                        <img src={loader} alt="loading" />
                        : data.items.length === 0
                            ? <p>Няма намерена информация.</p>
                            :
                            <>
                                <div className="row">
                                    <Table
                                        data={data.items}
                                        handleEdit={handleEdit}
                                        handleShowPrices={handleShowPrices}
                                        refreshData={refreshData}
                                    />
                                </div>

                            </>
                    }
                </div>

            </section>
        </>
    )
}

export default Index