import React from 'react'
import { Link } from 'react-router-dom'

//context
import { useAuthContext } from '../../contexts/AuthContext'

//styles
import '../../assets/css/header.css'

//images
import logo from '../../assets/img/app/header/logo.png'
import outlets from '../../assets/img/app/icons/outlets.png'
import temporaryShop from '../../assets/img/app/icons/temporary-shop.png'
import mobileFacilities from '../../assets/img/app/icons/mobile-facilities.png'
import administration from '../../assets/img/app/icons/administration.png'
import tourism from '../../assets/img/app/icons/tourism.png'
import profile from '../../assets/img/app/icons/profile.png'
import logoutIcon from '../../assets/img/app/icons/logout.png'
import certificates from '../../assets/img/app/icons/certificates.png'

function Header() {
    const auth = useAuthContext()

    const handleLogout = e => {
        auth.login()
    }

    return (
        <header>
            <div className="wrapper">
                <Link to="/" className="brand">
                    <img src={logo} alt="home" />
                        Община
                        <br />
                        Велико Търново
                </Link>
                <nav>
                    {auth.canViewTradeObjects() &&
                        <Link
                            to="/outlets"
                            className={window.location.pathname.indexOf('outlets') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                <img src={outlets} alt="Outlets" />
                            </i>
                        Търговски
                        <br />
                        обекти
                    </Link>
                    }
                    {auth.canViewTemporaryShops() &&
                        <Link
                            to="/temporary-shops"
                            className={window.location.pathname.indexOf('temporary-shops') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                <img src={temporaryShop} alt="Temporary shops" />
                            </i>
                        Временна
                        <br />
                        търговия
                    </Link>
                    }
                    {auth.canViewMobileFacilities() &&
                        <Link
                            to="/mobile-facilities"
                            className={window.location.pathname.indexOf('mobile-facilities') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                <img src={mobileFacilities} alt="Mobile facilities" />
                            </i>
                        Преместваеми
                        <br />
                        съоръжения
                    </Link>
                    }
                    {auth.canViewCategorizations() &&
                        <Link
                            to="/tourism"
                            className={window.location.pathname.indexOf('tourism') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                <img src={tourism} alt="Tourism" />
                            </i>
                        Категоризация на
                        <br />
                        туристически обекти
                    </Link>
                    }
                    {auth.canViewObligations() &&
                        <Link
                            to="/obligations"
                            className={window.location.pathname.indexOf('obligations') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                <img src={certificates} alt="Cert" />
                            </i>
                        Удостоверения
                        <br />
                        за наличие / липса на задължения
                    </Link>
                    }
                    <div className="right" style={{ display: 'flex', flexDirection: 'row' }}>
                        {auth.isAdmin() &&
                            <Link
                                to="/administration"
                                className={`col ${window.location.pathname.indexOf('administration') != -1 ?
                                    'active'
                                    :
                                    ''}`
                                }
                            >
                                <img src={administration} alt="Administration" />
                                Администрация
                            </Link>
                        }
                        <Link
                            to="/profile"
                            className={`right col ${window.location.pathname == '/profile' ?
                                'active'
                                :
                                'closed-nav'}`
                            }
                        >
                            <img src={profile} alt="Profile" />
                            {auth.user && auth.user.user && auth.user.user.name}
                        </Link>
                        <Link
                            onClick={handleLogout}
                            className="col"
                            to="/login"
                        >
                            <img src={logoutIcon} alt="Logout" />
                            Изход
                        </Link>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header