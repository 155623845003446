import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

//styles
import '../../assets/css/submenu.css'

//images
import outlets from '../../assets/img/app/icons/outlets-submenu.png'
import inProgress from '../../assets/img/app/icons/in-progress.png'
import expired from '../../assets/img/app/icons/expired.png'
import closed from '../../assets/img/app/icons/closed.png'
import reports from '../../assets/img/app/icons/reports.png'
import companyIcon from '../../assets/img/app/icons/administration/company.png'
import businessmanIcon from '../../assets/img/app/icons/administration/businessman.png'
import Api from '../../helpers/Api'
import { useAuthContext } from '../../contexts/AuthContext'

function Submenu(props) {
    const auth = useAuthContext()

    // Съдържа първия параметър в URL-a
    let mainDirectory = props.location.pathname.split('/')[1]
    let reload = new URLSearchParams(props.location.search).get('reload')

    const initialCounts = {
        current: 0,
        active: 0,
        expiring: 0,
        closed: 0,
        canceled: 0
    };

    const [counts, setCounts] = useState(initialCounts);

    useEffect(() => {
        loadCounts()
    }, [props.location.pathname]);

    useEffect(() => {
        if (reload) {
            loadCounts()
        }
    }, [reload]);


    const loadCounts = () => {
        let url;

        if (mainDirectory === 'outlets') {
            url = 'procedures/tradeobjects/counts'
        } else if (mainDirectory === 'mobile-facilities') {
            url = 'procedures/temptrade/counts?temptrade_type_id=4'
        } else if (mainDirectory === 'temporary-shops') {
            url = 'procedures/temptrade/counts?temptrade_type_id=3'
        } else if (mainDirectory === 'tourism') {
            url = 'procedures/categorization/counts'
        } else if (mainDirectory === 'obligations') {
            url = 'procedures/obligations/counts'
        }

        if (url) {

            setCounts(initialCounts);

            Api.get(url)
                .then(res => {
                    setCounts({
                        current: res.data.current,
                        active: res.data.active,
                        expiring: res.data.expiring,
                        closed: res.data.closed,
                        canceled: res.data.canceled
                    });
                }).catch(err => {
                    setCounts(initialCounts);
                })
        }

    }

    return (
        mainDirectory &&

        <section id="submenu">
            <nav className="wrapper">
                {mainDirectory === 'obligations'
                    ?
                    <>
                        <Link
                            to={`/${mainDirectory}?current=1`}
                            className={window.location.search.indexOf('current') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                {counts.current ?
                                    <span>
                                        {counts.current}
                                    </span>
                                    :
                                    ''
                                }
                                <img src={inProgress} alt="In progress" />
                            </i>
                            {auth.canWriteRequest() ? 'Текущи' : 'Чакащи подпис'}

                        </Link>
                        <Link
                            to={`/${mainDirectory}?companies=1`}
                            className={window.location.search.indexOf('companies') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                {counts.companies ?
                                    <span>
                                        {counts.companies}
                                    </span>
                                    :
                                    ''
                                }
                                <img src={companyIcon} alt="" />
                            </i>
                            Към фирми
                        </Link>

                        <Link
                            to={`/${mainDirectory}?persons=1`}
                            className={window.location.search.indexOf('persons') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                {counts.persons ?
                                    <span>
                                        {counts.persons}
                                    </span>
                                    :
                                    ''
                                }
                                <img src={businessmanIcon} alt="" />
                            </i>
                            Към физически лица
                        </Link>
                    </>
                    :
                    <>
                        <Link
                            to={`/${mainDirectory}?current=1`}
                            className={window.location.search.indexOf('current') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                {counts.current ?
                                    <span>
                                        {counts.current}
                                    </span>
                                    :
                                    ''
                                }
                                <img src={inProgress} alt="In progress" />
                            </i>
                            Текущи
                        </Link>
                        <Link
                            to={`/${mainDirectory}?objects=1`}
                            className={window.location.pathname.indexOf('reports') === -1 && window.location.search.indexOf('objects') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                {counts.active ?
                                    <span>
                                        {counts.active}
                                    </span>
                                    :
                                    ''
                                }
                            </i>
                            <img src={outlets} alt="Outlets" />
                            Активни
                        </Link>
                        <Link
                            to={`/${mainDirectory}?expiring=1`}
                            className={window.location.search.indexOf('expiring') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                {counts.expiring ?
                                    <span>
                                        {counts.expiring}
                                    </span>
                                    :
                                    ''
                                }
                                <img src={expired} alt="Expired" />
                            </i>
                            Изтичащи
                        </Link>


                        <Link
                            to={`/${mainDirectory}?closed=1`}
                            className={window.location.search.indexOf('closed') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                {counts.closed ?
                                    <span>
                                        {counts.closed}
                                    </span>
                                    :
                                    ''
                                }
                                <img src={closed} alt="Closed" />
                            </i>
                                Неактивни
                            </Link>

                        <Link
                            to={`/${mainDirectory}?canceled=1`}
                            className={window.location.search.indexOf('canceled') != -1 ?
                                'active'
                                :
                                ''
                            }>
                            <i>
                                {counts.canceled ?
                                    <span>
                                        {counts.canceled}
                                    </span>
                                    :
                                    ''
                                }
                                <img src={closed} alt="Closed" />
                            </i>
                                Отказани
                            </Link>

                        <Link
                            to={`/${mainDirectory}/reports`}
                            className={window.location.pathname.indexOf('/reports') != -1 ?
                                'active'
                                :
                                ''
                            }>

                            <img src={reports} alt="Closed" />
                                Справки
                        </Link>

                    </>
                }
            </nav>
        </section>

    )
}

export default Submenu