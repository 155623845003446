import React, { useState, useEffect } from 'react'

function Steps(props) {
    let currentStep = props.getCurrent

    const [steps, setSteps] = useState({
        current: currentStep,
    })

    useEffect(() => {
        setSteps(steps => ({
            ...steps,
            current: currentStep,
        }))
    }, [currentStep])



    const handlePosition = e => {
        let position = e.target.getAttribute('data-position')
        setSteps(steps => ({
            ...steps,
            current: position,
        }))
        props.handleCurrent(position)
    }

    return (
        <div className="scroller">
            <ul className="steps">
                <li
                    data-position="1"
                    className={[
                        props.firstStepFinish ? 'finished' : 'current',
                        currentStep == 1 ? 'active' : '',
                        props.firstStepFinish && !props.secondStepFinish ? 'current-is-next' : ''
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Заявление
                </span>
                </li>
                <li
                    data-position="2"
                    className={[
                        props.secondStepFinish ? 'finished' : '',
                        currentStep == 2 && props.firstStepFinish ? 'active' : '',
                        props.firstStepFinish && !props.secondStepFinish ? 'current' : '',
                        props.secondStepFinish && !props.thirdStepFinish ? 'current-is-next' : ''
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Писмо
                    </span>
                </li>

            </ul>
        </div>
    )
}

export default Steps