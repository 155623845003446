import React, { useRef, useState, useEffect } from 'react'

//modals
import CommisionModal from '../../../../../../modals/Commission'
import { useAuthContext } from '../../../../../../../contexts/AuthContext'

function Commission(props) {

    const auth = useAuthContext()

    const commisionModalRef = useRef(null)

    const [state, setState] = useState([]);

    useEffect(() => {
        if (props.data) {
            setState(props.data);
        }
    }, [props.data]);

    const handleCommision = () => {
        commisionModalRef.current.open()
    }
    return (
        <>
            <CommisionModal
                ref={commisionModalRef}
                inputName="findingsprotocol[commission]"
                selected={state}
                setSelected={setState}
            />
            <h3>
                <span>
                    2
                </span>
                Комисия
            </h3>
            <div className="holder" name="findingsprotocol_commission">
                <ul className="col">
                    {state.length > 0 ?

                        state.map(p =>
                            <li>
                                {p.name}
                                <i>
                                    {p.position && p.position.name}
                                </i>
                            </li>
                        )
                        :
                        <li className="centered">
                            Няма избрана комисия
                        </li>
                    }
                </ul>
                {auth.canWriteDocuments() &&
                    <>
                        <hr />
                        <div className="buttons">
                            <button type="button" onClick={handleCommision}>
                                {state.length > 0 ?
                                    'Промяна на комисията'
                                    :
                                    'Избор на комисия'
                                }
                            </button>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default Commission