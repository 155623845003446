import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import AuthContext from './contexts/AuthContext'
import FieldsContext from './contexts/FieldsContext'
import App from './App';
import * as serviceWorker from './serviceWorker';

const history = require("history").createLocation;

ReactDOM.render(
    <Router history={history}>
        <AuthContext>
            <FieldsContext>
                <App />
            </FieldsContext>
        </AuthContext>
    </Router>,
    document.getElementById('root')
);

serviceWorker.unregister();
