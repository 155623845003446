import React, { useEffect, useState } from 'react'
import { useFieldsContext } from '../../../../../../contexts/FieldsContext'

import editIcon from '../../../../../../assets/img/pages/icons/edit-field.jpg'
import Dropdown from '../../../../../app/Dropdown';

function Application(props) {

    const fieldsContext = useFieldsContext();

    const data = props.data;
    const original = props.original;
    const editable = props.editable || false;

    const [fields, setFields] = useState({
        request: {},
        company: {},
        object: {},
    });

    useEffect(() => {
        if (original) {
            setFields(original);
        }
    }, [original]);

    const handleFieldChange = e => {

        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    return (
        <div className="panel">

            <div className="row">

                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="request_no">
                                    Входящ №
                                </label>
                                {editable && fields && fields.request && fields.request.no && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request"
                                    name="request[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={editable && fields && fields.request ? fields.request.no : ''}
                                    readOnly={editable && fields && fields.request && fields.request.no ? true : false}
                                />
                            </div>

                        </div>
                        <div className="row">

                            <div className="col able-to-edit">
                                <label htmlFor="request_date">
                                    Дата
                                </label>
                                {editable && original && original.request && original.request.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="request_date"
                                    name="request[date]"
                                    type="date"
                                    defaultValue={editable && fields && fields.request && fields.request.date_formatted ? fields.request.date_formatted : fieldsContext.today()}
                                    readOnly={editable && original && original.request && original.request.date ? true : false}

                                />
                            </div>
                        </div>

                    </div>
                </div>


                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Данни за фирмата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="company_name">
                                    Наименование на търговеца
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields && fields.company && fields.company.name}
                                    readOnly
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="company_eik">
                                    ЕИК / Булстат
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields && fields.company && fields.company.eik ? fields.company.eik : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Данни за обекта
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label>
                                    Наименование
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object ? `${fields.object.name}` : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>
                                    Адрес
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object
                                        ? (
                                            fields.object.town ?
                                                (
                                                    (`${fields.object.town.kind} ${fields.object.town.name} `)
                                                    + (fields.object.region ? `кв. ${fields.object.region.name} ` : '')
                                                    + (fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name} ${fields.object.street_number} ` : '')
                                                    + (fields.object.block ? `бл. ${fields.object.block} ` : '')
                                                    + (fields.object.floor ? `ет. ${fields.object.floor} ` : '')
                                                    + (fields.object.apartment ? `ап. ${fields.object.apartment} ` : '')
                                                )
                                                :
                                                ''
                                        )
                                        : ''}
                                    readOnly
                                />
                            </div>
                            <div className="col">
                                <label>
                                    Собственост
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? fields.owner.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col">
                                <label>
                                    Вид
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object.activity ? fields.object.activity.name : ''}
                                    readOnly
                                />
                            </div>

                            <div className="col">
                                <label>
                                    № на издаденото удостоверение
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.approved_certificate_no ? fields.approved_certificate_no : ''}
                                    readOnly
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default Application