import React, { createContext, useContext, useState, useMemo } from "react";

export const StepsContext = createContext({});

const Steps = props => {
    const [stepsData, setStepsData] = useState({
        modified: false,
        cancelModifi: false,
    })

    const handleModified = e => {
        let modified = e
        setStepsData(stepsData => ({
            ...stepsData,
            modified: modified
        }))
    }

    const handleCancelModifi = e => {
        let cancel = e
        setStepsData(stepsData => ({
            ...stepsData,
            cancelModifi: cancel
        }))
    }


    const steps = useMemo(() => ({ ...stepsData, handleModified, handleCancelModifi }), [stepsData]);
    return <StepsContext.Provider value={steps} {...props} />;
}

export const useStepsContext = () => useContext(StepsContext);

export default Steps;