import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import Api from '../../../helpers/Api'

//images
import protocolIcon from '../../../assets/img/pages/icons/protocol.png'
import loader from '../../../assets/img/app/loader.svg'

//partials
import Fields from './steps/letter/Fields'

//modals
import Success from '../../modals/messages/Success'
import Attention from '../../modals/messages/Attention'
import { useAuthContext } from '../../../contexts/AuthContext'

function Create(props) {
    const history = useHistory()
    const auth = useAuthContext()
    const params = useParams()

    if (!auth.canWriteRequest()) {
        props.history.push('/');
    }

    const id = params.id;
    const type = params.type;

    //refs
    const formRef = useRef(null)
    const successModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const [procedure, setProcedure] = useState({
        loading: false,
        data: []
    })

    useEffect(() => {
        loadProcedure();
    }, []);

    const loadProcedure = () => {

        let url;

        switch (type) {
            case 'tradeobject':
                url = '/procedures/tradeobjects/show/' + id;
                break;
            case 'outdoortrade':
                url = '/procedures/outdoortrade/show/' + id;
                break;
            case 'temptrade':
                url = '/procedures/temptrade/show/' + id;
                break;
            case 'categorization':
                url = '/procedures/categorization/show/' + id;
                break;
        }

        url += '?get_default_cancel_text=1';

        Api.get(url)
            .then(res => {
                setProcedure(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false,
                }))
            });
    }

    const handleSubmit = e => {
        e.preventDefault()

        setProcedure(prev => ({
            ...prev,
            loading: true,
        }))

        let form = formRef.current;

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form)

        data.append('id', Number(params.id))

        let url;

        switch (type) {
            case 'tradeobject':
                url = '/procedures/tradeobjects/cancel';
                break;
            case 'outdoortrade':
                url = '/procedures/outdoortrade/cancel';
                break;
            case 'temptrade':
                url = '/procedures/temptrade/cancel';
                break;
            case 'categorization':
                url = '/procedures/categorization/cancel';
                break;
        }

        Api.post(url, data)
            .then(res => {
                successModalRef.current.open()

                successModalRef.current.onClose(() => {
                    let url;

                    switch (type) {
                        case 'tradeobject':
                            url = '/outlets';
                            break;
                        case 'outdoortrade':
                            url = '/outlets';
                            break;
                        case 'temptrade':
                            if (res.data.type_id == 3) {
                                url = '/temporary-shops';
                            } else {
                                url = '/mobile-facilities';
                            }
                            break;
                        case 'categorization':
                            url = '/tourism';
                            break;
                    }

                    props.history.push(url + '?id=' + res.data.id);
                })

            }).catch(err => {
                if (err.response.status === 422) {

                    let fields = err.response.data.fields;

                    if (typeof fields === 'object') {
                        attentionModalRef.current.open(err.response.data.error)

                        Object.entries(fields).map(i => {
                            if (i[1] && typeof i[1] === 'object') {
                                Object.entries(i[1]).map(j => {
                                    if (j[1]) {
                                        let name = `${i[0]}[${j[0]}]`;

                                        let inputs = form.querySelectorAll("[name='" + name + "']");

                                        inputs.forEach(i => {
                                            i.classList.add('validate');
                                        });
                                    }
                                })
                            } else {
                                if (i[1]) {
                                    let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            }
                        });
                    } else {
                        errorModalRef.current.open()
                    }

                } else {
                    errorModalRef.current.open()
                }

            }).finally(() => {
                handleStopLoading()
            })
    }

    const handleStopLoading = () => {
        setProcedure(prev => ({
            ...prev,
            loading: false,
        }))
    }

    const handleCancel = e => {
        e.preventDefault();

        history.go(-1);
    }


    return (
        <section>
            <Success
                mainMessage="Писмото е запазено!"
                ref={successModalRef}
            />

            <Attention
                ref={attentionModalRef}
                mainMessage='Моля, попълнете всички полета!'
                secondaryMessage="За да запазите писмото, трябва да попълните всички полета."
            />

            <Attention
                ref={errorModalRef}
                mainMessage='Нещо се обърка!'
            />
            <div className="wrapper top negative-margin">
                <h1>
                    <img src={protocolIcon} alt="protocol" />
                    Писмо относно отказване процедура
                </h1>
            </div>
            <div className="wrapper">
                <hr />
                <div className="main-holder">
                    <form autoComplete="off" role="presentation" ref={formRef} id="application" onSubmit={handleSubmit}>
                        <Fields
                            type={type}
                            data={procedure.data}
                            original={procedure.data}
                            editable={false}
                        />
                        <hr />
                        <div className="buttons">
                            {auth.canWriteRequest()
                                ?
                                procedure.loading
                                    ?
                                    <img src={loader} alt="loading" />
                                    :
                                    <>
                                        <button
                                            onClick={handleCancel}
                                            className="btn-default"
                                        >
                                            Отказ
                                        </button>
                                        <button type="button" onClick={handleSubmit}>
                                            Запази
                                        </button>
                                    </>
                                :
                                ''
                            }
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Create