import React, { useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react'

//context
import { useFieldsContext } from '../../../../../../contexts/FieldsContext'

//partials
import FreeDaysInput from '../../../../../partials/FreeDays'
import WorkTimeInput from '../../../../../partials/WorkTime'
import Dropdown from '../../../../../app/Dropdown'
import Periods from '../../../../../partials/Periods'

//images
import editIcon from '../../../../../../assets/img/pages/icons/edit-field.jpg'
import SignPanel from '../../../../../partials/SignPanel'
import { useAuthContext } from '../../../../../../contexts/AuthContext'
import Objects from './Objects'
import WeekHours from '../../../../../modals/WeekHours'
import FreeDays from '../../../../../modals/FreeDays'

function Fields(props, ref) {
    const auth = useAuthContext()
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        object: {},
        order: {}
    }

    const [fields, setFields] = useState(fieldsData);

    const weekHoursRef = useRef(null)
    const freeDaysRef = useRef(null)

    const editable = props.editable || false
    const original = props.original

    useEffect(() => {
        if (original) {
            setFields(original)
            handleFreeDays(original.free_days)
        }
    }, [original])

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }


    const handlePriceChange = data => {
        if (data.type_id) {
            setFields(prev => ({
                ...prev,
                selected_price_type_id: data.type_id
            }))
        }

        props.handleModify();
    }

    const handleEditPrice = () => {
        document.getElementById('price-type').click();
    }

    const handleShowFreeDays = e => {
        e.preventDefault()
        freeDaysRef.current.open()
    }

    const handleFreeDays = selected => {
        setFields(prev => ({
            ...prev,
            free_days: selected,
        }))
    }

    const handleShowWorkTime = e => {
        e.preventDefault()
        weekHoursRef.current.open()
    }

    const handleWorkTime = hours => {
        setFields(prev => ({
            ...prev,
            work_time_morning: hours.work_time_morning,
            work_time_afternoon: hours.work_time_afternoon,
            work_time_afternoon_win: hours.work_time_afternoon_win,
            work_time_morning_sat: hours.work_time_morning_sat,
            work_time_afternoon_sat: hours.work_time_afternoon_sat,
            work_time_afternoon_sat_win: hours.work_time_afternoon_sat_win,
            work_time_morning_sun: hours.work_time_morning_sun,
            work_time_afternoon_sun: hours.work_time_afternoon_sun,
            work_time_afternoon_sun_win: hours.work_time_afternoon_sun_win
        }))
    }

    const handleOrderChange = data => {
        if (data.id) {
            setFields(prev => ({
                ...prev,
                order: data
            }));
        }

    }

    return (
        <>
            <WeekHours
                ref={weekHoursRef}
                work_time_morning={fields.work_time_morning}
                work_time_afternoon={fields.work_time_afternoon}
                work_time_afternoon_win={fields.work_time_afternoon_win}
                work_time_morning_sat={fields.work_time_morning_sat}
                work_time_afternoon_sat={fields.work_time_afternoon_sat}
                work_time_afternoon_sat_win={fields.work_time_afternoon_sat_win}
                work_time_morning_sun={fields.work_time_morning_sun}
                work_time_afternoon_sun={fields.work_time_afternoon_sun}
                work_time_afternoon_sun_win={fields.work_time_afternoon_sun_win}
                callback={handleWorkTime}
            />
            <FreeDays
                ref={freeDaysRef}
                selected={fields.free_days || []}
                callback={handleFreeDays}
            />
            <div className="panel">
                <div className="row">
                    <div className="col col-auto">
                        <h3>
                            <span>
                                1
                        </span>
                        Номер и Дата
                    </h3>
                        <div className="holder">

                            <div className="row">
                                <div className="col">
                                    <label>
                                        Тип заповед
                                    </label>
                                    <select
                                        name="order[type_id]"
                                        value={fields.order && fields.order.type_id ? fields.order.type_id : ''}
                                        onChange={handleFieldChange}
                                    >
                                        <option value="1">Индивидуална заповед</option>
                                        <option value="2">Обща заповед</option>
                                    </select>
                                </div>
                            </div>
                            {fields.order && fields.order.type_id == 2 &&
                                <div className="row">
                                    <div className="col">
                                        <label>Име на заповед</label>
                                        <Dropdown
                                            inputName="order[name]"
                                            inputValue={fields.order && fields.order.name}
                                            inputIdName="order[id]"
                                            inputIdValue={fields.order && fields.order.id ? fields.order.id : ''}
                                            url="procedures/temptrade/generalorders"
                                            onChange={data => handleOrderChange(data)}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="order_no">
                                        № заповед
                                </label>
                                    {editable && original && original.order && original.order.no && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="order_no"
                                        name="order[no]"
                                        type="text"
                                        placeholder="Въведете №"
                                        className="field-big"
                                        value={fields && fields.order && fields.order.no}
                                        readOnly={editable && original.order && original.order.no ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="order_date">
                                        Дата
                                </label>
                                    {editable && original && original.order && original.order.date && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="order_date"
                                        name="order[date]"
                                        type="date"
                                        className="field-big"
                                        value={fields && fields.order && fields.order.date_formatted ? fields.order.date_formatted : fieldsContext.today()}
                                        readOnly={editable && original && original.order && original.order.date}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col">
                        {/* {fields.order && fields.order.type_id == 2 &&
                            <div className="row">
                                <div className="col">
                                    <h3>
                                        <span>
                                            2
                                        </span>
                                        Данни за заповедта
                                    </h3>
                                    <div className="holder">
                                        <div className="col">
                                            <label>
                                                Име
                                            </label>
                                            <Dropdown />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } */}
                        <div className="row">
                            <div className="col">
                                <h3>
                                    <span>
                                        2
                                    </span>
                                        Данни за заявителя
                                 </h3>
                                <div className="holder">

                                    {!fields.applicant_type || fields.applicant_type === 'company'
                                        ?
                                        <div className="col">

                                            <div className="row">

                                                <div className="col able-to-edit">
                                                    <label htmlFor="company_name">
                                                        Точно наименование на фирмата
                                                    </label>
                                                    <input autoComplete="off" role="presentation"
                                                        type="text"
                                                        id="company_name"
                                                        value={fields && fields.company && fields.company.name}
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="col able-to-edit">
                                                    <label htmlFor="company_eik">
                                                        ЕИК / Булстат
                                        </label>
                                                    <input autoComplete="off" role="presentation"
                                                        id="company_eik"
                                                        type="text"
                                                        value={fields && fields.company && fields.company.eik}
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="col able-to-edit">
                                                    <label htmlFor="company_phone">
                                                        Тел. номер
                                        </label>
                                                    <input autoComplete="off" role="presentation"
                                                        id="company_phone"
                                                        type="text"
                                                        value={fields && fields.company && fields.company.phone}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">

                                                <div className="col able-to-edit">
                                                    <label htmlFor="company_town">
                                                        Населено място
                                        </label>
                                                    <input autoComplete="off" role="presentation"
                                                        id="company_phone"
                                                        type="text"
                                                        value={fields && fields.company && fields.company.town
                                                            ? fields.company.town.id
                                                                ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                                : fields.company.town.name
                                                            : ''}
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="col able-to-edit">
                                                    <label htmlFor="company_street">
                                                        Улица
                                        </label>
                                                    <input autoComplete="off" role="presentation"
                                                        id="company_phone"
                                                        type="text"
                                                        value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col col-auto able-to-edit">
                                                            <label htmlFor="company_street_number">
                                                                №
                                                </label>
                                                            <input autoComplete="off" role="presentation"
                                                                id="company_street_number"
                                                                type="text"
                                                                value={fields && fields.company && fields.company.street_number}
                                                                readOnly
                                                                min="0"
                                                                step="1"
                                                            />
                                                        </div>

                                                        <div className="col col-auto able-to-edit">
                                                            <label htmlFor="company_entrance">
                                                                Вход
                                                </label>
                                                            <input autoComplete="off" role="presentation"
                                                                id="company_entrance"
                                                                type="text"
                                                                value={fields && fields.company && fields.company.entrance}
                                                                readOnly
                                                            />
                                                        </div>

                                                        <div className="col col-auto able-to-edit">
                                                            <label htmlFor="company_block">
                                                                Блок
                                                </label>
                                                            <input autoComplete="off" role="presentation"
                                                                id="company_block"
                                                                type="text"
                                                                value={fields && fields.company && fields.company.block}
                                                                readOnly
                                                            />
                                                        </div>

                                                        <div className="col col-auto able-to-edit">
                                                            <label htmlFor="company_floor">
                                                                Етаж
                                                </label>
                                                            <input autoComplete="off" role="presentation"
                                                                id="company_floor"
                                                                type="text"
                                                                value={fields && fields.company && fields.company.floor}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="company_mol">
                                                        МОЛ
                                        </label>

                                                    <input autoComplete="off" role="presentation"
                                                        id="mol"
                                                        type="text"
                                                        value={fields && fields.company && fields.company.mol}
                                                        readOnly={true}
                                                    />
                                                </div>

                                                <div className="col">

                                                </div>

                                                <div className="col">

                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col">

                                            <div className="row">
                                                <div className="col able-to-edit">
                                                    <label htmlFor="applicant_name">
                                                        Трите имена на лицето
                                        </label>
                                                    <input autoComplete="off" role="presentation"
                                                        id="applicant_name"
                                                        type="text"
                                                        value={fields && fields.applicant && fields.applicant.name}
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="col able-to-edit">
                                                    <label htmlFor="applicant_egn">
                                                        ЕГН
                                        </label>
                                                    <input autoComplete="off" role="presentation"
                                                        id="applicant_egn"
                                                        type="text"
                                                        value={fields && fields.applicant && fields.applicant.egn}
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="col able-to-edit">
                                                    <label htmlFor="applicant_phone">
                                                        Тел. номер
                                        </label>
                                                    <input autoComplete="off" role="presentation"
                                                        id="applicant_phone"
                                                        type="text"
                                                        value={fields && fields.applicant && fields.applicant.phone}
                                                        readOnly
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col able-to-edit">
                                                    <label htmlFor="applicant_town">
                                                        Населено място
                                        </label>
                                                    <input autoComplete="off" role="presentation"
                                                        id="applicant_town"
                                                        type="text"
                                                        value={fields && fields.applicant && fields.applicant.town
                                                            ? fields.applicant.town.id
                                                                ? `${fields.applicant.town.kind} ${fields.applicant.town.name}`
                                                                : fields.applicant.town.name
                                                            : ''}
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="col able-to-edit">
                                                    <label htmlFor="applicant_street">
                                                        Улица
                                        </label>
                                                    <input autoComplete="off" role="presentation"
                                                        id="applicant_street"
                                                        type="text"
                                                        value={fields && fields.applicant && fields.applicant.street ? `${fields.applicant.street.suff} ${fields.applicant.street.name}` : ''}
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col col-auto able-to-edit">
                                                            <label htmlFor="applicant_street_number">
                                                                №
                                                </label>
                                                            <input autoComplete="off" role="presentation"
                                                                id="applicant_street_number"
                                                                type="number"
                                                                min="0"
                                                                step="1"
                                                                className="field-small"
                                                                value={fields && fields.applicant && fields.applicant.street_number}
                                                                readOnly
                                                            />
                                                        </div>

                                                        <div className="col col-auto able-to-edit">
                                                            <label htmlFor="applicant_entrance">
                                                                Вход
                                                </label>
                                                            <input autoComplete="off" role="presentation"
                                                                id="applicant_entrance"
                                                                type="number"
                                                                min="0"
                                                                step="1"
                                                                className="field-small"
                                                                value={fields && fields.applicant && fields.applicant.entrance}
                                                                readOnly
                                                            />
                                                        </div>

                                                        <div className="col col-auto able-to-edit">
                                                            <label htmlFor="applicant_block">
                                                                Блок
                                                </label>
                                                            <input autoComplete="off" role="presentation"
                                                                id="applicant_block"
                                                                type="text"
                                                                className="field-small"
                                                                value={fields && fields.applicant && fields.applicant.block}
                                                                readOnly
                                                            />
                                                        </div>

                                                        <div className="col col-auto able-to-edit">
                                                            <label htmlFor="applicant_floor">
                                                                Етаж
                                                </label>
                                                            <input autoComplete="off" role="presentation"
                                                                id="applicant_floor"
                                                                type="text"
                                                                className="field-small"
                                                                value={fields && fields.applicant && fields.applicant.floor}
                                                                readOnly
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


                <div className="row">
                    <div className="col">
                        <div className="row">
                            <div className="col">
                                <Objects
                                    procedure_id={fields.id}
                                />
                            </div>
                        </div>
                        {!fields.order || fields.order.type_id == 1
                            ?
                            <div className="row">
                                <div className="col">
                                    <SignPanel
                                        panelName="order_signature"
                                        panelNumber="5"
                                        mayor={fields && fields.order && fields.order.mayorsignature}
                                        mayorInput="order[signed_by_mayor]"
                                        agreed={fields && fields.order && fields.order.commission_text}
                                        agreedInput="order[commission]"
                                        prepared={fields && fields.order && fields.order.preparedsignature}
                                        preparedInput="order[prepared_by]"
                                        handleModify={props.handleModify}
                                    />
                                </div>
                            </div>
                            :
                            ''
                        }
                    </div>

                    <div className="col">
                        <h3>
                            <span>
                                4
                            </span>
                            Условия и детайли
                        </h3>
                        <div className="holder">

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="request_equipment_type">
                                        Разполагане на вид съоръжения
                                </label>

                                    {fields && (fields.license_text || fields.request_equipment_type) && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        id="license_text"
                                        name="license_text"
                                        value={fields && fields.license_text ? fields.license_text : fields.request_equipment_type}
                                        readOnly={fields && (fields.license_text || fields.request_equipment_type) ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col col-auto able-to-edit">
                                    <label>
                                        Зона
                                </label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        className="field-medium"
                                        value={fields && fields.street_zone && fields.street_zone.text}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="col col-auto able-to-edit">
                                    <label>
                                        Цена
                                    </label>

                                    {fields && fields.order_selected_price ?
                                        <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => {
                                                fieldsContext.handleEdit(e);
                                                handleEditPrice();
                                            }}
                                        />
                                        :
                                        ''
                                    }
                                    {fields && fields.id &&
                                        <Dropdown
                                            inputName="order_selected_price"
                                            inputIdName="order_selected_price_id"
                                            inputValue={fields.order && fields.order_selected_price ? fields.order_selected_price : ''}
                                            inputIdValue={fields.order && fields.order_selected_price_id ? fields.order_selected_price_id : ''}
                                            data={fields && fields.available_prices}
                                            renderText={(data) => {
                                                return `${data.price} лв. ${data.type}`
                                            }}
                                            renderInputText={(data) => {
                                                return data.price
                                            }}
                                            inputDisabled={fields && fields.order_selected_price ? true : false}
                                            onChange={handlePriceChange}
                                        />
                                    }

                                </div>

                                <div className="col col-auto able-to-edit">
                                    <label>
                                        Тип цена
                                </label>
                                    {fields && fields.order_selected_price ?
                                        <img
                                            src={editIcon}
                                            alt="edit"
                                            id="price-type"
                                            className="edit"
                                            onClick={e => {
                                                fieldsContext.handleEdit(e);
                                            }}
                                        />
                                        :
                                        ''
                                    }
                                    <select
                                        className="field-medium"
                                        name="order_selected_price_type_id"
                                        readOnly={fields && fields.order_selected_price ? true : false}
                                    >
                                        {fields && fields.available_prices_types && Object.entries(fields.available_prices_types).map(p =>
                                            <option
                                                value={p[0]}
                                                selected={fields && fields.order_selected_price_type_id == p[0]}
                                            >
                                                {p[1]}
                                            </option>
                                        )}
                                    </select>
                                </div>


                                <div className="col col-auto able-to-edit">
                                    <label htmlFor="area" id="license_area_size">
                                        Площ
                                </label>
                                    <div className="row extra-holder">
                                        <div className="extra-holder">
                                            <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />
                                            <input autoComplete="off" role="presentation"
                                                id="license_area_size"
                                                type="text"
                                                name="license_area_size"
                                                className="field-medium"
                                                defaultValue={fields && fields.license_area_size ? fields.license_area_size : fields.request_area_size}
                                                readOnly={true}
                                            />

                                            <input autoComplete="off" role="presentation"
                                                type="hidden"
                                            />
                                        </div>
                                        <span className="extra-label">
                                            кв.м.
                                    </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <WorkTimeInput
                                        editable={editable}
                                        work_time_morning={fields.work_time_morning}
                                        work_time_afternoon={fields.work_time_afternoon}
                                        work_time_afternoon_win={fields.work_time_afternoon_win}
                                        work_time_morning_sat={fields.work_time_morning_sat}
                                        work_time_afternoon_sat={fields.work_time_afternoon_sat}
                                        work_time_afternoon_sat_win={fields.work_time_afternoon_sat_win}
                                        work_time_morning_sun={fields.work_time_morning_sun}
                                        work_time_afternoon_sun={fields.work_time_afternoon_sun}
                                        work_time_afternoon_sun_win={fields.work_time_afternoon_sun_win}
                                        work_time_preholiday_morning={fields.work_time_preholiday_morning}
                                        work_time_preholiday_afternoon={fields.work_time_preholiday_afternoon}
                                        work_time_preholiday_afternoon_win={fields.work_time_preholiday_afternoon_win}
                                        work_time_holiday_morning={fields.work_time_holiday_morning}
                                        work_time_holiday_afternoon={fields.work_time_holiday_afternoon}
                                        work_time_holiday_afternoon_win={fields.work_time_holiday_afternoon_win}
                                        handleShowWorkTime={handleShowWorkTime}
                                    />
                                </div>

                                {/* <div className="col able-to-edit">
                                    <FreeDaysInput
                                        editable={editable}
                                        free_days={fields.free_days}
                                        handleShowFreeDays={handleShowFreeDays}
                                    />
                                </div> */}

                            </div>

                            <div className="row">
                                <Periods
                                    periods={fields && fields.license_periods && fields.license_periods.length ? fields.license_periods : fields.request_periods}
                                    inputNameFrom="license_period_from"
                                    inputNameTo="license_period_to"
                                    allowEdit={auth.canWriteDocuments()}
                                />
                            </div>

                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}

export default React.memo(forwardRef(Fields), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});