import React, { useState, forwardRef, useEffect } from 'react'

//context
import { useFieldsContext } from '../../../../../../contexts/FieldsContext'

//images
import editIcon from '../../../../../../assets/img/pages/icons/edit-field.jpg'

//partials
import FreeDaysInput from '../../../../../partials/FreeDays'
import WorkTimeInput from '../../../../../partials/WorkTime'
import Dropdown from '../../../../../app/Dropdown';
import * as Constants from '../../../../../../helpers/Constants'
import Signature from '../../../../../partials/Signature'

function Fields(props, ref) {
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        object: {},
    }

    const [fields, setFields] = useState(fieldsData);

    const editable = props.editable || false
    const original = props.original

    useEffect(() => {
        if (original) setFields(original)
    }, [original])


    return (
        <div className="panel">

            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и Дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="request_no">
                                    № разрешение
                                </label>
                                {fields && (fields.license_no || fields.generated_license_no) && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="license_no"
                                    name="license_no"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={fields && fields.license_no ? fields.license_no : fields.generated_license_no}
                                    readOnly={fields && (fields.license_no || fields.generated_license_no) ? true : false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="license_date">
                                    Дата
                                </label>
                                {fields && fields.license_date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="license_date"
                                    name="license_date"
                                    type="date"
                                    defaultValue={fields && fields.license_date_formatted ? fields.license_date_formatted : fieldsContext.today()}
                                    readOnly={fields && fields.license_date ? true : false}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col">
                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    2
                                </span>
                                Данни за заявителя
                            </h3>
                            <div className="holder">

                                {!fields.applicant_type || fields.applicant_type === 'company'
                                    ?
                                    <div className="col">

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_name">
                                                    Точно наименование на фирмата
                                        </label>
                                                <input autoComplete="off" role="presentation"
                                                    type="text"
                                                    id="company_name"
                                                    value={fields && fields.company && fields.company.name}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_eik">
                                                    ЕИК / Булстат
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_eik"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.eik}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_phone">
                                                    Тел. номер
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_phone"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.phone}
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className="row">

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_town">
                                                    Населено място
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_phone"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.town
                                                        ? fields.company.town.id
                                                            ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                            : fields.company.town.name
                                                        : ''}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="company_street">
                                                    Улица
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="company_phone"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col">
                                                <div className="row">
                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="company_street_number">
                                                            №
                                                        </label>
                                                        <input autoComplete="off" role="presentation"
                                                            id="company_street_number"
                                                            type="text"
                                                            value={fields && fields.company && fields.company.street_number}
                                                            readOnly
                                                            min="0"
                                                            step="1"
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="company_entrance">
                                                            Вход
                                                        </label>
                                                        <input autoComplete="off" role="presentation"
                                                            id="company_entrance"
                                                            type="text"
                                                            value={fields && fields.company && fields.company.entrance}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="company_block">
                                                            Блок
                                                        </label>
                                                        <input autoComplete="off" role="presentation"
                                                            id="company_block"
                                                            type="text"
                                                            value={fields && fields.company && fields.company.block}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="company_floor">
                                                            Етаж
                                                        </label>
                                                        <input autoComplete="off" role="presentation"
                                                            id="company_floor"
                                                            type="text"
                                                            value={fields && fields.company && fields.company.floor}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <label htmlFor="company_mol">
                                                    МОЛ
                                                </label>

                                                <input autoComplete="off" role="presentation"
                                                    id="mol"
                                                    type="text"
                                                    value={fields && fields.company && fields.company.mol}
                                                    readOnly={true}
                                                />
                                            </div>

                                            <div className="col">

                                            </div>

                                            <div className="col">

                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col">


                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_name">
                                                    Трите имена на лицето
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_name"
                                                    type="text"
                                                    value={fields && fields.applicant && fields.applicant.name}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_egn">
                                                    ЕГН
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_egn"
                                                    type="text"
                                                    value={fields && fields.applicant && fields.applicant.egn}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_phone">
                                                    Тел. номер
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_phone"
                                                    type="text"
                                                    value={fields && fields.applicant && fields.applicant.phone}
                                                    readOnly
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_town">
                                                    Населено място
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_town"
                                                    type="text"
                                                    value={fields && fields.applicant && fields.applicant.town
                                                        ? fields.applicant.town.id
                                                            ? `${fields.applicant.town.kind} ${fields.applicant.town.name}`
                                                            : fields.applicant.town.name
                                                        : ''}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col able-to-edit">
                                                <label htmlFor="applicant_street">
                                                    Улица
                                                </label>
                                                <input autoComplete="off" role="presentation"
                                                    id="applicant_street"
                                                    type="text"
                                                    value={fields && fields.applicant && fields.applicant.street ? `${fields.applicant.street.suff} ${fields.applicant.street.name}` : ''}
                                                    readOnly
                                                />
                                            </div>

                                            <div className="col">
                                                <div className="row">
                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="applicant_street_number">
                                                            №
                                                        </label>
                                                        <input autoComplete="off" role="presentation"
                                                            id="applicant_street_number"
                                                            type="number"
                                                            min="0"
                                                            step="1"
                                                            className="field-small"
                                                            value={fields && fields.applicant && fields.applicant.street_number}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="applicant_entrance">
                                                            Вход
                                                        </label>
                                                        <input autoComplete="off" role="presentation"
                                                            id="applicant_entrance"
                                                            type="text"
                                                            className="field-small"
                                                            value={fields && fields.applicant && fields.applicant.entrance}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="applicant_block">
                                                            Блок
                                                        </label>
                                                        <input autoComplete="off" role="presentation"
                                                            id="applicant_block"
                                                            type="text"
                                                            className="field-small"
                                                            value={fields && fields.applicant && fields.applicant.block}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className="col col-auto able-to-edit">
                                                        <label htmlFor="applicant_floor">
                                                            Етаж
                                                </label>
                                                        <input autoComplete="off" role="presentation"
                                                            id="applicant_floor"
                                                            type="text"
                                                            className="field-small"
                                                            value={fields && fields.applicant && fields.applicant.floor}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            5
                            </span>
                            Данни за обекта
                        </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_town">
                                    Населено място
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_town"
                                    type="text"
                                    value={fields && fields.object && fields.object.town
                                        ? fields.object.town.id
                                            ? `${fields.object.town.kind} ${fields.object.town.name}`
                                            : fields.object.town.name
                                        : ''}
                                    readOnly
                                />
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_region">
                                            Район/квартал
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_region"
                                            type="text"
                                            value={fields && fields.object && fields.object.region && fields.object.region.name}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_upi">
                                            УПИ
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields.object && fields.object.upi}
                                            className="field-medium"
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col able-to-edit">
                                <label htmlFor="object_street">
                                    Улица
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_street"
                                    type="text"
                                    value={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                    readOnly
                                />
                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_street_number">
                                            №
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_street_number"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.street_number}
                                            readOnly
                                            min="0"
                                            step="1"
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_entrance">
                                            Вход
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_entrance"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.entrance}
                                            readOnly
                                        />
                                    </div>


                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_block">
                                            Блок
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_block"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.block}
                                            readOnly
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_floor">
                                            Етаж
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            id="object_floor"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.object && fields.object.floor}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="object_name">
                                    Допълнително пояснение
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_name"
                                    type="text"
                                    value={fields && fields.object && fields.object.name}
                                    readOnly
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_phone">
                                    Тел. номер
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_phone"
                                    type="text"
                                    value={fields && fields.object && fields.object.phone}
                                    readOnly
                                />
                            </div>
                            <div className="col able-to-edit">
                                <label htmlFor="object_type">
                                    Вид на търговския обект
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_type"
                                    type="text"
                                    value={fields && fields.object && fields.object.type && fields.object.type.name}
                                    readOnly
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="object_activity">
                                    Предмет на дейност
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_activity"
                                    type="text"
                                    value={fields && fields.object && fields.object.activity && fields.object.activity.name}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label>
                                    Собственик
                                </label>
                                <input autoComplete="off" role="presentation"
                                    id="object_owner"
                                    type="text"
                                    value={fields && fields.object && fields.object.owner}
                                    readOnly
                                />
                            </div>
                            <div className="col able-to-edit"></div>
                            <div className="col able-to-edit"></div>
                            <div className="col able-to-edit"></div>
                        </div>


                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="license_text">
                                    Разполагане на вид съоръжения
                                        </label>
                                {fields && fields.license_text && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    id="license_text"
                                    name="license_text"
                                    value={fields && fields.license_text}
                                    readOnly={fields && fields.license_text ? true : false}
                                />
                            </div>


                            <div className="col">
                                <div className="row">

                                    <div className="col col-auto able-to-edit">
                                        <label>
                                            Зона
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            className="field-medium"
                                            value={fields && fields.street_zone && fields.street_zone.text}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label>
                                            Цена
                                        </label>

                                        <input autoComplete="off" role="presentation"
                                            className="field-medium"
                                            type="text"
                                            value={fields && fields.order_selected_price}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col col-auto">
                                        <label>Тип цена</label>
                                        <select
                                            className="field-big"
                                            name="order_selected_price_type_id"
                                            readOnly={true}
                                        >
                                            {fields && fields.available_prices_types && Object.entries(fields.available_prices_types).map(p =>
                                                <option
                                                    value={p[0]}
                                                    selected={fields && fields.order_selected_price_type_id == p[0]}
                                                >
                                                    {p[1]}
                                                </option>
                                            )}
                                        </select>
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="license_area_size">
                                            Площ
                                        </label>
                                        <div className="row extra-holder">
                                            <div className="extra-holder">

                                                <input autoComplete="off" role="presentation"
                                                    id="license_area_size"
                                                    type="text"
                                                    // name="license_area_size"
                                                    className="field-medium"
                                                    defaultValue={fields && fields.license_area_size ? fields.license_area_size : fields.request_area_size}
                                                    readOnly={true}
                                                />

                                                <input autoComplete="off" role="presentation"
                                                    type="hidden"
                                                />
                                            </div>
                                            <span className="extra-label">
                                                кв.м.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="license_place_number">
                                            Място №
                                        </label>
                                        <div className="row">
                                            {fields && fields.license_place_number && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="license_place_number"
                                                type="text"
                                                name="license_place_number"
                                                className="field-medium"
                                                defaultValue={fields && fields.license_place_number}
                                                readOnly={fields && fields.license_place_number ? true : false}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div className="row">
                            <div className="col col-auto able-to-edit">

                                {fields && fields.license_periods && fields.license_periods.map((period, i) =>
                                    <div key={'period-' + period.id} className={['row period'].join(' ')}>
                                        <div className="col">
                                            <label>
                                                Срок от
                                            </label>

                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                // name="license_period_from[]"
                                                defaultValue={period.from_formatted}
                                                readOnly={true}
                                            />
                                        </div>
                                        <div className="col">
                                            <label>
                                                Срок до
                                            </label>

                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                // name="license_period_to[]"
                                                defaultValue={period.to_formatted}
                                                readOnly={true}
                                            />
                                        </div>

                                    </div>
                                )}


                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="row">

                <div className="row">
                    <div className="col col-auto">
                        <h3>
                            <span>
                                3
                            </span>
                            Подпис
                        </h3>
                        <div className="holder">
                            <div className="row">
                                <div className="col able-to-edit">
                                    <Signature
                                        name="license_signed_by"
                                        group_id={Constants.MAYORS}
                                        selected={fields && fields.licensesignature}
                                        reason={fields && fields.license_signed_by_reason}
                                        handleModify={props.handleModify}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default forwardRef(Fields)