import React, { forwardRef, useEffect, useState } from 'react'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext';
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import Dropdown from '../../../../../../app/Dropdown';
import * as Constants from '../../../../../../../helpers/Constants'
import Signature from '../../../../../../partials/Signature';

function Fields(props) {
    const fieldsContext = useFieldsContext();

    const editable = props.editable || false;
    const original = props.original;
    const data = props.data;

    const fieldsData = {
        request: {},
        order: {},
        object: {},
        company: {},
        owner: {}
    }

    const [fields, setFields] = useState(fieldsData)

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="order_no">
                                    № заповед
                                </label>
                                {fields && fields.order && fields.order.no && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="order"
                                    name="order[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={fields && fields.order ? fields.order.no : ''}
                                    readOnly={fields && fields.order && fields.order.no ? true : false}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="order_date">
                                    Дата
                                </label>
                                {fields && fields.order && fields.order.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="order_date"
                                    name="order[date]"
                                    type="date"
                                    defaultValue={fields && fields.order && fields.order.date_formatted}
                                    readOnly={fields && fields.order && fields.order.date ? true : false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Данни за обекта
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Наименование
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object ? `${fields.object.type ? fields.object.type.name : ''} ${fields.object.name}` : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>
                                    Адрес
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object
                                        ? (
                                            fields.object.town ?
                                                (
                                                    (`${fields.object.town.kind} ${fields.object.town.name} `)
                                                    + (fields.object.region ? `кв. ${fields.object.region.name} ` : '')
                                                    + (fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name} ${fields.object.street_number} ` : '')
                                                    + (fields.object.block ? `бл. ${fields.object.block} ` : '')
                                                    + (fields.object.floor ? `ет. ${fields.object.floor} ` : '')
                                                    + (fields.object.apartment ? `ап. ${fields.object.apartment} ` : '')
                                                )
                                                :
                                                ''
                                        )
                                        : ''}
                                    readOnly
                                />
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Данни на собственика
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Име
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? fields.owner.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Населено място
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? (fields.owner.town ? `${fields.owner.town.kind} ${fields.owner.town.name}` : '') : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Улица/Булевард
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? (fields.owner.street ? `${fields.owner.street.suff} ${fields.owner.street.name} ${fields.owner.street_number}` : '') : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Булстат/ЕИК/ЕГН
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? (fields.request.owner_type === 'company' ? fields.owner.eik : fields.owner.egn) : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            4
                        </span>
                        Данни на стопанина
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Име
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.worker ? fields.worker.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Населено място
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.worker ? (fields.worker.town ? `${fields.worker.town.kind} ${fields.worker.town.name}` : '') : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Улица/Булевард
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.worker ? (fields.worker.street ? `${fields.worker.street.suff} ${fields.worker.street.name} ${fields.worker.street_number}` : '') : ''}
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <label>
                                    Булстат/ЕИК/ЕГН
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.worker ? (fields.request.worker_type === 'company' ? fields.worker.eik : fields.worker.egn) : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">


                <div className="col col-auto">
                    <h3>
                        <span>
                            5
                        </span>
                       Подпис
                    </h3>
                    <div className="holder" name="order[signed]">
                        <div className="col able-to-edit">

                            <Signature
                                name="order[signed_by]"
                                group_id={Constants.MAYORS}
                                selected={fields && fields.order && fields.order.signature}
                                reason={fields && fields.order && fields.order.signed_by_reason}
                                handleModify={props.handleModify}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default React.memo(Fields, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
});