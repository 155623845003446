import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'

//context
import { useStepsContext } from '../../../../../../contexts/StepsContext'

//partials
import FilesPanel from '../../../../../partials/FilesPanel'
import Application from '../../partials/shared/Application'
import { Texts } from '../../../../../../helpers/Texts'

//images
import protocolIcon from '../../../../../../assets/img/pages/icons/protocol.png'
import { useHistory, Link } from 'react-router-dom'
import { useFieldsContext } from '../../../../../../contexts/FieldsContext'

import loader from '../../../../../../assets/img/app/loader.svg'

//modals
import SuccessModal from '../../../../../modals/messages/Success'
import Attention from '../../../../../modals/messages/Attention'
import Question from '../../../../../modals/messages/Question'
import Api from '../../../../../../helpers/Api'
import { useAuthContext } from '../../../../../../contexts/AuthContext'

function Index(props) {
    const history = useHistory()
    const auth = useAuthContext()

    //refs
    const appRef = useRef(null)
    const companyValidationRef = useRef(null)
    const SuccessModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const data = props.data
    const original = props.original
    const setData = props.setData
    const setOriginal = props.setOriginal
    const loading = props.loading
    const editable = props.editable || false

    const fieldsContext = useFieldsContext()

    const initialState = {
        validated: false,
        rewriteCompany: true,
        validateTabs: {
            applicant: false,
            object: false,
            employees: false,
            others: false,
            documents: false,
        },

    }

    const [application, setApplication] = useState({
        position: 1,
        finished: false,
        loading: false,
        fields: initialState,
        modified: false,
        currentTab: 1,
    })

    useEffect(() => {
        if (application.fields.validated) {
            setTimeout(() => {
                submitData()
            }, 100)
        }
    }, [application.fields.validated])

    useEffect(() => {
        let form = document.querySelector('.main-holder form');

        if (form) {
            form.addEventListener('change', fieldsListener);
        }

        return () => {
            if (form) {
                form.removeEventListener('change', fieldsListener)
            }
        }

    }, [])

    useLayoutEffect(() => {
        if (!auth.canWriteRequest()) {
            let form = document.querySelector('.main-holder form');

            if (form) {
                setTimeout(() => {
                    form.querySelectorAll('input, select').forEach(i => {
                        i.setAttribute('readonly', true);
                    })
                    form.querySelectorAll('img.edit').forEach(i => {
                        i.style.display = 'none';
                    })
                    form.querySelectorAll('.MuiSelect-root, label').forEach(i => {
                        i.classList.add('disabled');
                    })
                })
            }

        }
    }, [])


    const fieldsListener = () => {
        if (!application.modified) {
            setApplication(prev => ({
                ...prev,
                modified: true
            }))
        }
    }

    const handleConfig = e => {
        e.preventDefault()

        if (application.currentTab != 5) {
            setApplication(application => ({
                ...application,
                currentTab: application.currentTab != 5 ? Number(application.currentTab) + 1 : application.currentTab
            }))
            return
        }

        let form = document.getElementById('application');

        setApplication(application => ({ ...application, loading: true }))

        let companyData = fieldsContext.simplifySerializedInputs(fieldsContext.serializeInputs(form, 'company'), 'company')

        // валидация за фирмата
        fieldsContext.validateCompany(companyData)
            .then(res => {
                setApplication(application => ({
                    ...application,
                    fields: {
                        ...application.fields,
                        validated: true
                    }
                }))
            })
            .catch(res => {
                // да се покаже попъп
                companyValidationRef.current.open()

                // да се изпълни при клик на Отказ бутон
                companyValidationRef.current.onCancel(() => {
                    if (res.company) appRef.current.setCompany(res.company)

                    setApplication(application => ({
                        ...application,
                        fields: {
                            ...application.fields,
                            rewriteCompany: false
                        }
                    }))
                })

                // да се изпълни при затваряне
                companyValidationRef.current.onClose(() => {
                    setApplication(application => ({
                        ...application,
                        fields: {
                            ...application.fields,
                            validated: true
                        }
                    }))
                })

            })
    }

    const submitData = () => {
        let form = document.getElementById('application');

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form)

        // id на процедурата
        data.append('id', props.data.id)

        // тип на процедурата
        data.append('categorization_type_id', original.categorization_type_id)

        if (!application.fields.rewriteCompany) data.append('company[readonly]', true)
        setApplication(application => ({
            ...application,
            fields: initialState
        }))
        Api.post('procedures/categorization/addedit', data)
            .then(res => {
                SuccessModalRef.current.open()

                if (res.data.id) {
                    // setOriginal(res.data)
                    props.refreshData();
                    history.push('?_=' + new Date().getTime())
                }

            })
            .catch(err => {
                if (err.response.status === 422) {
                    attentionModalRef.current.open(err.response.data.error)

                    let fields = err.response.data.fields;

                    if (typeof fields === 'object') {

                        if (fields.owner || fields.worker) {
                            setApplication(prev => ({
                                ...prev,
                                validateTabs: {
                                    ...prev.validateTabs,
                                    object: false,
                                    applicant: false,
                                    others: true,
                                },
                                currentTab: 1
                            }))
                        }

                        if (
                            fields.object.activity ||
                            fields.object.activity_id ||
                            fields.object.municipality ||
                            fields.object.street ||
                            fields.object.street_number ||
                            fields.object.town ||
                            fields.object.upi
                        ) {
                            setApplication(prev => ({
                                ...prev,
                                validateTabs: {
                                    ...prev.validateTabs,
                                    object: true,
                                    applicant: false,
                                    others: false,
                                },
                                currentTab: 2
                            }))
                        }

                        if (
                            fields.company.eik ||
                            fields.company.municipality ||
                            fields.company.name ||
                            fields.company.street ||
                            fields.company.street_number ||
                            fields.company.town ||
                            fields['request[date]'] ||
                            fields['request[no]']

                        ) {
                            setApplication(prev => ({
                                ...prev,
                                validateTabs: {
                                    ...prev.validateTabs,
                                    applicant: true,
                                    object: false,
                                    others: false,
                                },
                                currentTab: 4
                            }))
                        }

                        Object.entries(fields).map(i => {
                            if (i[1] && typeof i[1] === 'object') {
                                Object.entries(i[1]).map(j => {
                                    if (j[1]) {
                                        let name = `${i[0]}[${j[0]}]`;

                                        let inputs = form.querySelectorAll("[name='" + name + "']");

                                        inputs.forEach(i => {
                                            i.classList.add('validate');
                                        });
                                    }
                                })
                            } else {
                                if (i[1]) {
                                    let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            }
                        });
                    }
                } else {
                    errorModalRef.current.open()
                }
            }).finally(() => {
                handleCancelModify()
            })
    }

    const handleCancelModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: false,
            loading: false
        }))
    }

    const handleCurrentTab = tab => {
        setApplication(prev => ({
            ...prev,
            currentTab: tab
        }))
    }

    return (
        !loading && original && original.id &&
        <>
            <Question
                ref={companyValidationRef}
                secondaryMessage={Texts.companyValidation}
                cancelButtonText="Не"
                continueButtonText="Да"
            />

            <SuccessModal
                ref={SuccessModalRef}
                mainMessage='Заявлението беше запазено успешно!'
                secondaryMessage='Моля, попълнете всички полета, за да продължите към Протокол.'
                canGenerate={data.can_generate_application}
            />

            <Attention
                ref={attentionModalRef}
                mainMessage='Моля, попълнете всички полета!'
                secondaryMessage="За да запазите заявлението, трябва да попълните всички полета."
            />

            <Attention
                ref={errorModalRef}
                mainMessage='Нещо се обърка!'
            />
            <h1 className="config-heading">
                <img src={protocolIcon} alt="protocol" />
                Заявление - места за настаняване
            </h1>
            <hr />
            <div className="main-holder">
                <form autoComplete="off" role="presentation" id="application" onSubmit={handleConfig}>
                    <Application
                        ref={appRef}
                        data={data}
                        original={original}
                        editable={editable}
                        setData={setData}
                        validateTabs={application.validateTabs}
                        currentTab={handleCurrentTab}
                        changeTab={application.currentTab}
                    />
                    <hr />
                    <div className="buttons">
                        {auth.canWriteRequest()
                            ?
                            application.loading
                                ?
                                <img src={loader} alt="loading" />
                                :
                                application.modified ?
                                    <>
                                        <Link
                                            to={"/tourism/config/hotel/" + props.original.id + '?_=' + new Date().getTime()}
                                            className="btn-default"
                                            onClick={handleCancelModify}
                                        >
                                            Отказ
                                            </Link>
                                        <button type="button" onClick={handleConfig}>
                                            {application.currentTab == 5 ?
                                                'Запазване'
                                                :
                                                'Напред'
                                            }

                                        </button>
                                    </>
                                    :
                                    ''
                            :
                            ''
                        }
                    </div>
                </form>
                <FilesPanel
                    id={original.procedure_id}
                    generateFile={data.can_generate_application}
                    type="application"
                    allowEdit={auth.canWriteRequest()}
                    uploadedDocument={data.uploaded_signed_application}
                    refreshData={props.refreshData}
                />
            </div>
        </>
    )
}

export default Index