import React, { useEffect } from 'react'
import Dropdown from '../../../app/Dropdown'

let timeout;

function Filter(props) {

    const filterData = props.filterData;
    const setState = props.setState;

    return (
        <form autoComplete="off" role="presentation" className="filter-standard">

            <div className="col">
                <span className="extra-label">
                    От
                </span>
                <input
                    autoComplete="off" role="presentation"
                    type="date"
                    defaultValue={props.filter.start_date}
                    onChange={e => filterData(e, 'start_date', e.target.value)}
                    style={{ marginRight: 0 }}
                />
            </div>
            <div className="col">
                <span className="extra-label">
                    До
                </span>
                <input
                    autoComplete="off" role="presentation"
                    type="date"
                    defaultValue={props.filter.end_date}
                    onChange={e => filterData(e, 'end_date', e.target.value)}
                    style={{ marginRight: 0 }}
                />
            </div>

            <input autoComplete="off" role="presentation"
                type="search"
                className="field-medium"
                defaultValue={props.filter.street}
                placeholder="УПИ"
                onChange={e => filterData(e, 'upi', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'upi', e.target.value, 300)}
            />

            <input autoComplete="off" role="presentation"
                type="search"
                className="field-big"
                defaultValue={props.filter.street}
                placeholder="Квартал"
                onChange={e => filterData(e, 'region', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'region', e.target.value, 300)}
            />

            <input autoComplete="off" role="presentation"
                type="search"
                className="field-big"
                defaultValue={props.filter.street}
                placeholder="Улица"
                onChange={e => filterData(e, 'street', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'street', e.target.value, 300)}
            />

            <input autoComplete="off" role="presentation"
                type="search"
                className="field-big"
                defaultValue={props.filter.company}
                placeholder="Фирма"
                onChange={e => filterData(e, 'company', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'company', e.target.value, 300)}
            />

            {/* <div className="col">
               
                <select
                    onChange={e => filterData(e, 'status', e.target.value, 0)}
                >
                    <option
                        value=""
                        selected={props.filter.status === ''}
                    >
                        Всички
                </option>
                    <option
                        value="1"
                        selected={props.filter.status === '1'}
                    >
                        Активни
                </option>
                    <option
                        value="0"
                        selected={props.filter.status === '0'}
                    >
                        Неактивни
                </option>
                </select>
            </div> */}

        </form>
    )
}

export default Filter