import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'

function WeekHours(props, ref) {
    const [state, setState] = useState({
        overlay: false,
        modal: false,
        work_time_morning: '',
        work_time_afternoon: '',
        work_time_afternoon_win: '',
        work_time_morning_sat: '',
        work_time_afternoon_sat: '',
        work_time_afternoon_sat_win: '',
        work_time_morning_sun: '',
        work_time_afternoon_sun: '',
        work_time_afternoon_sun_win: '',
        work_time_preholiday_morning: '',
        work_time_preholiday_afternoon: '',
        work_time_preholiday_afternoon_win: '',
        work_time_holiday_morning: '',
        work_time_holiday_afternoon: '',
        work_time_holiday_afternoon_win: '',
        modified: false,
    });

    const [hasWinterPeriod, setHasWinterPeriod] = useState(false);

    useEffect(() => {
        setState(state => ({
            ...state,
            work_time_morning: props.work_time_morning,
            work_time_afternoon: props.work_time_afternoon,
            work_time_afternoon_win: props.work_time_afternoon_win,
            work_time_morning_sat: props.work_time_morning_sat,
            work_time_afternoon_sat: props.work_time_afternoon_sat,
            work_time_afternoon_sat_win: props.work_time_afternoon_sat_win,
            work_time_morning_sun: props.work_time_morning_sun,
            work_time_afternoon_sun: props.work_time_afternoon_sun,
            work_time_afternoon_sun_win: props.work_time_afternoon_sun_win,
            work_time_preholiday_morning: props.work_time_preholiday_morning,
            work_time_preholiday_afternoon: props.work_time_preholiday_afternoon,
            work_time_preholiday_afternoon_win: props.work_time_preholiday_afternoon_win,
            work_time_holiday_morning: props.work_time_holiday_morning,
            work_time_holiday_afternoon: props.work_time_holiday_afternoon,
            work_time_holiday_afternoon_win: props.work_time_holiday_afternoon_win,
        }))
    }, [props]);

    useEffect(() => {
        let winter = false;

        let wta = state.work_time_afternoon;
        let wtaSat = state.work_time_afternoon_sat;
        let wtaSun = state.work_time_afternoon_sun;
        let wtaWin = state.work_time_afternoon_win;
        let wtaSatWin = state.work_time_afternoon_sat_win;
        let wtaSunWin = state.work_time_afternoon_sun_win;
        let wtpa = state.work_time_preholiday_afternoon;
        let wtpaWin = state.work_time_preholiday_afternoon_win;
        let wtha = state.work_time_holiday_afternoon;
        let wthaWin = state.work_time_holiday_afternoon_win;

        let hours = [];
        let hour;
        let min;

        if (wta) {
            hour = wta.split(':')[0];
            min = wta.split(':')[1];

            if (!wtaWin) {
                wtaWin = (hour - 1) + ':' + min;
            }

            if (hour) {
                hours.push(hour);
            }
        }

        if (wtaSat) {
            hour = wtaSat.split(':')[0];
            min = wtaSat.split(':')[1];

            if (!wtaSatWin) {
                wtaSatWin = (hour - 1) + ':' + min;
            }

            if (hour) {
                hours.push(hour);
            }
        }

        if (wtaSun) {
            hour = wtaSun.split(':')[0];
            min = wtaSun.split(':')[1];

            if (!wtaSunWin) {
                wtaSunWin = (hour - 1) + ':' + min;
            }

            if (hour) {
                hours.push(hour);
            }
        }

        if (wtpa) {
            hour = wtpa.split(':')[0];
            min = wtpa.split(':')[1];

            if (!wtpaWin) {
                wtpaWin = (hour - 1) + ':' + min;
            }

            if (hour) {
                hours.push(hour);
            }
        }


        if (wtha) {
            hour = wtha.split(':')[0];
            min = wtha.split(':')[1];

            if (!wthaWin) {
                wthaWin = (hour - 1) + ':' + min;
            }

            if (hour) {
                hours.push(hour);
            }
        }

        for (let i = 0; i < hours.length; i++) {
            let h = hours[i];

            if (h >= 23 || h === '00') {
                winter = true;
                break;
            }
        }

        setHasWinterPeriod(winter);

        setState(prev => ({
            ...prev,
            work_time_afternoon_win: wtaWin,
            work_time_afternoon_sat_win: wtaSatWin,
            work_time_afternoon_sun_win: wtaSunWin,
            work_time_preholiday_afternoon_win: wtpaWin,
            work_time_holiday_afternoon_win: wthaWin
        }));


    }, [
        state.work_time_afternoon,
        state.work_time_afternoon_sat,
        state.work_time_afternoon_sun,
        state.work_time_preholiday_afternoon,
        state.work_time_holiday_afternoon
    ]);


    useEffect(() => {
        if (!hasWinterPeriod) {

            let data = state;
            data.work_time_afternoon_win = null;
            data.work_time_afternoon_sat_win = null;
            data.work_time_afternoon_sun_win = null;
            data.work_time_preholiday_afternoon_win = null;
            data.work_time_holiday_afternoon_win = null;

            setState(data);

            if (typeof props.callback === 'function') {
                props.callback(data);
            }
        }
    }, [hasWinterPeriod]);

    useImperativeHandle(ref, () => ({
        open: () => {
            setState(state => ({
                ...state,
                overlay: true,
            }))
            setTimeout(() => {
                setState(state => ({
                    ...state,
                    modal: true,
                }))
            }, 50)
        },
        close: () => {
            handleClose()
        }
    }))

    const handleClose = e => {
        e.preventDefault()

        setState(state => ({
            ...state,
            modal: false,
        }))
        setTimeout(() => {
            setState(state => ({
                ...state,
                overlay: false,
                modified: false,
            }))
        }, 100)
    }

    const handleChange = e => {
        let name = e.target.name;
        let val = e.target.value;

        let data = state;

        data[name] = val;

        setState(prev => ({
            ...prev,
            data,
            modified: true,
        }));

        if (typeof props.callback === 'function') {
            props.callback(data);
        }

    }

    const holderStyle = {
        width: '100%',
        justifyContent: 'center'
    };

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <div className={`${state.modal ? 'show' : ''} form-box box-large`}>
                <h2>
                    Въведете работно време
               </h2>
                <p>
                    Работното време на текущия обект
                </p>
                <hr />
                <div className="row center">
                    <div className="col center full-width">
                        <label htmlFor="work-days">
                            Делнични дни
                        </label>
                        <div className="extra-holder" style={holderStyle}>
                            <span className="extra-label">
                                От
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                id="work-days"
                                name="work_time_morning"
                                value={state.work_time_morning}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />
                            <span className="extra-label">
                                {hasWinterPeriod ? 'Летен период до' : 'До'}
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                name="work_time_afternoon"
                                value={state.work_time_afternoon}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />

                            {hasWinterPeriod &&
                                <>
                                    <span className="extra-label">
                                        Зимен период до
                                    </span>
                                    <input autoComplete="off" role="presentation"
                                        type="time"
                                        name="work_time_afternoon_win"
                                        value={state.work_time_afternoon_win}
                                        className="field-medium"
                                        onChange={handleChange}
                                        onKeyUp={handleChange}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row center">
                    <div className="col center full-width">
                        <label htmlFor="saturday">
                            Събота
                        </label>
                        <div className="extra-holder" style={holderStyle}>
                            <span className="extra-label">
                                От
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                id="saturday"
                                name="work_time_morning_sat"
                                value={state.work_time_morning_sat}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />

                            <span className="extra-label">
                                {hasWinterPeriod ? 'Летен период до' : 'До'}
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                name="work_time_afternoon_sat"
                                value={state.work_time_afternoon_sat}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />

                            {hasWinterPeriod &&
                                <>
                                    <span className="extra-label">
                                        Зимен период до
                                    </span>
                                    <input autoComplete="off" role="presentation"
                                        type="time"
                                        name="work_time_afternoon_sat_win"
                                        value={state.work_time_afternoon_sat_win}
                                        className="field-medium"
                                        onChange={handleChange}
                                        onKeyUp={handleChange}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row center">
                    <div className="col center full-width">
                        <label htmlFor="sunday">
                            Неделя
                        </label>
                        <div className="extra-holder" style={holderStyle}>
                            <span className="extra-label">
                                От
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                id="sunday"
                                name="work_time_morning_sun"
                                value={state.work_time_morning_sun}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />

                            <span className="extra-label">
                                {hasWinterPeriod ? 'Летен период до' : 'До'}
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                name="work_time_afternoon_sun"
                                value={state.work_time_afternoon_sun}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />

                            {hasWinterPeriod &&
                                <>
                                    <span className="extra-label">
                                        Зимен период до
                                    </span>
                                    <input autoComplete="off" role="presentation"
                                        type="time"
                                        name="work_time_afternoon_sun_win"
                                        value={state.work_time_afternoon_sun_win}
                                        className="field-medium"
                                        onChange={handleChange}
                                        onKeyUp={handleChange}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row center">
                    <div className="col center full-width">
                        <label htmlFor="work-days">
                            Предпразнични дни
                        </label>
                        <div className="extra-holder" style={holderStyle}>
                            <span className="extra-label">
                                От
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                id="work-days"
                                name="work_time_preholiday_morning"
                                value={state.work_time_preholiday_morning}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />
                            <span className="extra-label">
                                {hasWinterPeriod ? 'Летен период до' : 'До'}
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                name="work_time_preholiday_afternoon"
                                value={state.work_time_preholiday_afternoon}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />

                            {hasWinterPeriod &&
                                <>
                                    <span className="extra-label">
                                        Зимен период до
                                    </span>
                                    <input autoComplete="off" role="presentation"
                                        type="time"
                                        name="work_time_preholiday_afternoon_win"
                                        value={state.work_time_preholiday_afternoon_win}
                                        className="field-medium"
                                        onChange={handleChange}
                                        onKeyUp={handleChange}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="row center">
                    <div className="col center full-width">
                        <label htmlFor="work-days">
                            Празнични дни
                        </label>
                        <div className="extra-holder" style={holderStyle}>
                            <span className="extra-label">
                                От
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                id="work-days"
                                name="work_time_holiday_morning"
                                value={state.work_time_holiday_morning}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />
                            <span className="extra-label">
                                {hasWinterPeriod ? 'Летен период до' : 'До'}
                            </span>
                            <input autoComplete="off" role="presentation"
                                type="time"
                                name="work_time_holiday_afternoon"
                                value={state.work_time_holiday_afternoon}
                                className="field-medium"
                                onChange={handleChange}
                                onKeyUp={handleChange}
                            />

                            {hasWinterPeriod &&
                                <>
                                    <span className="extra-label">
                                        Зимен период до
                                    </span>
                                    <input autoComplete="off" role="presentation"
                                        type="time"
                                        name="work_time_holiday_afternoon_win"
                                        value={state.work_time_holiday_afternoon_win}
                                        className="field-medium"
                                        onChange={handleChange}
                                        onKeyUp={handleChange}
                                    />
                                </>
                            }
                        </div>
                    </div>
                </div>

                <hr />
                <div className="buttons">
                    <button className={`btn-default ${state.modified ? 'btn-primary' : ''}`} onClick={handleClose}>
                        {state.modified ?
                            'Готово'
                            :
                            'Затвори'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(WeekHours)