import React, { useState, useEffect } from 'react'

function Steps(props) {
    let currentStep = props.getCurrent

    const [steps, setSteps] = useState({
        current: 1,
    })

    useEffect(() => {
        setSteps(steps => ({
            ...steps,
            current: currentStep,
        }))
    }, [currentStep])

    const handlePosition = e => {
        let position = e.target.getAttribute('data-position')
        setSteps(steps => ({
            ...steps,
            current: position,
        }))
        props.handleCurrent(position)
    }

    return (
        <div className="scroller">
            <ul className={`steps ${!props.isMunicProperty ? 'x3' : ''}`}>
                <li
                    data-position="1"
                    className={[
                        props.firstStepFinish ? 'finished' : 'current',
                        currentStep == 1 ? 'active' : '',
                        props.firstStepFinish && !props.secondStepFinish ? 'current-is-next' : ''
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Заявление
                    </span>
                </li>
                <li
                    data-position="2"
                    className={[
                        props.secondStepFinish ? 'finished' : '',
                        currentStep == 2 && props.firstStepFinish ? 'active' : '',
                        props.firstStepFinish && !props.secondStepFinish ? 'current' : '',
                        props.secondStepFinish && !props.thirdStepFinish ? 'current-is-next' : ''
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Констативен протокол
                    </span>
                </li>
                <li
                    data-position="3"
                    className={[
                        props.thirdStepFinish ? 'finished' : '',
                        currentStep == 3 && props.secondStepFinish ? 'active' : '',
                        props.secondStepFinish && !props.thirdStepFinish ? 'current' : '',
                        props.thirdStepFinish && !props.fourthStepFinish ? 'current-is-next' : ''
                    ].join(' ')}
                    onClick={handlePosition}
                >
                    <span>
                        Разрешение
                    </span>
                </li>
                {props.isMunicProperty &&
                    <>
                        <li
                            data-position="4"
                            className={[
                                props.fourthStepFinish ? 'finished' : '',
                                currentStep == 4 && props.thirdStepFinish ? 'active' : '',
                                props.thirdStepFinish && !props.fourthStepFinish ? 'current' : '',
                            ].join(' ')}
                            onClick={handlePosition}
                        >
                            <span>
                                Декларация
                            </span>
                        </li>
                        <li
                            data-position="5"
                            className={[
                                props.fifthStepFinish ? 'finished' : '',
                                currentStep == 5 && props.fourthStepFinish ? 'active' : '',
                                props.fourthStepFinish && !props.fifthStepFinish ? 'current' : '',
                            ].join(' ')}
                            onClick={handlePosition}
                        >
                            <span>
                                Договор
                            </span>
                        </li>
                    </>
                }
            </ul>
        </div>
    )
}

export default Steps