import React, { useState, useRef, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Api from '../../../helpers/Api'

//partials
import Letter from './steps/letter/Index'

//modals
import { useAuthContext } from '../../../contexts/AuthContext'

function Edit(props) {
    const params = useParams()
    const auth = useAuthContext()

    const id = params.id;
    const type = params.type;

    const [config, setConfig] = useState({
        step: 1,
        firstStepFinish: false,
        secondStepFinish: false,
    })

    const [procedure, setProcedure] = useState({
        data: null,
        original: null,
        loading: true,
        refresh: false,
        initial: true,
    })

    useEffect(() => {
        loadProcedure()
    }, [procedure.refresh])

    const handleCurrent = current => {
        let currentStep
        currentStep = current

        setConfig(config => ({
            ...config,
            step: currentStep,
        }))
    }

    //steps finish
    const handleFinish = (position, isFinished) => {
        if (position == 1) {
            setConfig(config => ({
                ...config,
                firstStepFinish: true,
            }))
        } else if (position == 2) {
            setConfig(config => ({
                ...config,
                firstStepFinish: true,
                secondStepFinish: true,
            }))
        }
    }

    const loadProcedure = () => {

        let url;

        switch (type) {
            case 'tradeobject':
                url = '/procedures/tradeobjects/show/' + id;
                break;
            case 'outdoortrade':
                url = '/procedures/outdoortrade/show/' + id;
                break;
            case 'temptrade':
                url = '/procedures/temptrade/show/' + id;
                break;
            case 'categorization':
                url = '/procedures/categorization/show/' + id;
                break;
        }

        url += '?get_default_cancel_text=1';

        Api.get(url)
            .then(res => {

                if (procedure.initial) {
                    setConfig(config => ({
                        ...config,
                        step: res.data.actual_phase_id == 0 ? '1' : res.data.actual_phase_id
                    }))
                }

                if (res.data.actual_phase_id > 1) {
                    handleFinish(1, true)
                }

                setProcedure(prev => ({
                    ...prev,
                    data: res.data,
                    original: res.data,
                    loading: false,
                    initial: false
                }))
            }).catch(() => {
                // props.history.push('/outlets?current=1&page=1')
            })
    }

    const setData = data => {
        setProcedure(prev => ({
            ...prev,
            data: data
        }))
    }

    const setOriginal = data => {
        setProcedure(prev => ({
            ...prev,
            data: data,
            original: data
        }))

        if (data.actual_phase_id > 1) {
            handleFinish(1, true)
        }


    }

    const refreshData = () => {
        setProcedure(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }))
    }

    return (
        <section id="outlets-config">
            {!procedure.loading &&
                <div className="wrapper top negative-margin">

                    <Letter
                        loading={procedure.loading}
                        data={procedure.data}
                        original={procedure.original}
                        editable={true}
                        setData={setData}
                        setOriginal={setOriginal}
                        refreshData={refreshData}
                        handleCurrent={handleCurrent}
                        handleFinish={handleFinish}
                    />

                </div>
            }
        </section>
    )
}

export default Edit