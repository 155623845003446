import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import Api from '../../../helpers/Api'


//partials
import Table from './partials/Table'
import Filter from './partials/Filter'
import Pagination from '../../partials/Pagination'

//styles
import '../../../assets/css/outlets.css'

//images
import loader from '../../../assets/img/app/loader.svg'
import { useAuthContext } from '../../../contexts/AuthContext'

let timeout

function Index(props) {
    const location = useLocation()
    const history = useHistory()
    const auth = useAuthContext()

    const searchParams = new URLSearchParams(location.search)

    const tabGroup = searchParams.get('current')
        ? 'current'
        : searchParams.get('expiring')
            ? 'expiring'
            : searchParams.get('objects')
                ? 'objects'
                : searchParams.get('closed')
                    ? 'closed'
                    : searchParams.get('canceled')
                        ? 'canceled'
                        : searchParams.get('companies')
                            ? 'companies'
                            : searchParams.get('owners')
                                ? 'owners'
                                : ''

    if (tabGroup === '') {
        if (auth.canViewExpiringLicenses()) {
            history.replace('/temporary-shops?expiring=1&page=1')
        } else {
            history.replace('/temporary-shops?current=1&page=1')
        }
    }

    const [data, setData] = useState({
        items: [],
        pages: 1,
        page: searchParams.get('page') || 1,
        total: 0,
        count: 0,
        procedures: 0,
        loading: true,
        refresh: false,
        filter: {
            id: searchParams.get('id') || '',
            requestNo: searchParams.get('requestNo') || '',
            licenseNo: searchParams.get('licenseNo') || '',
            applicant: searchParams.get('applicant') || '',
            street: searchParams.get('street') || '',
            startDate: searchParams.get('startDate') || '',
            endDate: searchParams.get('endDate') || '',
            sort: searchParams.get('sort') || ''
        }
    })

    useEffect(() => {
        loadData()

        let url = location.pathname + '?' + tabGroup + '=1'

        Object.entries(data.filter).forEach(p => {
            if (p[1]) url += '&' + (p[0] + '=' + p[1])
        })

        url += '&page=' + data.page

        history.replace(url)

    }, [tabGroup, data.filter, data.refresh, data.page])

    useEffect(() => {
        return clearTimeout(timeout)
    }, [])

    const loadData = () => {

        if (!tabGroup) {
            return;
        }

        setData(prev => ({
            ...prev,
            loading: true
        }))

        let url = 'procedures/temptrade?temptrade_type_id=3'
            + '&group=' + tabGroup
            + '&requestNo=' + data.filter.requestNo
            + '&licenseNo=' + data.filter.licenseNo
            + '&applicant=' + data.filter.applicant
            + '&street=' + data.filter.street
            + '&startDate=' + data.filter.startDate
            + '&endDate=' + data.filter.endDate
            + '&sort=' + data.filter.sort
            + '&page=' + data.page

        Api.get(url)
            .then(res => {
                setData(prev => ({
                    ...prev,
                    items: res.data.items,
                    pages: res.data.pages,
                    total: res.data.total,
                    count: res.data.count,
                    loading: false
                }))
            })
    }

    const refreshData = () => {
        setData(prev => ({
            ...prev,
            refresh: new Date().getTime(),
            loading: true,
            page: 1,
            total: 0,
            count: 0,
            procedures: 0,
            items: []
        }))
    }

    const filterData = (e, name, val, timeoutDuration = 0) => {
        e.preventDefault()

        clearTimeout(timeout)

        timeout = setTimeout(() => {
            setData(prev => ({
                ...prev,
                page: 1,
                filter: {
                    ...prev.filter,
                    [name]: val
                }
            }))
        }, timeoutDuration)

    }

    const handlePage = page => setData(prev => ({ ...prev, page: page.selected + 1 }))

    return (
        <section id="outlets">
            <div className="wrapper top row">
                <h1>
                    {tabGroup === 'current' ?
                        `Текущи процедури (${data.count})`
                        :
                        tabGroup === 'expiring' ?
                            `Изтичащи разрешения (${data.count})`
                            :
                            tabGroup === 'objects' ?
                                `Активни процедури (${data.count})`
                                :
                                tabGroup === 'canceled' ?
                                    `С отказана процедура (${data.count})`
                                    :
                                    tabGroup === 'closed' ?
                                        `Неактивни (${data.count})`
                                        :
                                        tabGroup === 'companies' ?
                                            `Заявления по фирми (${data.count})`
                                            :
                                            tabGroup === 'owners' ?
                                                `Заявления по собственици (${data.count})`
                                                :
                                                ''
                    }
                </h1>
                {auth.canWriteRequest() &&
                    <Link to="/temporary-shops/create" className="btn-add">
                        Ново заявление
                    </Link>
                }
            </div>
            <div className="wrapper">
                <div className="row">
                    <Filter
                        filter={data.filter}
                        filterData={filterData}
                    />
                </div>
                {data.loading
                    ?
                    <img src={loader} alt="loading" />
                    : data.items.length === 0
                        ? <p className="no-info">
                            {tabGroup === 'expiring' ?
                                'Няма обекти с изтичащи разрешения'
                                :
                                'Няма намерени данни'
                            }
                        </p>
                        :
                        <>
                            <div className="row">
                                <Table
                                    tabGroup={tabGroup}
                                    data={data.items}
                                    selected={data.filter.id}
                                    refreshData={refreshData}
                                />
                            </div>
                            <div className="row">
                                <Pagination
                                    page={data.page}
                                    pages={data.pages}
                                    handlePage={handlePage}
                                />
                            </div>
                        </>
                }
            </div>

        </section>
    )
}

export default Index