import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function Filter(props) {

    const location = useLocation();

    const sort = new URLSearchParams(location.search).get('sort');

    const filterData = props.filterData;

    return (
        <form autoComplete="off" role="presentation" className="filter-standard">
            <input autoComplete="off" role="presentation"
                type="search"
                className="field-medium"
                defaultValue={props.filter.requestNo}
                placeholder="Вх. №"
                onChange={e => filterData(e, 'requestNo', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'requestNo', e.target.value, 300)}
            />
            <input autoComplete="off" role="presentation"
                type="search"
                className="field-medium"
                defaultValue={props.filter.licenseNo}
                placeholder="Лиценз №"
                onChange={e => filterData(e, 'licenseNo', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'licenseNo', e.target.value, 300)}
            />
            <div className="col">
                <span className="extra-label">
                    От
                </span>
                <input autoComplete="off" role="presentation"
                    type="date"
                    defaultValue={props.filter.startDate}
                    onChange={e => filterData(e, 'startDate', e.target.value)}
                    style={{
                        marginRight: 0
                    }}
                />
            </div>
            <div className="col">
                <span className="extra-label">
                    До
                </span>
                <input autoComplete="off" role="presentation"
                    type="date"
                    defaultValue={props.filter.endDate}
                    onChange={e => filterData(e, 'endDate', e.target.value)}
                    style={{
                        marginRight: 0
                    }}
                />
            </div>
            <input autoComplete="off" role="presentation"
                type="search"
                className="field-medium"
                defaultValue={props.filter.object}
                placeholder="Обект"
                onChange={e => filterData(e, 'object', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'object', e.target.value, 300)}
            />
            <input autoComplete="off" role="presentation"
                type="search"
                className="field-medium"
                defaultValue={props.filter.company}
                placeholder="Фирма"
                onChange={e => filterData(e, 'company', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'company', e.target.value, 300)}
            />
            <input autoComplete="off" role="presentation"
                type="search"
                className="field-medium"
                defaultValue={props.filter.eik}
                placeholder="ЕИК"
                onChange={e => filterData(e, 'eik', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'eik', e.target.value, 300)}
            />
            <input autoComplete="off" role="presentation"
                type="search"
                className="field-medium"
                defaultValue={props.filter.street}
                placeholder="Улица"
                onChange={e => filterData(e, 'street', e.target.value, 300)}
                onKeyUp={e => filterData(e, 'street', e.target.value, 300)}
            />
            <div className="sort">
                <p>
                    Сортиране по:
                </p>
                <button
                    className={['btn-filter', sort === 'date-asc' || sort === 'date-desc' ? 'btn-filter-active' : 'btn-filter-active', !sort || sort == 'date-desc' ? 'filter-down' : ''].join(' ')}
                    onClick={(e) => filterData(e, 'sort', (sort === 'date-asc' ? 'date-desc' : 'date-asc'))}
                >
                    Дата
                </button>
            </div>
        </form>
    )
}

export default Filter