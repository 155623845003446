import React, { useState, useEffect } from 'react'

//partials
import Steps from './steps/Nav'
import Application from './steps/application/Index'
import Permission from './steps/permission/Index'
import Order from './steps/order/Index'
import Contract from './steps/contract/Index'
import Api from '../../../helpers/Api'
import { useParams } from 'react-router-dom'

function Config(props) {

    const params = useParams()

    // id на процедурата
    const id = params.id

    //Стейта на навигацията е вдигнат едно ниво нагоре, за да може лесно да се променя
    const [config, setConfig] = useState({
        step: 1,
        firstStepFinish: false,
        secondStepFinish: false,
        thirdStepFinish: false,
        fourthStepFinish: false,
    })

    const [procedure, setProcedure] = useState({
        data: null,
        original: null,
        loading: true,
        refresh: false,
        initial: true,
    })

    useEffect(() => {
        loadProcedure()
    }, [procedure.refresh])

    const handleCurrent = current => {
        let currentStep
        currentStep = current

        setConfig(config => ({
            ...config,
            step: currentStep,
        }))
    }

    //steps finish
    const handleFinish = (position, finish) => {
        let isFinished
        isFinished = finish

        if (position == 1) {
            setConfig(config => ({
                ...config,
                firstStepFinish: isFinished,
            }))
        } else if (position == 2) {
            setConfig(config => ({
                ...config,
                secondStepFinish: isFinished,
            }))
        } else if (position == 3) {
            setConfig(config => ({
                ...config,
                thirdStepFinish: isFinished,
            }))
        } else if (position == 4) {
            setConfig(config => ({
                ...config,
                fourthStepFinish: isFinished,
            }))
        }
    }

    const loadProcedure = () => {
        Api.get(`/procedures/temptrade/show/${id}`)
            .then(res => {

                if (procedure.initial) {
                    setConfig(config => ({
                        ...config,
                        step: res.data.actual_phase_id == 0 ? 4 : res.data.actual_phase_id
                    }))
                }

                if (res.data.actual_phase_id == 2) {
                    handleFinish(1, true)
                } else if (res.data.actual_phase_id == 3) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                } else if (res.data.actual_phase_id == 4) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                    handleFinish(3, true)
                } else if (res.data.actual_phase_id == 0) {
                    handleFinish(1, true)
                    handleFinish(2, true)
                    handleFinish(3, true)
                    handleFinish(4, true)
                }

                setProcedure(prev => ({
                    ...prev,
                    data: res.data,
                    original: res.data,
                    loading: false,
                    initial: false
                }))


            }).catch(err => {
                setProcedure(prev => ({
                    ...prev,
                    data: null,
                    original: null,
                    loading: false
                }))
            })
    }

    const setData = data => {
        setProcedure(prev => ({
            ...prev,
            data: data
        }))
    }

    const setOriginal = data => {
        setProcedure(prev => ({
            ...prev,
            data: data,
            original: data
        }))

    }

    const refreshData = () => {
        setProcedure(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }))
    }

    return (
        <section id="temporary-shops">
            {!procedure.loading &&
                <div className="wrapper top negative-margin">
                    <Steps
                        handleCurrent={handleCurrent}
                        getCurrent={config.step}
                        firstStepFinish={config.firstStepFinish}
                        secondStepFinish={config.secondStepFinish}
                        thirdStepFinish={config.thirdStepFinish}
                        fourthStepFinish={config.fourthStepFinish}
                    />
                    {config.step == 1 ?
                        <Application
                            loading={procedure.loading}
                            data={procedure.data}
                            original={procedure.original}
                            editable={true}
                            setData={setData}
                            setOriginal={setOriginal}
                            refreshData={refreshData}
                            handleCurrent={handleCurrent}
                            handleFinish={handleFinish}
                        />

                        :
                        config.step == 2 ?
                            <Order
                                loading={procedure.loading}
                                data={procedure.data}
                                original={procedure.original}
                                editable={true}
                                setData={setData}
                                setOriginal={setOriginal}
                                refreshData={refreshData}
                                handleCurrent={handleCurrent}
                                handleFinish={handleFinish}
                            />
                            :
                            config.step == 3 ?
                                <Permission
                                    loading={procedure.loading}
                                    data={procedure.data}
                                    original={procedure.original}
                                    editable={true}
                                    setData={setData}
                                    setOriginal={setOriginal}
                                    refreshData={refreshData}
                                    handleCurrent={handleCurrent}
                                    handleFinish={handleFinish}
                                />
                                :
                                config.step == 4 ?
                                    <Contract
                                        loading={procedure.loading}
                                        data={procedure.data}
                                        original={procedure.original}
                                        editable={true}
                                        setData={setData}
                                        setOriginal={setOriginal}
                                        refreshData={refreshData}
                                        handleCurrent={handleCurrent}
                                        handleFinish={handleFinish}
                                    />
                                    :
                                    ''
                    }


                </div>
            }
        </section>
    )
}

export default Config