import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from 'react'

//context
import { useStepsContext } from '../../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../../contexts/FieldsContext'


//images
import editIcon from '../../../../../../assets/img/pages/icons/edit-field.jpg'

//partials
import Dropdown from '../../../../../app/Dropdown'
import WeekHours from '../../../../../modals/WeekHours'
import FreeDays from '../../../../../modals/FreeDays'
import FreeDaysInput from '../../../../../partials/FreeDays'
import WorkTimeInput from '../../../../../partials/WorkTime'


function Application(props, ref) {
    const stepsContext = useStepsContext()
    const fieldsContext = useFieldsContext()

    //refs
    const weekHoursRef = useRef(null)
    const freeDaysRef = useRef(null)
    const objectStreetRef = useRef(null)
    const objectActivityRef = useRef(null)
    const companyStreetRef = useRef(null)
    const ownerStreetRef = useRef(null)

    const fieldsData = {
        company: {},
        object: {},
    }

    const [fields, setFields] = useState(fieldsData)

    const editable = props.editable || false
    const original = props.original

    useImperativeHandle(ref, () => ({
        setCompany: data => setCompany(data),
        setCompanyId: id => setCompanyId(id),
        setObjectId: id => setObjectId(id)
    }))

    useEffect(() => {
        if (original) {
            setFields(original)
            handleFreeDays(original.free_days)
        }
    }, [original])

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    // dropdown change
    const handleCompanyChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                company: newData
            }))
        }
    }

    const setCompany = newData => {
        setFields(prev => ({
            ...prev,
            company: newData
        }))
    }

    const setCompanyId = id => {
        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                id: id
            }
        }))
    }


    const handleCompanyTownChange = newData => {
        companyStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                town_id: newData.id,
                // town: newData.id ? newData : (prev.company ? prev.company.town : '')
                town: newData
            }
        }))
    }

    const setObjectId = id => {
        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                id: id
            }
        }))
    }

    const handleObjectTownChange = newData => {
        objectStreetRef.current.reset()

        // console.log(newData)

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                town_id: newData.id,
                // town: newData.id ? newData : (prev.object ? prev.object.town : '')
                town: newData
            }
        }))
    }

    const handleObjectTypeChange = newData => {
        objectActivityRef.current.reset()

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                type_id: newData.id,
                // town: newData.id ? newData : (prev.object ? prev.object.town : '')
                type: newData
            }
        }))
    }

    const handleShowFreeDays = e => {
        e.preventDefault()
        freeDaysRef.current.open()
    }

    const handleFreeDays = selected => {
        setFields(prev => ({
            ...prev,
            free_days: selected,
        }))
    }

    const handleShowWorkTime = e => {
        e.preventDefault()
        weekHoursRef.current.open()
    }

    const handleWorkTime = hours => {
        setFields(prev => ({
            ...prev,
            work_time_morning: hours.work_time_morning,
            work_time_afternoon: hours.work_time_afternoon,
            work_time_afternoon_win: hours.work_time_afternoon_win,
            work_time_morning_sat: hours.work_time_morning_sat,
            work_time_afternoon_sat: hours.work_time_afternoon_sat,
            work_time_afternoon_sat_win: hours.work_time_afternoon_sat_win,
            work_time_morning_sun: hours.work_time_morning_sun,
            work_time_afternoon_sun: hours.work_time_afternoon_sun,
            work_time_afternoon_sun_win: hours.work_time_afternoon_sun_win,
            work_time_preholiday_morning: hours.work_time_preholiday_morning,
            work_time_preholiday_afternoon: hours.work_time_preholiday_afternoon,
            work_time_preholiday_afternoon_win: hours.work_time_preholiday_afternoon_win,
            work_time_holiday_morning: hours.work_time_holiday_morning,
            work_time_holiday_afternoon: hours.work_time_holiday_afternoon,
            work_time_holiday_afternoon_win: hours.work_time_holiday_afternoon_win,
        }))
    }

    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name + '_formatted';

        setFields(prev => ({
            ...prev,
            [name]: val
        }));
    }

    return (
        <>
            <WeekHours
                ref={weekHoursRef}
                work_time_morning={fields.work_time_morning}
                work_time_afternoon={fields.work_time_afternoon}
                work_time_afternoon_win={fields.work_time_afternoon_win}
                work_time_morning_sat={fields.work_time_morning_sat}
                work_time_afternoon_sat={fields.work_time_afternoon_sat}
                work_time_afternoon_sat_win={fields.work_time_afternoon_sat_win}
                work_time_morning_sun={fields.work_time_morning_sun}
                work_time_afternoon_sun={fields.work_time_afternoon_sun}
                work_time_afternoon_sun_win={fields.work_time_afternoon_sun_win}
                work_time_preholiday_morning={fields.work_time_preholiday_morning}
                work_time_preholiday_afternoon={fields.work_time_preholiday_afternoon}
                work_time_preholiday_afternoon_win={fields.work_time_preholiday_afternoon_win}
                work_time_holiday_morning={fields.work_time_holiday_morning}
                work_time_holiday_afternoon={fields.work_time_holiday_afternoon}
                work_time_holiday_afternoon_win={fields.work_time_holiday_afternoon_win}
                callback={handleWorkTime}
            />
            <FreeDays
                ref={freeDaysRef}
                selected={fields.free_days || []}
                callback={handleFreeDays}
            />

            <div className="panel">
                <div className="row">
                    <div className="col col-auto">
                        <h3>
                            <span>
                                1
                            </span>
                            Номер и Дата
                        </h3>
                        <div className="holder">
                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="request_no">
                                        Входящ №
                                    </label>
                                    {editable && fields && fields.request_no && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="request_no"
                                        name="request_no"
                                        type="text"
                                        placeholder="Въведете №"
                                        className="field-medium"
                                        defaultValue={editable && fields ? fields.request_no : ''}
                                        readOnly={editable && fields && fields.request_no ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="request_date">
                                        Дата
                                    </label>
                                    {editable && fields && fields.request_date && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="request_date"
                                        name="request_date"
                                        type="date"
                                        value={fields && fields.request_date_formatted ? fields.request_date_formatted : fieldsContext.today()}
                                        readOnly={editable && fields && fields.request_date ? true : false}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* step 2 */}
                    <div className="col">
                        <h3>
                            <span>
                                2
                            </span>
                            Данни за фирмата заявител
                        </h3>
                        <div className="holder">
                            <div className="row">

                                <div className="col able-to-edit">
                                    <label htmlFor="company_name">
                                        Точно наименование на фирмата
                                    </label>
                                    {editable && original && original.company && original.company.name && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        inputName="company[name]"
                                        inputValue={fields && fields.company && fields.company.name}
                                        inputIdName="company[id]"
                                        inputIdValue={fields && fields.company && fields.company.id}
                                        inputPlaceholder="Въведете име"
                                        inputDisabled={editable && original && original.company && original.company.name ? true : false}
                                        url="companies/find"
                                        onChange={handleCompanyChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="company_eik">
                                        ЕИК / Булстат
                                    </label>
                                    {editable && original && original.company && original.company.eik && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="eik"
                                        name="company[eik]"
                                        type="text"
                                        placeholder="Въведете ЕИК / Булстат"
                                        value={fields && fields.company && fields.company.eik}
                                        readOnly={editable && original && original.company && original.company.eik ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="company_phone">
                                        Тел. номер
                                    </label>
                                    {editable && original && original.company && original.company.phone && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="company_phone"
                                        name="company[phone]"
                                        type="text"
                                        placeholder="Въведете тел. номер"
                                        value={fields && fields.company && fields.company.phone}
                                        readOnly={editable && original && original.company && original.company.phone ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>
                            </div>

                            <div className="row">

                                <div className="col able-to-edit">
                                    <label htmlFor="company_town">
                                        Населено място
                                    </label>
                                    {editable && original && original.company && original.company.town && original.company.town && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        inputName="company[town]"
                                        inputValue={fields && fields.company && fields.company.town
                                            ? fields.company.town.id
                                                ? `${fields.company.town.kind} ${fields.company.town.name}`
                                                : fields.company.town.name
                                            : ''}
                                        inputIdName="company[town_id]"
                                        inputIdValue={fields && fields.company && fields.company.town_id}
                                        inputPlaceholder="Изберете населено място"
                                        inputDisabled={editable && original && original.company && original.company.town && original.company.town.id ? true : false}
                                        url="towns/find"
                                        renderText={(data) => {
                                            return <>{data.kind} {data.name}</>
                                        }}
                                        onChange={handleCompanyTownChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="company_street">
                                        Улица
                                    </label>
                                    {editable && original && original.company && original.company.street && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <Dropdown
                                        ref={companyStreetRef}
                                        inputName="company[street]"
                                        inputValue={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                        inputIdName="company[street_id]"
                                        inputIdValue={fields && fields.company && fields.company.street_id}
                                        inputPlaceholder="Изберете улица"
                                        inputDisabled={editable && original && original.company && original.company.street ? true : false}
                                        url="streets/find"
                                        params={{
                                            town_id: fields && fields.company && fields.company.town_id
                                        }}
                                        renderText={(data) => {
                                            return <>{data.suff} {data.name}</>
                                        }}
                                    />
                                </div>

                                <div className="col">
                                    <div className="row">
                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="company_street_number">
                                                №
                                            </label>
                                            {editable && original && original.company && original.company.street_number && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="company_street_number"
                                                name="company[street_number]"
                                                type="number"
                                                min="0"
                                                step="1"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.company && fields.company.street_number}
                                                readOnly={editable && original && original.company && original.company.street_number ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="company_block">
                                                Блок
                                            </label>
                                            {editable && original && original.company && original.company.block && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="company_block"
                                                name="company[block]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.company && fields.company.block}
                                                readOnly={editable && original && original.company && original.company.block ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="company_entrance">
                                                Вход
                                            </label>
                                            {editable && original && original.company && original.company.entrance && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="company_entrance"
                                                name="company[entrance]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.company && fields.company.entrance}
                                                readOnly={editable && original && original.company && original.company.entrance ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="company_floor">
                                                Етаж
                                        </label>
                                            {editable && original && original.company && original.company.floor && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="company_floor"
                                                name="company[floor]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                value={fields && fields.company && fields.company.floor}
                                                readOnly={editable && original && original.company && original.company.floor ? true : false}
                                                onChange={handleFieldChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="company_mol">
                                        МОЛ
                                    </label>
                                    {editable && original && original.company && original.company.mol && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="mol"
                                        name="company[mol]"
                                        type="text"
                                        placeholder="Въведете МОЛ"
                                        value={fields && fields.company && fields.company.mol}
                                        readOnly={editable && original && original.company && original.company.mol ? true : false}
                                        onChange={handleFieldChange}
                                    />
                                </div>

                                <div className="col"></div>
                                <div className="col"></div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h3>
                            <span>
                                4
                            </span>
                            Данни за обекта
                        </h3>
                        <div className="holder">

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_town">
                                        Населено място
                                    </label>
                                    {/* {editable && fields && fields.object && fields.object.town && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />} */}
                                    <Dropdown
                                        // inputName="object[town]"
                                        inputValue={fields && fields.object && fields.object.town
                                            ? `${fields.object.town.kind} ${fields.object.town.name}`
                                            : ''}
                                        // inputIdName="object[town_id]"
                                        inputIdValue={fields && fields.object && fields.object.town_id}
                                        inputPlaceholder="Изберете населено място"
                                        // inputDisabled={editable && fields && fields.object && fields.object.town ? true : false}
                                        inputDisabled={true}
                                        url="towns/find"
                                        renderText={(data) => {
                                            return <>{data.kind} {data.name}, общ. {data.municipality.name}, обл. {data.district.name}</>
                                        }}
                                        onChange={handleObjectTownChange}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="object_street">
                                        Улица
                                    </label>
                                    {/* {editable && fields && fields.object && fields.object.street && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />} */}
                                    <Dropdown
                                        ref={objectStreetRef}
                                        // inputName="object[street]"
                                        inputValue={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                        // inputIdName="object[street_id]"
                                        inputIdValue={fields && fields.object && fields.object.street_id}
                                        inputPlaceholder="Изберете улица"
                                        // inputDisabled={editable && fields && fields.object && fields.object.street ? true : false}
                                        inputDisabled={true}
                                        url="streets/find"
                                        params={{
                                            town_id: fields && fields.object && fields.object.town_id
                                        }}
                                        renderText={(data) => {
                                            return <>{data.suff} {data.name}</>
                                        }}
                                    />
                                </div>

                                <div className="col">
                                    <div className="row">
                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="object_street_number">
                                                №
                                            </label>
                                            {/* {editable && fields && fields.object && fields.object.street_number && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />} */}
                                            <input autoComplete="off" role="presentation"
                                                id="object_street_number"
                                                // name="object[street_number]"
                                                type="number"
                                                min="0"
                                                step="1"
                                                placeholder=""
                                                className="field-small"
                                                defaultValue={fields && fields.object && fields.object.street_number}
                                                // readOnly={editable && fields && fields.object && fields.object.street_number ? true : false}
                                                readOnly={true}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="object_entrance">
                                                Вход
                                            </label>
                                            <input autoComplete="off" role="presentation"
                                                id="object_entrance"
                                                value={fields && fields.object && fields.object.entrance}
                                                className="field-small"
                                                readOnly
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="object_block">
                                                Блок
                                            </label>
                                            {/* {editable && fields && fields.object && fields.object.block && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />} */}
                                            <input autoComplete="off" role="presentation"
                                                id="object_block"
                                                // name="object[block]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                defaultValue={fields && fields.object && fields.object.block}
                                                // readOnly={editable && fields && fields.object && fields.object.block ? true : false}
                                                readOnly={true}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="object_floor">
                                                Етаж
                                            </label>
                                            {/* {editable && fields && fields.object && fields.object.floor && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />} */}
                                            <input autoComplete="off" role="presentation"
                                                id="object_floor"
                                                // name="object[floor]"
                                                type="text"
                                                placeholder=""
                                                className="field-small"
                                                defaultValue={fields && fields.object && fields.object.floor}
                                                // readOnly={editable && fields && fields.object && fields.object.floor ? true : false}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_name">
                                        Наименование
                                    </label>
                                    {/* {editable && fields && fields.object && fields.object.name && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />} */}
                                    <input autoComplete="off" role="presentation"
                                        id="object_name"
                                        // name="object[name]"
                                        type="text"
                                        placeholder="Въведете име"
                                        defaultValue={fields && fields.object && fields.object.name}
                                        // readOnly={editable && fields && fields.object && fields.object.name ? true : false}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="object_phone">
                                        Тел. номер
                                    </label>
                                    {/* {editable && fields && fields.object && fields.object.phone && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />} */}
                                    <input autoComplete="off" role="presentation"
                                        id="object_phone"
                                        // name="object[phone]"
                                        type="text"
                                        placeholder="Въведете тел. номер"
                                        defaultValue={fields && fields.object && fields.object.phone}
                                        // readOnly={editable && fields && fields.object && fields.object.phone ? true : false}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="col">
                                    <div className="row">
                                        <div className="col able-to-edit">
                                            <label htmlFor="object_type">
                                                Вид на търговския обект
                                            </label>
                                            {/* {editable && fields && fields.object && fields.object.type && <img
                                                    src={editIcon}
                                                    alt="edit"
                                                    className="edit"
                                                    onClick={e => fieldsContext.handleEdit(e)}
                                                />} */}
                                            <Dropdown
                                                // inputName="object[type]"
                                                inputValue={fields && fields.object && fields.object.type && fields.object.type.name}
                                                // inputIdName="object[type_id]"
                                                inputIdValue={fields && fields.object && fields.object.type_id}
                                                inputPlaceholder="Изберете вид"
                                                // inputDisabled={editable && fields && fields.object && fields.object.type ? true : false}
                                                inputDisabled={true}
                                                url="objects/types"
                                                onChange={handleObjectTypeChange}
                                            />
                                        </div>


                                    </div>
                                </div>

                                <div className="col able-to-edit">
                                    <label htmlFor="object_activity">
                                        Предмет на дейност
                                    </label>
                                    {/* {editable && fields && fields.object && fields.object.activity && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />} */}
                                    <Dropdown
                                        ref={objectActivityRef}
                                        // inputName="object[activity]"
                                        inputValue={fields && fields.object && fields.object.activity && fields.object.activity.name}
                                        // inputIdName="object[activity_id]"
                                        inputIdValue={fields && fields.object && fields.object.activity_id}
                                        inputPlaceholder="Изберете вид"
                                        // inputDisabled={editable && fields && fields.object && fields.object.activity ? true : false}
                                        inputDisabled={true}
                                        url="objects/activities"
                                        params={{
                                            group_id: fields && fields.object && fields.object.type_id
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <WorkTimeInput
                                        readOnly={true}
                                        editable={true}
                                        work_time_morning={fields.work_time_morning}
                                        work_time_afternoon={fields.work_time_afternoon}
                                        work_time_afternoon_win={fields.work_time_afternoon_win}
                                        work_time_morning_sat={fields.work_time_morning_sat}
                                        work_time_afternoon_sat={fields.work_time_afternoon_sat}
                                        work_time_afternoon_sat_win={fields.work_time_afternoon_sat_win}
                                        work_time_morning_sun={fields.work_time_morning_sun}
                                        work_time_afternoon_sun={fields.work_time_afternoon_sun}
                                        work_time_afternoon_sun_win={fields.work_time_afternoon_sun_win}
                                        work_time_preholiday_morning={fields.work_time_preholiday_morning}
                                        work_time_preholiday_afternoon={fields.work_time_preholiday_afternoon}
                                        work_time_preholiday_afternoon_win={fields.work_time_preholiday_afternoon_win}
                                        work_time_holiday_morning={fields.work_time_holiday_morning}
                                        work_time_holiday_afternoon={fields.work_time_holiday_afternoon}
                                        work_time_holiday_afternoon_win={fields.work_time_holiday_afternoon_win}
                                        handleShowWorkTime={handleShowWorkTime}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <FreeDaysInput
                                        readOnly={true}
                                        readOnly={true}
                                        editable={true}
                                        free_days={fields.free_days}
                                        handleShowFreeDays={handleShowFreeDays}
                                    />
                                </div>

                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="row">
                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="request_equipment_type">
                                                Вид съоръжения
                                        </label>
                                            {editable && fields && fields.request_equipment_type && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <Dropdown
                                                inputId="request_equipment_type"
                                                inputName="request_equipment_type"
                                                inputValue={fields && fields.request_equipment_type ? fields.request_equipment_type : ''}
                                                inputDisabled={editable && fields && fields.request_equipment_type}
                                                inputClassName="field-large"
                                                filter={true}
                                                data={[
                                                    {
                                                        id: '',
                                                        name: '3 маси с по 2 стола'
                                                    },
                                                    {
                                                        id: '',
                                                        name: '4 маси с по 2 стола'
                                                    },
                                                    {
                                                        id: '',
                                                        name: '5 маси с по 3 стола'
                                                    },
                                                    {
                                                        id: '',
                                                        name: '10 маси с по 4 стола'
                                                    }
                                                ]}
                                            />
                                        </div>

                                        <div className="col col-auto able-to-edit">
                                            <label htmlFor="area" id="request_area_size">
                                                Площ
                                            </label>
                                            <div className="row extra-holder">
                                                <div className="extra-holder">
                                                    {editable && fields && fields.request_area_size && <img
                                                        src={editIcon}
                                                        alt="edit"
                                                        className="edit"
                                                        onClick={e => fieldsContext.handleEdit(e)}
                                                    />}
                                                    <input autoComplete="off" role="presentation"
                                                        id="request_area_size"
                                                        type="text"
                                                        className="field-small"
                                                        name="request_area_size"
                                                        defaultValue={fields && fields.request_area_size}
                                                        readOnly={editable && fields && fields.request_area_size}
                                                    />
                                                    <input autoComplete="off" role="presentation"
                                                        type="hidden"
                                                    />
                                                </div>
                                                <span className="extra-label">
                                                    кв.м.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-auto able-to-edit">
                                    <label htmlFor="request_period_from">
                                        Срок
                                    </label>

                                    <div className="row period">
                                        <div className="extra-holder">
                                            <span className="extra-label">
                                                От
                                            </span>
                                            {editable && fields && fields.request_period_from_formatted && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="request_period_from"
                                                type="date"
                                                name="request_period_from"
                                                defaultValue={fields && fields.request_period_from_formatted}
                                                readOnly={fields && fields.request_period_from_formatted ? true : false}
                                            />
                                        </div>
                                        <div className="extra-holder">
                                            <span className="extra-label">
                                                До
                                            </span>
                                            {editable && fields && fields.request_period_to_formatted && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                id="request_period_to"
                                                type="date"
                                                name="request_period_to"
                                                defaultValue={fields && fields.request_period_to_formatted}
                                                readOnly={fields && fields.request_period_to_formatted ? true : false}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <input autoComplete="off" role="presentation" type="hidden" name="object[id]" value={
                                    fields && fields.object && fields.object.id
                                } />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h3>
                            <span>
                                5
                            </span>
                            Адрес на преместваемото съоръжение
                        </h3>
                        <div className="holder">

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label>
                                        Населено място
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        defaultValue={fields && fields.object && fields.object.town
                                            ? `${fields.object.town.kind} ${fields.object.town.name}`
                                            : ''}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="col able-to-edit">
                                    <label>
                                        Улица
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        defaultValue={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="col col-auto able-to-edit">
                                    <label>
                                        №
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        defaultValue={fields && fields.object && fields.object.street_number}
                                        readOnly={true}
                                        min="0"
                                        step="1"
                                    />
                                </div>

                                <div className="col col-auto able-to-edit">
                                    <label>
                                        Вход
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        defaultValue={fields && fields.object && fields.object.entrance}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="col col-auto able-to-edit">
                                    <label>
                                        Блок
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        defaultValue={fields && fields.object && fields.object.block}
                                        readOnly={true}
                                    />
                                </div>

                                <div className="col col-auto able-to-edit">
                                    <label>
                                        Етаж
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        defaultValue={fields && fields.object && fields.object.floor}
                                        readOnly={true}
                                    />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h3>
                            <span>
                                6
                            </span>
                            Стопанисвано от
                        </h3>
                        <div className="holder">

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label>
                                        Точно наименование на фирмата
                                    </label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        defaultValue={fields && fields.company && fields.company.name}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h3>
                            <span>
                                7
                            </span>
                            Допълнителни документи
                        </h3>
                        <div className="holder">
                            <div className="row x2">
                                <div className="col col-auto">
                                    <h3>
                                        7.1. Актуално удостоверение за вписване в търговския регистър
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit">
                                            <label htmlFor="reg_certificate">
                                                Удостоверение
                                            </label>

                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="reg_certificate"
                                                // name="reg_certificate_no"
                                                // className="field-medium"
                                                defaultValue={fields && fields.tradeobject && fields.tradeobject.reg_certificate ? fields.tradeobject.reg_certificate : ''}
                                                readOnly={true}
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div className="col col-auto">
                                    <h3>
                                        7.2. Идентификация ЕИК /БУЛСТАТ/.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit">
                                            <label htmlFor="reg_eik_no">
                                                №
                                            </label>

                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="reg_eik_no"
                                                name="reg_eik_no"
                                                // className="field-medium"
                                                value={fields && fields.company && fields.company.eik ? fields.company.eik : ''}
                                                readOnly={true}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row x2">
                                <div className="col col-auto">
                                    <h3>
                                        7.3. Документи за право на ползване на терена, където ще се осъществява дейността:
                                        Договор за наем или документ за собственост на терена или обекта, към който ще се
                                        разполагат съоръженията.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_ownership_no">
                                                № разрешение
                                            </label>
                                            {editable && fields && fields.reg_ownership_no && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="reg_ownership_no"
                                                name="reg_ownership_no"
                                                className="field-medium"
                                                defaultValue={editable && fields && fields.reg_ownership_no ? fields.reg_ownership_no : ''}
                                                readOnly={editable && fields && fields.reg_ownership_no ? true : false}
                                            />
                                        </div>
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_ownership_date">
                                                Дата
                                            </label>
                                            {editable && fields && fields.reg_ownership_date && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                id="reg_ownership_date"
                                                name="reg_ownership_date"
                                                defaultValue={editable && fields && fields.reg_ownership_date_formatted ? fields.reg_ownership_date_formatted : ''}
                                                readOnly={editable && fields && fields.reg_ownership_date ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col col-auto">
                                    <h3>
                                        7.4. За търговски обекти на територията на град Велико Търново намиращи се в
                                        защитени територии за опазване на културното наследство се издава след писмено
                                        становище съгласно чл. 83 от Закон за културното наследство.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="others_license_no">
                                                № документ
                                            </label>
                                            {editable && fields && fields.others_license_no && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="others_license_no"
                                                name="others_license_no"
                                                className="field-medium"
                                                defaultValue={editable && fields && fields.others_license_no ? fields.others_license_no : ''}
                                                readOnly={editable && fields && fields.others_license_no ? true : false}
                                            />
                                        </div>
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="others_license_date">
                                                Дата
                                            </label>
                                            {editable && fields && fields.others_license_date && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                id="others_license_date"
                                                name="others_license_date"
                                                defaultValue={editable && fields && fields.others_license_date_formatted ? fields.others_license_date_formatted : ''}
                                                readOnly={editable && fields && fields.others_license_date ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row x2">
                                <div className="col col-auto">
                                    <h3>
                                        7.5. Скица с виза от Главен архитект на Община Велико Търново /при необходимост/.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_sketch_no">
                                                № разрешение
                                            </label>
                                            {editable && fields && fields.reg_sketch_no && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="reg_sketch_no"
                                                name="reg_sketch_no"
                                                className="field-medium"
                                                defaultValue={fields && fields.reg_sketch_no ? fields.reg_sketch_no : ''}
                                                readOnly={fields && fields.reg_sketch_no ? true : false}
                                            />
                                        </div>
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_sketch_date">
                                                Дата
                                            </label>
                                            {editable && fields && fields.reg_sketch_date && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                id="reg_sketch_date"
                                                name="reg_sketch_date"
                                                defaultValue={fields && fields.reg_sketch_date_formatted ? fields.reg_sketch_date_formatted : ''}
                                                readOnly={editable && fields && fields.reg_sketch_date ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col col-auto">
                                    <h3>
                                        7.6. Документ за собственост на преместваемото съоръжение/при необходимост/.
                                    </h3>
                                    <div className="row">
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="reg_equipment_ownership_no">
                                                № документ
                                            </label>
                                            {editable && fields && fields.reg_equipment_ownership_no && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="text"
                                                id="reg_equipment_ownership_no"
                                                name="reg_equipment_ownership_no"
                                                className="field-medium"
                                                defaultValue={editable && fields && fields.reg_equipment_ownership_no ? fields.reg_equipment_ownership_no : ''}
                                                readOnly={editable && fields && fields.reg_equipment_ownership_no ? true : false}
                                            />
                                        </div>
                                        <div className="col able-to-edit col-auto">
                                            <label htmlFor="others_license_date">
                                                Дата
                                            </label>
                                            {editable && fields && fields.reg_equipment_ownership_date && <img
                                                src={editIcon}
                                                alt="edit"
                                                className="edit"
                                                onClick={e => fieldsContext.handleEdit(e)}
                                            />}
                                            <input autoComplete="off" role="presentation"
                                                type="date"
                                                id="reg_equipment_ownership_date"
                                                name="reg_equipment_ownership_date"
                                                defaultValue={editable && fields && fields.reg_equipment_ownership_date_formatted ? fields.reg_equipment_ownership_date_formatted : ''}
                                                readOnly={editable && fields && fields.reg_equipment_ownership_date ? true : false}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(React.forwardRef(Application), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
});