import React from 'react'

function Table(props) {

    return (
        <table>
            <thead>
                <tr>
                    <th>Име</th>
                    <th>Код</th>

                    <th className="options">Опции</th>
                </tr>
            </thead>
            <tbody>
                {props.data.map(s =>
                    <>
                        <tr key={'s-' + s.id} data-id={s.id}>

                            <td>
                                {s.text}
                            </td>
                            <td>
                                {s.code}
                            </td>

                            <td>
                                <div className="row" style={{ margin: 0 }}>
                                    <div className="col">
                                        <button
                                            onClick={() => props.handleEdit(s.id)}
                                            className="btn-edit"
                                        >
                                            Редактиране
                                        </button>
                                    </div>
                                    <div className="col">
                                        <button
                                            onClick={() => props.handleShowPrices(s.id)}
                                            className="btn-small"
                                        >
                                            Цени
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </>
                )}
            </tbody>
        </table>
    )
}

export default Table