import React, { forwardRef, useEffect, useState } from 'react'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'

//partials
import Commission from './Commission'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext';

function Fields(props) {
    const fieldsContext = useFieldsContext();

    const editable = props.editable || false;
    const original = props.original;
    const data = props.data;

    const fieldsData = {
        findingsprotocol: {},
        object: {}
    }

    const [fields, setFields] = useState(fieldsData)

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name
        let nameFirstPart = name.split('[')[0]

        setFields(prev => ({
            ...prev,
            [nameFirstPart]: {
                ...prev[nameFirstPart],
                date_formatted: val
            }
        }));
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Номер и дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="findingsprotocol_no">
                                    № протокол
                                </label>
                                {fields && ((fields.findingsprotocol && fields.findingsprotocol.no) || fields.findings_protocol_gno) && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="findingsprotocol_no"
                                    name="findingsprotocol[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    defaultValue={fields && fields.findingsprotocol ? fields.findingsprotocol.no : fields.findings_protocol_gno}
                                    readOnly={fields && ((fields.findingsprotocol && fields.findingsprotocol.no) || fields.findings_protocol_gno) ? true : false}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="findingsprotocol_date">
                                    Дата
                                </label>
                                {original && original.findingsprotocol && original.findingsprotocol.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="findingsprotocol_date"
                                    name="findingsprotocol[date]"
                                    type="date"
                                    value={fields && fields.findingsprotocol && fields.findingsprotocol.date_formatted ? fields.findingsprotocol.date_formatted : fieldsContext.today()}
                                    readOnly={original && original.findingsprotocol && original.findingsprotocol.date ? true : false}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <Commission
                        data={fields.findingsprotocol ? fields.findingsprotocol.commission_text : []}
                    />
                </div>


            </div>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Данни за обекта
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Наименование
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object ? `${fields.object.type ? fields.object.type.name : ''} ${fields.object.name}` : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>
                                    Адрес
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object
                                        ? (
                                            fields.object.town ?
                                                (
                                                    (`${fields.object.town.kind} ${fields.object.town.name} `)
                                                    + (fields.object.region ? `кв. ${fields.object.region.name} ` : '')
                                                    + (fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name} ${fields.object.street_number} ` : '')
                                                    + (fields.object.block ? `бл. ${fields.object.block} ` : '')
                                                    + (fields.object.floor ? `ет. ${fields.object.floor} ` : '')
                                                    + (fields.object.apartment ? `ап. ${fields.object.apartment} ` : '')
                                                )
                                                :
                                                ''
                                        )
                                        : ''}
                                    readOnly
                                />
                            </div>
                            <div className="col">
                                <label>
                                    Собственост
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? fields.owner.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>
                                    Наемател
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.company ? fields.company.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    )
}

export default React.memo(Fields, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
});