import React, { forwardRef, useRef, useEffect, useState } from 'react'

//context
import { useStepsContext } from '../../../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

import Dropdown from '../../../../../../app/Dropdown';

import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import Select from '../../../../../../app/Select';
import Api from '../../../../../../../helpers/Api';

function BuildingData(props, ref) {

    const fieldsContext = useFieldsContext();

    const data = props.data;
    const original = props.original;
    const fields = props.fields;
    const setFields = props.setFields;
    const editable = props.editable || false;
    const handleFieldChange = props.handleFieldChange;

    const [types, setTypes] = useState([]);

    useEffect(() => {
        if (original) {
            if (original.object.activity_id) {
                loadTypes(original.object.activity_id)
            }
        }
    }, [original])

    // const fieldsData = {
    //     object: {},
    // }

    // const [fields, setFields] = useState(fieldsData)

    // useEffect(() => {
    //     if (original) {
    //         setFields(original)
    //     }
    // }, [original])


    // const handleFieldChange = e => {

    //     let isObj = Boolean(e.target.name.includes('['))
    //     let name = e.target.name
    //     let val = e.target.value

    //     if (isObj) {
    //         let nameFirstPart = name.split('[')[0]
    //         let nameSecondPart = name.split('[')[1].split(']')[0]

    //         setFields(prev => ({
    //             ...prev,
    //             [nameFirstPart]: {
    //                 ...prev[nameFirstPart],
    //                 [nameSecondPart]: val,
    //             }
    //         }))
    //     } else {
    //         setFields(prev => ({
    //             ...prev,
    //             [name]: val
    //         }))
    //     }

    // }

    const objectMunicipalityRef = useRef(null);
    const objectTownRef = useRef(null);
    const objectRegionRef = useRef(null);
    const objectStreetRef = useRef(null);

    // dropdown change
    const handleObjectChange = newData => {
        if (newData.id) {
            setFields(prev => ({
                ...prev,
                object: newData
            }))
        }
    }

    const handleObjectCountryChange = newData => {
        objectMunicipalityRef.current.reset()
        objectTownRef.current.reset();
        objectRegionRef.current.reset();
        objectStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                country_id: newData.id,
                country: newData,
            }
        }))
    }

    const handleObjectMunicipalityChange = newData => {
        objectTownRef.current.reset();
        objectRegionRef.current.reset();
        objectStreetRef.current.reset();

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                municipality_id: newData.id,
                municipality: newData,
            }
        }))
    }

    const handleObjectTownChange = newData => {
        objectStreetRef.current.reset()

        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                town_id: newData.id,
                town: newData,
            }
        }))
    }

    const loadTypes = categoryId => {
        Api.get('objects/activities?parent_id=' + categoryId)
            .then(res => {
                setTypes(res.data)
            });
    }

    const handleCategoryChange = e => {
        let val = e.target.value;

        loadTypes(val);
        handleFieldChange(e);
    }

    return (
        <div className="panel" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    1
                                </span>
                                Наименование на обекта
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_name">
                                            Наименование на обекта
                                        </label>
                                        {editable && original && original.object && original.object.name && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_name"
                                            name="object[name]"
                                            type="text"
                                            placeholder="Въведете име"
                                            value={fields && fields.object ? fields.object.name : ''}
                                            readOnly={editable && original && original.object && original.object.name ? true : false}
                                            onChange={handleFieldChange}
                                        />

                                        <input autoComplete="off" role="presentation" type="hidden" name="object[id]" value={
                                            fields.object.id ? fields.object.id : ''
                                        } />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    3
                                </span>
                                Капацитет на обекта
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_seats">
                                            Брой места за сядане
                                        </label>
                                        {editable && original && original.object && original.object.seats != null && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_seats"
                                            name="object[seats]"
                                            type="number"
                                            value={fields && fields.object ? fields.object.seats : ''}
                                            readOnly={editable && original && original.object && original.object.seats != null ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_outdoor_seats">
                                            Места на открито
                                        </label>
                                        {editable && original && original.object && original.object.outdoor_seats != null && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_outdoor_seats"
                                            name="object[outdoor_seats]"
                                            type="number"
                                            value={fields && fields.object ? fields.object.outdoor_seats : ''}
                                            readOnly={editable && original && original.object && original.object.outdoor_seats != null ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_indoor_seats">
                                            Места на закрито
                                        </label>
                                        {editable && original && original.object && original.object.indoor_seats != null && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_indoor_seats"
                                            name="object[indoor_seats]"
                                            type="number"
                                            value={fields && fields.object ? fields.object.indoor_seats : ''}
                                            readOnly={editable && original && original.object && original.object.indoor_seats != null ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <h3>
                                <span>
                                    5
                                </span>
                                Вид на заведението
                            </h3>
                            <div className="holder">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_restaurant_type_id">
                                        Вид
                                    </label>
                                    {editable && original && original.object && original.object.restaurant_type_id && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <select
                                        id="object_restaurant_type_id"
                                        name="object[restaurant_type_id]"
                                        value={fields.object.restaurant_type_id ? fields.object.restaurant_type_id : ''}
                                        readOnly={editable && original && original.object && original.object.restaurant_type_id ? true : false}
                                        onChange={handleFieldChange}
                                    >
                                        <option disabled>
                                            Избор
                                        </option>
                                        <option value="1">
                                            Самостоятелно
                                        </option>
                                        <option value="2">
                                            В място за настаняване клас „А“
                                        </option>
                                        <option value="3">
                                            В място за настаняване клас „Б“
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <div className="row">
                        <div className="col col-auto">
                            <h3>
                                <span>
                                    2
                                </span>
                                Категория заведение за хранене и развлечения
                            </h3>
                            <div className="holder">
                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_type_id">
                                            Категория
                                        </label>
                                        {editable && original.object.activity_id && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <select
                                            id="object_activity_id"
                                            name="object[activity_id]"
                                            className="field-big"
                                            value={fields.object.activity_id ? fields.object.activity_id : ''}
                                            readOnly={editable && original.object.activity_id ? true : false}
                                            onChange={handleCategoryChange}
                                            style={{ width: '180px' }}
                                        >
                                            <option value="" disabled>
                                                Избор
                                            </option>
                                            <option value="17">
                                                Ресторант
                                        </option>
                                            <option value="44">
                                                Заведение за бързо обслужване
                                        </option>
                                            <option value="45">
                                                Питейно заведение
                                        </option>
                                            <option value="26">
                                                Кафе-сладкарница
                                        </option>
                                            <option value="20">
                                                Бар
                                        </option>
                                        </select>
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_type_id">
                                            Вид
                                        </label>
                                        {editable && original.object.subactivity_id && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <select
                                            id="object_subactivity_id"
                                            name="object[subactivity_id]"
                                            className="field-big"
                                            value={fields.object.subactivity_id ? fields.object.subactivity_id : ''}
                                            readOnly={editable && original.object.subactivity_id ? true : false}
                                            onChange={handleFieldChange}
                                            style={{ width: '180px' }}
                                        >
                                            <option value="" disabled>
                                                Избор
                                            </option>
                                            {types.map(t =>
                                                <option key={'t-' + t.id} value={t.id}>{t.name}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                    <div className="row">
                        <div className="col col-auto">
                            <h3>
                                <span>
                                    4
                                </span>
                                Период на експлоатация
                            </h3>
                            <div className="holder">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_work_period_id">
                                        Период
                                    </label>
                                    {editable && original && original.object && original.object.work_period_id && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <select
                                        id="object_work_period_id"
                                        name="object[work_period_id]"
                                        value={fields && fields.object && fields.object.work_period_id ? fields.object.work_period_id : ''}
                                        readOnly={editable && original && original.object && original.object.work_period_id ? true : false}
                                        onChange={handleFieldChange}
                                    >
                                        <option disabled>
                                            Избор
                                        </option>
                                        <option value="1">
                                            Целогодишно
                                        </option>
                                        <option value="2">
                                            Сезонно
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-auto">
                            <h3>
                                <span>
                                    6
                                </span>
                                Категория, която се заявява
                            </h3>
                            <div className="holder">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_category_id">
                                        Брой звезди
                                    </label>
                                    {editable && original && original.object && original.object.category_id && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <select
                                        id="object_category_id"
                                        name="object[category_id]"
                                        value={fields && fields.object && fields.object.category_id ? fields.object.category_id : ''}
                                        readOnly={editable && original && original.object && original.object.category_id ? true : false}
                                        onChange={handleFieldChange}
                                    >
                                        <option disabled>
                                            Избор
                                        </option>
                                        <option value="1">
                                            Една звезда
                                        </option>
                                        <option value="2">
                                            Две звезди
                                        </option>
                                        <option value="3">
                                            Три звезди
                                        </option>
                                        <option value="4">
                                            Четири звезди
                                        </option>
                                        <option value="5">
                                            Пет звезди
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="col col-auto">
                            <h3>
                                <span>
                                    7
                                </span>
                                Настояща категория
                            </h3>
                            <div className="holder">
                                <div className="col able-to-edit">
                                    <label htmlFor="object_current_category_id">
                                        Брой звезди
                                    </label>
                                    {editable && original && original.object && original.object.current_category_id != null && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <select
                                        id="object_current_category_id"
                                        name="object[current_category_id]"
                                        value={fields.object.current_category_id != null ? fields.object.current_category_id : ''}
                                        readOnly={editable && original.object.current_category_id != null ? true : false}
                                        onChange={handleFieldChange}
                                    >
                                        <option disabled>
                                            Избор
                                        </option>
                                        <option value="0">
                                            Няма
                                        </option>
                                        <option value="1">
                                            Една звезда
                                        </option>
                                        <option value="2">
                                            Две звезди
                                        </option>
                                        <option value="3">
                                            Три звезди
                                        </option>
                                        <option value="4">
                                            Четири звезди
                                        </option>
                                        <option value="5">
                                            Пет звезди
                                        </option>
                                    </select>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <h3>
                        <span>
                            8
                        </span>
                        Адрес на обекта
                    </h3>

                    <div className="holder">
                        <div className="row x2">

                            <div className="col">

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_country">
                                            Държава
                                        </label>
                                        {editable && original && original.object && original.object.country && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            inputName="object[country]"
                                            inputValue={fields && fields.object && fields.object.country ? fields.object.country.name : 'България'}
                                            inputIdName="object[country_id]"
                                            inputIdValue={fields && fields.object && fields.object.country_id ? fields.object.country_id : 1}
                                            inputPlaceholder="Изберете държава"
                                            inputDisabled={editable && original && original.object && original.object.country ? true : false}
                                            url="countries/find"
                                            onChange={handleObjectCountryChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_municipality">
                                            Община
                                        </label>
                                        {editable && original && original.object && original.object.municipality && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={objectMunicipalityRef}
                                            inputName="object[municipality]"
                                            inputValue={fields && fields.object && fields.object.municipality ? fields.object.municipality.name : ''}
                                            inputIdName="object[municipality_id]"
                                            inputIdValue={fields && fields.object && fields.object.municipality_id}
                                            inputPlaceholder="Изберете община"
                                            inputDisabled={editable && original && original.object && original.object.municipality ? true : false}
                                            url="municipalities/find"
                                            params={{
                                                country_id: fields && fields.object && fields.object.country_id
                                            }}
                                            onChange={handleObjectMunicipalityChange}
                                        />
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_town">
                                            Населено място
                                        </label>
                                        {editable && original && original.object && original.object.town && original.object.town && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={objectTownRef}
                                            inputName="object[town]"
                                            inputValue={fields && fields.object && fields.object.town
                                                ? fields.object.town.id
                                                    ? `${fields.object.town.kind} ${fields.object.town.name}`
                                                    : fields.object.town.name
                                                : ''}
                                            inputIdName="object[town_id]"
                                            inputIdValue={fields && fields.object && fields.object.town_id}
                                            inputPlaceholder="Изберете населено място"
                                            inputDisabled={editable && original && original.object && original.object.town && original.object.town.id ? true : false}
                                            url="towns/find"
                                            params={{
                                                munic_id: fields && fields.object && fields.object.municipality && fields.object.municipality.id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.kind} {data.name}</>;
                                            }}
                                            onChange={handleObjectTownChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_postcode">
                                            Пощенски код
                                        </label>
                                        {editable && original && original.object && original.object.postcode && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_postcode"
                                            name="object[postcode]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            className="field-medium"
                                            value={fields && fields.object && fields.object.postcode ? fields.object.postcode : ''}
                                            readOnly={editable && original && original.object && original.object.postcode ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_region">
                                            Район/квартал
                                        </label>
                                        {editable && original && original.object && original.object.region && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={objectRegionRef}
                                            inputName="object[region]"
                                            inputValue={fields && fields.object && fields.object.region ? fields.object.region.name : ''}
                                            inputIdName="object[region_id]"
                                            inputIdValue={fields && fields.object && fields.object.region_id}
                                            inputPlaceholder="Изберете район/квартал"
                                            inputDisabled={editable && original && original.object && original.object.region ? true : false}
                                            url="regions/find"
                                            params={{
                                                town_id: fields && fields.object && fields.object.town_id
                                            }}

                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_street">
                                            Булевард/площад/улица
                                        </label>
                                        {editable && original && original.object && original.object.street && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <Dropdown
                                            ref={objectStreetRef}
                                            inputName="object[street]"
                                            inputValue={fields && fields.object && fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name}` : ''}
                                            inputIdName="object[street_id]"
                                            inputIdValue={fields && fields.object && fields.object.street_id}
                                            inputPlaceholder="Изберете улица"
                                            inputDisabled={editable && original && original.object && original.object.street ? true : false}
                                            url="streets/find"
                                            params={{
                                                town_id: fields && fields.object && fields.object.town_id
                                            }}
                                            renderText={(data) => {
                                                return <>{data.suff} {data.name}</>;
                                            }}
                                        />
                                    </div>

                                </div>

                            </div>

                            <div className="col">

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_phone">
                                            Тел. номер
                                        </label>
                                        {editable && original && original.object && original.object.phone && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_phone"
                                            name="object[phone]"
                                            type="text"
                                            placeholder="Въведете тел. номер"
                                            value={fields && fields.object && fields.object.phone ? fields.object.phone : ''}
                                            readOnly={editable && original && original.object && original.object.phone ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col able-to-edit">
                                        <label htmlFor="object_fax">
                                            Факс
                                        </label>
                                        {editable && original && original.object && original.object.fax && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_fax"
                                            name="object[fax]"
                                            type="text"
                                            placeholder="Въведете факс"
                                            value={fields && fields.object && fields.object.fax ? fields.object.fax : ''}
                                            readOnly={editable && original && original.object && original.object.fax ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col able-to-edit">
                                        <label htmlFor="object_email">
                                            Е-поща
                                        </label>
                                        {editable && original && original.object && original.object.email && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_email"
                                            name="object[email]"
                                            type="text"
                                            placeholder="Въведете е-поща"
                                            value={fields && fields.object && fields.object.email ? fields.object.email : ''}
                                            readOnly={editable && original && original.object && original.object.email ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_street_number">
                                            №
                                        </label>
                                        {editable && original && original.object && original.object.street_number && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_street_number"
                                            name="object[street_number]"
                                            type="number"
                                            min="0"
                                            step="1"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.street_number ? fields.object.street_number : ''}
                                            readOnly={editable && original && original.object && original.object.street_number ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_block">
                                            Блок
                                        </label>
                                        {editable && original && original.object && original.object.block && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_block"
                                            name="object[block]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.block ? fields.object.block : ''}
                                            readOnly={editable && original && original.object && original.object.block ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_entrance">
                                            Вход
                                        </label>
                                        {editable && original && original.object && original.object.entrance && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_entrance"
                                            name="object[entrance]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.entrance ? fields.object.entrance : ''}
                                            readOnly={editable && original && original.object && original.object.entrance ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_floor">
                                            Етаж
                                        </label>
                                        {editable && original && original.object && original.object.floor && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_floor"
                                            name="object[floor]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.floor ? fields.object.floor : ''}
                                            readOnly={editable && original && original.object && original.object.floor ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="object_apartment">
                                            Апартамент
                                        </label>
                                        {editable && original && original.object && original.object.apartment && <img
                                            src={editIcon}
                                            alt="edit"
                                            className="edit"
                                            onClick={e => fieldsContext.handleEdit(e)}
                                        />}
                                        <input autoComplete="off" role="presentation"
                                            id="object_apartment"
                                            name="object[apartment]"
                                            type="text"
                                            placeholder=""
                                            className="field-small"
                                            value={fields && fields.object && fields.object.apartment ? fields.object.apartment : ''}
                                            readOnly={editable && original && original.object && original.object.apartment ? true : false}
                                            onChange={handleFieldChange}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default React.memo(BuildingData, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})