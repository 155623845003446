import React from 'react'

//images
import dsLogo from '../../assets/img/app/footer/ds-logo.png'

function Footer() {
    return (
        <footer>
            <div className="wrapper">
                <p>
                    Община Велико Търново © {new Date().getFullYear()}. Всички права запазени
                </p>
                <div className="ds">
                    <span>
                        Софтуерен партньор
                    </span>
                    <a href="https://danielstandard.com" target="_blank">
                        <img src={dsLogo} alt="Daniel Standard" />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer