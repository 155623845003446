import React, { forwardRef, useEffect, useState } from 'react'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import Commission from './Commission'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext';
import Objects from './Objects';
import Dropdown from '../../../../../../app/Dropdown';
import * as Constants from '../../../../../../../helpers/Constants'
import Signature from '../../../../../../partials/Signature'

function Fields(props) {
    const fieldsContext = useFieldsContext();

    const editable = props.editable || false;
    const original = props.original;
    const data = props.data;

    const fieldsData = {
        suggestion: {},
        object: {}
    }

    const [fields, setFields] = useState(fieldsData)

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    const handleFieldChange = e => {

        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }


    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name
        let nameFirstPart = name.split('[')[0]
        let nameSecondPart = name.split('[')[1].split(']')[0] + '_formatted'

        setFields(prev => ({
            ...prev,
            [nameFirstPart]: {
                ...prev[nameFirstPart],
                [nameSecondPart]: val
            }
        }));
    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Период на оглед
                    </h3>
                    <div className="holder">
                        <div className="col">
                            <div className="row">
                                <div className="col able-to-edit">
                                    <label>
                                        От
                                    </label>
                                    {original && original.suggestion && original.suggestion.period_from && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="suggestion_period_from"
                                        name="suggestion[period_from]"
                                        type="date"
                                        value={fields && fields.suggestion && fields.suggestion.period_from_formatted}
                                        readOnly={original && original.suggestion && original.suggestion.period_from ? true : false}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col able-to-edit">
                                    <label>
                                        До
                                    </label>
                                    {original && original.suggestion && original.suggestion.period_to && <img
                                        src={editIcon}
                                        alt="edit"
                                        className="edit"
                                        onClick={e => fieldsContext.handleEdit(e)}
                                    />}
                                    <input autoComplete="off" role="presentation"
                                        id="suggestion_period_to"
                                        name="suggestion[period_to]"
                                        type="date"
                                        value={fields && fields.suggestion && fields.suggestion.period_to_formatted}
                                        readOnly={original && original.suggestion && original.suggestion.period_to ? true : false}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col col-auto">
                        <h3>
                            <span>
                                4
                            </span>
                            Председател на ОЕККТО
                        </h3>
                        <div className="holder" name="suggestion[signed]">
                            <div className="col able-to-edit">
                                <Signature
                                    name="suggestion[signed_by]"
                                    group_id={Constants.OEKKTO}
                                    selected={fields && fields.suggestion && fields.suggestion.signature}
                                    handleModify={props.handleModify}
                                />
                            </div>
                        </div>

                    </div>
                </div>


                <div className="col">
                    <Objects
                        procedure_id={fields.id}
                    />
                </div>

                <div className="col">
                    <Commission
                        data={fields.suggestion ? fields.suggestion.commission_text : []}
                    />
                </div>
            </div>

        </div>
    )
}

export default React.memo(Fields, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
});