import React, { forwardRef, useEffect, useState } from 'react'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext';
import Dropdown from '../../../../../../app/Dropdown';
import * as Constants from '../../../../../../../helpers/Constants'
import Signature from '../../../../../../partials/Signature'

function Fields(props) {
    const fieldsContext = useFieldsContext();

    const editable = props.editable || false;
    const original = props.original;
    const data = props.data;

    const fieldsData = {
        certificate: {},
        object: {}
    }

    const [fields, setFields] = useState(fieldsData)

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])

    const handleFieldChange = e => {

        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }


    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name
        let nameFirstPart = name.split('[')[0]
        let nameSecondPart = name.split('[')[1].split(']')[0] + '_formatted'

        setFields(prev => ({
            ...prev,
            [nameFirstPart]: {
                ...prev[nameFirstPart],
                [nameSecondPart]: val
            }
        }));

    }

    return (
        <div className="panel">
            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                            </span>
                            Дата и №
                        </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="certificate_no">
                                    № удостоверение
                                </label>
                                {original && ((original.certificate && original.certificate.no) || original.certificate_gno) && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="certificate_no"
                                    name="certificate[no]"
                                    type="text"
                                    placeholder="Въведете №"
                                    className="field-medium"
                                    value={fields && fields.certificate ? fields.certificate.no : fields.certificate_gno}
                                    readOnly={original && ((original.certificate && original.certificate.no) || original.certificate_gno) ? true : false}
                                    onChange={handleFieldChange}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="certificate_date">
                                    Дата
                                </label>
                                {original && original.certificate && original.certificate.date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="certificate_date"
                                    name="certificate[date]"
                                    type="date"
                                    value={fields && fields.certificate && fields.certificate.date_formatted ? fields.certificate.date_formatted : fieldsContext.today()}
                                    readOnly={original && original.certificate && original.certificate.date ? true : false}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            2
                        </span>
                        Данни за обекта
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col">
                                <label>
                                    Наименование
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object ? `${fields.object.type ? fields.object.type.name : ''} ${fields.object.name}` : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>
                                    Адрес
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.object
                                        ? (
                                            fields.object.town ?
                                                (
                                                    (`${fields.object.town.kind} ${fields.object.town.name} `)
                                                    + (fields.object.region ? `кв. ${fields.object.region.name} ` : '')
                                                    + (fields.object.street ? `${fields.object.street.suff} ${fields.object.street.name} ${fields.object.street_number} ` : '')
                                                    + (fields.object.block ? `бл. ${fields.object.block} ` : '')
                                                    + (fields.object.floor ? `ет. ${fields.object.floor} ` : '')
                                                    + (fields.object.apartment ? `ап. ${fields.object.apartment} ` : '')
                                                )
                                                :
                                                ''
                                        )
                                        : ''}
                                    readOnly
                                />
                            </div>
                            <div className="col">
                                <label>
                                    Собственост
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.owner ? fields.owner.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <label>
                                    Наемател
                                </label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields.company ? fields.company.name : ''}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            3
                        </span>
                        Удостоверението важи до
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="certificate_validation">
                                    Дата
                                </label>
                                {original && original.certificate && original.certificate.validation && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="certificate_validation"
                                    name="certificate[validation]"
                                    type="date"
                                    value={fields && fields.certificate && fields.certificate.validation_formatted}
                                    readOnly={original && original.certificate && original.certificate.validation ? true : false}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col col-auto">
                    <h3>
                        <span>
                            4
                        </span>
                        Председател на ОЕККТО
                    </h3>
                    <div className="holder" name="certificate[signed]">
                        <div className="col able-to-edit">
                            <Signature
                                name="certificate[signed_by]"
                                group_id={Constants.OEKKTO}
                                selected={fields && fields.certificate && fields.certificate.signature}
                                handleModify={props.handleModify}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Fields, (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
});