import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Dropdown from '../app/Dropdown'
import Api from '../../helpers/Api';

//modals
import SuccessModal from '../modals/messages/Success'
//temporary
import AttentionModal from '../modals/messages/Attention'

function AddOrEditCompany(props, ref) {

    //refs
    const form = useRef(null)
    const streetRef = useRef(null);
    const SuccessModalRef = useRef(null)
    const AttentionModalRef = useRef(null)

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        id: null,
        company: null,
        loading: false,
        refresh: false
    })

    const [fields, setFields] = useState({})

    useEffect(() => {

        setFields({})

        if (state.id) {
            Api.get('companies/show/' + state.id)
                .then(res => {
                    setState(prev => ({
                        ...prev,
                        company: res.data,
                        loading: false
                    }))

                    setFields(res.data);
                });
        }
    }, [state.id, state.refresh]);

    useImperativeHandle(ref, () => ({
        open: () => {

            setState(prev => ({
                ...prev,
                id: null,
                company: null,
            }))

            setFields({})

            handleOpen()
        },
        edit: id => {

            setState(prev => ({
                ...prev,
                id: id,
                refresh: new Date().getTime(),
                loading: true
            }))

            setFields({})

            handleOpen()
        },
        close: () => {
            handleClose()
        }
    }))

    const handleOpen = () => {
        setState(prev => ({
            ...prev,
            overlay: true,
        }))

        setTimeout(() => {
            setState(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setState(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setState(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault();

        let data = new FormData(form.current);

        if (state.id) {
            data.append('id', state.id);
        }

        Api.post('companies/addedit', data)
            .then(res => {
                SuccessModalRef.current.open()

                if (typeof props.refreshData === 'function') {
                    props.refreshData()
                }

                setTimeout(() => {
                    handleClose()
                }, 3300)

            }).catch(err => {
                AttentionModalRef.current.open()
            });
    }

    const handleFieldChange = e => {
        let isObj = Boolean(e.target.name.includes('['));
        let name = e.target.name;
        let val = e.target.value;

        if (isObj) {

            let nameFirstPart = name.split('[')[0];
            let nameSecondPart = name.split('[')[1].split(']')[0];

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }));
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleTownChange = (newData) => {
        streetRef.current.reset();

        // console.log(newData);

        setFields(prev => ({
            ...prev,
            town_id: newData.id,
            town: newData
        }));

    }

    return (
        <form autoComplete="off" role="presentation" ref={form} className={`${state.overlay ? 'visible' : ''} overlay`} onSubmit={handleSubmit}>
            <SuccessModal
                ref={SuccessModalRef}
                mainMessage={'Промените бяха запазени успешно!'}
            />
            <AttentionModal
                ref={AttentionModalRef}
                mainMessage={'Имате непопълнени полета!'}
                secondaryMessage={'Моля, попълнете всички полета, за да продължите.'}
            />
            {!state.loading &&
                <div className={`${state.modal ? 'show' : ''} form-box`}>
                    <h2>
                        {state.id ? 'Редактиране на фирма' : 'Добавяне на фирма'}
                    </h2>
                    <p>
                        {/*  */}
                    </p>
                    <hr />

                    <div className="row center">
                        <div className="col">
                            <label>Име</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="name"
                                value={fields.name ? fields.name : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                        <div className="col">
                            <label>ЕИК / Булстат</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="eik"
                                value={fields.eik ? fields.eik : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                        <div className="col">
                            <label>Тел. номер</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="phone"
                                value={fields.phone ? fields.phone : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                    </div>

                    <div className="row center">
                        <div className="col">
                            <label>Населено място</label>
                            <Dropdown
                                inputName="town"
                                inputValue={state.id ? (fields.town && fields.town.name) : ''}
                                inputIdName="town_id"
                                inputIdValue={state.id ? fields.town_id : ''}
                                url="towns/find"
                                renderText={(data) => {
                                    return <>{data.kind} {data.name}, общ. {data.municipality ? data.municipality.name : ''}, обл. {data.district ? data.district.name : ''}</>;
                                }}
                                onChange={handleTownChange}
                            />
                        </div>

                        <div className="col">
                            <label>Улица</label>
                            <Dropdown
                                ref={streetRef}
                                inputName="street"
                                inputValue={state.id ? (fields && fields.street ? `${fields.street.suff} ${fields.street.name}` : '') : ''}
                                inputIdName="street_id"
                                inputIdValue={fields && fields.street_id}
                                url="streets/find"
                                params={{
                                    town_id: fields && fields.town_id
                                }}
                                renderText={(data) => {
                                    return <>{data.suff} {data.name}</>;
                                }}
                            />
                        </div>

                        <div className="col">
                            <label>Улица №</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="street_number"
                                value={fields.street_number ? fields.street_number : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label>Блок</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="block"
                                value={fields.block ? fields.block : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                        <div className="col">
                            <label>Вход</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="entrance"
                                value={fields.entrance ? fields.entrance : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                        <div className="col">
                            <label>Етаж</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="floor"
                                value={fields.floor ? fields.floor : ''}
                                onChange={handleFieldChange}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label>МОЛ</label>
                            <input autoComplete="off" role="presentation"
                                type="text"
                                name="mol"
                                value={fields.mol ? fields.mol : ''}
                                onChange={handleFieldChange}
                            />
                        </div>

                        <div className="col"></div>
                        <div className="col"></div>
                    </div>

                    <hr />

                    <div className="buttons">
                        <span className="btn-default" onClick={handleClose}>
                            Отказ
                        </span>
                        <button type="button" onClick={handleSubmit}>
                            Запази
                        </button>
                    </div>
                </div>
            }
        </form>
    )
}

export default forwardRef(AddOrEditCompany)