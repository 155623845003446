import React, { useState, forwardRef, useEffect } from 'react'

//context
import { useStepsContext } from '../../../../../../../contexts/StepsContext'
import { useFieldsContext } from '../../../../../../../contexts/FieldsContext'

//images
import editIcon from '../../../../../../../assets/img/pages/icons/edit-field.jpg'
import SignPanel from '../../../../../../partials/SignPanel'

function Fields(props, ref) {
    const stepsContext = useStepsContext()
    const fieldsContext = useFieldsContext()

    const fieldsData = {
        company: {},
        object: {},
    }

    const [fields, setFields] = useState(fieldsData)

    const editable = props.editable || false;
    const original = props.original;

    useEffect(() => setFields(original), [original])

    const handleDateChange = e => {
        let val = e.target.value;
        let name = e.target.name + '_formatted';

        setFields(prev => ({
            ...prev,
            [name]: val
        }));
    }

    return (
        <div className="panel">

            <div className="row">
                <div className="col col-auto">
                    <h3>
                        <span>
                            1
                        </span>
                        Дата
                    </h3>
                    <div className="holder">
                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="contract_date">
                                    Дата
                                </label>
                                {original && original.contract_date && <img
                                    src={editIcon}
                                    alt="edit"
                                    className="edit"
                                    onClick={e => fieldsContext.handleEdit(e)}
                                />}
                                <input autoComplete="off" role="presentation"
                                    id="contract_date"
                                    name="contract_date"
                                    type="date"
                                    value={fields && fields.contract_date_formatted ? fields.contract_date_formatted : fieldsContext.today()}
                                    readOnly={original && original.contract_date ? true : false}
                                    onChange={handleDateChange}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col">
                    <h3>
                        <span>
                            2
                            </span>
                            Данни за фирмата заявител
                        </h3>
                    <div className="holder">

                        <div className="row">
                            <div className="col able-to-edit">
                                <label htmlFor="company_name">
                                    Точно наименование на фирмата
                                </label>

                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.name}
                                    readOnly={true}
                                />

                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="company_eik">
                                    ЕИК / Булстат
                                </label>

                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.eik}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="company_phone">
                                    Тел. номер
                                </label>

                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.phone}
                                    // readOnly={editable && fields && fields.company && fields.company.phone ? true : false}
                                    readOnly={true}
                                />
                            </div>
                        </div>

                        <div className="row">

                            <div className="col able-to-edit">
                                <label htmlFor="company_town">
                                    Населено място
                                    </label>
                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.town
                                        ? fields.company.town.id
                                            ? `${fields.company.town.kind} ${fields.company.town.name}`
                                            : fields.company.town.name
                                        : ''}
                                    readOnly={true}
                                />

                            </div>

                            <div className="col able-to-edit">
                                <label htmlFor="company_street">
                                    Улица
                                    </label>
                                <input autoComplete="off" role="presentation"
                                    value={fields && fields.company && fields.company.street ? `${fields.company.street.suff} ${fields.company.street.name}` : ''}
                                    readOnly={true}
                                />

                            </div>

                            <div className="col">
                                <div className="row">
                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_street_number">
                                            №
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.company && fields.company.street_number}
                                            className="field-small"
                                            readOnly={true}
                                            min="0"
                                            step="1"
                                        />

                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_entrance">
                                            Вход
                                        </label>
                                        <input autoComplete="off" role="presentation"
                                            value={fields && fields.company && fields.company.entrance}
                                            className="field-small"
                                            readOnly={true}
                                        />

                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_block">
                                            Блок
                                            </label>

                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            className="field-small"
                                            value={fields && fields.company && fields.company.block}
                                            readOnly={editable && fields && fields.company && fields.company.block ? true : false}
                                            readOnly={true}
                                        />
                                    </div>

                                    <div className="col col-auto able-to-edit">
                                        <label htmlFor="company_floor">
                                            Етаж
                                            </label>

                                        <input autoComplete="off" role="presentation"
                                            className="field-small"
                                            value={fields && fields.company && fields.company.floor}
                                            // readOnly={editable && fields && fields.company && fields.company.floor ? true : false}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col">
                                <label htmlFor="company_mol">
                                    МОЛ
                                        </label>
                                <input autoComplete="off" role="presentation"
                                    id="company_mol"
                                    type="text"
                                    value={fields && fields.company && fields.company.mol}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col">

                            </div>

                            <div className="col">

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="row">

                <div className="col">
                    <h3>
                        <span>
                            3
                        </span>
                        Условия на договора
                    </h3>
                    <div className="holder">

                        <div className="row">

                            <div className="col">
                                <label htmlFor="license_area_size">
                                    Площ
                                </label>
                                <div className="extra-holder">
                                    <input autoComplete="off" role="presentation"
                                        id="license_area_size"
                                        type="text"
                                        className="field-small"
                                        value={fields && fields.license_area_size}
                                        readOnly
                                    />
                                    <span className="extra-label">
                                        кв.м.
                                </span>
                                    <input autoComplete="off" role="presentation" type="hidden" />
                                </div>
                            </div>


                            <div className="col col-auto">
                                <label htmlFor="">
                                    Срок
                                </label>
                                <div className="extra-holder">
                                    <span className="extra-label">
                                        От
                                    </span>
                                    <input autoComplete="off" role="presentation"
                                        type="date"
                                        className="field-medium"
                                        value={fields && fields.license_period_from_formatted}
                                        readOnly
                                    />
                                    <span className="extra-label">
                                        До
                                    </span>
                                    <input autoComplete="off" role="presentation"
                                        type="date"
                                        className="field-medium"
                                        value={fields && fields.license_period_to_formatted}
                                        readOnly
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col">
                                <label>Зона</label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields && fields.street_zone && fields.street_zone.text}
                                    readOnly
                                />
                            </div>
                            <div className="col">
                                <label>Наемна цена</label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields && fields.declaration_month_price}
                                    readOnly
                                />
                            </div>
                            <div className="col">
                                <label>Обща наемна цена за месец</label>
                                <input autoComplete="off" role="presentation"
                                    type="text"
                                    value={fields && fields.declaration_total_month_price}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col">
                    <SignPanel
                        panelName="contract_signature"
                        mayor={fields && fields.contractmayorsignature}
                        mayorInput="contract_signed_by_mayor"
                        mayorReason={fields && fields.contract_signed_by_mayor_reason}
                        accountant={fields && fields.contractaccountantsignature}
                        accountantInput="contract_signed_by_accountant"
                        prepared={fields && fields.contractpreparedsignature}
                        preparedInput="contract_prepared_by"
                        agreed={fields && fields.contract_commission_text}
                        agreedInput="contract_commission"
                        readOnly={original.closed}
                        handleModify={props.handleModify}
                    />
                </div>

            </div>

        </div>
    )
}

export default forwardRef(Fields)