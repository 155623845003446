import React, { useRef } from 'react'
import { Link } from 'react-router-dom'

//partials
import Application from './partials/shared/Application.js'
import FilesPanel from './partials/FilesPanel'

//images
import addIcon from '../../../../assets/img/pages/icons/add.png'

function Create() {

    const formRef = useRef(null)

    const handleCreate = e => {
        e.preventDefault()
    }

    return (
        <section>
            <div className="wrapper top negative-margin">
                <h1>
                    <img src={addIcon} alt="add" />
                    Създаване на заявление за места за получаване на удостоверение и табела
                </h1>
            </div>
            <div className="wrapper">
                <hr />
                <div className="main-holder">
                    <form autoComplete="off" role="presentation"  ref={formRef} onSubmit={handleCreate}>
                        <Application />
                        <hr />
                        <div className="buttons">
                            <Link
                                to="/tourism"
                                className="btn-default">
                                Отказ
                            </Link>
                            <button type="button" onClick={handleCreate}>
                                Запазване
                            </button>
                        </div>
                    </form>
                    <FilesPanel />
                </div>
            </div>
        </section>
    )
}

export default Create