import React from 'react'

//images
import deleteIcon from '../../assets/img/pages/icons/delete.png'

function Index(props) {
    return (
        <tr className={[props.example ? 'example' : '', props.hidden ? 'hidden' : ''].join(' ')}>
            <td>
                <input autoComplete="off" role="presentation" 
                    type="date"
                    name="prices[date][2][]"
                    defaultValue={props.data ? props.data.date_formatted : ''}
                />
            </td>
            <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input autoComplete="off" role="presentation" 
                        type="number"
                        min="0"
                        step="0.01"
                        name="prices[price][2][1][]"
                        defaultValue={props.data ? props.data.price : ''}
                        style={{ width: '100px' }}
                    />
                    <span style={{ marginLeft: '10px' }}>
                        лв.
                    </span>
                </div>
            </td>
            <td>
                <img
                    className="delete"
                    src={deleteIcon}
                    alt="delete"
                    onClick={e => props.handleRemovePrice(e)}
                />
            </td>
        </tr>

    )
}

export default Index;