import React, { useState, forwardRef, useEffect, useRef, useImperativeHandle } from 'react'

//partials
import Tabs from './partials/Tabs'
import Applicant from './partials/Applicant'
import BuildingData from './partials/BuildingData'
import EmployeesData from './partials/EmployeesData'
import Circumstances from './partials/Circumstances'
import Documents from './partials/Documents'

function Applicaiton(props, ref) {

    const [application, setApplication] = useState({
        currentTab: 1,
    })

    const key = new URLSearchParams(window.location.search).get('_') || 'key'
    const editable = props.editable || false;
    const original = props.original;
    const data = props.data;

    useImperativeHandle(ref, () => ({
        setCompany: data => setCompany(data),
        setCompanyId: id => setCompanyId(id),
        setObjectId: id => setObjectId(id)
    }))

    const fieldsData = {
        request: {},
        company: {},
        object: {},
        owner: {},
        worker: {},
        addr_same_as_company_addr: 1
    }

    const [fields, setFields] = useState(fieldsData)

    useEffect(() => {
        if (original) {
            setFields(original)
        }
    }, [original])


    const handleFieldChange = e => {

        let isObj = Boolean(e.target.name.includes('['))
        let name = e.target.name
        let val = e.target.value

        if (isObj) {
            let nameFirstPart = name.split('[')[0]
            let nameSecondPart = name.split('[')[1].split(']')[0]

            setFields(prev => ({
                ...prev,
                [nameFirstPart]: {
                    ...prev[nameFirstPart],
                    [nameSecondPart]: val,
                }
            }))
        } else {
            setFields(prev => ({
                ...prev,
                [name]: val
            }))
        }

    }

    const handleCurrentTab = tab => {
        setApplication(application => ({
            ...application,
            currentTab: tab,
        }))
        props.currentTab(tab)
    }

    const setCompany = newData => {
        setFields(prev => ({
            ...prev,
            company: newData
        }))
    }

    const setCompanyId = id => {
        setFields(prev => ({
            ...prev,
            company: {
                ...prev.company,
                id: id
            }
        }))
    }

    const setObjectId = id => {
        setFields(prev => ({
            ...prev,
            object: {
                ...prev.object,
                id: id
            }
        }))
    }

    return (
        <>
            <Tabs
                currentTab={handleCurrentTab}
                changeTab={props.changeTab}
                validateTabs={props.validateTabs}
            />

            <Applicant
                key={'a' + key}
                show={application.currentTab == 1}
                data={data}
                original={original}
                fields={fields}
                setFields={setFields}
                editable={editable}
                handleFieldChange={handleFieldChange}
            />
            <BuildingData
                key={'o' + key}
                show={application.currentTab == 2}
                data={data}
                original={original}
                fields={fields}
                setFields={setFields}
                editable={editable}
                handleFieldChange={handleFieldChange}
            />
            <EmployeesData
                key={'p' + key}
                show={application.currentTab == 3}
                data={data}
                original={original}
                fields={fields}
                setFields={setFields}
                editable={editable}
                handleFieldChange={handleFieldChange}
            />
            <Circumstances
                key={'c' + key}
                show={application.currentTab == 4}
                data={data}
                original={original}
                fields={fields}
                setFields={setFields}
                editable={editable}
                handleFieldChange={handleFieldChange}
            />
            <Documents
                key={'d' + key}
                show={application.currentTab == 5}
                data={data}
                original={original}
                fields={fields}
                setFields={setFields}
                editable={editable}
                handleFieldChange={handleFieldChange}
            />
        </>
    )
}

export default forwardRef(Applicaiton)