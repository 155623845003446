import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import Api from '../../../../../../helpers/Api'

//context
import { useStepsContext } from '../../../../../../contexts/StepsContext'

//partials
import Fields from './partials/Fields'
import FilesPanel from '../../../../../partials/FilesPanel'

//images
import protocolIcon from '../../../../../../assets/img/pages/icons/protocol.png'
import loader from '../../../../../.././assets/img/app/loader.svg'

//modals
import SuccessModal from '../../../../../modals/messages/Success'
import Attention from '../../../../../modals/messages/Attention'
import { useAuthContext } from '../../../../../../contexts/AuthContext'

function Index(props) {
    const history = useHistory()
    const auth = useAuthContext()

    //refs
    const formRef = useRef(null)
    const SuccessModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const data = props.data
    const original = props.original
    const setData = props.setData
    const setOriginal = props.setOriginal
    const loading = props.loading
    const editable = props.editable || false

    const [application, setApplication] = useState({
        position: 4,
        //Трябва да се определя от това дали вече има данни или не 
        finished: false,
        loading: false,
        modified: false,
    })

    const key = new URLSearchParams(window.location.search).get('_') || 'key'


    useEffect(() => {
        let form = document.querySelector('.main-holder form');

        if (form) {
            form.addEventListener('change', fieldsListener);
        }

        return () => {
            if (form) {
                form.removeEventListener('change', fieldsListener)
            }
        }

    }, [])

    useLayoutEffect(() => {
        if (!auth.canWriteDocuments() || data.closed) {
            let form = document.querySelector('.main-holder form');

            if (form) {
                setTimeout(() => {
                    form.querySelectorAll('input').forEach(i => {
                        i.setAttribute('readonly', true);
                    })
                    form.querySelectorAll('img.edit').forEach(i => {
                        i.style.display = 'none';
                    })
                    form.querySelectorAll('.MuiSelect-root').forEach(i => {
                        i.classList.add('disabled');
                    })
                })
            }

        }
    }, [])

    const fieldsListener = () => {
        if (!application.modified) {
            handleModify()
        }
    }

    //refs
    const fieldsRef = useRef(null)

    const handleConfig = e => {
        e.preventDefault()

        let form = formRef.current;

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form)

        setApplication(prev => ({ ...prev, loading: true }))

        // id на процедурата
        data.append('id', props.data.id)
        data.append('trade_object_procedure_id', original.trade_object_procedure_id)

        Api.post('procedures/outdoortrade/addedit', data)
            .then(res => {
                SuccessModalRef.current.open()

                setApplication(prev => ({ ...prev, loading: false }))

                if (res.data.id) {
                    setOriginal(res.data)
                    history.push('?_=' + new Date().getTime())
                }
            }).catch(err => {
                if (err.response.status === 422) {
                    attentionModalRef.current.open(err.response.data.error)

                    let fields = err.response.data.fields;

                    if (typeof fields === 'object') {
                        Object.entries(fields).map(i => {
                            if (i[1] && typeof i[1] === 'object') {
                                Object.entries(i[1]).map(j => {
                                    if (j[1]) {
                                        let name = `${i[0]}[${j[0]}]`;

                                        let inputs = form.querySelectorAll("[name='" + name + "']");

                                        inputs.forEach(i => {
                                            i.classList.add('validate');
                                        });
                                    }
                                })
                            } else {
                                if (i[1]) {
                                    let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            }
                        });
                    }


                } else {
                    errorModalRef.current.open()
                }
            }).finally(() => {
                handleCancelModify()
            })
    }

    const handleModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: true
        }))
    }

    const handleCancelModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: false,
            loading: false
        }))
    }

    return (
        !loading && original && original.id &&
        <>
            <SuccessModal
                ref={SuccessModalRef}
                mainMessage='Декларацията беше запазена успешно!'
                secondaryMessage='Моля, попълнете всички полета, за да генерирате документа.'
                canGenerate={data.can_generate_declaration}
            />

            <Attention
                ref={attentionModalRef}
                mainMessage='Моля, попълнете всички полета!'
                secondaryMessage="За да запазите декларацията, трябва да попълните всички полета."
            />

            <Attention
                ref={errorModalRef}
                mainMessage='Нещо се обърка!'
            />

            <h1 className="config-heading">
                <img src={protocolIcon} alt="protocol" />
                Съставяне на декларация
            </h1>
            <hr />

            <div className="main-holder">
                <form autoComplete="off" role="presentation" ref={formRef} onSubmit={handleConfig}>
                    <Fields
                        key={key}
                        ref={fieldsRef}
                        loading={props.loading}
                        data={props.data}
                        original={props.original}
                        editable={true}
                        setData={props.setData}
                        setOriginal={props.setOriginal}
                        handleModify={handleModify}
                    />
                    <hr />
                    <div className="buttons">
                        {!data.closed && auth.canWriteDocuments()
                            ?
                            application.loading ?
                                <img src={loader} alt="loading" />
                                :
                                application.modified ?
                                    <>
                                        <Link
                                            to={"/outlets/config/outdoor/" + props.original.id + '?_=' + new Date().getTime()}
                                            className="btn-default"
                                            onClick={handleCancelModify}
                                        >
                                            Отказ
                                        </Link>
                                        <button type="button" onClick={handleConfig}>
                                            Запази
                                        </button>
                                    </>
                                    :
                                    ''
                            :
                            ''
                        }
                    </div>
                </form>

                <FilesPanel
                    id={original.procedure_id}
                    generateFile={data.can_generate_declaration}
                    type="declaration"
                    allowEdit={!data.closed && auth.canWriteDocuments()}
                    uploadedDocument={data.uploaded_signed_declaration}
                    refreshData={props.refreshData}
                />

            </div>

        </>
    )
}

export default Index