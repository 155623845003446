import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import Api from '../../../../../helpers/Api'
import { Link, useHistory } from 'react-router-dom'

//context
import { useFieldsContext } from '../../../../../contexts/FieldsContext'

//partials
import FilesPanel from '../../../../partials/FilesPanel'
import Fields from './Fields'

//images
import protocolIcon from '../../../../../assets/img/pages/icons/protocol.png'
import loader from '../../../../../assets/img/app/loader.svg'

//modals
import SuccessModal from '../../../../modals/messages/Success'
import Attention from '../../../../modals/messages/Attention'
import { useAuthContext } from '../../../../../contexts/AuthContext'

function Index(props) {

    const history = useHistory()
    const auth = useAuthContext()

    //refs
    const appRef = useRef(null)
    const SuccessModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const errorModalRef = useRef(null)

    const data = props.data
    const original = props.original
    const setData = props.setData
    const setOriginal = props.setOriginal
    const loading = props.loading
    const editable = props.editable || false
    const type = original.type.relation

    const fieldsContext = useFieldsContext()

    const [application, setApplication] = useState({
        position: 1,
        loading: false,
        modified: false,
    })

    const key = new URLSearchParams(window.location.search).get('_') || 'key'

    useEffect(() => {
        let form = document.querySelector('.main-holder form');

        if (form) {
            form.addEventListener('change', fieldsListener);
        }

        return () => {
            if (form) {
                form.removeEventListener('change', fieldsListener)
            }
        }

    }, [])

    useLayoutEffect(() => {
        if (!auth.canWriteRequest()) {
            let form = document.querySelector('.main-holder form');

            if (form) {
                setTimeout(() => {
                    form.querySelectorAll('input').forEach(i => {
                        i.setAttribute('readonly', true);
                    })
                    form.querySelectorAll('img.edit').forEach(i => {
                        i.style.display = 'none';
                    })
                    form.querySelectorAll('.MuiSelect-root').forEach(i => {
                        i.classList.add('disabled');
                    })
                })
            }

        }
    }, [])

    const fieldsListener = () => {
        if (!application.modified) {
            handleModify()
        }
    }

    const handleConfig = e => {
        e.preventDefault()

        let form = document.getElementById('application');

        setApplication(application => ({ ...application, loading: true }))

        form.querySelectorAll('.validate').forEach(i => {
            i.classList.remove('validate');
        });

        let data = new FormData(form)

        // id на процедурата
        data.append('id', props.data.id)

        let url;

        switch (type) {
            case 'tradeobject':
                url = '/procedures/tradeobjects/addedit';
                break;
            case 'outdoortrade':
                url = '/procedures/outdoortrade/addedit';
                break;
            case 'temptrade':
                url = '/procedures/temptrade/addedit';
                break;

        }

        Api.post(url, data)
            .then(res => {
                SuccessModalRef.current.open()

                if (res.data.id) {
                    props.refreshData();
                    history.push('?_=' + new Date().getTime())
                }
            }).catch(err => {
                if (err.response.status === 422) {
                    attentionModalRef.current.open(err.response.data.error)

                    let fields = err.response.data.fields;

                    Object.entries(fields).map(i => {
                        if (i[1] && typeof i[1] === 'object') {
                            Object.entries(i[1]).map(j => {
                                if (j[1]) {
                                    let name = `${i[0]}[${j[0]}]`;

                                    let inputs = form.querySelectorAll("[name='" + name + "']");

                                    inputs.forEach(i => {
                                        i.classList.add('validate');
                                    });
                                }
                            })
                        } else {
                            if (i[1]) {
                                let inputs = form.querySelectorAll("[name='" + i[0] + "']");

                                inputs.forEach(i => {
                                    i.classList.add('validate');
                                });
                            }
                        }
                    });
                } else {
                    errorModalRef.current.open()
                }
            }).finally(() => {
                handleCancelModify()
            })

    }

    const handleModify = () => {
        setApplication(prev => ({
            ...prev,
            modified: true
        }))
    }

    const handleCancelModify = () => {

        history.push('?_=' + new Date().getTime());

        setApplication(prev => ({
            ...prev,
            modified: false,
            loading: false
        }))
    }

    return (
        <>
            {!loading && original && original.id &&
                <>

                    <SuccessModal
                        ref={SuccessModalRef}
                        mainMessage='Данните бяха запазени успешно!'
                        canGenerate={data.can_generate_letter}
                    />

                    <Attention
                        ref={attentionModalRef}
                        mainMessage='Моля, попълнете всички полета!'
                        secondaryMessage="За да запазите данните, трябва да попълните всички полета."
                    />

                    <Attention
                        ref={errorModalRef}
                        mainMessage='Нещо се обърка!'
                    />

                    <h1 className="config-heading">
                        <img src={protocolIcon} alt="protocol" />
                        Писмо
                    </h1>
                    <hr />
                    <div className="main-holder">
                        <form autoComplete="off" role="presentation" id="application" onSubmit={handleConfig}>

                            <Fields
                                ref={appRef}
                                // key={key}
                                data={data}
                                original={original}
                                editable={editable}
                                setData={setData}
                                handleModify={handleModify}
                            />

                            <hr />
                            <div className="buttons">
                                {application.loading
                                    ?
                                    <img src={loader} alt="loading" />
                                    :
                                    application.modified ?
                                        <>
                                            <button
                                                className="btn-default"
                                                onClick={handleCancelModify}
                                            >
                                                Отказ
                                            </button>
                                            <button type="button" onClick={handleConfig}>
                                                Запази
                                            </button>
                                        </>
                                        :
                                        ''
                                }
                            </div>
                        </form>
                        <FilesPanel
                            id={original.procedure_id}
                            generateFile={data.can_generate_letter}
                            type="letter"
                            allowEdit={true}
                            uploadedDocument={data.uploaded_signed_letter}
                            refreshData={props.refreshData}
                        />
                    </div>
                </>
            }
        </>
    )
}

export default Index