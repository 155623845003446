export const Texts = {
    companyValidation: "Фирмата, която сте избрали вече съществува, но въведените данни в някои от полетата не съвпадат. Желаете ли да презапишете данните?",
    ownerValidation: "Собственикът, който сте избрали вече съществува, но въведените данни в някои от полетата не съвпадат. Желаете ли да презапишете данните?",
    personValidation: "Лицето, което сте избрали вече съществува, но въведените данни в някои от полетата не съвпадат. Желаете ли да презапишете данните?",

    filesPanel: {
        application: {
            generate: "Генериране на заявление",
            upload: "Прикачване на подписано заявление"
        },
        permission: {
            generate: "Генериране на разрешение",
            upload: "Прикачване на разрешение"
        },
        protocol: {
            generate: "Генериране на протокол",
            upload: "Прикачване на протокол"
        },
        declaration: {
            generate: "Генериране на декларация",
            upload: "Прикачване на декларация"
        },
        contract: {
            generate: "Генериране на договор",
            upload: "Прикачване на договор"
        },
        request: {
            generate: "Генериране на молба",
            upload: "Прикачване на молба"
        },
        order: {
            generate: "Генериране на заповед",
            upload: "Прикачване на заповед"
        },
        openingorder: {
            generate: "Генериране на заповед",
            upload: "Прикачване на заповед"
        },
        certificate: {
            generate: "Генериране на временно удостоверение",
            upload: "Прикачване на временно удостоверение"
        },
        findingsprotocol: {
            generate: "Генериране на констативен протокол",
            upload: "Прикачване на констативен протокол"
        },
        suggestion: {
            generate: "Генериране на предложение",
            upload: "Прикачване на предложение"
        },
        letter: {
            generate: "Генериране на писмо",
            upload: "Прикачване на писмо"
        }
    },


    obligations: {
        default_position_text: "<p style='text-indent:40px;'>Във връзка с работна инструкция за документ \"Удостоверение за наличие или липса на задължения към Община Велико Търново\" и предвид предстоящо сключване на договор е необходимо да бъде издаден 1 (един) брой оригинал - обикновена поръчка - на:</p>",
    },

    validations: {
        procedure_is_closed: {
            main: "Процедурата е прекратена!",
            secondary: ""
        },
        empty_fields: {
            main: "Имате невъведени данни!",
            secondary: "За да продължите напред, трябва да ги попълните."
        },
        company_is_missing: {
            main: "Данните за фирмата са задължителни",
            secondary: "За да продължите напред, трябва да ги попълните."
        },
        object_is_missing: {
            main: "Данните за обекта са задължителни",
            secondary: "За да продължите напред, трябва да ги попълните."
        },
        work_time_empty: {
            main: "Полетата за роботно време са задължителни!",
            secondary: "За да продължите напред, трябва да ги попълните."
        },
        required_name: {
            main: "Полето име е задължително!"
        },
        required_email: {
            main: "Полето е-поща е задължително!"
        },
        invalid_email: {
            main: "Полето е-поща трябва да съдържа валидна е-поща!"
        },
        unique_email: {
            main: "Тази е-поща вече се използва!"
        },
        required_username: {
            main: "Полето потребителско име е задължително!"
        },
        unique_username: {
            main: "Това потребителско име вече се използва от друг потребител!"
        },
        required_password: {
            main: "Полето парола е задължително!"
        },
        min_length_password: {
            main: "Паролата е прекалено къса!"
        },
        confirmed_password: {
            main: "Моля, повторете паролата правилно!"
        },
        required_position_name: {
            main: "Полето позиция е задължително!"
        },
    }
};